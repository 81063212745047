<template>
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <button-control></button-control>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12">
        <output-voucher-delivery></output-voucher-delivery>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import DataViewer from '../../components/DataViewer';
import ButtonControl from './components/ButtonControl';
import OutputVoucherDelivery from './components/OutputVoucherDelivery';

export default {
    name: 'dashboardIndex',
    components: {
      VuejsDialog, DataViewer, ButtonControl, OutputVoucherDelivery
    },
    data() {
        return {
          title: 'Quản lý bán hàng',
          data: ''
        }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
    },
    methods: {
    
    }
}
</script>