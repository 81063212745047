<template>
<!-- info-box -->
<div class="info-box pull-left">
    <span class="info-box-icon">
        <!-- <img :src="data.images[0].link_image"> -->
        <v-lazy-image :src="data.link_image" />
    </span>

    <div class="info-box-content row">
        <div class="col-md-12 col-xs-12">
            <div class="row">
                <div class="col-md-12">
                    <span class="info-box-text text-bold">
                        {{data.id}} - {{data.name}}
                        <span class="label label-success pull-right" v-if="data.product_count == data.product_count_ok">SOẠN ĐỦ {{data.product_count}}</span>
                        <span class="label label-warning pull-right" v-else>ĐÃ SOẠN {{data.product_count_ok}}/{{data.product_count}}</span>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <span>Vị trí: {{data.location}}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <a class="btn btn-xs btn-success btn-block btn-flat" @click="open">
                        Chi tiết sản phẩm
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- /.info-box-content -->

    <!-- Soạn hàng -->
    <SoanHangVourcher @changeddata="$emit('changeddata')" 
        ref="SoanHangVourcher"
        :data="vourcher"
        :model_id="data.id">
    </SoanHangVourcher>
    <!-- ./Soạn hàng -->
</div>
<!-- /.info-box -->
</template>

<script>
import VLazyImage from "v-lazy-image";
import SoanHangVourcher from './SoanHangVourcher.vue'
export default {
    name: 'SoanHangModelView',
    components: {VLazyImage, SoanHangVourcher},
    props: {
        data: {
          type: Object
        },
        vourcher: {
          type: Array
        }
    },
    data() {
        return {

        }
    },
    methods: {
        open(){
            this.$refs.SoanHangVourcher.open()
        }
    }
}
</script>
<style lang="css" scoped>
.info-box-icon>img {
    width: 100%;
    max-height: 90px;
    vertical-align: unset;
}
.info-box-content{
    padding: 0px;
}
.info-box-text{
    white-space: unset;
}
.info-box {
    padding-bottom: 10px;
}
</style>