<template>
    <div class="row">
        <div class="col-md-3 col-xs-4" v-for="item in uploadData" :key="item[keyUrl]">
            <div class="thumbnail">
                <img :src="item[keyUrlFull]" class="imagelist">
                <div class="caption">
                    <button type="button" 
                        class="btn-xs btn-success btn-block"
                        v-on:click="SelectedImage(item)">
                        {{SelectedText}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- /.row -->
</template>

<script>
import axios from 'axios'

    export default {
        name: 'SelectedImage',
        props: {
            SelectedText: {
                type: String,
                default: 'Chọn'
            },
            keyID: {
                type: String,
                default: 'id'
            },
            keyUrl: {
                type: String,
                default: 'url'
            },
            keyUrlFull: {
                type: String,
                default: 'urlfull'
            },
            data: {
                type: Array
            },
        },
        data() {
            return {
                uploadData: []
            }
        },
        watch: {
            data() {
                this.uploadData = this.data
            }
        },
        methods: {
            SelectedImage(item){
                this.$emit('SelectedImage', item)
            }
        }
    }
</script>
<style lang="css" scoped>
.imagelist{
        height: 115px;
    }
@media (max-width:767px){
    .imagelist{
        height: 70px;
    }
}
</style>