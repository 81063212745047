export default {
    id: '',
    name: '',
    phone: '',
    phone_text: '',
    website: '',
    facbook: '',
    address: '',
    shopmanagername: '',
    status: 1
}
