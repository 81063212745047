export default {
    id: '',
    category_model_id: 0,
    name: 'Đồ bộ cát hàn',
    content: '',
    location: '',
    status: 1,
    shop_prices:[],
    images:[],
    products: []
}
