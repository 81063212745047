<template>
  <div>
     <form role="form" @submit.prevent="save">
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="form-group" :class="{'has-error': formErrors.name}">
              <label class="control-label" for="name">Tên</label>
              <input type="text" class="form-control" id="name" name="name" v-model="form.name">
              <span class="help-block" v-if="formErrors.name">{{formErrors.name[0]}}</span>
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <div class="form-group" :class="{'has-error': formErrors.nickname}">
              <label class="control-label" for="nickname">Nick Name</label>
              <input type="text" class="form-control" id="nickname" name="nickname" v-model="form.nickname">
              <span class="help-block" v-if="formErrors.nickname">{{formErrors.nickname[0]}}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="form-group" :class="{'has-error': formErrors.phone}">
              <label class="control-label" for="phone">Điện thoại</label>
              <input type="text" 
                  class="form-control"
                  id="phone" 
                  name="phone" 
                  v-model="form.phone" 
                  maxlength="20"
                  autocomplete = "off">
              <span class="help-block" v-if="formErrors.phone">{{formErrors.phone[0]}}</span>
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <div class="form-group" :class="{'has-error': formErrors.type}">
              <label class="control-label" for="type">Loại khách hàng:</label>
              <select class="form-control" id="type" name="type" v-model="form.type">
                  <option value="0">Khách lẻ</option>
                  <option value="1">Khách sỉ</option>
                  <option value="2">Nhà cung cấp</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group" :class="{'has-error': formErrors.province_id}">
            <label class="control-label" for="province_id">Địa chỉ</label>
            <div class="input-group">
                <input type="text" 
                v-model="addressText"
                class="form-control" 
                id="province_id" 
                name="province_id" 
                readonly
                placeholder="Chọn địa chỉ...">
                <span class="input-group-btn">
                    <button type="button" 
                      class="btn btn-default btn-flat"
                      @click="showAddressSelected()"><i class="glyphicon glyphicon-home"></i>
                    </button>
                </span>
            </div>
            <AddressSelected ref="AddressSelected"
              @selected="AddressSelected">
            </AddressSelected>
            <span class="help-block" v-if="formErrors.province_id">{{formErrors.province_id[0]}}</span>
        </div>

        <div class="form-group" :class="{'has-error': formErrors.address}">
            <label class="control-label" for="address">Địa chỉ đầy đủ</label>
            <textarea class="form-control" 
              rows="2" id="address" 
              name="address" 
              v-model="form.address"></textarea>
            <span class="help-block" v-if="formErrors.address">{{formErrors.address[0]}}</span>
        </div>
        <div class="form-group" :class="{'has-error': formErrors.status_warning}">
            <label class="control-label" for="status_warning">Tình trạng cảnh báo</label>
            <select v-model="form.status_warning" 
                id="status_warning" 
                name="status_warning" 
                class="form-control">
                <option value="0">Bình thường</option>
                <option value="1">Cảnh báo</option>
                <option value="2">Không bán</option>
            </select>
            <span class="help-block" v-if="formErrors.status_warning">{{formErrors.status_warning[0]}}</span>
        </div>
        <div class="form-group">
            <label class="control-label" for="description">Mô tả:</label>
            <textarea class="form-control" rows="2" id="description" name="description" v-model="form.description"></textarea>
        </div>
        <div class="form-group">
          <label class="control-label" for="status">Trạng thái:</label>
          <switches v-model="form.status" name="status" id="status" type-bold="true" theme="bootstrap" color="success"></switches>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary pull-right">Cập nhật</button>
        </div>
     </form>
  </div>
</template>

<script>
  import axios from 'axios'
  import Switches from '../../components/Switches';
  import AddressSelected from '../../components/AddressSelected.vue';
  import customer from '../../objects/customer'

  export default {
    name: 'formCustomer',
    components: {Switches, AddressSelected},
    props: {
      clickSubmit: {
        type: Function,
        default: () => { }
      }
    },
    data() {
      return {
        formErrors: {},
        isEdit:false,
        form: {},
        addressText: ''
      }
    },
    computed: {

    },
    methods: {
      open(id){
        this.formErrors = {}
        if(typeof id != 'undefined'){
          this.isEdit = true
          this.getCustomer(id)
        }
        else{
          this.isEdit = false
          this.form = Object.assign({}, customer);
        }
      },
      save() {
        var vm = this
        if(!this.isEdit){
          axios['post']('/api/customer/insert', this.form)
          .then(function (response) {
              vm.clickSubmit()
              vm.$snotify.success('Thêm mới thành công!', 'Success')
          })
          .catch(function (error) {
              vm.formErrors = error.response.data.errors
          })
        }
        else{
          axios['put']('/api/customer/update', this.form)
          .then(function (response) {
              vm.clickSubmit()
              vm.$snotify.success('Cập nhật thành công!', 'Success')
          })
          .catch(function (error) {
              vm.formErrors = error.response.data.errors
          })
        }
        
      },
      getCustomer(id){
        var vm = this
        axios.get('/api/customer/select/' + id)
            .then(function(response) {
                vm.form = response.data.data
                vm.addressText = vm.fullAddress()
            })
            .catch(function(error) {
                console.log(error)
            })
      },
      showAddressSelected(){
        this.$refs.AddressSelected.open();
      },
      AddressSelected(result){
        this.form.province_id = result.province.id
        this.form.province = result.province
        this.form.district_id = result.district.id
        this.form.district = result.district
        this.form.ward_id = result.ward.id
        this.form.ward = result.ward
        this.form.street = result.address
        this.form.address = this.fullAddress()
        this.addressText =  this.form.address
      },
      fullAddress(){
        var AddressTemp = ''
        AddressTemp = this.form.street + ', '
        AddressTemp += this.form.ward.name + ', '
        AddressTemp += this.form.district.name  + ', '
        AddressTemp +=this.form.province.name
        return AddressTemp
      }
    }
  }
</script>