<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-header -->
          <div class="box-header">
            <div class="row">
              <div class="col-xs-6 col-md-2">
                <div class="form-group">
                  <label class="control-label" for="payment_confirm_date">Đến ngày</label>
                  <date-picker 
                    class="form-control"
                    :config="configDate" 
                    v-model="params.payment_confirm_date">
                  </date-picker>
                </div>
              </div>

              <div class="col-xs-6 col-md-4">
                <div class="form-group">
                  <label class="control-label" for="keyword">Từ khóa</label>
                  <input type="text" class="form-control" 
                  v-model="params.keyword" 
                  v-on:keyup.enter="seach"
                  placeholder="Nhập từ khóa...">
                </div>
              </div>

              <div class="col-xs-12 col-md-3">
                <div class="form-group">
                  <label class="control-label" for="payment_partner_id">Cộng tác viên</label>
                  <Select2 :id="'payment_partner_id'" 
                    :name="'payment_partner_id'" 
                    :dataUrl="'/api/ctv/partner/all'"
                    :keyID="'partner_id'"
                    :keyName="'partner_name'"
                    v-model="params.payment_partner_id"
                    :allowClear="true"
                    :placeholder="'Chọn CTV'"
                    :multiple="false">
                  </Select2>
                </div>
              </div>

              <div class="col-xs-12 col-md-3">
                  <div class="form-group">
                      <label class="control-label" for="payment_status_confirm">Trạng thái</label>
                      <select v-model="params.payment_status_confirm" 
                          id="payment_status_confirm" 
                          name="payment_status_confirm" 
                          class="form-control">
                          <option v-for="option in statusData" :key="option.id" v-bind:value="option.id">
                              {{ option.name }}
                          </option>
                      </select>
                  </div>
              </div>
            
              <div class="col-xs-12 col-md-12">
                <div class="form-group">
                  <button type="button" class="btn btn-default btn-flat" v-on:click="seach"><i class="fa fa-fw fa-search"></i> Tìm kiếm</button>
                  <button type="button" class="btn btn-primary btn-flat" v-on:click="createPayment"><i class="fa fa-fw fa-money"></i> Tạo thanh toán</button>
                </div>
              </div>
            </div>
          </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <div class="row" v-if="data.length>0">
              <div class="col-xs-6 col-md-3">
                <span class="text-bold">Tổng SL đơn: {{total_count}}</span>
              </div>
              <div class="col-xs-6 col-md-3">
                <span class="text-bold">Tổng tiền thu khách: {{payment_total_money_sum | numFormat}}</span>
              </div>
              <div class="col-xs-6 col-md-3">
                <span class="text-bold">Tổng tiền hàng shop: {{payment_total_cost_sum | numFormat}}</span>
              </div>
              <div class="col-xs-6 col-md-3">
                <span class="text-bold">Tổng vận chuyển đi: {{payment_fee_sum | numFormat}}</span>
              </div>
              <div class="col-xs-6 col-md-3">
                <span class="text-bold">Tổng vận chuyển hoàn: {{payment_fee_return_sum | numFormat}}</span>
              </div>
              <div class="col-xs-6 col-md-3">
                <span class="text-bold text-red">Tổng tiền CTV nhận: {{totalCTV_sum | numFormat}}</span>
              </div>
              <div class="col-xs-6 col-md-3">
                <span class="text-bold text-red">Tổng CK giới thiệu: {{payment_broker_discount_sum | numFormat}}</span>
              </div>
            </div>

            <data-viewer :thead="thead" :data="data">
              <template slot-scope="props" slot='outputvourcher_info'>
                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Shop: </span>{{props.item.outputvourcher.shop.name}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">CTV: </span>{{props.item.payment_partner.partner_name}} - {{props.item.payment_partner.partner_phone}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Mã đơn: </span>
                  <router-link
                    :to="'/outputvourcher/show/'+props.item.outputvourcher.id">
                    {{props.item.outputvourcher.id}}
                  </router-link>
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Ngày tạo: </span>{{props.item.outputvourcher.created_at}}
                </div>
                
                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Mã VĐ: </span>{{props.item.outputvourcher.shipment_id}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Trạng thái: </span>
                  <span class="label label-info" v-if="(props.item.outputvourcher.delivery_type === 1 || props.item.outputvourcher.delivery_type === 2) && props.item.outputvourcher.status === 1">Đã giao tại shop</span>
                  <span class="label label-info" v-if="props.item.outputvourcher.delivery_type === 3 && props.item.outputvourcher.status === 1">Đã gửi bưu điện</span>
                  <span class="label label-info" v-if="(props.item.outputvourcher.delivery_type === 0 || props.item.outputvourcher.delivery_type === 4 || props.item.outputvourcher.delivery_type === 5) && props.item.outputvourcher.status === 1">{{props.item.outputvourcher.shipment_status_text}}</span>
                  <!-- <span class="label label-info" v-if="props.item.outputvourcher.delivery_type === 5 && props.item.outputvourcher.status === 1">Đã gửi JTEXPRESS</span> -->
                </div>
                
                
                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Trạng thái giao: </span>  
                  <span class="label label-success" v-if="props.item.outputvourcher.payment_status === 1">Giao thành công</span>
                  <span class="label label-default" v-else-if="props.item.outputvourcher.payment_status === 0">Chưa giao</span>
                  <span class="label label-warning" v-else-if="props.item.outputvourcher.payment_status === 2">Giao 1 phần</span>
                  <span class="label label-danger" v-else-if="props.item.outputvourcher.payment_status === 3">Trả về</span>
                  <span class="label label-danger" v-else>Không xác định</span> 
                </div>
                
                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Tên KH: </span>{{props.item.outputvourcher.customer_name}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">ĐT KH: </span>{{props.item.outputvourcher.customer_phone}}
                </div>
                
                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Tiền thu khách: </span>{{parseFloat(props.item.outputvourcher.total_payment) | numFormat}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">ĐC KH: </span>{{props.item.outputvourcher.customer_address}}
                </div> 
              </template>

              <template slot-scope="props" slot='outputvourcher_payment'>
                <div class="col-xs-12 col-md-12" v-if="props.item.payment_note">
                  <span class="text-bold">Ghi chú xác nhận: </span><span class="text-bold text-red">{{props.item.payment_note}}</span>
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Tổng SL trên đơn: </span>{{props.item.payment_total_count}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Tổng SL xác nhận: </span>{{props.item.payment_total_countconfirm}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Tổng thu khách: </span>{{parseFloat(props.item.payment_total_money) | numFormat}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Tổng tiền hàng: </span>{{parseFloat(props.item.payment_total_cost) | numFormat}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Ship gửi: </span>{{parseFloat(props.item.payment_fee) | numFormat}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Ship hoàn: </span>{{parseFloat(props.item.payment_fee_return) | numFormat}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Người xác nhận: </span>{{props.item.payment_user.name}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Ngày xác nhận: </span>{{props.item.payment_confirm_date}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Chiết khấu giới thiệu: </span> <span class="text-bold text-red">{{parseFloat(props.item.payment_broker_discount) | numFormat}} </span>
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Tiền thực tế CTV: </span> <span class="text-bold text-red">{{parseFloat(props.item.payment_total_money - props.item.payment_total_cost - props.item.payment_fee - props.item.payment_fee_return) | numFormat}} </span>
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="label label-success" v-if="props.item.payment_status === 1">Đã giao thành công</span>
                  <span class="label label-info" v-else-if="props.item.payment_status === 2">Giao 1 phần</span>
                  <span class="label label-warning" v-else-if="props.item.payment_status === 3">Trả về</span>
                </div>
              </template>

               <template slot-scope="props" slot='outputvourcher_control'>
                <div class="col-xs-12 col-md-12" v-if="props.item.payment_status_confirm === 0">
                    <button type="button" @click="remove(props.item.outputvourcher_id)" class="btn btn btn-danger pull-right"> <i class="fa fa-trash"></i> Xóa</button>
                </div>
               </template>
            </data-viewer>
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>

     <!-- Modal Xác nhận thông tin thanh toán-->
    <div class="modal fade in" :id="'modal-form-payment'" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Xác nhận thông tin thanh toán</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xs-12 col-md-12" v-if="data[0]">
              <span class="text-bold">CTV: </span>{{data[0].payment_partner.partner_name}} - {{data[0].payment_partner.partner_phone}}
            </div>

            <div class="col-xs-12 col-md-12">
              <span class="text-bold">Tổng số đơn: </span>{{parseFloat(dataPayment.paymentvourcher_total_count) | numFormat}} (đơn)
            </div>

            <div class="col-xs-12 col-md-12">
              <span class="text-bold">Tổng tiền tính được: </span><span class="text-bold text-red">{{parseFloat(dataPayment.paymentvourcher_total_amount) | numFormat}} (VNĐ) </span>
            </div>

            <div class="col-md-12 col-xs-12">
              <div class="form-group" :class="{'has-error': formErrors.paymentvourcher_total_money}">
                <label class="control-label" for="paymentvourcher_total_money">Tiền thực tế chi:</label>
                <input type="number" class="form-control"
                    id="paymentvourcher_total_money" 
                    name="paymentvourcher_total_money"
                    v-model="dataPayment.paymentvourcher_total_money">
                <span class="help-block" v-if="formErrors.paymentvourcher_total_money">{{formErrors.paymentvourcher_total_money[0]}}</span>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-xs-12 col-md-12">
              <div class="form-group" :class="{'has-error': formErrors.paymentvourcher_note}">
                  <label class="control-label" for="paymentvourcher_note">Nội dung thanh toán:</label>
                  <textarea class="form-control" 
                    rows="2" id="paymentvourcher_note" 
                    name="paymentvourcher_note" 
                    v-model="dataPayment.paymentvourcher_note"></textarea>
                  <span class="help-block" v-if="formErrors.paymentvourcher_note">{{formErrors.paymentvourcher_note[0]}}</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-md-12">
              <div class="form-group">
                  <button type="button" class="btn btn-primary btn-block" :disabled="enableButtonSave" @click="processPayment()">Cập nhật</button>
              </div>
            </div>
          </div>
            
        </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->

  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import DataViewer from '../../../components/DataViewer'
import Select2 from '../../../components/Select2New.vue'
import paymentvourcher from '../../../objects/ctv/paymentvourcher'

import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'
import datePicker from 'vue-bootstrap-datetimepicker'

export default {
    name: 'OutputvourcherPaymentSeachIndex',
    components: {
      VuejsDialog, DataViewer, Select2, datePicker
    },
    data() {
        return {
          title: 'Thông tin đơn đã xác nhận CTV',
          enableButtonSave: false,
          formErrors: {},
          data:[],
          dataPayment: {},
          params_confirm: {
              keyword: '',
              payment_partner_id: '',
              payment_confirm_date:'',
              payment_status_confirm: 0
          },
          params: {
              keyword: '',
              payment_partner_id: '',
              payment_confirm_date:'',
              payment_status_confirm: 0
          },
          configDate: {
            format: 'DD/MM/YYYY',
            useCurrent: false,
            showClear: false,
            showClose: true,
            allowInputToggle: true,
          },
          statusData:[
            {
              id: 0,
              name: 'Chưa tạo yêu cầu thanh toán'
            },
            {
              id: 1,
              name: 'Đã tạo yêu cầu thanh toán'
            }
          ],
          thead: [
            {
              key: 'outputvourcher_info',
              title: 'Thông tin đơn',
              class: 'col-xs-12 col-md-6'
            },
            {
              key: 'outputvourcher_payment',
              title: 'Thông tin xác nhận',
              class: 'col-xs-12 col-md-5'
            },
            {
              key: 'outputvourcher_control',
              title: '',
              class: 'col-xs-12 col-md-1'
            }
          ]
        }
    },
    computed: {
      total_count: function () {
        var countRow = 0
        if(this.data){
          countRow = this.data.length
        }
        return countRow
      },
      payment_total_money_sum: function () {
        var amount_sum = 0
        if(this.data){
          for (var i = 0; i < this.data.length; i++) {
            amount_sum += parseFloat(this.data[i].payment_total_money)
          }
        }
        return amount_sum
      },
      payment_total_cost_sum: function () {
        var amount_sum = 0
        if(this.data){
          for (var i = 0; i < this.data.length; i++) {
            amount_sum += parseFloat(this.data[i].payment_total_cost)
          }
        }
        return amount_sum
      },
      payment_fee_sum: function () {
        var amount_sum = 0
        if(this.data){
          for (var i = 0; i < this.data.length; i++) {
            amount_sum += parseFloat(this.data[i].payment_fee)
          }
        }
        return amount_sum
      },
      payment_fee_return_sum: function () {
        var amount_sum = 0
        if(this.data){
          for (var i = 0; i < this.data.length; i++) {
            amount_sum += parseFloat(this.data[i].payment_fee_return)
          }
        }
        return amount_sum
      },
      totalCTV_sum: function () {
        var amount_sum = 0
        amount_sum = parseFloat(this.payment_total_money_sum - this.payment_total_cost_sum - this.payment_fee_sum - this.payment_fee_return_sum)
        return amount_sum
      },
      payment_broker_discount_sum: function () {
        var amount_sum = 0
        if(this.data){
          for (var i = 0; i < this.data.length; i++) {
            amount_sum += parseFloat(this.data[i].payment_broker_discount)
          }
        }
        return amount_sum
      },
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
        this.params.payment_confirm_date = this.moment(new Date()).add(-0, 'days').format('DD/MM/YYYY')
        //this.seach();
    },
    methods: {
      seach() {
        NProgress.start()
        var vm = this
        axios.get('/api/ctv/outputvourcherpayment/seach'+ '?' + $.param(this.params))
            .then(function(response) {
                NProgress.done()
                vm.data = response.data.data

                //gán các giá trị
                vm.params_confirm.keyword = vm.params.keyword
                vm.params_confirm.payment_partner_id = vm.params.payment_partner_id
                vm.params_confirm.payment_confirm_date = vm.params.payment_confirm_date
                vm.params_confirm.payment_status_confirm = vm.params.payment_status_confirm
                vm.dataPayment = Object.assign({}, paymentvourcher)
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
      remove(id) {
        var $this = this
        this.$dialog.confirm('Bạn có muốn xóa?', {
            loader: true,
            okText: 'Đồng ý',
            cancelText: 'Hủy bỏ'
        })
            .then((dialog) => {
                $this.del(id)
                dialog.close()
            });
      },
      del(id){
        var vm = this
        axios['put']('/api/ctv/outputvourcherpayment/delete/'+id)
        .then(function (response) {
            var result = response.data
            if(result.success==false){
              vm.$snotify.error(result.message, 'Lỗi')
            }else{
              vm.$snotify.success(result.message, 'Success')
            }
            vm.seach()
        })
        .catch(function (error) {
          vm.$snotify.error('Xóa xác nhận đơn hàng lỗi vui lòng kiểm tra và thử lại', 'Lỗi')
          console.log(error)
        })
      },
      createPayment() {
        var vm = this

        if(!vm.data || vm.data.length == 0){
            var textMessage = 'Không có dữ liệu tạo yêu cầu thanh toán!'
            vm.$dialog.alert(textMessage, {
                                okText: 'Đồng ý'
                            })
            .then(function(dialog) {
                return
            });
        }

        else if(vm.params_confirm.keyword != ""){
            var textMessage = 'Vui lòng bỏ từ khóa và tìm kiếm lại trước khi tạo thanh toán!'
            vm.$dialog.alert(textMessage, {
                                okText: 'Đồng ý'
                            })
            .then(function(dialog) {
                return
            });
        }

        else if(vm.params_confirm.payment_partner_id === "" || vm.params_confirm.payment_partner_id <= 0){
            var textMessage = 'Vui lòng chọn cộng tác viên và tìm kiếm lại trước khi tạo thanh toán!'
            vm.$dialog.alert(textMessage, {
                                okText: 'Đồng ý'
                            })
            .then(function(dialog) {
                return
            });
        }

        else if(vm.params_confirm.payment_status_confirm > 0){
            var textMessage = 'Vui lòng chọn trạng thái chưa tạo yêu cầu và tìm kiếm lại trước khi tạo thanh toán!'
            vm.$dialog.alert(textMessage, {
                                okText: 'Đồng ý'
                            })
            .then(function(dialog) {
                return
            });
        }

        // Cho thanh toán tới ngày hiện tại
        // else if(vm.params_confirm.payment_confirm_date >= this.moment(new Date()).format('DD/MM/YYYY')){
        //     var textMessage = 'Vui lòng chọn ngày bé hơn hiện tại và tìm kiếm lại trước khi tạo thanh toán!'
        //     vm.$dialog.alert(textMessage, {
        //                         okText: 'Đồng ý'
        //                     })
        //     .then(function(dialog) {
        //         return
        //     });
        // }

        //ngược lại đáp ứng điều kiện đi xử lý
        else{
          vm.dataPayment.paymentvourcher_partner_id = vm.params_confirm.payment_partner_id
          vm.dataPayment.paymentvourcher_total_amount = vm.totalCTV_sum
          vm.dataPayment.paymentvourcher_total_money = vm.totalCTV_sum
          vm.dataPayment.paymentvourcher_total_count = vm.total_count
          vm.dataPayment.paymentvourcher_date = vm.params_confirm.payment_confirm_date
          vm.dataPayment.paymentvourcher_note = "THANH TOÁN CTV " + vm.data[0].payment_partner.partner_name.toUpperCase() + " ĐẾN NGÀY " + vm.dataPayment.paymentvourcher_date
          if(vm.data){
            for (var i = 0; i < vm.data.length; i++) {
                vm.dataPayment.paymentvourcher_details.push(vm.data[i].outputvourcher_id)
              }
          }
          $('#modal-form-payment').modal('show')
        }
      },
      processPayment(){
        var vm = this
        //Kiểm tra bắt nhập tiền thanh toán
        if(!vm.dataPayment.paymentvourcher_total_money || vm.dataPayment.paymentvourcher_total_money < 0){
            var textMessage = 'Vui lòng nhập số tiền thanh toán lớn hơn 0!'
            vm.$dialog.alert(textMessage, {
                                okText: 'Đồng ý'
                            })
            .then(function(dialog) {
                return
            });
            return
        }

        //Nếu tiền thanh toán khác tiền hệ thống tính thì cảnh báo
        if(vm.dataPayment.paymentvourcher_total_money != vm.dataPayment.paymentvourcher_total_amount){
          vm.$dialog.confirm('Tiền thanh toán và tiền xác nhận khác nhau, bạn muốn tạo phiếu thanh toán?', {
              loader: true,
              okText: 'Đồng ý',
              cancelText: 'Hủy bỏ'
          })
          .then((dialog) => {
                  vm.processSavePayment()
                  dialog.close()
              });
          return
        }

        //Nếu không có vấn đề gì thì xử lý bình thường
        vm.processSavePayment()
      },
      processSavePayment()
      {
        NProgress.start()
        this.enableButtonSave = true
        let vm = this

        //gán data
        vm.dataPayment.paymentvourcher_details = vm.data.map(obj =>{ 
                                                              return obj.outputvourcher.id;
                                                            });

        axios['post']('/api/ctv/paymentvourcher/insert', vm.dataPayment)
            .then(function (response) {
                var result = response.data
                NProgress.done()
                if(result.success==true){
                  vm.$snotify.success('Cập nhật thành công!', 'Success')
                  vm.$router.push({path: '/ctv/paymentvourcher/'+response.data.data.paymentvourcher_id})
                }
                else{
                    vm.$snotify.error(result.message, 'Lỗi')
                    vm.enableButtonSave = false
                    return
                }
                vm.enableButtonSave = false
                $('#modal-form-payment').modal('hide')
                vm.seach()
            })
            .catch(function (error) {
                NProgress.done()
                vm.$snotify.error('Cập nhật thất bại, vui lòng kiểm tra lại!', 'Error')
                vm.enableButtonSave = false
                console.log(error)
            })
      },
    }
}
</script>