<template>
  <div>
     <form role="form" @submit.prevent="save">
        <input type="hidden" id="partner_id" name="partner_id" v-model="form.partner_id">
         <div class="row">
            <div class="col-xs-12 col-md-6">
              <div class="form-group" :class="{'has-error': formErrors.partner_shop_id}">
                <label class="control-label" for="name">Cữa hàng</label>
                  <Select2 :id="'partner_shop_id'" 
                    :name="'partner_shop_id'" 
                    :data="listShop"
                    v-model="form.partner_shop_id"
                    :placeholder="'Chọn cữa hàng'"
                    :allowClear="false"
                    :multiple="false">
                    <option value="">--Cữa hàng--</option>
                  </Select2>
                <span class="help-block" v-if="formErrors.partner_shop_id">{{formErrors.partner_shop_id[0]}}</span>
              </div>
            </div>

            <div class="col-xs-12 col-md-6">
              <div class="form-group" :class="{'has-error': formErrors.partner_type}">
                <label class="control-label" for="partner_type">Loại CTV</label>
                <select class="form-control" id="partner_type" name="partner_type" v-model="form.partner_type">
                  <option value="0">CTV bình thường</option>
                  <option value="1">CTV ưu đãi</option>
                </select>
                <span class="help-block" v-if="formErrors.partner_type">{{formErrors.partner_type[0]}}</span>
              </div>
            </div>
         </div>
        
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="form-group" :class="{'has-error': formErrors.partner_phone}">
              <label class="control-label" for="partner_phone">Điện thoại</label>
              <input type="text" 
                  class="form-control"
                  id="partner_phone" 
                  name="partner_phone" 
                  v-model="form.partner_phone" 
                  maxlength="20"
                  autocomplete = "off">
              <span class="help-block" v-if="formErrors.partner_phone">{{formErrors.partner_phone[0]}}</span>
            </div>
          </div>

          <div class="col-xs-12 col-md-6">
            <div class="form-group" :class="{'has-error': formErrors.partner_phone_text}">
              <label class="control-label" for="partner_phone_text">Điện thoại text</label>
              <input type="text" 
                  class="form-control"
                  id="partner_phone_text" 
                  name="partner_phone_text" 
                  v-model="form.partner_phone_text" 
                  autocomplete = "off">
              <span class="help-block" v-if="formErrors.partner_phone_text">{{formErrors.partner_phone_text[0]}}</span>
            </div>
          </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-md-6">
              <div class="form-group" :class="{'has-error': formErrors.partner_name}">
                <label class="control-label" for="partner_name">Tên</label>
                <input type="text" class="form-control" id="partner_name" name="partner_name" v-model="form.partner_name">
                <span class="help-block" v-if="formErrors.partner_name">{{formErrors.partner_name[0]}}</span>
              </div>
            </div>

            <div class="col-xs-12 col-md-6">
              <div class="form-group" :class="{'has-error': formErrors.partner_shop_name}">
                <label class="control-label" for="partner_shop_name">Tên shop CTV</label>
                <input type="text" class="form-control" id="partner_shop_name" name="partner_shop_name" v-model="form.partner_shop_name">
                <span class="help-block" v-if="formErrors.partner_shop_name">{{formErrors.partner_shop_name[0]}}</span>
              </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-md-6">
              <div class="form-group" :class="{'has-error': formErrors.partner_website}">
                <label class="control-label" for="partner_website">Website</label>
                <input type="text" class="form-control" id="partner_website" name="partner_website" v-model="form.partner_website">
                <span class="help-block" v-if="formErrors.partner_website">{{formErrors.partner_website[0]}}</span>
              </div>
            </div>

            <div class="col-xs-12 col-md-6">
              <div class="form-group" :class="{'has-error': formErrors.partner_facbook}">
                <label class="control-label" for="partner_facbook">Facebook</label>
                <input type="text" class="form-control" id="partner_facbook" name="partner_facbook" v-model="form.partner_facbook">
                <span class="help-block" v-if="formErrors.partner_facbook">{{formErrors.partner_facbook[0]}}</span>
              </div>
            </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-md-6">
              <div class="form-group" :class="{'has-error': formErrors.partner_email}">
                <label class="control-label" for="partner_email">Email</label>
                <input type="email" class="form-control" id="partner_email" name="partner_email" v-model="form.partner_email" autocomplete = "off">
                <span class="help-block" v-if="formErrors.partner_email">{{formErrors.partner_email[0]}}</span>
              </div>
          </div>

          <div class="col-xs-12 col-md-6">
            <div class="form-group" :class="{'has-error': formErrors.partner_nickname}">
              <label class="control-label" for="partner_nickname">Nick Name</label>
              <input type="text" class="form-control" id="partner_nickname" name="partner_nickname" v-model="form.partner_nickname">
              <span class="help-block" v-if="formErrors.partner_nickname">{{formErrors.partner_nickname[0]}}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-md-12">
            <div class="form-group" :class="{'has-error': formErrors.partner_address}">
                <label class="control-label" for="partner_address">Địa chỉ</label>
                <textarea class="form-control" 
                  rows="1" id="partner_address" 
                  name="partner_address" 
                  v-model="form.partner_address"></textarea>
                <span class="help-block" v-if="formErrors.partner_address">{{formErrors.partner_address[0]}}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="form-group" :class="{'has-error': formErrors.partner_begindate}">
              <label class="control-label" for="name">Ngày nhận</label>
                  <date-picker 
                    class="form-control"
                    :config="configDate" 
                    id="partner_begindate" 
                    name="partner_begindate"
                    v-model="form.partner_begindate">
                  </date-picker>
              <span class="help-block" v-if="formErrors.partner_begindate">{{formErrors.partner_begindate[0]}}</span>
            </div>
          </div>

          <div class="col-xs-12 col-md-6">
            <div class="form-group" :class="{'has-error': formErrors.partner_user_id}">
              <label class="control-label" for="name">Người nhận</label>
                <Select2 :id="'partner_user_id'" 
                  :name="'partner_user_id'" 
                  :data="listUser"
                  v-model="form.partner_user_id"
                  :placeholder="'Chọn nhân viên'"
                  :allowClear="false"
                  :multiple="false">
                  <option value="">--Nhân viên--</option>
                </Select2>
              <span class="help-block" v-if="formErrors.partner_user_id">{{formErrors.partner_user_id[0]}}</span>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-xs-12 col-md-12">
            <div class="form-group" :class="{'has-error': formErrors.partner_description}">
                <label class="control-label" for="partner_description">Ghi chú</label>
                <textarea class="form-control" 
                  rows="1" id="partner_description" 
                  name="partner_description" 
                  v-model="form.partner_description"></textarea>
                <span class="help-block" v-if="formErrors.partner_description">{{formErrors.partner_description[0]}}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-md-12">
            <div class="form-group" :class="{'has-error': formErrors.partner_description_pay}">
                <label class="control-label" for="partner_description_pay">Tài khoản thanh toán</label>
                <textarea class="form-control" 
                  rows="1" id="partner_description_pay" 
                  name="partner_description_pay" 
                  v-model="form.partner_description_pay"></textarea>
                <span class="help-block" v-if="formErrors.partner_description_pay">{{formErrors.partner_description_pay[0]}}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-md-12">
            <div class="form-group" :class="{'has-error': formErrors.partner_description_price}">
                <label class="control-label" for="partner_description_price">Thông tin giá bán</label>
                <textarea class="form-control" 
                  rows="1" id="partner_description_price" 
                  name="partner_description_price" 
                  v-model="form.partner_description_price"></textarea>
                <span class="help-block" v-if="formErrors.partner_description_price">{{formErrors.partner_description_price[0]}}</span>
            </div>
          </div>
        </div>

         <div class="row">
          <div class="col-xs-12 col-md-12">
             <div class="form-group">
              <input
                type="checkbox"
                v-model="form.partner_surcharge"
                name="partner_surcharge"
                true-value="1"
                false-value="0"
              >
              <label class="control-label" for="partner_surcharge">Phụ thu SP</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-md-12">
            <div class="form-group">
              <label class="control-label" for="partner_status">Trạng thái:</label>
              <switches v-model="form.partner_status" name="partner_status" id="partner_status" type-bold="true" theme="bootstrap" color="success"></switches>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-md-12">
             <div class="form-group">
              <table class="table table-striped">
                  <tbody><tr>
                      <th>Loại đồ</th>
                      <th style="width: 150px">Chiết khấu</th>
                      <th style="width: 150px">Chiết khấu giới thiệu</th>
                    </tr>
                    <tr v-for="(item, index) in form.partner_discounts" :key="item.category_model_id">
                      <input type="hidden" :id="'partner_discounts['+index+'][category_model_id]'" :name="'partner_discounts['+index+'][category_model_id]'" v-model="item.category_model_id">
                      <td><span v-if="item.category_model">{{item.category_model.name}}</span></td>

                      <td>
                        <div class="form-group" :class="{'has-error': formErrors['partner_discounts.'+index+'.partner_discount']}">
                          <input type="number" class="form-control" :id="'partner_discounts['+index+'][partner_discount]'" :name="'partner_discounts['+index+'][partner_discount]'" v-model="item.partner_discount" :precision="0">
                          <span class="help-block" v-if="formErrors['partner_discounts.'+index+'.partner_discount']">{{formErrors['partner_discounts.'+index+'.partner_discount'][0]}}</span>
                        </div>
                      </td>

                      <td>
                        <div class="form-group" :class="{'has-error': formErrors['partner_discounts.'+index+'.broker_discount']}">
                          <input type="number" class="form-control" :id="'partner_discounts['+index+'][broker_discount]'" :name="'partner_discounts['+index+'][broker_discount]'" v-model="item.broker_discount" :precision="0">
                          <span class="help-block" v-if="formErrors['partner_discounts.'+index+'.broker_discount']">{{formErrors['partner_discounts.'+index+'.broker_discount'][0]}}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
        </div>

         <div class="row">
            <div class="col-xs-12 col-md-12">
              <div class="form-group">
                <button type="submit" class="btn btn-primary btn-block">Cập nhật</button>
              </div>
            </div>
         </div>
     </form>
  </div>
</template>

<script>
  import axios from 'axios'
  import Switches from '../../../components/Switches'
  import partner from '../../../objects/ctv/partner'
  import Select2 from '../../../components/Select2New.vue'
  import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'
  import datePicker from 'vue-bootstrap-datetimepicker'

  export default {
    name: 'formPartner',
    components: {Switches, Select2, datePicker},
    props: {
      clickSubmit: {
        type: Function,
        default: () => { }
      }
    },
    data() {
      return {
        formErrors: {},
        configDate: {
          format: 'DD/MM/YYYY',
          useCurrent: true,
          showClear: false,
          showClose: true,
          allowInputToggle: true,
        },
        isEdit:false,
        form: {},
        listShop: [],
        listUser: [],
        listCategoryModel: []
      }
    },
    methods: {
      open(id){
        this.formErrors = {}
        this.getData()
        if(typeof id != 'undefined'){
          this.isEdit = true
          this.getPartner(id)
        }
        else{
          this.isEdit = false
          this.form = Object.assign({}, partner);
          this.form.partner_discounts = []
          this.initDataAdd()
        }
      },
      getData() {
        //Lấy danh mục shop
        let vm = this
        axios.get('/api/shop/all')
            .then(function(response) {
              vm.listShop = response.data.data
            })
            .catch(function(error) {
                vm.listShop = []
                console.log(error)
            })

        //Lấy danh mục user
        axios.get('/api/user/all')
            .then(function(response) {
              vm.listUser = response.data.data
            })
            .catch(function(error) {
                vm.listUser = []
                console.log(error)
            })
      },
      initDataAdd(){
        let vm = this
        vm.form.partner_begindate = this.moment(new Date()).format('DD/MM/YYYY')
        //Lấy danh mục loại đồ
        axios.get('/api/category_model/all')
            .then(function(response) {
              vm.listCategoryModel = response.data.data
              for (var i = 0; i < vm.listCategoryModel.length; i++) {
                var newItem = {};
                newItem.partner_id = 0
                newItem.category_model = vm.listCategoryModel[i]
                newItem.category_model_id = vm.listCategoryModel[i].id
                newItem.partner_discount = 0
                newItem.broker_discount = 0
                vm.form.partner_discounts.push(newItem)
              }
            })
            .catch(function(error) {
                vm.form.partner_discounts = []
                vm.form.listCategoryModel = []
                console.log(error)
            })
      },
      save() {
        var vm = this
        if(!this.isEdit){
          axios['post']('/api/ctv/partner/insert', this.form)
          .then(function (response) {
              vm.clickSubmit()
              vm.$snotify.success('Thêm mới thành công!', 'Success')
          })
          .catch(function (error) {
              vm.formErrors = error.response.data.errors
          })
        }
        else{
          axios['put']('/api/ctv/partner/update', this.form)
          .then(function (response) {
              vm.clickSubmit()
              vm.$snotify.success('Cập nhật thành công!', 'Success')
          })
          .catch(function (error) {
              vm.formErrors = error.response.data.errors
          })
        }
        
      },
      getPartner(id){
        var vm = this
        axios.get('/api/ctv/partner/select/' + id)
            .then(function(response) {
                vm.form = response.data.data
                vm.form.partner_begindate = vm.moment(vm.form.partner_begindate).format('DD/MM/YYYY')
            })
            .catch(function(error) {
                console.log(error)
            })
      }
    }
  }
</script>