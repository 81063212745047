<template>
  <div class="row">
    <div class="col-xs-12">
          <!-- box -->
          <div class="box">
            <!-- box-header -->
            <div class="box-header">
                <div class="row">
                  <div class="col-xs-6 col-md-2">
                    <div class="form-group">
                      <date-picker 
                        class="form-control"
                        :config="configDate" 
                        v-model="params.from_date">
                      </date-picker>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-2">
                    <div class="form-group">
                      <date-picker 
                        class="form-control"
                        :config="configDate" 
                        v-model="params.to_date">
                      </date-picker>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-2">
                    <div class="form-group">
                      <select v-model="params.date_type" 
                          id="date_type" 
                          name="date_type" 
                          class="form-control">
                          <option value="0">Ngày tạo</option>
                          <option value="1">Ngày cập nhật trạng thái</option>
                          <option value="2">Ngày gửi hàng</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-2">
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="params.keyword" v-on:keyup.enter="seach" placeholder="Nhập từ khóa...">
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-2">
                    <div class="form-group">
                      <select v-model="params.shipment_status" 
                          id="shipment_status" 
                          name="shipment_status" 
                          class="form-control">
                          <option value="-1">Tất cả</option>
                          <option value="4">Đã điều phối giao hàng/Đang giao hàng</option>
                          <option value="5">Đã giao hàng/Chưa đối soát</option>
                          <option value="6">Đã đối soát</option>
                          <option value="9">Không giao được hàng</option>
                          <option value="10">Delay giao hàng</option>
                          <option value="13">Đơn hàng bồi hoàn</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-2">
                    <div class="form-group">
                      <Select2 :id="'status'" 
                        :name="'status'" 
                        :data="statusData" 
                        v-model="params.status"
                        :multiple="true">
                      </Select2>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-12 col-md-2">
                    <div class="form-group">
                      <Select2 :id="'partner_id'" 
                        :name="'partner_id'" 
                        :keyID="'partner_id'"
                        :keyName="'partner_name'"
                        :dataUrl="'/api/ctv/partner/all'"
                        v-model="params.partner_id"
                        :multiple="true">
                      </Select2>
                    </div>
                  </div>

                  <div class="col-xs-12 col-md-2">
                    <div class="form-group">
                      <Select2 :id="'shop_id'" 
                        :name="'shop_id'" 
                        :dataUrl="'/api/shop/all'"
                        v-model="params.shop_id"
                        :multiple="true">
                      </Select2>
                    </div>
                  </div>

                  <div class="col-xs-12 col-md-2">
                    <div class="form-group">
                      <select v-model="params.partner_type" 
                          id="partner_type" 
                          name="partner_type" 
                          class="form-control">
                          <option value="-1">Tất cả</option>
                          <option value="1">Đơn của shop</option>
                          <option value="2">Đơn CTV</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-xs-12 col-md-2">
                    <div class="form-group">
                      <select v-model="params.delivery_type" 
                          id="delivery_type" 
                          name="delivery_type" 
                          class="form-control">
                          <option value="-1">Tất cả</option>
                          <option value="0">Đơn vị vận chuyển - GHTK</option>
                          <option value="5">Đơn vị vận chuyển - JTEXPRESS</option>
                          <option value="3">Đơn vị vận chuyển - Bưu điện</option>
                          <option value="4">Đơn vị vận chuyển - VNCPOST</option>
                          <option value="1">Shop giao</option>
                          <option value="2">Lấy tại shop</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-2">
                    <div class="form-group">
                      <select v-model="params.isprint" 
                          id="isprint" 
                          name="isprint" 
                          class="form-control">
                          <option value="-1">Tất cả</option>
                          <option value="0">Chưa in</option>
                          <option value="1">Đã in</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-2">
                    <div class="form-group">
                      <button type="button" class="btn btn-default btn-block btn-flat" v-on:click="seach"><i class="fa fa-fw fa-search"></i> Tìm kiếm</button>
                    </div>
                  </div>
                </div>
            </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <div class="row">
              <div class="col-xs-6 col-md-3">
                <span class="text-bold">Tổng đơn: {{total_count}}</span>
              </div>
              <div class="col-xs-6 col-md-3">
                <span class="text-bold">Tổng tiền hàng: {{total_amount_sum | numFormat}}</span>
              </div>
              <div class="col-xs-6 col-md-3">
                <span class="text-bold">Tổng vận chuyển: {{total_shipping_cost_sum | numFormat}}</span>
              </div>
              <div class="col-xs-6 col-md-3">
                <span class="text-bold">Tổng vận chuyển TT: {{total_shipping_cost_tt_sum | numFormat}}</span>
              </div>
              <div class="col-xs-6 col-md-3">
                <span class="text-bold">Tổng thu khách: {{total_payment_sum | numFormat}}</span>
              </div>
              <div class="col-xs-6 col-md-3">
                <span class="text-bold">Tổng số lượng: {{totalQuantity | numFormat}}</span>
              </div>
            </div>
            <data-viewer :thead="thead" :data="data">
              <template slot-scope="props" slot='outputvourcher_info'>
                <div class="col-xs-12 col-md-12">
                  <span class="label label-success" v-if="props.item.status === 1">Hoàn tất</span>
                  <span class="label label-default" v-else-if="props.item.status === 2">Chưa chốt</span>
                  <span class="label label-primary" v-else-if="props.item.status === 3">Đã chốt</span>
                  <span class="label label-info" v-else-if="props.item.status === 4">Đang soạn</span>
                  <span class="label label-warning" v-else-if="props.item.status === 5">Đang soạn - chờ hàng</span>
                  <span class="label label-primary" v-else-if="props.item.status === 6">Soạn xong</span>
                  <span class="label label-danger" v-else>Đã hủy</span>
                </div>

                <div class="col-xs-12 col-md-12" v-if="props.item.shop_name">
                    <span class="text-bold">Cữa hàng: </span>{{props.item.shop_name}}
                </div>
                
                <div class="col-xs-12 col-md-12" v-if="props.item.partner_name">
                    <span class="text-bold">CTV: </span>{{props.item.partner_name}} - {{props.item.partner_phone}}
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Mã PX: </span>
                  <router-link
                    :to="'/outputvourcher/sale/edit/'+props.item.id">
                    {{props.item.id}}
                  </router-link>
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Ngày xuất: </span>{{moment(props.item.output_date).format('DD/MM/YYYY')}}
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Ngày gửi: </span>{{moment(props.item.shipment_date).format('DD/MM/YYYY hh:mm')}}
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Tiền thu khách: </span>{{parseFloat(props.item.total_payment)| numFormat}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Ghi chú: </span><span class="text-bold text-info">{{props.item.note}}</span>
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Ghi chú nội bộ: </span><span class="text-bold text-red">{{props.item.note_internal}}</span>
                </div>
              </template>

              <template slot-scope="props" slot='outputvourcher_customer'>
                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Khách hàng: </span>{{props.item.customer_name}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Điện thoại: </span>
                  <a :href="'tel:' + props.item.customer_phone">{{props.item.customer_phone}}</a>
                </div>

                <div class="col-xs-12 col-md-12">
                  <a :href="'sms:' + props.item.customer_phone+';?&body=ĐH quần áo ' + new Intl.NumberFormat().format(parseFloat(props.item.total_payment)) + 'đ đang đi giao. Vui lòng để ý điện thoại nhận hàng giúp Shop nhé!'"> <i class="glyphicon glyphicon-envelope"></i> Nhắn tin giao hàng</a>
                  <br>
                  <a :href="'sms:' + props.item.customer_phone+';?&body=ĐH quần áo ' + new Intl.NumberFormat().format(parseFloat(props.item.total_payment)) + 'đ Shipper liên hệ Bạn chưa được. Vui lòng kiểm tra cuộc gọi hoặc nhắn tin qua trang đặt hàng cho shop xử lý nhé!'"> <i class="glyphicon glyphicon-envelope"></i> Nhắn tin liên hệ</a>
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Nick Name: </span>{{props.item.customer_nickname}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Địa chỉ: </span>{{props.item.customer_address}}
                </div>
              </template>

              <template slot-scope="props" slot='outputvourcher_shipment'>
                <div class="col-xs-12 col-md-12">
                  <span class="label label-success" v-if="props.item.delivery_type == 0">Đơn vị vận chuyển - GHTK</span> 
                  <span class="label label-success" v-else-if="props.item.delivery_type == 3">Đơn vị vận chuyển - Bưu điện</span> 
                  <span class="label label-success" v-else-if="props.item.delivery_type == 4">Đơn vị vận chuyển - VNCPOST</span> 
                  <span class="label label-success" v-else-if="props.item.delivery_type == 1">Shop giao</span> 
                  <span class="label label-success" v-else-if="props.item.delivery_type == 5">Đơn vị vận chuyển - JTEXPRESS</span> 
                  <span class="label label-success" v-else>Lấy tại shop</span> 
                </div>

                <div class="col-xs-12 col-md-12" v-if="props.item.delivery_type == 0">
                  <span class="text-bold">TK GHTK: </span>{{props.item.logisticinfo_name}}
                </div>

                <div class="col-xs-12 col-md-12" v-if="props.item.shipment_id">
                  <span class="text-bold">Mã VĐ: </span>{{props.item.shipment_id}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Trạng thái: </span>
                  <span v-if="(props.item.delivery_type === 1 || props.item.delivery_type === 2) && props.item.status === 1">Đã giao tại shop</span>
                  <span v-if="props.item.delivery_type === 3 && props.item.status === 1">Đã gửi bưu điện</span>
                  <!-- <span v-if="props.item.delivery_type === 5 && props.item.status === 1">Đã gửi JTEXPRESS</span> -->
                  <span v-if="(props.item.delivery_type === 0 || props.item.delivery_type === 4 || props.item.delivery_type === 5) && props.item.status === 1">{{props.item.shipment_status_text}}</span>
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Tiền ship: </span>{{parseFloat(props.item.shipment_fee) | numFormat}}
                </div>

                 <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Tiền ship cod thu: </span>{{parseFloat(props.item.shipment_pick_money) | numFormat}}
                </div>
                
                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Trạng thái giao: </span>  
                  <span v-if="props.item.payment_status === 1">Giao thành công</span>
                  <span v-else-if="props.item.payment_status === 0">Chưa giao</span>
                  <span v-else-if="props.item.payment_status === 2">Giao 1 phần</span>
                  <span v-else-if="props.item.payment_status === 3">Trả về</span>
                  <span v-else>Không xác định</span> 
                </div>
              </template>

              <template slot-scope="props"  slot='control'>
                <div class="col-xs-12 col-md-12">
                  <router-link class="btn btn-xs btn-primary btn-block btn-flat" 
                    :to="'/outputvourcher/show/'+props.item.id">
                    Xem
                  </router-link>
                  <a class="btn btn-xs btn-default btn-block btn-flat" @click="print('print', props.item.id)">
                    <i class="fa fa-print"></i> In hóa đơn <span class="text-red" v-if="props.item.isprint == true">(Đã in)</span>
                  </a>
                  <a class="btn btn-xs btn-default btn-block btn-flat" @click="print('print_a7', props.item.id)">
                    <i class="fa fa-print"></i> In hóa đơn A7<span class="text-red" v-if="props.item.isprint == true">(Đã in)</span>
                  </a>
                   <!-- Mẫu in bill-->
                  <bill :data="props.item" :id="'print_'+props.item.id" v-show="false"></bill>
                  <!-- ./Mẫu in bill-->
                   <!-- Mẫu in bill A7-->
                  <BillA7 :data="props.item" :id="'print_a7_'+props.item.id" v-show="false"></BillA7>
                  <!-- ./Mẫu in bill A7-->
                </div>
              </template>
            </data-viewer>
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import Select2 from '../../../components/Select2.vue'
import DataViewer from '../../../components/DataViewer';
import Bill from '../../reports/bill';
import BillA7 from '../../reports/BillA7';

import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'
import datePicker from 'vue-bootstrap-datetimepicker'

export default {
    name: 'OutputVourcherIndex',
    components: {
       VuejsDialog, DataViewer, datePicker, Select2, Bill, BillA7
    },
    data() {
        return {
          title: 'Quản lý phiếu xuất bán',
          data:[],
          params: {
              keyword: '',
              status: '',
              from_date: '',
              to_date: '',
              date_type: 0,
              delivery_type: -1,
              shop_id: '',
              partner_id: '',
              isprint: -1,
              shipment_status: -1,
              partner_type: -1
          },
          configDate: {
            format: 'DD/MM/YYYY',
            useCurrent: false,
            showClear: false,
            showClose: true,
            allowInputToggle: true,
          },
          statusData:[
            {
              id: 1,
              name: 'Hoàn tất'
            },
            {
              id: 2,
              name: 'Chưa chốt'
            },
            {
              id: 3,
              name: 'Đã chốt'
            },
            {
              id: 4,
              name: 'Đang soạn'
            },
            {
              id: 5,
              name: 'Đang soạn - chờ hàng'
            },
            {
              id: 6,
              name: 'Soạn xong'
            },
          ],
          thead: [
            {
              key: 'outputvourcher_info',
              title: 'Thông tin đơn',
              class: 'col-xs-12 col-md-4'
            },
            {
              key: 'outputvourcher_customer',
              title: 'Thông tin khách hàng',
              class: 'col-xs-12 col-md-3'
            },
            {
              key: 'outputvourcher_shipment',
              title: 'Thông tin vận đơn',
              class: 'col-xs-12 col-md-3'
            },
            {
              key: 'control',
              title: '',
              class: 'col-xs-12 col-md-2'
            }
          ]
        }
    },
    computed: {
        total_count: function () {
          var countRow = 0
          if(this.data){
            countRow = this.data.length
          }
          return countRow
        },
        total_amount_sum: function () {
          var amount_sum = 0
          if(this.data){
            for (var i = 0; i < this.data.length; i++) {
              amount_sum += parseFloat(this.data[i].total_amount)
            }
          }
          return amount_sum
        },
        total_shipping_cost_sum: function () {
          var shipping_cost_sum = 0
          if(this.data){
            for (var i = 0; i < this.data.length; i++) {
              shipping_cost_sum += parseFloat(this.data[i].shipping_cost)
            }
          }
          return shipping_cost_sum
        },
        total_shipping_cost_tt_sum: function () {
          var shipping_cost_sum = 0
          if(this.data){
            for (var i = 0; i < this.data.length; i++) {
              if(this.data[i].delivery_type==0 || this.data[i].delivery_type==4){
                shipping_cost_sum += parseFloat(this.data[i].shipment_fee)
              }else{
                shipping_cost_sum += parseFloat(this.data[i].shipping_cost)
              }
            }
          }
          return shipping_cost_sum
        },
        total_payment_sum: function () {
          var payment_sum = 0
          if(this.data){
            for (var i = 0; i < this.data.length; i++) {
              payment_sum += parseFloat(this.data[i].total_payment)
            }
          }
          return payment_sum
        },
        totalQuantity: function () {
          var total_quantity = 0
          // if(this.data){
          //     for (var i = 0; i < this.data.length; i++) {
          //       if(this.data[i].status != 0){
          //         if(this.data[i].outputvourcher_details){
          //           for (var j = 0; j < this.data[i].outputvourcher_details.length; j++) {
          //             if(this.data[i].outputvourcher_details[j].status != 0){
          //               total_quantity += parseFloat(this.data[i].outputvourcher_details[j].quantity)
          //             }
          //           }
          //         }
          //       }
          //     }
          // }
          if(this.data){
            for (var i = 0; i < this.data.length; i++) {
              if(this.data[i].total_quantity){
                total_quantity += parseFloat(this.data[i].total_quantity)
              }
            }
          }
          return total_quantity
        }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
        this.params.from_date = this.moment(new Date()).add(-1, 'days').format('DD/MM/YYYY')
        this.params.to_date = this.moment(new Date()).format('DD/MM/YYYY')
        this.seach();
    },
    methods: {
      seach() {
        NProgress.start()
        var vm = this
        axios.get('/api/inventory/output/sale/seach'+ '?' + $.param(this.params))
            .then(function(response) {
                NProgress.done()
                vm.data = response.data.data
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
      print(printName, id) {
          var vm = this
          this.$htmlToPaper(printName+'_'+id, () => {
              axios['put']('/api/inventory/output/sale/update_outputvourcher_isprint/'+id)
              .then(function (response) {
                  vm.seach();
              })
              .catch(function (error) {
                  console.log(error)
              })
              //console.log('In thành công');
          });
      },
     
    }
}
</script>