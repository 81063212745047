<template>
  <div>
     <form role="form" @submit.prevent="save">
        <input type="hidden" id="id" name="id" v-model="form.id">
        <div class="form-group" :class="{'has-error': formErrors.name}">
          <label class="control-label" for="name">Tên</label>
          <input type="text" class="form-control" id="name" name="name" v-model="form.name" autocomplete = "off">
          <span class="help-block" v-if="formErrors.name">{{formErrors.name[0]}}</span>
        </div>
        <div class="form-group" :class="{'has-error': formErrors.email}">
          <label class="control-label" for="email">Email</label>
          <input type="email" class="form-control" id="email" name="email" v-model="form.email" autocomplete = "off">
          <span class="help-block" v-if="formErrors.email">{{formErrors.email[0]}}</span>
        </div>
        <div class="form-group" :class="{'has-error': formErrors.password}" v-show="!isEdit">
          <label class="control-label" for="name">Mật khẩu</label>
          <input type="password" class="form-control" id="password" name="password" v-model="form.password">
          <span class="help-block" v-if="formErrors.password">{{formErrors.password[0]}}</span>
        </div>
        <div class="form-group" :class="{'has-error': formErrors.password_confirmation}" v-show="!isEdit">
          <label class="control-label" for="password_confirmation">Xác nhận</label>
          <input type="password" class="form-control" id="password_confirmation" name="password_confirmation" v-model="form.password_confirmation">
          <span class="help-block" v-if="formErrors.password_confirmation">{{formErrors.password_confirmation[0]}}</span>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary btn-block">Cập nhật</button>
        </div>
     </form>
  </div>
</template>

<script>
  import axios from 'axios'
  import Switches from '../../components/Switches';
  import user from '../../objects/user'

  export default {
    name: 'formUser',
    components: {Switches},
    props: {
      clickSubmit: {
        type: Function,
        default: () => { }
      }
    },
    data() {
      return {
        formErrors: {},
        isEdit:false,
        form: {}
      }
    },
    methods: {
      open(id){
        this.formErrors = {}
        if(typeof id != 'undefined'){
          this.isEdit = true
          this.getUser(id)
        }
        else{
          this.isEdit = false
          this.form = Object.assign({}, user);
        }
      },
      save() {
        var vm = this
        if(!this.isEdit){
          axios['post']('/api/user/insert', this.form)
          .then(function (response) {
              vm.clickSubmit()
              vm.$snotify.success('Thêm mới thành công!', 'Success')
          })
          .catch(function (error) {
              vm.formErrors = error.response.data.errors
          })
        }
        else{
          axios['put']('/api/user/update', this.form)
          .then(function (response) {
              vm.clickSubmit()
              vm.$snotify.success('Cập nhật thành công!', 'Success')
          })
          .catch(function (error) {
              vm.formErrors = error.response.data.errors
          })
        }
        
      },
      getUser(id){
        var vm = this
        axios.get('/api/user/select/' + id)
            .then(function(response) {
                vm.form = response.data.data
            })
            .catch(function(error) {
                console.log(error)
            })
      }
    }
  }
</script>