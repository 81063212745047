<template>
    <div>
        <h4>Danh sách vận đơn giao trễ <span class="label label-info" v-if="dataHetHan">{{dataHetHan.length}}</span></h4>
        <br>
        <data-viewer :thead="thead" :data="dataHetHan">
            <template slot-scope="props" slot='id'>
                <span class="text-bold hidden-lg">Mã phiếu/Vận đơn: </span>
                <router-link :to="'/outputvourcher/show/'+props.item.id">
                {{props.item.id}}
                </router-link>
                <br>
                {{props.item.shipment_id}}
            </template>
            <template slot-scope="props" slot='customer_name'>
                <span class="text-bold hidden-lg">Khách hàng: </span>{{props.item.customer_name}}({{props.item.customer_nickname}})
                <br>
                ĐC: {{props.item.customer_address}}
            </template>
            <template slot-scope="props" slot='customer_phone'>
                <span class="text-bold hidden-lg">Điện thoại: </span>{{props.item.customer_phone}}
            </template>
            <template slot-scope="props" slot='ghtk_order_responses.estimated_pick_time'>
                <span class="text-bold hidden-lg">Ngày gửi: </span>
                <span v-if="props.item.ghtk_order_responses && props.item.ghtk_order_responses.length>0">{{props.item.ghtk_order_responses[0].estimated_pick_time}}</span>
            </template>
            <template slot-scope="props" slot='ghtk_order_responses.estimated_deliver_time'>
                <span class="text-bold hidden-lg">Giao dự kiến: </span>
                <span v-if="props.item.ghtk_order_responses && props.item.ghtk_order_responses.length>0">{{props.item.ghtk_order_responses[0].estimated_deliver_time}}</span>
            </template>
            <template slot-scope="props" slot='shipment_status_text'>
                <span class="label label-danger">{{props.item.shipment_status_text}}</span>
                <br>
                <span class="label label-danger" v-if="props.item.slow===1">Giao trễ</span>
                <span class="label label-warning" v-if="props.item.slow===2">Phải giao hôm nay</span>
                <br>
                <button type="button" 
                  class="btn btn-default btn-flat" 
                  @click="updateStatusShipment(props.item.id, props.item.shipment_status)"
                  v-if="props.item.shipment_id"><i class="glyphicon glyphicon-refresh"></i></button>
            </template>
        </data-viewer>
    </div>
</template>
<script>
import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import DataViewer from '../../../components/DataViewer'

export default {
    name: 'DonHangGHTKChuaGiao',
    components: {
       VuejsDialog, DataViewer
    },
    data() {
        return {
          title: 'Soạn hàng',
          data:[],
          params: {
              keyword: ''
          },
          thead: [
            {
              key: 'id',
              title: 'Mã phiếu/Vận đơn',
              class: 'col-xs-12 col-md-3'
            },
            {
              key: 'customer_name',
              title: 'Khách hàng',
              class: 'col-xs-12 col-md-2'
            },
            {
              key: 'customer_phone',
              title: 'Điện thoại',
              class: 'col-xs-12 col-md-1'
            },
            {
              key: 'ghtk_order_responses.estimated_pick_time',
              title: 'Ngày gửi',
              class: 'col-xs-12 col-md-2'
            },
            {
              key: 'ghtk_order_responses.estimated_deliver_time',
              title: 'Giao dự kiến',
              class: 'col-xs-12 col-md-2'
            },
            {
              key: 'shipment_status_text',
              title: 'Trạng thái',
              class: 'col-xs-12 col-md-2'
            }
          ]
        }
    },
    computed: {
      dataHetHan: function () {
          return this.data.filter(o=>o.slow === 1)
      }
    },
    mounted(){
        //this.seach();
    },
    methods: {
      seach() {
        NProgress.start()
        var vm = this
        axios.get('/api/inventory/output/logistics/delivery')
            .then(function(response) {
                NProgress.done()
                vm.data = response.data.data

                //xử lý data
                for (var i = 0; i < vm.data.length; i++) {
                  vm.data[i].slow = 0
                  if(vm.data[i].ghtk_order_responses && vm.data[i].ghtk_order_responses.length>0){
                    var estimated_deliver_timeTemp = vm.data[i].ghtk_order_responses[0].estimated_deliver_time
                    var dateTemp = estimated_deliver_timeTemp.substr(estimated_deliver_timeTemp.length-10, 10)

                    var dcheck = new Date(vm.moment(new Date(dateTemp)).add(1, 'days').format('YYYY-MM-DD'))
                    dcheck.setHours(0);
                    dcheck.setMinutes(0);
                    dcheck.setSeconds(0);
                    dcheck.setMilliseconds(0);

                    var dcheck_Today = new Date(vm.moment(new Date(dateTemp)).format('YYYY-MM-DD'))
                    dcheck_Today.setHours(0);
                    dcheck_Today.setMinutes(0);
                    dcheck_Today.setSeconds(0);
                    dcheck_Today.setMilliseconds(0);

                    var dcheckNow = new Date(vm.moment(new Date()).format('YYYY-MM-DD'))
                    dcheckNow.setHours(0);
                    dcheckNow.setMinutes(0);
                    dcheckNow.setSeconds(0);
                    dcheckNow.setMilliseconds(0);

                    if(dcheck<=dcheckNow){
                      vm.data[i].slow = 1
                    }

                    if((dcheck_Today.getFullYear() === dcheckNow.getFullYear()) && (dcheck_Today.getMonth() === dcheckNow.getMonth()) && (dcheck_Today.getDate() === dcheckNow.getDate())){
                      vm.data[i].slow = 2
                    }
                  }
                }
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
      updateStatusShipment(id, statusold) {
          var vm = this
          axios['post']('/api/logistics/shipment/ghtk/status/'+id)
          .then(function (response) {
              var result = response.data
              if(result.success==true){
                var statusnew = result.data.status
                vm.$snotify.success('Cập nhật trạng thái thành công!', 'Success')
                if(statusold!=statusnew){
                  vm.seach()
                }
              }else{
                  vm.$snotify.error(result.message, 'Lỗi cập nhật')
              }
          })
          .catch(function (error) {
              vm.$snotify.error('Không xử lý được yêu cầu cập nhật', 'Lỗi cập nhật')
              console.log(error)
          })
      }
    }
}
</script>