<template>
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <h4 class="text-blue">Thông Tin người nhận</h4>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="form-group" :class="{'has-error': formErrors.province_id}">
                <label class="control-label" for="name">Tỉnh thành</label>
                <Select2 :id="'province_id'" 
                    :name="'province_id'" 
                    :data="provinceList"
                    @changed="changedprovince()" 
                    v-model="province_id"
                    :allowClear="false"
                    :placeholder="'Chọn tỉnh thành'"
                    :multiple="false">
                </Select2>
                <span class="text-bold">Tỉnh thành JTEXPRESS: </span>  
                <span class="text-red">{{province_jtexpress_name}}</span>
                <span class="help-block" v-if="formErrors.province_id">{{formErrors.province_id[0]}}</span>
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="form-group" :class="{'has-error': formErrors.district_id}">
                <label class="control-label" for="name">Quận huyện</label>
                <Select2 :id="'district_id'" 
                    :name="'district_id'" 
                    @changed="changeddistrict()" 
                    :data="districtList"
                    v-model="district_id"
                    :allowClear="false"
                    :placeholder="'Chọn quận huyện'"
                    :multiple="false">
                </Select2>
                <span class="text-bold">Quận huyện JTEXPRESS: </span>  
                <span class="text-red">{{district_jtexpress_name}}</span>
                <span class="help-block" v-if="formErrors.district_id">{{formErrors.district_id[0]}}</span>
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="form-group" :class="{'has-error': formErrors.ward_id}">
                <label class="control-label" for="name">Phường xã</label>
                <Select2 :id="'ward_id'" 
                    :name="'ward_id'" 
                    :data="wardList"
                    v-model="ward_id"
                    :allowClear="false"
                    :placeholder="'Chọn phường xã'"
                    :multiple="false">
                </Select2>
                <span class="text-bold">Phường xã JTEXPRESS: </span>  
                <span class="text-red">{{ward_jtexpress_name}}</span>
                <span class="help-block" v-if="formErrors.ward_id">{{formErrors.ward_id[0]}}</span>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
           <h4 class="text-blue">Thông Tin vận chuyển</h4>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
            <label class="control-label">Loại vận chuyển:</label>
            <div class="form-group">
                <select class="form-control" v-model="producttype">
                    <option value="EZ">EZ</option>
                </select>
            </div> 
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="form-group" :class="{'has-error': formErrors.total_weight}">
                <label class="control-label">Tổng trọng lượng(1 bộ = 0.3 kg):</label>
                <input type="number" class="form-control"
                    id="total_weight"
                    list="datalist_weights" 
                    name="total_weight"
                    v-model="total_weight"
                    step="0.1">
                <datalist id="datalist_weights">
                    <option value="0.2" />
                    <option value="0.3" />
                    <option value="0.5" />
                    <option value="1" />
                    <option value="1.5" />
                    <option value="2" />
                    <option value="2.5" />
                    <option value="3" />
                </datalist>
                <span class="help-block" v-if="formErrors.total_weight">{{formErrors.total_weight[0]}}</span>
            </div> 
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
            <span class="text-bold">Phí ship: </span> 
            <span class="text-bold text-red" v-if="data.inquiryfee">{{data.inquiryfee | numFormat}} đ</span> 
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="form-group">
                <button 
                    @click="submit()"
                    type="button" 
                    class="btn btn-primary pull-right">Xác nhận
                </button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import NProgress from 'nprogress'
import Select2 from '../Select2New.vue'

export default {
    name: 'JTEXPRESSFee',
    components: {
      Select2
    },
    data() {
        return {
            formErrors: {},
            data:{},
            provinceList: [],
            districtList: [],
            wardList: [],
            province_id: '',
            district_id: '',
            ward_id: '',
            producttype: 'EZ',
            total_weight: ''
        }
    },
    mounted(){
        var vm = this
        axios.get('/api/province/all')
            .then(function(response) {
                vm.provinceList = response.data.data
            })
            .catch(function(error) {
                vm.provinceList = []
            })
    },
    computed: {
        ward_jtexpress_name: function () {
          var ward_jtexpress_name_tempp = ''
          var ward = this.wardList.find(o => o.id == this.ward_id)
          if(ward){
            ward_jtexpress_name_tempp = ward.jtexpress_name
          }
          return ward_jtexpress_name_tempp
        },
        district_jtexpress_name: function () {
          var district_jtexpress_name_tempp = ''
          var district = this.districtList.find(o => o.id == this.district_id)
          if(district){
            district_jtexpress_name_tempp = district.jtexpress_name
          }
          return district_jtexpress_name_tempp
        },
        province_jtexpress_name: function () {
          var province_jtexpress_name_tempp = ''
          var province = this.provinceList.find(o => o.id == this.province_id)
          if(province){
            province_jtexpress_name_tempp = province.jtexpress_name
          }
          return province_jtexpress_name_tempp
        }
    },
    methods: {
        submit(){
            var vm = this
            this.formErrors = {}
            this.validate()
            if(Object.keys(this.formErrors).length === 0 && this.formErrors.constructor === Object){
                var province = this.provinceList.find(o => o.id == this.province_id)
                var district = this.districtList.find(o => o.id == this.district_id)
                var ward = this.wardList.find(o => o.id == this.ward_id)

                var result = {
                    sendsitecode: district.jtexpress_name,
                    destareacode: ward.jtexpress_name,
                    weight: this.total_weight,
                    producttype: this.producttype
                }

                axios['post']('/api/logistics/shipment/jtexpress/fee', result)
                .then(function (response) {
                    vm.data = {}
                    var result = response.data
                    if(result.success == true){
                        vm.data = result.data
                        vm.$snotify.success('Lấy cước phí thành công!', 'Success')
                    }else{
                        vm.$snotify.error(result.message, 'Lỗi')
                    }
                })
                .catch(function (error) {
                    vm.formErrors = error.response.data.errors
                })
            }
        },
        changedprovince(){
            var vm = this
            if(vm.province_id != ''){
            axios.get('/api/district/seachbyprovince/'+vm.province_id)
                .then(function(response) {
                    vm.districtList = response.data.data
                })
                .catch(function(error) {
                    vm.districtList = []
                })
            }else{
                vm.districtList = []
            }
        },
        changeddistrict(){
            var vm = this
            if(vm.district_id != ''){
            axios.get('/api/ward/seachbydistrict/'+vm.district_id)
                .then(function(response) {
                    vm.wardList = response.data.data
                })
                .catch(function(error) {
                    vm.wardList = []
                })
            }else{
                vm.wardList = []
            }
        },
        validate(){
            if(this.province_id == null || this.province_id == ''){
                var error = ["Vui lòng chọn tỉnh thành"]
                this.formErrors.province_id = error
            }
            if(this.district_id == null || this.district_id == ''){
                var error = ["Vui lòng chọn quận huyện"]
                this.formErrors.district_id = error
            }
            if(this.ward_id == null || this.ward_id == ''){
                var error = ["Vui lòng chọn phường xã"]
                this.formErrors.ward_id = error
            }
            if(this.total_weight == null || this.total_weight == '' || parseFloat(this.total_weight)<=0){
                var error = ["Vui lòng nhập trọng lượng"]
                this.formErrors.total_weight = error
            }
        }
    }
}
</script>
