<template>
<!-- Modal -->
<div class="modal fade in" id="modal-form-keep-by-phone" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">Danh sách hàng được giữ</h4>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12" v-for="item in data" :key="item.id">
                    <!-- info-box -->
                    <div class="info-box pull-left">
                        <span class="info-box-icon">
                            <img :src="item.product.image.link_image" />
                        </span>

                        <div class="info-box-content row">
                                <div class="col-md-12 col-xs-12">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="info-box-text text-green">
                                                {{item.product.id + ' - ' + item.product.name}} 
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 col-xs-12">
                                            <span class="text-bold">Khách hàng: </span>  
                                            <span>{{item.customer_name}}</span> 
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 col-xs-12">
                                            <span class="text-bold">Nhân viên:</span>  
                                            <span>{{item.created_user.name}}</span>  
                                        </div>
                                        <div class="col-md-4 col-xs-12">
                                            <span class="text-bold">Số lượng: </span>  
                                            <span class="text-bold text-red">{{parseFloat(item.quantity)}}</span> 
                                        </div>
                                        <div class="col-md-6 col-xs-12">
                                            <span class="text-bold">Ngày tạo:</span>  
                                            <span>{{moment(item.created_at).format('DD/MM/YYYY hh:mm')}}</span>  
                                        </div>
                                        <div class="col-md-2 col-xs-12">
                                            <a class="btn btn-danger btn-block"  
                                                v-on:click="remove(item.id)">
                                                <i class="fa fa-fw fa-trash"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="form-group">
                <button 
                    @click="submit()"
                    type="button" 
                    class="btn btn-primary btn-block">Hủy giữ hàng và tạo đơn
                </button>
            </div>
        </div>
    </div>
    <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
</template>

<script>
    import axios from 'axios'
    import VuejsDialog from "vuejs-dialog"

    export default {
        name: 'KeepByPhone',
        components: {VuejsDialog},
        props: {
        },
        data() {
            return {
                formErrors: {},
                enableButtonSave: false,
                data: [],
                customer_phone: ''
            }
        },
        computed: {

        },
        methods: {
            open(data, customer_phone){
                $('#modal-form-keep-by-phone').modal('show')
                var vm = this
                vm.data = []
                vm.customer_phone = customer_phone
                vm.enableButtonSave = false
                vm.data = data
            },
            submit(){
                var vm = this
                axios['put']('/api/keep/delete_by_phone/'+vm.customer_phone)
                .then(function (response) {
                    vm.$emit('form-submit', vm.data)
                    $('#modal-form-keep-by-phone').modal('hide')
                })
                .catch(function (error) {
                    console.log(error)
                    vm.$snotify.error('Cập nhật thất bại, vui lòng kiểm tra lại!', 'Error')
                })
            },
            remove(id) {
                var $this = this
                this.$dialog.confirm('Bạn có muốn xóa?', {
                    loader: true,
                    okText: 'Đồng ý',
                    cancelText: 'Hủy bỏ'
                })
                    .then((dialog) => {
                        $this.del(id)
                        dialog.close()
                    });
            },
            del(id){
                var vm = this
                axios['put']('/api/keep/delete/'+id)
                .then(function (response) {
                    vm.$snotify.success('Xóa thành công!', 'Success')
                    vm.data.splice(vm.data.findIndex(v => v.id === id), 1);
                })
                .catch(function (error) {
                    console.log(error)
                })
            },
        }
    }
</script>
<style lang="css" scoped>
.info-box-icon>img {
    width: 100%;
    max-height: 90px;
    vertical-align: unset;
}
.info-box-content{
    padding: 0px;
}
.info-box-text{
    white-space: unset;
}
.info-box {
    padding-bottom: 10px;
}
</style>

