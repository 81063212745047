<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-header -->
          <div class="box-header">
            <div class="row">
              <div class="col-xs-12 col-md-4">
                <div class="form-group">
                  <input type="text" class="form-control" 
                  v-model="params.keyword" 
                  v-on:keyup.enter="seach"
                  placeholder="Nhập từ khóa...">
                </div>
              </div>

              <div class="col-xs-12 col-md-4">
                <div class="form-group">
                  <Select2 :id="'partner_shop_id_srh'" 
                    :name="'partner_shop_id_srh'" 
                    :dataUrl="'/api/shop/all'"
                    v-model="params.partner_shop_id"
                    :allowClear="true"
                    :placeholder="'Chọn shop'"
                    :multiple="true">
                  </Select2>
                </div>
              </div>

              <div class="col-xs-12 col-md-4">
                <div class="form-group">
                  <Select2 :id="'partner_type_srh'" 
                    :name="'partner_type_srh'" 
                    :data="partner_typeData"
                    v-model="params.partner_type"
                    :allowClear="true"
                    :placeholder="'Chọn loại CTV'"
                    :multiple="true">
                  </Select2>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 col-md-12">
                <div class="form-group">
                  <button type="button" class="btn btn-default btn-flat" v-on:click="seach"><i class="fa fa-fw fa-search"></i> Tìm kiếm</button>
                  <button type="button" class="btn btn-primary btn-flat" v-on:click="add"><i class="fa fa-fw fa-plus"></i> Thêm mới</button>
                </div>
              </div>
            </div>
          </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <data-viewer :thead="thead" :data="data">
              <template slot-scope="props" slot='partner_id'>
                <span class="text-bold hidden-lg">Mã: </span>{{props.item.partner_id}}
              </template>

              <template slot-scope="props" slot='partner_shop_id'>
                <span class="text-bold hidden-lg">Tên shop: </span>{{props.item.partner_shop.name}}
              </template>

              <template slot-scope="props" slot='partner_name'>
                <span class="text-bold hidden-lg">Tên CTV: </span>{{props.item.partner_name}}
              </template>

              <template slot-scope="props" slot='partner_shop_name'>
                <span class="text-bold hidden-lg">Tên shop CTV: </span>{{props.item.partner_shop_name}}
              </template>

              <template slot-scope="props" slot='partner_status'>
                <span class="label label-success" v-if="props.item.partner_status === 1">Sử dụng</span>
                <span class="label label-warning" v-else>Tạm ngưng</span>
              </template>

              <template slot-scope="props" slot='control'>
                <div class="pull-right">
                  <a @click="edit(props.item.partner_id)" class="btn btn-info btn-xs" data-toggle="tooltip" title="Chỉnh sửa"><i class="fa fa-pencil"></i></a>
                  <a @click="remove(props.item.partner_id)" class="btn btn-danger btn-xs" data-toggle="tooltip" title="Xóa"><i class="fa fa-trash"></i></a>
                </div>
              </template>
            </data-viewer>

            <b-pagination
              v-model="pagination.currentPage" 
              :total-rows="pagination.rows"
              :per-page="pagination.perPage"
              @change="handlePageChange">
            </b-pagination>
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>
    <!-- Modal -->
    <div class="modal fade in" id="modal-form">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Cộng tác viên</h4>
          </div>
          <div class="modal-body">
            <form-partner ref="formPartner" :click-submit="saveSuccess"></form-partner>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import DataViewer from '../../../components/DataViewer'
import Select2 from '../../../components/Select2New.vue'
import formPartner from './form.vue'

export default {
    name: 'PartnerIndex',
    components: {
      formPartner, VuejsDialog, DataViewer, Select2
    },
    data() {
        return {
          title: 'Quản lý cộng tác viên',
          data:[],
          pagination: {
            rows: 0,
            perPage: 20,
            currentPage: 1
          },
          params: {
              keyword: '',
              partner_type: '',
              partner_shop_id: '',
              page: 1,
              perPage: 20
          },
          partner_typeData:[
            {
              id: 0,
              name: 'CTV bình thường'
            },
            {
              id: 1,
              name: 'CTV ưu đãi'
            }
          ],
          thead: [
            {
              key: 'partner_id',
              title: 'Mã',
              class: 'col-xs-12 col-md-1'
            },
            {
              key: 'partner_shop_id',
              title: 'Tên shop',
              class: 'col-xs-12 col-md-3'
            },
            {
              key: 'partner_name',
              title: 'Tên CTV',
              class: 'col-xs-12 col-md-3'
            },
            {
              key: 'partner_shop_name',
              title: 'Tên shop CTV',
              class: 'col-xs-12 col-md-3'
            },
            {
              key: 'partner_status',
              title: 'Trạng thái',
              class: 'col-xs-6 col-md-1'
            },
            {
              key: 'control',
              title: 'Xử lý',
              class: 'col-xs-6 col-md-1'
            }
          ]
        }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
        this.seach();
    },
    methods: {
      getData() {
        var vm = this
        axios.get('/api/ctv/partner/seach'+ '?' + $.param(this.params))
            .then(function(response) {
                NProgress.done()
                var dataResponse = response.data.data
                vm.data = dataResponse.data
                //Phân trang - gắng tổng số row
                vm.pagination.rows = dataResponse.total
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
      handlePageChange(value){
        //Phân trang - gáng giá trị
        this.params.page = value
        this.params.perPage = this.pagination.perPage

        this.getData()
      },
      seach() {
        //Phân trang - gáng giá trị
        this.params.page = 1
        this.pagination.currentPage = 1
        this.params.perPage = this.pagination.perPage

        this.getData()
      },
      add() {
        this.$refs.formPartner.open();
        $('#modal-form').modal('show')
      },
      edit(id) {
        this.$refs.formPartner.open(id);
        $('#modal-form').modal('show')
      },
      remove(id) {
        var $this = this
        this.$dialog.confirm('Bạn có muốn xóa?', {
            loader: true,
            okText: 'Đồng ý',
            cancelText: 'Hủy bỏ'
        })
            .then((dialog) => {
                $this.del(id)
                dialog.close()
            });
      },
      del(id){
        var vm = this
        axios['put']('/api/ctv/partner/delete/'+id)
        .then(function (response) {
            vm.$snotify.success('Xóa thành công!', 'Success')
            vm.seach()
        })
        .catch(function (error) {
            console.log(error)
        })
      },
      saveSuccess()
      {
        $('#modal-form').modal('hide')
        this.seach()
      }
    }
}
</script>