export default {
    partner_id: '',
    partner_shop_id: '',
    partner_type: 0,
    partner_phone: '',
    partner_name: '',
    partner_shop_name: '',
    partner_email: '',
    partner_nickname: '',
    partner_address: '',
    partner_description: '',
    partner_status: 1,
    partner_user_id: 0,
    partner_begindate: '',
    partner_phone_text: '',
    partner_website: '',
    partner_facbook: '',
    partner_description_pay: '',
    partner_description_price: '',
    partner_surcharge: 0,
    
    partner_discounts: []
}
