export default {
    id: '',
    output_type_id: 0,
    output_date: '',
    output_user_id: 0,
    total_payment: 0,
    total_amount: 0,
    shipping_cost: 0,
    total_weight: 0,
    customer_id: 0,
    customer_name: '',
    customer_nickname: '',
    customer_phone: '',
    customer_address: '',
    note: '',
    note_internal: '',
    voucherconcern: '',
    status: 2,
    status_update_date: '',
    status_update_user_id: 0,
    outputvourcher_details: [],
    shop_id: '',
    shop_price_id: '',
    source_address_id: '',
    
    total_quantity: 0,


    customer_province_id: '',
    customer_province: {},
    customer_district_id: '',
    customer_district: {},
    customer_ward_id: '',
    customer_ward: {},
    customer_street: '',

    delivery_type: 0,
    customer_link: '',
    partner_id: '',

    payment_not_auto_confirm: 0,
    payment_note: '',
    return_quantity_note: 0,
    surcharge: 0
}
