<template>
<div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
            <!-- box-body -->
            <div class="box-body">
                <VNCPOSTFee>
                </VNCPOSTFee>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import VNCPOSTFee from '../../../components/VNCPOST/VNCPOSTFee.vue'

    export default {
        name: 'VNCPOSTFeeShow',
        components: {
            VNCPOSTFee
        },
        data() {
            return {
                title: 'Tra cứu cước phí VNCPOST',
            }
        },
        mounted(){
            this.$store.dispatch('setPageTitle', this.title);
        },
        methods: {
            
        }
    }
</script>
<style lang="css" scoped>

</style>