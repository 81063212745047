<template>
    <ul class="list-group list-group-unbordered">
        <li class="list-group-item hidden-xs">
            <div class="row">
                <div class="" v-for="item in thead" :key="item.key" :class="item.class">
                    <span class="text-bold">{{item.title}}</span>
                </div>
            </div>
        </li>
        <li class="list-group-item" v-for="(item, index) in data" :item="item" :key="index">
            <div class="row">
                <div class="" v-for="itemhead in thead" :key="itemhead.key" :class="itemhead.class">
                    <slot :item="item" :name="itemhead.key"></slot>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'DataViewer',
    components: {
    },
    props: {
        thead: '',
        data: ''
    },
    data() {
        return {
            
        }
    },
    computed: {

    },
    methods: {
        
    }
}
</script>