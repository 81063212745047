<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-header -->
          <div class="box-header">
            <div class="row">
              <div class="col-xs-12 col-md-12" v-if="user_count>0">
                  <span class="text-bold text-red">Tài khoản bạn có {{user_count}} chưa soạn xong </span>
                  <router-link
                    :to="'/outputvourcher/soan-hang-nhan-vien'">
                    Tới trang soạn hàng
                  </router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-md-2">
                <div class="form-group">
                  <input type="text" class="form-control" v-model="params.keyword" v-on:keyup.enter="seach">
                </div> 
              </div>

              <div class="col-xs-12 col-md-4">
                <div class="form-group">
                  <Select2 :id="'shop_id'" 
                    :name="'shop_id'" 
                    :dataUrl="'/api/shop/all'"
                    v-model="params.shop_id"
                    :multiple="true">
                  </Select2>
                </div>
              </div>

              <div class="col-xs-12 col-md-2">
                    <div class="form-group">
                      <select v-model="params.pickup_user_id" 
                          id="pickup_user_id" 
                          name="pickup_user_id" 
                          class="form-control">
                          <option value="-1">Tất cả</option>
                          <option value="5">Phan Tiến Nhất</option>
                          <option value="1">Hà Thanh Mỹ</option>
                          <option value="3">Hà Thị Hồng Thúy</option>
                          <option value="4">Hà Thanh Thùy</option>
                          <option value="7">Trần Thị Thu</option>
                          <option value="6">Nguyễn Quang Minh</option>
                      </select>
                    </div>
              </div>

              <div class="col-xs-12 col-md-2">
                    <div class="form-group">
                      <select v-model="params.isprint" 
                          id="isprint" 
                          name="isprint" 
                          class="form-control">
                          <option value="-1">Tất cả</option>
                          <option value="0">Chưa in</option>
                          <option value="1">Đã in</option>
                      </select>
                    </div>
              </div>

              <div class="col-xs-12 col-md-2">
                <div class="form-group">
                  <button type="button" class="btn btn-default btn-block btn-flat" v-on:click="seach"><i class="fa fa-fw fa-search"></i> Tìm kiếm</button>
                </div>
              </div>

            </div>
          </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <data-viewer :thead="thead" :data="data">
              <template slot-scope="props" slot='outputvourcher_info'>
                <div class="col-xs-12 col-md-12">
                  <span class="label label-success" v-if="props.item.status === 1">Hoàn tất</span>
                  <span class="label label-default" v-else-if="props.item.status === 2">Chưa chốt</span>
                  <span class="label label-primary" v-else-if="props.item.status === 3">Đã chốt</span>
                  <span class="label label-info" v-else-if="props.item.status === 4">Đang soạn</span>
                  <span class="label label-warning" v-else-if="props.item.status === 5">Đang soạn - chờ hàng</span>
                  <span class="label label-primary" v-else-if="props.item.status === 6">Soạn xong</span>
                  <span class="label label-danger" v-else>Đã hủy</span>
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="label label-success" v-if="props.item.delivery_type == 0">Đơn vị vận chuyển - GHTK</span> 
                  <span class="label label-success" v-else-if="props.item.delivery_type == 3">Đơn vị vận chuyển - Bưu điện</span> 
                  <span class="label label-success" v-else-if="props.item.delivery_type == 4">Đơn vị vận chuyển - VNCPOST</span> 
                  <span class="label label-success" v-else-if="props.item.delivery_type == 1">Shop giao</span> 
                  <span class="label label-success" v-else-if="props.item.delivery_type == 5">Đơn vị vận chuyển - JTEXPRESS</span> 
                  <span class="label label-success" v-else>Lấy tại shop</span> 
                </div>

                <div class="col-xs-12 col-md-6" v-if="props.item.shipment_id">
                  <span class="text-bold">Mã VĐ: </span>{{props.item.shipment_id}}
                </div>

                <div class="col-xs-12 col-md-12" v-if="props.item.shop_name">
                    <span class="text-bold">Cữa hàng: </span>{{props.item.shop_name}}
                </div>
                
                <div class="col-xs-12 col-md-12" v-if="props.item.partner_name">
                    <span class="text-bold">CTV: </span>{{props.item.partner_name}} - {{props.item.partner_phone}}
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Mã phiếu: </span>
                  <router-link
                    :to="'/outputvourcher/sale/edit/'+props.item.id">
                    {{props.item.id}}
                  </router-link>
                </div>

                 <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Ngày xuất: </span>{{moment(props.item.output_date).format('DD/MM/YYYY')}}
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Ghi chú: </span><span class="text-bold text-info">{{props.item.note}}</span>
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Ghi chú nội bộ: </span><span class="text-bold text-red">{{props.item.note_internal}}</span>
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Tiền thu khách: </span>{{parseFloat(props.item.total_payment)| numFormat}}
                </div>
              </template>

              <template slot-scope="props" slot='outputvourcher_customer'>
                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Khách hàng: </span>{{props.item.customer_name}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Điện thoại: </span>{{props.item.customer_phone}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Nick Name: </span>{{props.item.customer_nickname}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Địa chỉ: </span>{{props.item.customer_address}}
                </div>
              </template>

              <template slot-scope="props"  slot='control'>
                <a v-if="props.item.pickup_user_id == null"
                  class="btn btn-xs btn-primary btn-block btn-flat" 
                  @click="updateStatus(props.item.id, props.item.status, 4)">
                  SOẠN
                </a>

                <a v-if="props.item.pickup_user_id == null"
                  class="btn btn-xs btn-success btn-block btn-flat" 
                  @click="user_add(props.item.id)">
                  Lấy đơn soạn
                </a>

                <span class="text-bold text-red" v-if="props.item.pickup_user_id">Đang soạn - {{props.item.pickup_user_name}}</span>

                <a class="btn btn-xs btn-default btn-block btn-flat" @click="print('print', props.item.id)">
                  <i class="fa fa-print"></i> In hóa đơn <span class="text-red" v-if="props.item.isprint == true">(Đã in)</span>
                </a>
                <a class="btn btn-xs btn-default btn-block btn-flat" @click="print('print_a7',props.item.id)">
                  <i class="fa fa-print"></i> In hóa đơn A7<span class="text-red" v-if="props.item.isprint == true">(Đã in)</span>
                </a>

                  <!-- Mẫu in bill-->
                <bill :data="props.item" :id="'print_'+props.item.id" v-show="false"></bill>
                <!-- ./Mẫu in bill-->
                <!-- Mẫu in bill A7-->
                <BillA7 :data="props.item" :id="'print_a7_'+props.item.id" v-show="false"></BillA7>
                <!-- ./Mẫu in bill A7-->
              </template>
            </data-viewer>
            <div class="row">
              <div class="col-xs-6 col-md-2">
                <span class="text-bold" v-if="total_count>0">Tổng đơn: {{total_count}}</span>
              </div>
            </div>
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import DataViewer from '../../../components/DataViewer'
import Bill from '../../reports/bill';
import Select2 from '../../../components/Select2.vue';
import BillA7 from '../../reports/BillA7';

export default {
    name: 'SoanHangIndex',
    components: {
       VuejsDialog, DataViewer, Bill, Select2, BillA7
    },
    data() {
        return {
          title: 'Soạn hàng',
          data:[],
          user_count: 0,
          params: {
              keyword: '',
              pickup_user_id: -1,
              isprint: -1,
              shop_id: ''
          },
          thead: [
            {
              key: 'outputvourcher_info',
              title: 'Thông tin đơn',
              class: 'col-xs-12 col-md-5'
            },
            {
              key: 'outputvourcher_customer',
              title: 'Thông tin khách hàng',
              class: 'col-xs-12 col-md-5'
            },
            {
              key: 'control',
              title: '',
              class: 'col-xs-12 col-md-2'
            }
          ]
        }
    },
    computed: {
        total_count: function () {
          var countRow = 0
          if(this.data){
            countRow = this.data.length
          }
          return countRow
        }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
        this.seach();
    },
    methods: {
      seach() {
        NProgress.start()
        var vm = this
        axios.get('/api/inventory/output/soan-hang/seach'+ '?' + $.param(this.params))
            .then(function(response) {
                NProgress.done()
                var result = response.data.data
                vm.data = result.data
                vm.user_count = result.user_count
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
      updateStatus(id, statusold, status){
          var vm = this
          if(statusold == 3){
            axios['put']('/api/inventory/output/update_status/'+id+'/'+status)
            .then(function (response) {
              vm.$router.push({path: '/outputvourcher/show/'+id})
            })
            .catch(function (error) {
                console.log(error)
            })
          }else{
            vm.$router.push({path: '/outputvourcher/show/'+id})
          }
      },
      print(printName, id) {
          var ouputTemp = this.data.find(output => output.id == id)
          if(ouputTemp.delivery_type == 0 && !ouputTemp.shipment_id){
              this.$dialog.alert('Đơn hàng gửi GHTK nhưng chưa đẩy đơn. Vui lòng đẩy đơn trước khi in!').then(function(dialog) {
                      
              });
              return;
          }

          var vm = this
          this.$htmlToPaper(printName+'_'+id, () => {
              axios['put']('/api/inventory/output/sale/update_outputvourcher_isprint/'+id)
              .then(function (response) {
                  vm.seach();
              })
              .catch(function (error) {
                  console.log(error)
              })
              //console.log('In thành công');
          });
      },
      user_add(id){
          var vm = this
          var result = {
                  id: id
              }

          axios['put']('/api/inventory/output/soan-hang/user_add', result)
          .then(function (response) {
              vm.data = {}
              var result = response.data
              if(result.success==true){
                  vm.data = result.data
                  vm.seach();
                  vm.$snotify.success(result.message, 'Success')
              }else{
                  vm.seach();
                  vm.$snotify.error(result.message, 'Lỗi')
                  
              }
          })
          .catch(function (error) {
              vm.$snotify.error('Có lỗi xảy ra', 'Lỗi')
              vm.formErrors = error.response.data.errors
          })
      }

    }
}
</script>