<template>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <!-- box -->
            <div class="box">
                <!-- box-body -->
                <div class="box-body">
                    <div class="row">
                        <!-- Thông tin khách hàng và tổng tiền -->
                        <div class="col-md-4 col-sm-12 col-xs-12">
                            <!-- box Thông tin -->
                            <div class="box box-primary">
                                <!-- box-header -->
                                <div class="box-header with-border">
                                    <h3 class="box-title">Thông tin</h3>
                                </div>
                                <!-- /.box-header -->
                                <!-- box-body -->
                                <div class="box-body">
                                    <div class="col-md-12 col-xs-12" v-if="seach_output">
                                        <div class="form-group">
                                            <label class="control-label" for="keywork_output_seach">Tìm phiếu xuất</label><br>
                                            <span class="text-red">Vui lòng nhập số điện thoại nếu khách mới hoặc đầy đủ mã phiếu xuất hoặc các số cuối(VD: 0120OV0008401 => nhập 8401)</span>
                                            <div class="input-group">
                                                <input type="text" 
                                                class="form-control" 
                                                id="keywork_output_seach" 
                                                name="keywork_output_seach" 
                                                onClick="this.select();"
                                                v-model="keywork_output_seach" 
                                                placeholder="Nhập mã phiếu xuất"
                                                autocomplete = "off">
                                                <span class="input-group-btn">
                                                    <button type="button" class="btn btn-default btn-flat" @click="SeachOutput()"><i class="fa fa-fw fa-search"></i></button>
                                                </span>
                                            </div>
                                            <span class="text-red" v-if="outputvourcher.id">Mã phiếu xuất: {{outputvourcher.id}}</span>
                                        </div>
                                        
                                    </div>

                                    <div class="col-xs-12 col-md-12">
                                        <div class="form-group" :class="{'has-error': formErrors.input_type_id}">
                                            <label class="control-label" for="input_type_id">Hình thức nhập<span class="text-red">(*)</span></label>
                                            <Select2 
                                                :id="'input_type_id'" 
                                                :name="'input_type_id'" 
                                                :dataUrl="'/api/input_type/all'" 
                                                v-model="form.input_type_id" 
                                                :disabled="input_type_id>0"
                                                :multiple="false">
                                            </Select2>
                                            <span class="help-block" v-if="formErrors.input_type_id">{{formErrors.input_type_id[0]}}</span>
                                        </div>
                                    </div>

                                    <input type="hidden" id="customer_id" name="customer_id" v-model="form.customer_id">
                                    <div class="col-md-12 col-xs-12">
                                        <div class="form-group" :class="{'has-error': formErrors.customer_name}">
                                            <label class="control-label" for="customer_name">Họ tên<span class="text-red">(*)</span></label>
                                            <div class="input-group">
                                                <input type="text" 
                                                class="form-control" 
                                                id="customer_name" 
                                                name="customer_name" 
                                                onClick="this.select();"
                                                v-model="form.customer_name" 
                                                placeholder="Nhập tên khách hàng"
                                                autocomplete = "off"
                                                readonly>
                                                <span class="input-group-btn">
                                                    <button type="button" class="btn btn-default btn-flat" @click="CustomerSeachShow()"><i class="fa fa-fw fa-search"></i></button>
                                                </span>
                                            </div>
                                            <CustomerSeach ref="CustomerSeach"
                                                :type="customer_type_id"
                                                @selected="CustomerSeach">
                                            </CustomerSeach>
                                            <span class="help-block" v-if="formErrors.customer_name">{{formErrors.customer_name[0]}}</span>
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-xs-12">
                                        <div class="form-group" :class="{'has-error': formErrors.customer_phone}">
                                            <label class="control-label" for="customer_phone">Điện thoại<span class="text-red">(*)</span></label>
                                            <input type="text" 
                                            class="form-control" 
                                            id="customer_phone" 
                                            name="customer_phone" 
                                            v-model="form.customer_phone" 
                                            placeholder="Nhập điện thoại"
                                            autocomplete = "off"
                                            readonly>
                                            <span class="help-block" v-if="formErrors.customer_phone">{{formErrors.customer_phone[0]}}</span>
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-xs-12">
                                        <div class="form-group" :class="{'has-error': formErrors.customer_nickname}">
                                            <label class="control-label" for="customer_nickname">Nick Name</label>
                                            <input type="text" 
                                            class="form-control" 
                                            id="customer_nickname" 
                                            name="customer_nickname" 
                                            onClick="this.select();"
                                            v-model="form.customer_nickname" 
                                            placeholder="Nhập facbook/zalo"
                                            autocomplete = "off"
                                            readonly>
                                            <span class="help-block" v-if="formErrors.customer_nickname">{{formErrors.customer_nickname[0]}}</span>
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-xs-12">
                                        <div class="form-group" :class="{'has-error': formErrors.customer_address}">
                                            <label class="control-label" for="customer_address">Địa chỉ đầy đủ<span class="text-red">(*)</span></label>
                                            <textarea class="form-control" rows="2" 
                                                id="customer_address" 
                                                name="customer_address"
                                                onClick="this.select();"
                                                v-model="form.customer_address"
                                                placeholder="Nhập địa chỉ"
                                                readonly>
                                            </textarea>
                                            <span class="help-block" v-if="formErrors.customer_address">{{formErrors.customer_address[0]}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-xs-12">
                                        <div class="form-group" :class="{'has-error': formErrors.note}">
                                            <label class="control-label" for="customer_address">Ghi chú</label>
                                            <textarea class="form-control" rows="2" 
                                                id="note" 
                                                name="note"
                                                v-model="form.note"
                                                placeholder="Nhập ghi chú thêm">
                                            </textarea>
                                            <span class="help-block" v-if="formErrors.note">{{formErrors.note[0]}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="control-label" for="GiaThoaThuan">Giá nhập:</label>
                                            <div class="input-group">
                                                <input type="number" class="form-control"
                                                id="GiaNhap" 
                                                name="GiaNhap"
                                                v-model="GiaNhap"
                                                min="0">
                                                <span class="input-group-btn">
                                                    <button type="button" class="btn btn-default btn-flat" @click="refreshPriceGiaNhap"><i class="glyphicon glyphicon-refresh"></i></button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Không hiển thị nếu là mobile -->
                                    <div class="col-md-12 col-xs-12 hidden-xs">
                                        <hr>
                                    </div>

                                    <div class="col-md-6 col-xs-6 hidden-xs">
                                        <div class="form-group" :class="{'has-error': formErrors.total_quantity}">
                                            <label class="control-label">Tổng số lượng:</label>
                                            <input type="number" class="form-control" readonly
                                                id="total_quantity" 
                                                name="total_quantity"
                                                v-model="form.total_quantity">
                                            <span class="help-block" v-if="formErrors.total_quantity">{{formErrors.total_quantity[0]}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-xs-6 hidden-xs">
                                        <div class="form-group" :class="{'has-error': formErrors.total_amount}">
                                            <label class="control-label">Tổng tiền hàng:</label>
                                            <input type="number" class="form-control"
                                                id="total_amount" 
                                                name="total_amount"
                                                v-model="form.total_amount" readonly>
                                            <span class="help-block" v-if="formErrors.total_amount">{{formErrors.total_amount[0]}}</span>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-xs-6 hidden-xs">
                                        <div class="form-group" :class="{'has-error': formErrors.shipping_cost}">
                                            <label class="control-label">Tiền vận chuyển:</label>
                                            <input type="number" class="form-control"
                                                id="shipping_cost" 
                                                name="shipping_cost"
                                                v-model="form.shipping_cost"
                                                @change="checkSum"
                                                min="0">
                                            <span class="help-block" v-if="formErrors.shipping_cost">{{formErrors.shipping_cost[0]}}</span>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-xs-6 hidden-xs">
                                        <div class="form-group" :class="{'has-error': formErrors.total_payment}">
                                            <label class="control-label">Tiền thanh toán:</label>
                                            <input type="number" class="form-control"
                                                id="total_payment" 
                                                name="total_payment"
                                                v-model="form.total_payment"
                                                min="0">
                                            <span class="help-block" v-if="formErrors.total_payment">{{formErrors.total_payment[0]}}</span>
                                        </div> 
                                    </div>
                                    <div class="col-md-12 col-xs-12 hidden-xs">
                                        <button type="button" class="btn btn-primary btn-block btn-flat" @click="save()" :disabled="enableButtonSave"><i class="fa fa-save"></i> Cập nhật</button>
                                    </div>
                                    <!-- ./Không hiển thị nếu là mobile -->
                                    
                                </div>
                                <!-- ./box-body -->
                            </div>
                            <!-- ./box Thông tin -->
                        </div>
                        <!-- ./Thông tin khách hàng và tổng tiền -->

                        <!-- Thông tin sản phẩm -->
                        <div class="col-md-8 col-sm-12 col-xs-12">
                            <!-- box Thông tin -->
                            <div class="box box-primary">
                                <!-- box-header -->
                                <div class="box-header with-border">
                                    <product-seach @productSelected="productSelected" :autoClearKeyword='true'>
                                    </product-seach>
                                </div>
                                <!-- /.box-header -->
                                <!-- box-body -->
                                <div class="box-body">
                                    <!-- row-product -->
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-xs-12" v-for="(item, index) in form.inputvourcher_details" :key="index">
                                            <!-- info-box -->
                                            <div class="info-box pull-left">
                                                <span class="info-box-icon">
                                                    <img :src="item.product.image.link_image" :alt="item.product.name">
                                                </span>

                                                <div class="info-box-content row">
                                                    <div class="col-md-12 col-xs-12">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <span class="info-box-text text-green">
                                                                    {{item.product.id}} - {{item.product.name}}
                                                                    <span class="label label-success" v-if="item.product.model.status === 1" v-show="false">Đang bán</span>
                                                                    <span class="label label-warning" v-else-if="item.product.model.status === 2">Chờ hàng</span>
                                                                    <span class="label label-danger" v-else>Ngưng bán</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6 col-xs-12">
                                                                <span class="text-bold">Tồn kho: </span>  
                                                                Tồn<span class="text-light-blue">({{parseFloat(item.product.currentinstock.quantity)}})</span> - Đặt<span class="text-red">({{parseFloat(item.product.currentinstock.order_quantity)}})</span>
                                                            </div>
                                                            <div class="col-md-6 col-xs-12">
                                                                <span class="text-bold">Vị trí:</span>  
                                                                <span>{{item.product.model.location}}</span> 
                                                            </div>
                                                            <div class="col-md-4 col-xs-7">
                                                                <div class="form-group" :class="{'has-error': formErrors['inputvourcher_details.'+index+'.quantity']}">
                                                                    <label class="control-label">Số lượng:</label>
                                                                    <number-button 
                                                                        :name="'inputvourcher_details['+index+'][quantity]'" 
                                                                        v-model="item.quantity"
                                                                        @change="checkSum">
                                                                    </number-button>
                                                                    <span class="help-block" v-if="formErrors['inputvourcher_details.'+index+'.quantity']">{{formErrors['inputvourcher_details.'+index+'.quantity'][0]}}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 col-xs-5">
                                                                <div class="form-group" :class="{'has-error': formErrors['inputvourcher_details.'+index+'.price']}">
                                                                    <label class="control-label">Đơn giá:</label>
                                                                    <input type="number" class="form-control" 
                                                                        id="inputvourcher_details['price']" 
                                                                        :name="'inputvourcher_details['+index+'][price]'" 
                                                                        v-model="item.price"
                                                                        step="1"
                                                                        min="0"
                                                                        @change="checkSum">
                                                                    <span class="help-block" v-if="formErrors['inputvourcher_details.'+index+'.price']">{{formErrors['inputvourcher_details.'+index+'.price'][0]}}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 col-xs-6">
                                                                <div class="form-group" :class="{'has-error': formErrors['inputvourcher_details.'+index+'.total']}">
                                                                    <label class="control-label">Thành tiền:</label>
                                                                    <input type="number" class="form-control" 
                                                                        id="inputvourcher_details['total']" 
                                                                        :name="'inputvourcher_details['+index+'][total]'" 
                                                                        v-model="item.total" readonly>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-2 col-xs-6">
                                                                <div class="form-group">
                                                                <label class="control-label"></label>
                                                                <a class="btn btn-danger btn-block"  
                                                                v-on:click="deleteDetail(index)">
                                                                    <i class="fa fa-fw fa-trash"></i>
                                                                </a>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- /.info-box-content -->
                                            </div>
                                            <!-- /.info-box -->
                                        </div>
                                    </div>
                                    <!-- ./row-product -->

                                    <!-- Không hiển thị web chú ý sửa hiển thị web -->
                                    <div class="row">
                                        <div class="col-md-12 col-xs-12 hidden-md hidden-sm hidden-lg">
                                            <hr>
                                        </div>

                                        <div class="col-md-6 col-xs-6 hidden-md hidden-sm hidden-lg">
                                            <div class="form-group" :class="{'has-error': formErrors.total_quantity}">
                                                <label class="control-label">Tổng số lượng:</label>
                                                <input type="number" class="form-control" readonly
                                                    id="total_quantity" 
                                                    name="total_quantity"
                                                    v-model="form.total_quantity">
                                                <span class="help-block" v-if="formErrors.total_quantity">{{formErrors.total_quantity[0]}}</span>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-xs-6 hidden-md hidden-sm hidden-lg">
                                            <div class="form-group" :class="{'has-error': formErrors.total_amount}">
                                                <label class="control-label">Tổng tiền hàng:</label>
                                                <input type="number" class="form-control"
                                                    id="total_amount" 
                                                    name="total_amount"
                                                    v-model="form.total_amount" readonly>
                                                <span class="help-block" v-if="formErrors.total_amount">{{formErrors.total_amount[0]}}</span>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-xs-6 hidden-md hidden-sm hidden-lg">
                                            <div class="form-group" :class="{'has-error': formErrors.shipping_cost}">
                                                <label class="control-label">Tiền vận chuyển:</label>
                                                <input type="number" class="form-control"
                                                    id="shipping_cost" 
                                                    name="shipping_cost"
                                                    v-model="form.shipping_cost"
                                                    @change="checkSum"
                                                    min="0">
                                                <span class="help-block" v-if="formErrors.shipping_cost">{{formErrors.shipping_cost[0]}}</span>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-xs-6 hidden-md hidden-sm hidden-lg">
                                            <div class="form-group" :class="{'has-error': formErrors.total_payment}">
                                                <label class="control-label">Tiền thanh toán:</label>
                                                <input type="number" class="form-control"
                                                    id="total_payment" 
                                                    name="total_payment"
                                                    v-model="form.total_payment"
                                                    min="0">
                                                <span class="help-block" v-if="formErrors.total_payment">{{formErrors.total_payment[0]}}</span>
                                            </div> 
                                        </div>

                                        <div class="col-md-12 col-xs-12 hidden-md hidden-sm hidden-lg">
                                            <button type="button" class="btn btn-primary btn-block btn-flat" @click="save()" :disabled="enableButtonSave"><i class="fa fa-save"></i> Cập nhật</button>
                                        </div>
                                    </div>
                                    <!-- ./Không hiển thị web chú ý sửa hiển thị web -->
                                </div>
                                <!-- ./box-body -->
                            </div>
                            <!-- ./box Thông tin -->
                        </div>
                        <!-- ./Thông tin sản phẩm -->
                    </div>
                    <!-- Điều hướng quay lại -->
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <a class="btn btn-default" @click="goBack()"><i class="glyphicon glyphicon-arrow-left"></i> Quay lại</a>
                    </div>
                    <!-- ./Điều hướng quay lại -->
                </div>
                <!-- /.box-body -->
            </div>
            <!-- /.box -->
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import VuejsDialog from "vuejs-dialog"
import NProgress from 'nprogress'
import Select2 from '../../../components/Select2New.vue'
import NumberButton from '../../../components/NumberButton.vue';
import ProductSeach from '../../../components/ProductSeach/ProductSeach.vue';
import CustomerSeach from '../../../components/CustomerSeach.vue';
import inputvourcher from '../../../objects/inputvourcher'

export default {
    name: 'inputVourcherAdd',
        components: {
            ProductSeach, 
            NProgress,
            Select2, 
            NumberButton, 
            VuejsDialog, 
            CustomerSeach
        },
    props: {
        input_type_id: {
            type: Number,
            default: 0
        },
        customer_type_id: {
            type: Number,
            default: -1
        },
        seach_output: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            formErrors: {},
            form: {},
            enableButtonSave: false,
            GiaNhap: '',
            keywork_output_seach: '',
            outputvourcher:{}
        }
    },
    watch: {
        input_type_id: function (val){
             if(val > 0){
                this.form.input_type_id = val

            }
        }
    },
    mounted(){
        this.form = Object.assign({}, inputvourcher)
        this.form.inputvourcher_details = []
        this.outputvourcher = {}
        this.keywork_output_seach = ''
        this.getData();
    },
    methods: {
        goBack(){
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push('/')
        },
        getData() {
            
        },
        SeachOutput(){
            var vm = this
            if(vm.keywork_output_seach.trim() == ''){
                var textMessage = 'Vui lòng nhập mã phiếu xuất!'
                vm.$dialog.alert(textMessage, {
                                    okText: 'Đồng ý'
                                })
                .then(function(dialog) {
                    return
                });
            }

            var requestdata = {
                keyword: vm.keywork_output_seach
            }
            axios.get('/api/inventory/output/sale/outputvourcher_return_sale' + '?' + $.param(requestdata))
                .then(function(response) {
                    if(response.data.success==true){
                        var result = response.data.data
                        if(result == null){
                            var error = "Không tìm thấy thông tin phiếu xuất."
                            vm.$snotify.error(error, 'Error')
                            return
                        }else{
                            vm.outputvourcher = result
                            vm.form = Object.assign({}, inputvourcher)
                            vm.form.inputvourcher_details = []
                            vm.form.input_type_id = vm.input_type_id

                            //gán thông tin master
                            vm.form.customer_id = result.customer_id
                            vm.form.customer_phone = result.customer_phone
                            vm.form.customer_name = result.customer_name
                            vm.form.customer_nickname = result.customer_nickname
                            vm.form.customer_address = result.customer_address
                            vm.form.voucherconcern = result.id
                            //gán thông tin detail
                            for (var i = 0; i < result.outputvourcher_details.length; i++) {
                                if(result.outputvourcher_details[i].status === 1){
                                    var newItem = {}
                                    newItem.id = ''
                                    newItem.inputvourcher_id = ''
                                    newItem.product_id = result.outputvourcher_details[i].product_id
                                    newItem.product = result.outputvourcher_details[i].product
                                    newItem.quantity = parseFloat(result.outputvourcher_details[i].quantity)
                                    newItem.price = 0
                                    newItem.total = 0
                                    newItem.status = 1
                                    vm.form.inputvourcher_details.push(newItem)
                                }
                            }

                            vm.checkSum()
                        }
                    }else{
                        vm.$snotify.warning(response.data.message, 'Thông báo')
                    }
                   
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        save() {
            this.formErrors = {}
            this.validate()
            if(Object.keys(this.formErrors).length === 0 && this.formErrors.constructor === Object){
                NProgress.start()
                let vm = this
                vm.enableButtonSave = true

                axios['post']('/api/inventory/input/insert', vm.form)
                .then(function (response) {
                    NProgress.done()
                    vm.$snotify.success('Thêm mới thành công!', 'Success')
                    vm.$router.push({path: '/inputvourcher/show/'+response.data.data.id})
                    vm.enableButtonSave = false
                })
                .catch(function (error) {
                    vm.enableButtonSave = false
                    NProgress.done()
                    vm.$snotify.error('Cập nhật thất bại, vui lòng kiểm tra lại!', 'Error')
                    vm.formErrors = error.response.data.errors
                })
            }
        },
        validate(){
            if(this.form.inputvourcher_details.length===0){
                var error = "Vui lòng chọn sản phẩm nhập"
                this.formErrors.product = error
                this.$snotify.error(error, 'Error')
                return
            }
        },
        productSelected(product, quantity) {
            var productIndex= this.form.inputvourcher_details.findIndex(o => o.product_id == product.id)                                                       
            if(productIndex<0){
                var newItem = {}
                newItem.id = ''
                newItem.inputvourcher_id = ''
                newItem.product_id = product.id
                newItem.product = product
                newItem.quantity = parseFloat(quantity)
                newItem.price = 0
                newItem.total = 0
                newItem.status = 1
                this.form.inputvourcher_details.push(newItem)
            }else{
                this.form.inputvourcher_details[productIndex].quantity = parseFloat(this.form.inputvourcher_details[productIndex].quantity) + parseFloat(quantity)
            }
            this.checkSum()
        },
        deleteDetail(index) {
            this.form.inputvourcher_details.splice(index, 1) 
            this.checkSum()
        },
        checkSum() {
            this.form.total_quantity = 0
            this.form.total_amount = 0
            for (var i = 0; i < this.form.inputvourcher_details.length; i++) {
                this.form.inputvourcher_details[i].total =  parseFloat(this.form.inputvourcher_details[i].quantity) * parseFloat(this.form.inputvourcher_details[i].price)
                this.form.total_quantity = parseFloat(this.form.total_quantity) + parseFloat(this.form.inputvourcher_details[i].quantity)
                this.form.total_amount = parseFloat(this.form.total_amount) + parseFloat(this.form.inputvourcher_details[i].total)
            }

            this.form.total_payment = parseFloat(this.form.total_amount) + parseFloat(this.form.shipping_cost)
        },
        refreshPriceGiaNhap(){
            let vm = this
            if(this.GiaNhap.trim() == ''){
                var textMessage = 'Vui lòng nhập giá nhập!'
                vm.$dialog.alert(textMessage, {
                                    okText: 'Đồng ý'
                                })
                .then(function(dialog) {
                    return
                });
            }
            if(this.form.inputvourcher_details.length>0){
                for (var i = 0; i < this.form.inputvourcher_details.length; i++) {
                    if(parseFloat(this.GiaNhap) > 0){
                        this.form.inputvourcher_details[i].price = parseFloat(this.GiaNhap)
                    }  
                }
                this.checkSum()
            }
        },
        CustomerSeachShow() {
            this.$refs.CustomerSeach.open();
        },
        CustomerSeach(result){
            this.form.customer_id = result.id
            this.form.customer_phone = result.phone
            this.form.customer_name = result.name
            this.form.customer_nickname = result.nickname
            this.form.customer_address = result.address
        },

    }
}
</script>
<style lang="css" scoped>
.info-box-icon>img {
    width: 100%;
    max-height: 90px;
    vertical-align: unset;
}
.info-box-content{
    padding: 0px;
}
.info-box-text{
    white-space: unset;
}
.info-box {
    padding-bottom: 10px;
}
</style>