<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-header -->
          <div class="box-header">
            <div class="row">
              <div class="col-xs-12 col-md-8">
                <div class="form-group">
                <div class="input-group">
                  <input type="text" class="form-control" 
                    onClick="this.select();" 
                    v-model="params.keyword" 
                    v-on:keyup.enter="seach"
                    ref="search"
                    autofocus>
                  <span class="input-group-btn">
                    <button type="button" class="btn btn-default btn-block btn-flat" v-on:click="seach"><i class="fa fa-fw fa-search"></i> Tìm kiếm</button>
                  </span>
                </div>
                </div>
              </div>

              <div class="col-xs-12 col-md-4">
                <div class="form-group">
                    <select class="form-control" v-model="pick_work_shift">
                        <option value="1">Buổi Sáng</option>
                        <option value="2">Buổi chiều</option>
                        <option value="3">Buổi tối</option>
                    </select>
                </div> 
              </div>

            </div>
          </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <data-viewer :thead="thead" :data="data">
              <template slot-scope="props" slot='outputvourcher_info'>
                <div class="col-xs-12 col-md-12">
                  <span class="label label-success" v-if="props.item.delivery_type == 0">Đơn vị vận chuyển - GHTK</span> 
                  <span class="label label-success" v-else-if="props.item.delivery_type == 3">Đơn vị vận chuyển - Bưu điện</span> 
                  <span class="label label-success" v-else-if="props.item.delivery_type == 4">Đơn vị vận chuyển - VNCPOST</span> 
                  <span class="label label-success" v-else-if="props.item.delivery_type == 1">Shop giao</span> 
                  <span class="label label-success" v-else-if="props.item.delivery_type == 5">Đơn vị vận chuyển - JTEXPRESS</span> 
                  <span class="label label-success" v-else>Lấy tại shop</span> 
                </div>

                <div class="col-xs-12 col-md-12" v-if="props.item.shop">
                    <span class="text-bold">Cữa hàng: </span>{{props.item.shop.name}}
                </div>
                
                <div class="col-xs-12 col-md-12" v-if="props.item.partner">
                    <span class="text-bold">CTV: </span>{{props.item.partner.partner_name}} - {{props.item.partner.partner_phone}}
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Mã phiếu: </span>
                  <router-link
                    :to="'/outputvourcher/sale/edit/'+props.item.id">
                    {{props.item.id}}
                  </router-link>
                </div>

                 <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Ngày xuất: </span>{{moment(props.item.output_date).format('DD/MM/YYYY')}}
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Ghi chú: </span><span class="text-bold text-red">{{props.item.note}}</span>
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Tiền thu khách: </span>{{parseFloat(props.item.total_payment)| numFormat}}
                </div>
              </template>

              <template slot-scope="props" slot='outputvourcher_customer'>
                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Khách hàng: </span>{{props.item.customer_name}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Điện thoại: </span>{{props.item.customer_phone}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Nick Name: </span>{{props.item.customer_nickname}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Địa chỉ: </span>{{props.item.customer_address}}
                </div>
              </template>

               <template slot-scope="props"  slot='control'>
                <div class="row">
                  <div class="col-xs-6 col-md-6">
                    <a @click="GHTKOrder(props.item.id)" 
                      class="btn btn-primary btn-block" 
                      data-toggle="tooltip" 
                      title="Nhập VĐ GHTK">
                      <i class="fa fa-fw fa-cab"></i> GHTK
                    </a>
                  </div>

                  <div class="col-xs-6 col-md-6">
                    <a @click="LayTaiNha(props.item.id)" 
                      class="btn btn-default btn-block" 
                      data-toggle="tooltip" 
                      title="Lấy tại shop">
                      <i class="fa fa-fw fa-cab"></i> Tại shop
                    </a>
                   </div>
                   
                </div>

                <br>
                <div class="row">
                  <div class="col-xs-6 col-md-6">
                    <a @click="GuiBuuDien(props.item.id)" 
                      class="btn btn-warning btn-block" 
                      data-toggle="tooltip" 
                      title="Gửi bưu điện">
                      <i class="fa fa-fw fa-cab"></i> Bưu điện
                    </a>
                  </div>
                </div>
              </template>

              <!-- <template slot-scope="props"  slot='control'>
                <div class="row">
                  <div class="col-xs-6 col-md-6">
                    <a @click="GHTKOrder(props.item.id)" 
                      class="btn btn-primary btn-block" 
                      data-toggle="tooltip" 
                      title="Nhập VĐ GHTK">
                      <i class="fa fa-fw fa-cab"></i> GHTK
                    </a>
                  </div>

                  <div class="col-xs-6 col-md-6">
                    <a @click="JTEXPRESSOrder(props.item.id)" 
                      class="btn btn-warning btn-block" 
                      data-toggle="tooltip" 
                      title="Gửi JTEXPRESS">
                      <i class="fa fa-fw fa-cab"></i> JTEXPRESS
                    </a>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="col-xs-6 col-md-6">
                    <a @click="VNCPOSTOrder(props.item.id)" 
                      class="btn btn-default btn-block" 
                      data-toggle="tooltip" 
                      title="Nhập VĐ VNCPOST">
                      <i class="fa fa-fw fa-cab"></i> VNCPOST
                    </a>
                  </div>

                  <div class="col-xs-6 col-md-6">
                    <a @click="GuiBuuDien(props.item.id)" 
                      class="btn btn-warning btn-block" 
                      data-toggle="tooltip" 
                      title="Gửi bưu điện">
                      <i class="fa fa-fw fa-cab"></i> Bưu điện
                    </a>
                  </div>
                </div>
                <br>
                 <div class="row">
                   <div class="col-xs-6 col-md-6">
                    <a @click="LayTaiNha(props.item.id)" 
                      class="btn btn-default btn-block" 
                      data-toggle="tooltip" 
                      title="Lấy tại shop">
                      <i class="fa fa-fw fa-cab"></i> Tại shop
                    </a>
                   </div>
                 </div>
              </template> -->
            </data-viewer>
            <div class="row">
              <div class="col-xs-6 col-md-2">
                <span class="text-bold" v-if="total_count>0">Tổng đơn: {{total_count}}</span>
              </div>
            </div>
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>
    <!-- Giao hàng-->
    <GHTKOrder ref="GHTKOrder" @form-submit="seachClosedForm()"></GHTKOrder>
    <VNCPOSTOrder ref="VNCPOSTOrder" @form-submit="seachClosedForm()"></VNCPOSTOrder>
    <JTEXPRESSOrder ref="JTEXPRESSOrder" @form-submit="seachClosedForm()"></JTEXPRESSOrder>
    <!-- ./Giao hàng-->
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import DataViewer from '../../../components/DataViewer'
import GHTKOrder from '../../../components/GHTK/GHTKOrder.vue';
import VNCPOSTOrder from '../../../components/VNCPOST/VNCPOSTOrder.vue';
import JTEXPRESSOrder from '../../../components/JTEXPRESS/JTEXPRESSOrder.vue';


export default {
    name: 'FinishIndex',
    components: {
       VuejsDialog, 
       DataViewer,
       GHTKOrder,
       VNCPOSTOrder,
       JTEXPRESSOrder
    },
    data() {
        return {
          title: 'Hoàn thành đơn hàng',
          pick_work_shift: 3,
          data:[],
          params: {
              keyword: ''
          },
          thead: [
            {
              key: 'outputvourcher_info',
              title: 'Thông tin đơn',
              class: 'col-xs-12 col-md-4'
            },
            {
              key: 'outputvourcher_customer',
              title: 'Thông tin khách hàng',
              class: 'col-xs-12 col-md-5'
            },
            {
              key: 'control',
              title: '',
              class: 'col-xs-12 col-md-3'
            }
          ]
        }
    },
    computed: {
        total_count: function () {
          var countRow = 0
          if(this.data){
            countRow = this.data.length
          }
          return countRow
        }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
        this.seach();
    },
    methods: {
      seach() {
        NProgress.start()
        var vm = this
        axios.get('/api/inventory/output/finish/seach'+ '?' + $.param(vm.params))
            .then(function(response) {
                NProgress.done()
                vm.data = response.data.data
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
      seachClosedForm(){
        this.seach()
        //this.params.keyword = ''
        this.$refs.search.focus()
      },
      GHTKOrder: function(id) {
          this.$refs.GHTKOrder.open(id, this.pick_work_shift);
      },
      VNCPOSTOrder: function(id) {
          this.$refs.VNCPOSTOrder.open(id);
      },
     JTEXPRESSOrder: function(id) {
          this.$refs.JTEXPRESSOrder.open(id);
      },
      // GuiJTEXPRESS: function(id) {
      //      var $this = this
      //       this.$dialog.confirm('Bạn có muốn cập nhật gửi JTEXPRESS', {
      //           loader: true,
      //           okText: 'Đồng ý',
      //           cancelText: 'Hủy bỏ'
      //       })
      //           .then((dialog) => {
      //               $this.GuiJTEXPRESSFunction(id)
      //               dialog.close()
      //           });
      // },
      // GuiJTEXPRESSFunction(id){
      //       var vm = this
      //       var resultRequest = {
      //               id: id,
      //               status: 1,
      //               delivery_type: 5
      //           }
      //       axios['put']('/api/inventory/output/update_outputvourcher_status_and_deliverytype', resultRequest)
      //       .then(function (response) {
      //         var result = response.data
      //               if(result.success==true){
      //                   vm.seach()
      //                   vm.$snotify.success('Cập nhật thành công!', 'Success')
      //               }else{
      //                   vm.$snotify.error(result.message, 'Lỗi')
      //               }
      //       })
      //       .catch(function (error) {
      //           console.log(error)
      //       })
      // },
      GuiBuuDien: function(id) {
           var $this = this
                this.$dialog.prompt({
                  title: "Bạn có muốn cập nhật gửi bưu điện",
                  body: "Nhập mã vận đơn đã tạo thành công trên bưu điện"
                })
                .then((dialog) => {
                    $this.GuiBuuDienFunction(id, dialog.data)
                    dialog.close()
                });
      },
      GuiBuuDienFunction(id, shipment_id){
            var vm = this
            var resultRequest = {
                    id: id,
                    status: 1,
                    delivery_type: 3,
                    shipment_id: shipment_id
                }
            axios['put']('/api/inventory/output/update_outputvourcher_status_and_deliverytype', resultRequest)
            .then(function (response) {
              var result = response.data
                    if(result.success==true){
                        vm.seach()
                        vm.$snotify.success('Cập nhật thành công!', 'Success')
                    }else{
                        vm.$snotify.error(result.message, 'Lỗi')
                    }
            })
            .catch(function (error) {
                console.log(error)
            })
      },
      LayTaiNha: function(id) {
           var $this = this
            this.$dialog.confirm('Bạn có muốn cập nhật lấy tại shop', {
                loader: true,
                okText: 'Đồng ý',
                cancelText: 'Hủy bỏ'
            })
                .then((dialog) => {
                    $this.LayTaiNhaFunction(id)
                    dialog.close()
                });
      },
      LayTaiNhaFunction(id){
            var vm = this
            var resultRequest = {
                    id: id,
                    status: 1,
                    delivery_type: 2
                }
            axios['put']('/api/inventory/output/update_outputvourcher_status_and_deliverytype', resultRequest)
            .then(function (response) {
              var result = response.data
                    if(result.success==true){
                        vm.seach()
                        vm.$snotify.success('Cập nhật thành công!', 'Success')
                    }else{
                        vm.$snotify.error(result.message, 'Lỗi')
                    }
            })
            .catch(function (error) {
                console.log(error)
            })
      },
    }
}
</script>