<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box danh sách đơn-->
        <div class="box">
          <!-- box-header -->
          <div class="box-header">
            <div class="row">
              <div class="col-xs-12 col-md-12">
                <span class="text-bold text-red">TỔNG ĐƠN CẦN SOẠN: {{total_count}}</span>
                <router-link
                  class="btn btn-xs btn-default pull-right" 
                  :to="'/outputvourcher/soan-hang/'">
                  DANH SÁCH ĐƠN SOẠN
                </router-link>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-6 col-md-6">
                <div class="form-group">
                  <input type="text" 
                  class="form-control"
                  onClick="this.select();" 
                  v-model="search" 
                  placeholder="Mã đơn hàng, Tên KH"/>
                </div>
              </div>

              <div class="col-xs-6 col-md-6">
                <div class="form-group">
                  <select  class="form-control" v-model="pick_work_shift">
                      <option value="1">Buổi Sáng</option>
                      <option value="2">Buổi chiều</option>
                      <option value="3">Buổi tối</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <data-viewer :thead="thead" :data="filteredList">
              <template slot-scope="props" slot='outputvourcher_info'>
                <div class="col-xs-12 col-md-12">
                  <span class="label label-info" v-if="props.item.delivery_type == 0">Đơn vị vận chuyển - GHTK</span> 
                  <span class="label label-info" v-else-if="props.item.delivery_type == 3">Đơn vị vận chuyển - Bưu điện</span> 
                  <span class="label label-info" v-else-if="props.item.delivery_type == 4">Đơn vị vận chuyển - VNCPOST</span> 
                  <span class="label label-info" v-else-if="props.item.delivery_type == 1">Shop giao</span> 
                  <span class="label label-info" v-else-if="props.item.delivery_type == 5">Đơn vị vận chuyển - JTEXPRESS</span> 
                  <span class="label label-info" v-else>Lấy tại shop</span> 

                  <span class="label label-success" v-if="checkOk(props.item.id)">ĐỦ SỐ LƯỢNG THEO ĐƠN</span>
                </div>
                <div class="col-xs-12 col-md-5">
                  <span class="text-bold">Mã phiếu: </span>
                  <router-link
                    :to="'/outputvourcher/sale/edit/'+props.item.id">
                    {{props.item.id}}
                  </router-link>
                  <br>
                  <span class="text-bold" v-if="props.item.shipment_id">Mã VĐ: </span><span v-if="props.item.shipment_id">{{props.item.shipment_id}}</span>
                </div>

                <div class="col-xs-12 col-md-7">
                  <span class="text-bold">Ghi chú: </span><span class="text-bold text-info">{{props.item.note}}</span>
                  <br>
                  <span class="text-bold">Ghi chú nội bộ: </span><span class="text-bold text-red">{{props.item.note_internal}}</span>
                </div>
              </template>

              <template slot-scope="props" slot='outputvourcher_customer'>
                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Khách hàng: </span>{{props.item.customer_name}}
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Điện thoại: </span>{{props.item.customer_phone}}
                </div>
              </template>

              <template slot-scope="props"  slot='control'>
                <a
                  v-if="props.item.delivery_type == 0 && !props.item.shipment_id"
                  @click="GHTKOrder(props.item.id)"
                  class="btn btn-xs btn-primary btn-block btn-flat" 
                  data-toggle="tooltip" 
                  title="Nhập VĐ GHTK">
                  <i class="fa fa-fw fa-cab"></i> GHTK
                </a>

                <router-link
                  class="btn btn-xs btn-success btn-block btn-flat" 
                  :to="'/outputvourcher/show/'+props.item.id">
                  Hoàn tất soạn
                </router-link>

                <a 
                  class="btn btn-xs btn-danger btn-block btn-flat" 
                  @click="remove(props.item.id)">
                  Trả về danh sách soạn
                </a>
              </template>
            </data-viewer>
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>
    <div class="col-xs-12">
        <!-- box danh sách sản phẩm-->
        <div class="box">
          <!-- box-header -->
          <div class="box-header">
            <span class="text-bold">ĐÃ SOẠN XONG: <span class="text-bold text-red">{{totalQuantityOK}}/{{totalQuantity}}</span></span>
          </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <div class="row">
              <!-- row-product -->
              <div class="col-md-6 col-sm-12 col-xs-12" v-for="item in listModel" :key="item.id">
                <soan-hang-model-view :vourcher="data" :data="item" :key="item.id" @changeddata="seach"></soan-hang-model-view>
              </div>
            </div>
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>

    <!-- Giao hàng-->
    <GHTKOrder ref="GHTKOrder" @form-submit="seachClosedForm()" :isfinished="false"></GHTKOrder>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import DataViewer from '../../../components/DataViewer'
import SoanHangModelView from './components/SoanHangModelView'
import GHTKOrder from '../../../components/GHTK/GHTKOrder.vue';

export default {
    name: 'SoanHangUserIndex',
    components: {
       VuejsDialog, DataViewer, SoanHangModelView, GHTKOrder
    },
    data() {
        return {
          title: 'Soạn hàng theo nhân viên',
          pick_work_shift: 3,
          search: '',
          data:[],
          thead: [
            {
              key: 'outputvourcher_info',
              title: 'Thông tin đơn',
              class: 'col-xs-12 col-md-5'
            },
            {
              key: 'outputvourcher_customer',
              title: 'Thông tin khách hàng',
              class: 'col-xs-12 col-md-5'
            },
            {
              key: 'control',
              title: '',
              class: 'col-xs-12 col-md-2'
            }
          ]
        }
    },
    computed: {
        listModel: function () {
          var listModelTemp = []
          if(this.data && this.data.length > 0){
            for (var i = 0; i < this.data.length; i++) {
                for (var j = 0; j < this.data[i].outputvourcher_details.length; j++) {
                    if(this.data[i].outputvourcher_details[j].status != 0 ){
                        var newItem = {};
                        if(listModelTemp.length == 0){
                          newItem.model = this.data[i].outputvourcher_details[j].product.model
                          newItem.id = this.data[i].outputvourcher_details[j].product.model.id
                          newItem.name = this.data[i].outputvourcher_details[j].product.model.name
                          newItem.location = this.data[i].outputvourcher_details[j].product.model.location
                          if(this.data[i].outputvourcher_details[j].product.image){
                            newItem.link_image = this.data[i].outputvourcher_details[j].product.image.link_image
                          }
                          
                          newItem.product_count = parseFloat(this.data[i].outputvourcher_details[j].quantity)
                          newItem.product_count_ok = 0
                          if(this.data[i].outputvourcher_details[j].status == 1){
                            newItem.product_count_ok += parseFloat(this.data[i].outputvourcher_details[j].quantity)
                          }
                          listModelTemp.push(newItem)
                        }else{
                          var productIndex= listModelTemp.findIndex(o => o.id == this.data[i].outputvourcher_details[j].product.model_id)
                          if(productIndex<0){
                            newItem.model = this.data[i].outputvourcher_details[j].product.model
                            newItem.id = this.data[i].outputvourcher_details[j].product.model.id
                            newItem.name = this.data[i].outputvourcher_details[j].product.model.name
                            newItem.location = this.data[i].outputvourcher_details[j].product.model.location
                            if(this.data[i].outputvourcher_details[j].product.image){
                              newItem.link_image = this.data[i].outputvourcher_details[j].product.image.link_image
                            }
                            newItem.product_count = parseFloat(this.data[i].outputvourcher_details[j].quantity)
                            newItem.product_count_ok = 0
                            if(this.data[i].outputvourcher_details[j].status == 1){
                              newItem.product_count_ok = parseFloat(this.data[i].outputvourcher_details[j].quantity)
                            }
                            listModelTemp.push(newItem)
                          }else{
                            listModelTemp[productIndex].product_count += parseFloat(this.data[i].outputvourcher_details[j].quantity)
                            if(this.data[i].outputvourcher_details[j].status == 1){
                              listModelTemp[productIndex].product_count_ok += parseFloat(this.data[i].outputvourcher_details[j].quantity)
                            }
                          }
                        }
                    }
                }
            }
          }
          return listModelTemp
        },

        filteredList() {
          return this.data.filter(item => {
            return item.id.toLowerCase().includes(this.search.toLowerCase()) || item.customer_name.toLowerCase().includes(this.search.toLowerCase())
          })
        },

        total_count: function () {
          var countRow = 0
          if(this.data){
            countRow = this.data.length
          }
          return countRow
        },
        totalQuantity(){
            var totalQuantityTemp = 0
            if(typeof(this.data) != 'undefined'){
              for (var i = 0; i < this.data.length; i++) {
                  for (var j = 0; j < this.data[i].outputvourcher_details.length; j++) {
                      if(this.data[i].outputvourcher_details[j].status != 0 ){
                          totalQuantityTemp = totalQuantityTemp + parseFloat(this.data[i].outputvourcher_details[j].quantity)
                      }
                  }
              }
            }
            return totalQuantityTemp
        },
        totalQuantityOK(){
            var totalQuantityTemp = 0
            if(typeof(this.data) != 'undefined'){
              for (var i = 0; i < this.data.length; i++) {
                  for (var j = 0; j < this.data[i].outputvourcher_details.length; j++) {
                      if(this.data[i].outputvourcher_details[j].status != 0 && this.data[i].outputvourcher_details[j].status == 1){
                          totalQuantityTemp = totalQuantityTemp + parseFloat(this.data[i].outputvourcher_details[j].quantity)
                      }
                  }
              }
            }
            return totalQuantityTemp
        }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
        this.seach();
    },
    methods: {
      seach() {
        NProgress.start()
        var vm = this
        axios.get('/api/inventory/output/soan-hang/seach_by_user')
            .then(function(response) {
                NProgress.done()
                var result = response.data.data
                vm.data = result
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
      updateStatus(id, statusold, status){
          var vm = this
          if(statusold == 3){
            axios['put']('/api/inventory/output/update_status/'+id+'/'+status)
            .then(function (response) {
              vm.$router.push({path: '/outputvourcher/show/'+id})
            })
            .catch(function (error) {
                console.log(error)
            })
          }else{
            vm.$router.push({path: '/outputvourcher/show/'+id})
          }
      },
      remove(id) {
        var vm = this
        this.$dialog.confirm('Bạn có muốn xóa?', {
            loader: true,
            okText: 'Đồng ý',
            cancelText: 'Hủy bỏ'
        })
            .then((dialog) => {
                vm.user_del(id)
                dialog.close()
            });
      },
      user_del(id){
        var vm = this
        var result = {
                id: id
            }

        axios['put']('/api/inventory/output/soan-hang/user_del', result)
        .then(function (response) {
            vm.data = {}
            var result = response.data
            if(result.success==true){
                vm.data = result.data
                vm.seach();
                vm.$snotify.success(result.message, 'Success')
            }else{
                vm.$snotify.error(result.message, 'Lỗi')
            }
        })
        .catch(function (error) {
            vm.$snotify.error('Có lỗi xảy ra', 'Lỗi')
            vm.formErrors = error.response.data.errors
        })
      },
      checkOk(id){
        var check = true
        if(this.data.length>0){
          var OutputIndex= this.data.findIndex(o => o.id == id)
          if(OutputIndex>=0){
            for (var j = 0; j < this.data[OutputIndex].outputvourcher_details.length; j++) {
                if(this.data[OutputIndex].outputvourcher_details[j].status != 0 && this.data[OutputIndex].outputvourcher_details[j].status != 1){
                  check = false
                  break
                }
            }
          }
        }
        return check
      },
      seachClosedForm(){
        this.seach();
      },
      GHTKOrder: function(id) {
          var OutputIndex= this.data.findIndex(o => o.id == id)
          var indexTemp= this.data[OutputIndex].outputvourcher_details.findIndex(o => o.status != 1 && o.status != 0)
              if(indexTemp>=0){
                  this.$dialog.alert('Chưa soạn hết tất cả sản phẩm trong đơn hàng này!').then(function(dialog) {
                      
                  });
                  return;
              }
          this.$refs.GHTKOrder.open(id, this.pick_work_shift);
      },

    }
}
</script>