<template>
<!-- info-box -->
<div class="info-box pull-left">
    <span class="info-box-icon">
        <!-- <img :src="imageUrl"> -->
        <v-lazy-image :src="imageUrl" />
    </span>

    <div class="info-box-content row">
        <div class="col-md-12 col-xs-12">
            <div class="row">
                <div class="col-md-12 col-xs-12">
                    <span class="info-box-text text-green">
                      {{data.id}} - {{data.name}}
                      <span class="label label-success" v-if="data.status === 1">Đang bán</span>
                      <span class="label label-warning" v-else-if="data.status === 2">Chờ hàng</span>
                      <span class="label label-danger" v-else>Ngưng bán</span>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-xs-12 form-group">
                    <select v-model="colorSelected" 
                      id="colorSelected" 
                      name="colorSelected" 
                      @change="changed(data.id)"
                      class="form-control">
                      <option v-for="item in colorList" :key="item.id" :value="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                </div>
                <div class="col-md-3 col-xs-12 form-group">
                    <select v-model="sizeSelected" 
                      id="sizeSelected" 
                      name="sizeSelected" 
                      @change="changed(data.id)"
                      class="form-control">
                      <option v-for="item in sizeList" :key="item.id" :value="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                </div>
                <div class="col-md-4 col-xs-12">
                  <span v-if="productInfo.name">{{productInfo.name}}</span>
                  <br>
                  <span class="label label-success" v-if="(parseFloat(productInfo.currentinstock.quantity) - parseFloat(productInfo.currentinstock.order_quantity)) > 0">Còn hàng</span>
                  <span class="label label-danger" v-else>Hết hàng</span>
                  <span v-if="showquantity===true">Tồn: {{(parseFloat(productInfo.currentinstock.quantity) - parseFloat(productInfo.currentinstock.order_quantity)) > 0 ? (parseFloat(productInfo.currentinstock.quantity) - parseFloat(productInfo.currentinstock.order_quantity)) : 0}}</span>
                </div>
            </div>
        </div>
    </div>
    <!-- /.info-box-content -->
</div>
<!-- /.info-box -->
</template>

<script>
import NumberButton from '../../NumberButton.vue';
import VLazyImage from "v-lazy-image";

export default {
    name: 'ProductItemClient',
    components: {NumberButton, VLazyImage},
    props: {
      data: {
          type: Object,
      },
      showquantity: {
          Boolean,
          default: false
      }
    },
    data() {
        return {
          colorSelected: '',
          sizeSelected: ''
        }
    },
    computed: {
      colorList: function () {
        var colorListTemp = []
        for (var i = 0; i < this.data.products.length; i++) {
            if(this.data.products && this.data.products[i].color){
              if(!colorListTemp.some(o => o.id == this.data.products[i].color.id) && this.data.products[i].status != 0){
                colorListTemp.push(this.data.products[i].color)
              }
            }
        }
        if(colorListTemp.length>0){
          this.colorSelected = colorListTemp[0].id
        }
        return colorListTemp
      },
      sizeList: function () {
        var sizeListTemp = []
        for (var i = 0; i < this.data.products.length; i++) {
          if(this.data.products && this.data.products[i].size){
            if(!sizeListTemp.some(o => o.id == this.data.products[i].size.id) && this.data.products[i].status == 1){
              if(this.colorSelected == ''){
                sizeListTemp.push(this.data.products[i].size)
              }else{
                if(this.data.products[i].color.id == this.colorSelected){
                  sizeListTemp.push(this.data.products[i].size)
                }
              } 
            } 
          }
        }
        if(sizeListTemp.length>0){
          this.sizeSelected = sizeListTemp[0].id
        }
        return sizeListTemp
      },
      imageUrl: function () {
        var imageUrlTemp = ''

        if(this.colorSelected != ''){
          var productTemp = this.data.products.find(o => o.color_id == this.colorSelected)
          imageUrlTemp = productTemp.image.link_image
        }else{
          imageUrlTemp = this.data.products[0].image.link_image
        }

        return imageUrlTemp
      },
      productInfo: function () {
        var result = new Object()
        if(this.colorSelected != '' && this.sizeSelected != ''){
          var productTemp = this.data.products.find(o => o.color_id == this.colorSelected && o.size_id == this.sizeSelected)
          result = productTemp
          //result = productTemp.name + '(Tồn: ' + parseFloat(productTemp.currentinstock.quantity) + ' - Đặt: '+ parseFloat(productTemp.currentinstock.order_quantity) + ' = Còn: '+(parseFloat(productTemp.currentinstock.quantity) - parseFloat(productTemp.currentinstock.order_quantity))+')'
        }
        return result
      }
    },
    methods: {
      changed(modelid){
        var vm = this
        var paramsSeachProduct = {keyword: modelid}
        axios.get('/api/product-seach-client/seach_product_sale'+ '?' + $.param(paramsSeachProduct))
        .then(function(response) {
            var productsTemp = response.data.data
            if(productsTemp != null && productsTemp.length>0){
              for (var i = 0; i < productsTemp[0].products.length; i++) {
                var productIndex= vm.data.products.findIndex(product => product.id ==  productsTemp[0].products[i].id)
                vm.data.products[productIndex].currentinstock = productsTemp[0].products[i].currentinstock
              }
            }
        })
        .catch(function(error) {
            console.log(error)
        })
      }
    }
}
</script>
<style lang="css" scoped>
.info-box-icon>img {
    width: 100%;
    max-height: 90px;
    vertical-align: unset;
}
.info-box-content{
    padding: 0px;
}
.info-box-text{
    white-space: unset;
}
.info-box {
    padding-bottom: 10px;
}
</style>