import Vue from 'vue';
import VueRouter from 'vue-router';

//dashboard
import dashboardIndex from '../views/dashboard/index';

import test from '../views/test';

import area from '../views/area/index';
import province from '../views/province/index';
import district from '../views/district/index';
import ward from '../views/ward/index';

import automatic_value from '../views/automatic_value/index';
import config from '../views/config/index';
import user from '../views/user/index';
import color from '../views/color/index';
import size from '../views/size/index';
import shop from '../views/shop/index';
import shop_price from '../views/shop_price/index';
import source_address from '../views/source_address/index';
import calculation_weight from '../views/calculation_weight/index';
//customer
import customer from '../views/customer/index';
import customerAdd from '../views/customer/add';
import customerEdit from '../views/customer/edit';

import input_type from '../views/input_type/index';
import output_type from '../views/output_type/index';

import category_model from '../views/category_model/index';

import productList from '../views/product/formModelList';
import productAdd from '../views/product/formModelAdd';
import productEdit from '../views/product/formModelEdit';

//Kiểm kê
import Inventory from '../views/inventory/formInventory';
//Nhập hàng
import InputVourcherIndex from '../views/inputvourcher/InputVourcherIndex';
import InputVourcherDetail from '../views/inputvourcher/InputVourcherDetail';
import inputVourcherAddSupplier from '../views/inputvourcher/inputVourcherAddSupplier';
import inputVourcherAddReturnSale from '../views/inputvourcher/inputVourcherAddReturnSale';
//Giữ hàng
import KeepIndex from '../views/outputvourcher/keep/index';
//Bán hàng
import OutputVourcherSaleAdd from '../views/outputvourcher/sale/OutputVourcherSaleAdd';
import OutputVourcherSaleEdit from '../views/outputvourcher/sale/OutputVourcherSaleEdit';
import OutputVourcherSaleIndex from '../views/outputvourcher/sale/OutputVourcherSaleIndex';
import productSeachSale from '../views/outputvourcher/productSeachSale';
import SoanHangIndex from '../views/outputvourcher/soan_hang/SoanHangIndex';
import SoanHangUserIndex from '../views/outputvourcher/soan_hang/SoanHangUserIndex';
import FinishIndex from '../views/outputvourcher/finish/FinishIndex';
import ReturnQuantityNoteIndex from '../views/outputvourcher/reports/ReturnQuantityNoteIndex';
//Phiếu xuất
import OutputVourcherIndex from '../views/outputvourcher/outputvourcher/OutputVourcherIndex';
import OutputVourcherDetail from '../views/outputvourcher/outputvourcher/OutputVourcherDetail';
//Giao hàng
import Fee from '../views/outputvourcher/logistics/Fee';
import VNCPOSTFee from '../views/outputvourcher/logistics/VNCPOSTFee';
import JTEXPRESSFee from '../views/outputvourcher/logistics/JTEXPRESSFee';

//Tra cứu sản phẩm client
import ProductViewClient from '../components/ProductSeachClient/productSeachClient';
import productSeachClientViewFull from '../components/ProductSeachClient/productSeachClientViewFull';

///////////////Cộng tác viên//////////////////
//Quản lý cộng tác viên
import partner from '../views/ctv/partner/index';
import OutputvourcherPaymentCalIndex from '../views/ctv/outputvourcherpayment/indexCal';
import OutputvourcherPaymentSeachIndex from '../views/ctv/outputvourcherpayment/indexSeach';
import PaymentvourcherSeach from '../views/ctv/paymentvourcher/seach';
import PaymentvourcherDetail from '../views/ctv/paymentvourcher/detail';


Vue.use(VueRouter);

const router = new VueRouter({
    routes: [
        //trang dashboard
        {path: '/', component: dashboardIndex},

        {path: '/area', component: area},
        {path: '/province', component: province},
        {path: '/district', component: district},
        {path: '/ward', component: ward},

        {path: '/automatic_value', component: automatic_value},
        {path: '/config', component: config},
        {path: '/user', component: user},
        {path: '/color', component: color},
        {path: '/size', component: size},
        {path: '/shop', component: shop},
        {path: '/shop_price', component: shop_price},
        {path: '/source_address', component: source_address},
        {path: '/calculation_weight', component: calculation_weight},
        //customer
        {path: '/customer', component: customer},
        {path: '/customer/add', component: customerAdd},
        {path: '/customer/edit/:id', component: customerEdit},
        {path: '/input_type', component: input_type},
        {path: '/output_type', component: output_type},

        {path: '/category_model', component: category_model},

        {path: '/product', component: productList},
        {path: '/product/add', component: productAdd},
        {path: '/product/edit/:id', component: productEdit},

        //Kiểm kê
        {path: '/inventory/add', component: Inventory},
        //Nhập hàng
        {path: '/inputvourcher', component: InputVourcherIndex},
        {path: '/inputvourcher/show/:id', component: InputVourcherDetail},
        {path: '/inputVourcher/add/supplier', component: inputVourcherAddSupplier},
        {path: '/inputVourcher/add/returnsale', component: inputVourcherAddReturnSale},

        //Giữ hàng
        {path: '/keep', component: KeepIndex},
        //Xuất hàng
        {path: '/outputvourcher', component: OutputVourcherIndex},
        {path: '/outputvourcher/show/:id', component: OutputVourcherDetail},
        //xuất bán
        {path: '/outputvourcher/sale', component: OutputVourcherSaleIndex},
        {path: '/outputvourcher/sale/add', component: OutputVourcherSaleAdd},
        {path: '/outputvourcher/sale/edit/:id', component: OutputVourcherSaleEdit},
        {path: '/productseach', component: productSeachSale},
        {path: '/outputvourcher/return_quantity_note', component: ReturnQuantityNoteIndex},
        //Xuất bán - soạn hàng
        {path: '/outputvourcher/soan-hang', component: SoanHangIndex},
        {path: '/outputvourcher/soan-hang-nhan-vien', component: SoanHangUserIndex},
        //xuất bán - hoàn thành
        {path: '/outputvourcher/finish', component: FinishIndex},
        //Xuất bán - Giao hàng
        {path: '/logistics/fee', component: Fee},
        {path: '/logistics/vncpost', component: VNCPOSTFee},
        {path: '/logistics/jtexpress', component: JTEXPRESSFee},
        
        ///////////////Cộng tác viên//////////////////
        //Quản lý cộng tác viên 
        {path: '/ctv/partner', component: partner},
        {path: '/ctv/outputvourcherpaymentcal', component: OutputvourcherPaymentCalIndex},
        {path: '/ctv/outputvourcherpaymentseach', component: OutputvourcherPaymentSeachIndex},
        {path: '/ctv/paymentvourcher', component: PaymentvourcherSeach},
        {path: '/ctv/paymentvourcher/:id', component: PaymentvourcherDetail},
    
        //Tra cứu sản phẩm client
        {path: '/tra-cuu', component: ProductViewClient},
        {path: '/tra-cuu-cuoc-phi', component: Fee},
        {path: '/tra-cuu-san-pham', component: productSeachClientViewFull},

        {path: '/test', component: test}
    ]
});

export default router
