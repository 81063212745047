<template>
<form role="form" @submit.prevent="save" name="formOutputVourcherSaleAdd">
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-header -->
          <div class="box-header with-border">
            <model-seach @modelSelected="modelSelected" :autoClearKeyword='true'>
            </model-seach>
          </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <div class="row" v-if="model.id">
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label class="control-label" for="location">Vị trí hàng hóa(Nhấn nút lưu trước khi cập nhật nếu có thay đổi vị trí):</label>
                    <div class="input-group">
                        <input type="text" 
                        class="form-control" 
                        id="location" 
                        name="location" 
                        v-model="model.location" 
                        placeholder="Nhập vị trí"
                        autocomplete = "off">
                        <span class="input-group-btn">
                            <button type="button" class="btn btn-default btn-flat" @click="saveLocation()"><i class="glyphicon glyphicon-floppy-disk"></i></button>
                        </span>
                    </div>
                  </div>
                </div>
            </div>

            <div class="row">
              <!-- row-product -->
              <div class="col-md-12 col-sm-12 col-xs-12" v-for="(item, index) in form.products" :key="item.product_id" v-show="(item.product.status != 0) || (item.product.status == 0 && item.oldquantity != 0)">
                <!-- info-box -->
                <div class="info-box pull-left">
                    <span class="info-box-icon">
                        <img v-if="item.product.image" :src="item.product.image.link_image">
                    </span>

                    <div class="info-box-content row">
                        <div class="col-md-12 col-xs-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <span class="info-box-text text-green">
                                        {{item.product.id}} - {{item.product.name}}
                                        <span class="label label-success" v-if="item.product.status === 1">Đang bán</span>
                                        <span class="label label-warning" v-else-if="item.product.status === 2">Chờ hàng</span>
                                        <span class="label label-danger" v-else>Ngưng bán</span>
                                    </span>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                  <div class="form-group">
                                    <label class="control-label" for="name">SL tồn:</label>
                                    <input type="number" class="form-control" id="oldquantity" 
                                      name="oldquantity" 
                                      v-model="item.oldquantity"
                                      readonly>
                                    <span class="help-block" v-if="formErrors.name">{{formErrors.name[0]}}</span>
                                  </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <div class="form-group" :class="{'has-error': formErrors['products.'+index+'.quantity']}">
                                      <label class="control-label" for="name">SL kiểm:</label>
                                      <number-button 
                                        :name="'products['+index+'][quantity]'" 
                                        v-model="item.quantity">
                                      </number-button>
                                      <span class="help-block" v-if="formErrors['products.'+index+'.quantity']">{{formErrors['products.'+index+'.quantity'][0]}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
              </div>
            </div>
          </div>
          <!-- /.box-body -->
            <div class="box-footer" v-show="model.id">
                <div class="row">
                    <div class="col-xs-8 col-md-10">
                        
                    </div>
                    <div class="col-xs-12 col-md-2">
                        <button type="button" v-on:click="save" class="btn btn-primary btn-block btn-flat" :disabled="enableButtonSave"><i class="fa fa-save"></i> Cập nhật</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.box -->
    </div>

  </div>
</form>
</template>

<script>
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import NumberButton from '../../components/NumberButton.vue';
import ModelSeach from '../../components/ModelSeach/ModelSeach.vue';

export default {
    name: 'formInventory',
    components: {
      ModelSeach, VuejsDialog, NumberButton
    },
    data() {
        return {
          title: 'Kiểm kê sản phẩm',
          model:{},
          formErrors: {},
          enableButtonSave: false,
          form: {},
          params: {
              keyword: ''
          }
        }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
    },
    methods: {
      save()
      {
        NProgress.start()
        this.enableButtonSave = true
        var vm = this
        this.$dialog.confirm('Bạn đã kiểm hết sản phẩm thuộc model - ' + vm.model.id, {
            loader: true,
            okText: 'Xác nhận',
            cancelText: 'Kiểm lại'
        })
            .then((dialog) => {
              dialog.close()
              axios['post']('/api/inventory/inventory/process', vm.form)
              .then(function (response) {
                  vm.$snotify.success('Xử lý kiểm kê thành công!', 'Success')
                  vm.form.products = []
                  vm.formErrors = {}
                  vm.model = {}
                  vm.title = 'Kiểm kê sản phẩm'
                  vm.$store.dispatch('setPageTitle', vm.title);
                  vm.enableButtonSave = false
                  NProgress.done()
              })
              .catch(function (error) {
                  NProgress.done()
                  vm.$snotify.error('Xử lý thất bại, vui lòng kiểm tra lại!', 'Success')
                  vm.formErrors = error.response.data.errors
                  vm.enableButtonSave = false
              })
            });
      },
      modelSelected(model) {
        this.form.products = []
        this.model = model
        this.title = 'Kiểm kê sản phẩm' + ' - ' + model.id
        this.$store.dispatch('setPageTitle', this.title);

        for (var i = 0; i < model.products.length; i++) {
          var newItem = {};
              newItem.product_id = model.products[i]['id']
              newItem.product = model.products[i]
              newItem.oldquantity = parseFloat(model.products[i]['currentinstock']['quantity'])
              newItem.quantity = parseFloat(model.products[i]['currentinstock']['quantity'])
              this.form.products.push(newItem)
        }
      },
      saveLocation(){
          let vm = this
          var dataUpdate = {id:vm.model.id, location: vm.model.location};
          axios['put']('/api/model/update_location/'+vm.model.id, dataUpdate)
          .then(function (response) {
              vm.$snotify.success('Cập nhật vị trí thành công!', 'Success')
          })
          .catch(function (error) {
              vm.$snotify.error('Cập nhật vị trí thất bại!', 'Error')
              console.log(error)
          })
      },
    }
}
</script>
<style lang="css" scoped>
.info-box-icon>img {
    width: 100%;
    max-height: 90px;
    vertical-align: unset;
}
.info-box-content{
    padding: 0px;
}
.info-box-text{
    white-space: unset;
}
.info-box {
    padding-bottom: 10px;
}
</style>