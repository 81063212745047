<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-header -->
          <div class="box-header">
            <div class="row">
              <div class="col-xs-12 col-md-3">
                <div class="form-group">
                  <input type="text" class="form-control" 
                  v-model="params.keyword" 
                  v-on:keyup.enter="seach"
                  placeholder="Nhập từ khóa...">
                </div>
              </div>
              <div class="col-xs-12 col-md-2">
                <div class="form-group">
                  <Select2 :id="'type'" 
                    :name="'type'" 
                    :data="customerTypeData" 
                    v-model="params.type"
                    :multiple="true">
                  </Select2>
                </div>
              </div>

              <div class="col-xs-12 col-md-3">
                <div class="form-group">
                  <Select2 :id="'status_warning'" 
                    :name="'status_warning'" 
                    :data="status_warningData" 
                    v-model="params.status_warning"
                    :multiple="true">
                  </Select2>
                </div>
              </div>

              <div class="col-xs-6 col-md-2">
                <div class="form-group">
                  <button type="button" class="btn btn-default btn-block btn-flat" v-on:click="seach"><i class="fa fa-fw fa-search"></i> Tìm kiếm</button>
                </div>
              </div>
              <div class="col-xs-6 col-md-2">
                 <router-link  
                  class="btn btn-primary btn-block btn-flat pull-right" 
                  :to="'customer/add'">
                  <i class="fa fa-fw fa-plus"></i> Thêm mới
                </router-link>
              </div>
            </div>
          </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <data-viewer :thead="thead" :data="data">
              <template slot-scope="props" slot='id'>
                <span class="text-bold hidden-lg">Mã: </span>{{props.item.id}}
              </template>
              <template slot-scope="props" slot='name'>
                <span class="text-bold hidden-lg">Tên: </span>{{props.item.name}}
              </template>
              <template slot-scope="props" slot='phone'>
                <span class="text-bold hidden-lg">Điện thoại: </span>{{props.item.phone}}
              </template>
              <template slot-scope="props" slot='address'>
                <span class="text-bold hidden-lg">Địa chỉ: </span>{{props.item.address}}
              </template>
              <template slot-scope="props" slot='type'>
                <span class="text-bold hidden-lg" >Loại khách: </span>
                <span v-if="props.item.type === 1">Khách sỉ</span>
                <span v-else-if="props.item.type === 2">Nhà cung cấp</span>
                <span v-else>Khách lẻ</span>
              </template>
              <template slot-scope="props" slot='status'>
                <span class="label label-success" v-if="props.item.status === 1">Sử dụng</span>
                <span class="label label-warning" v-else>Tạm ngưng</span>
              </template>
              <template slot-scope="props" slot='status_warning'>
                <span class="label label-success" v-if="props.item.status_warning === 0">Bình thường</span>
                <span class="label label-warning" v-else-if="props.item.status_warning === 1">Cảnh báo</span>
                <span class="label label-danger" v-else>Không bán</span>
              </template>
              <template slot-scope="props" slot='control'>
                <div class="pull-right">
                  <router-link  
                    class="btn btn-info btn-xs" 
                    :to="'customer/edit/'+props.item.id">
                    <i class="fa fa-pencil"></i>
                  </router-link>
                  <a @click="remove(props.item.id)" class="btn btn-danger btn-xs" data-toggle="tooltip" title="Xóa"><i class="fa fa-trash"></i></a>
                </div>
              </template>
            </data-viewer>

            <b-pagination
              v-model="pagination.currentPage" 
              :total-rows="pagination.rows"
              :per-page="pagination.perPage"
              @change="handlePageChange">
            </b-pagination>

          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import Select2 from '../../components/Select2.vue'
import DataViewer from '../../components/DataViewer';
import formCustomer from './form.vue'

export default {
    name: 'CustomerIndex',
    components: {
      formCustomer, VuejsDialog, DataViewer, Select2
    },
    data() {
        return {
          title: 'Quản lý khách hàng',
          data:[],
          pagination: {
            rows: 0,
            perPage: 20,
            currentPage: 1
          },
          params: {
              keyword: '',
              type: '',
              status_warning: '',
              page: 1,
              perPage: 20
          },
          customerTypeData:[
            {
              id: 0,
              name: 'Khách lẻ'
            },
            {
              id: 1,
              name: 'Khách sỉ'
            },
            {
              id: 2,
              name: 'Nhà cung cấp'
            }
          ],
          status_warningData:[
            {
              id: 0,
              name: 'Bình thường'
            },
            {
              id: 1,
              name: 'Cảnh báo'
            },
            {
              id: 2,
              name: 'Không bán'
            }
          ],
          thead: [
            {
              key: 'id',
              title: 'Mã',
              class: 'col-xs-3 col-md-1'
            },
            {
              key: 'name',
              title: 'Tên',
              class: 'col-xs-9 col-md-2'
            },
            {
              key: 'phone',
              title: 'Điện thoại',
              class: 'col-xs-12 col-md-1'
            },
            {
              key: 'address',
              title: 'Địa chỉ',
              class: 'col-xs-12 col-md-3'
            },
            {
              key: 'type',
              title: 'Loại khách hàng',
              class: 'col-xs-12 col-md-2'
            },
            {
              key: 'status',
              title: 'Trạng thái',
              class: 'col-xs-4 col-md-1'
            },
            {
              key: 'status_warning',
              title: 'TT cảnh báo',
              class: 'col-xs-4 col-md-1'
            },
            {
              key: 'control',
              label: 'Xử lý',
              class: 'col-xs-4 col-md-1'
            }
          ]
        }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
        //this.seach();
    },
    methods: {
       getData() {
        var vm = this
        axios.get('/api/customer/seach'+ '?' + $.param(this.params))
            .then(function(response) {
                NProgress.done()
                var dataResponse = response.data.data
                vm.data = dataResponse.data
                //Phân trang - gắng tổng số row
                vm.pagination.rows = dataResponse.total
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
       },
      handlePageChange(value){
        //Phân trang - gáng giá trị
        this.params.page = value
        this.params.perPage = this.pagination.perPage

        this.getData()
      },
      seach() {
        //Phân trang - gáng giá trị
        this.params.page = 1
        this.pagination.currentPage = 1
        this.params.perPage = this.pagination.perPage

        this.getData()
      },
      remove(id) {
        var $this = this
        this.$dialog.confirm('Bạn có muốn xóa?', {
            loader: true,
            okText: 'Đồng ý',
            cancelText: 'Hủy bỏ'
        })
            .then((dialog) => {
                $this.del(id)
                dialog.close()
            });
      },
      del(id){
        var vm = this
        axios['put']('/api/customer/delete/'+id)
        .then(function (response) {
            vm.$snotify.success('Xóa thành công!', 'Success')
            vm.seach()
        })
        .catch(function (error) {
            console.log(error)
        })
      }
    }
}
</script>