<template>
<!-- Modal -->
<div class="modal fade in" id="modal-form-keep-add" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title"><span v-if="product.name"> {{product.id}} - {{product.name}}</span></h4>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12" v-if="product.image">
                    <img :src="product.image.link_image" width="100%">
                </div>
                <div class="col-md-8 col-sm-8 col-xs-12">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="form-group" :class="{'has-error': formErrors.quantity}">
                                <label class="control-label" for="quantity">Số lượng<span class="text-red">(*)</span></label>
                                <number-button 
                                    v-model="form.quantity">
                                </number-button>
                            <span class="help-block" v-if="formErrors.quantity">{{formErrors.quantity[0]}}</span>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="form-group" :class="{'has-error': formErrors.customer_phone}">
                                <label class="control-label" for="customer_phone">Điện thoại<span class="text-red">(*)</span></label>
                                <input type="text" 
                                class="form-control" 
                                id="customer_phone" 
                                name="customer_phone" 
                                v-model="form.customer_phone" 
                                @blur="getCustomerByPhone(form.customer_phone)"
                                placeholder="Nhập điện thoại"
                                autocomplete = "off">
                                <span class="help-block" v-if="formErrors.customer_phone">{{formErrors.customer_phone[0]}}</span>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="form-group" :class="{'has-error': formErrors.customer_name}">
                                <label class="control-label" for="customer_name">Họ tên</label>
                                <input type="text" 
                                class="form-control" 
                                id="customer_name" 
                                name="customer_name" 
                                onClick="this.select();"
                                v-model="form.customer_name" 
                                placeholder="Nhập tên khách hàng"
                                autocomplete = "off">
                                <span class="help-block" v-if="formErrors.customer_name">{{formErrors.customer_name[0]}}</span>
                            </div>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <a @click="submit()" class="btn btn-primary btn-xs btn-block">
                                <i class="glyphicon glyphicon-ok"></i> Cập nhật
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
</template>

<script>
    import axios from 'axios'
    import NProgress from 'nprogress'
    import VuejsDialog from "vuejs-dialog"
    import NumberButton from '../../NumberButton.vue';
    export default {
        name: 'KeepAdd',
        components: {NProgress, VuejsDialog, NumberButton},
        props: {

        },
        data() {
            return {
                formErrors: {},
                enableButtonSave: false,
                product: {},
                form: {
                    customer_phone: '',
                    customer_name: '',
                    product_id: '',
                    quantity: 1
                }
            }
        },
        computed: {

        },
        methods: {
            open(product){
                this.product = product
                this.form.customer_phone = ''
                this.form.customer_name = ''
                this.form.product_id = product.id
                this.form.quantity = 1
                this.enableButtonSave = false
                $('#modal-form-keep-add').modal('show')
            },
            submit(){
                this.enableButtonSave = true
                var vm = this
                this.formErrors = {}
                this.validate()
                if(Object.keys(this.formErrors).length === 0 && this.formErrors.constructor === Object){
                    axios['post']('/api/keep/insert', this.form)
                    .then(function (response) {
                        var result = response.data
                        if(result.success==true){
                            vm.$emit('form-submit', result.data)
                            vm.$snotify.success('Giữ hàng thành công!', 'Success')
                            $('#modal-form-keep-add').modal('hide')
                        }else{
                            vm.$snotify.error(result.message, 'Lỗi')
                            vm.enableButtonSave = false
                        }
                    })
                    .catch(function (error) {
                        vm.formErrors = error.response.data.errors
                        vm.$snotify.error('Giữ hàng bị lỗi', 'Lỗi')
                        vm.enableButtonSave = false
                    })
                }
            },
            validate(){
                if(this.form.customer_phone == null || this.form.customer_phone == ''){
                    var error = ["Vui lòng nhập điện thoại"]
                    this.formErrors.customer_phone = error
                    this.enableButtonSave = false
                }
                if(parseFloat(this.form.quantity) <= 0){
                    var error = ["Vui lòng chọn số lượng"]
                    this.formErrors.quantity = error
                    this.enableButtonSave = false
                }
            },
            getCustomerByPhone(phone){
                let vm = this
                if(phone.trim().length == 0){
                    return
                }
                axios.get('/api/inventory/output/sale/select_by_phone_sale/'+ phone)
                    .then(function(response) {
                        var dataTemp = response.data.data
                        if(dataTemp == null){
                            return
                        }else{
                            if(dataTemp.status_warning == 2){
                                var textMessage = 'Khách hàng được liệt kê vào danh sách không bán ['+dataTemp.description+']'
                                vm.$dialog.alert(textMessage, {
                                                    okText: 'Đồng ý'
                                                })
                                .then(function(dialog) {
                                    vm.form.customer_phone = ''
                                    vm.form.customer_name = ''
                                    return
                                });
                            }else if(dataTemp.status_warning == 1){
                                var textMessage = 'Khách hàng được liệt kê vào danh sách cần cảnh báo khi bán ['+dataTemp.description+']. Đồng ý để tiếp tục!'
                                vm.$dialog.confirm(textMessage, {
                                    loader: true,
                                    okText: 'Đồng ý',
                                    cancelText: 'Hủy bỏ'
                                })
                                .then((dialog) => {
                                    dialog.close()
                                    if(dataTemp.outputvourchers.length>0){
                                        //Kiểm tra đơn hàng xem khách hàng có bị trùng đơn không
                                        if(dataTemp.outputvourchers[0].status != 1){
                                            var textMessage = 'Khách hàng ['+dataTemp.name+'] tồn tại đơn hàng chưa hoàn thành vào ngày ' + vm.moment(dataTemp.outputvourchers[0].output_date).format('DD/MM/YYYY') + ' . Vui lòng kiểm tra lại trước khi giữ hàng cho đơn mới!'
                                            vm.$dialog.alert(textMessage, {
                                                                okText: 'Đồng ý'
                                                            })
                                            .then(function(dialog) {
                                                vm.form.customer_phone = ''
                                                vm.form.customer_name = ''
                                                return
                                            });
                                        }else{
                                            var date_output = vm.moment(dataTemp.outputvourchers[0].status_update_date).format('DD/MM/YYYY')
                                            var date_check = vm.moment(new Date()).add(-1, 'days').format('DD/MM/YYYY')
                                            if(date_output >= date_check){
                                                var textMessage = 'Khách hàng ['+dataTemp.name+'] vừa được gửi đơn vào ngày ' + date_output + ' . Bạn có tiếp tục giữ hàng cho đơn mới cho khách hàng này!'
                                                vm.$dialog.confirm(textMessage, {
                                                    loader: true,
                                                    okText: 'Đồng ý',
                                                    cancelText: 'Hủy bỏ'
                                                })
                                                .then((dialog) => {
                                                    vm.setCustomerInfo(dataTemp)
                                                    dialog.close()
                                                });
                                            }else{
                                                vm.setCustomerInfo(dataTemp)
                                            }
                                        }
                                    }else{
                                        vm.setCustomerInfo(dataTemp)
                                    }
                                });
                            }else{
                                if(dataTemp.outputvourchers.length>0){
                                    //Kiểm tra đơn hàng xem khách hàng có bị trùng đơn không
                                    if(dataTemp.outputvourchers[0].status != 1){
                                        var textMessage = 'Khách hàng ['+dataTemp.name+'] tồn tại đơn hàng chưa hoàn thành vào ngày ' + vm.moment(dataTemp.outputvourchers[0].output_date).format('DD/MM/YYYY') + ' . Vui lòng kiểm tra lại trước khi giữ hàng cho đơn mới!'
                                        vm.$dialog.alert(textMessage, {
                                                            okText: 'Đồng ý'
                                                        })
                                        .then(function(dialog) {
                                            vm.form.customer_phone = ''
                                            vm.form.customer_name = ''
                                            return
                                        });
                                    }else{
                                        var date_output = vm.moment(dataTemp.outputvourchers[0].status_update_date).format('DD/MM/YYYY')
                                        var date_check = vm.moment(new Date()).add(-1, 'days').format('DD/MM/YYYY')
                                        if(date_output >= date_check){
                                            var textMessage = 'Khách hàng ['+dataTemp.name+'] vừa được gửi đơn vào ngày ' + date_output + ' . Bạn có tiếp tục giữ hàng cho đơn mới cho khách hàng này!'
                                            vm.$dialog.confirm(textMessage, {
                                                loader: true,
                                                okText: 'Đồng ý',
                                                cancelText: 'Hủy bỏ'
                                            })
                                            .then((dialog) => {
                                                vm.setCustomerInfo(dataTemp)
                                                dialog.close()
                                            });
                                        }else{
                                            vm.setCustomerInfo(dataTemp)
                                        }
                                    }
                                }else{
                                    vm.setCustomerInfo(dataTemp)
                                }
                            }
                        }
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
            },
            setCustomerInfo(customerInfo){
                this.form.customer_phone = customerInfo.phone
                this.form.customer_name = customerInfo.name
            },
        }
    }
</script>
<style lang="css" scoped>
img {
    vertical-align: unset;
}
</style>
