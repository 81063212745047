import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        isReloadPage: false,
        pageTitle: ''
    },
    mutations: {
        SET_IS_RELOAD_PAGE(state, isReloadPage){
            state.isReloadPage = isReloadPage
        },
        SET_PAGE_TITLE(state, pageTitle){
            state.pageTitle = pageTitle
        },
    },
    actions: {
        setIsReloadPage({commit}, isReloadPage){
            commit('SET_IS_RELOAD_PAGE', isReloadPage)
        },
        setPageTitle({commit}, pageTitle){
            commit('SET_PAGE_TITLE', pageTitle)
        },
    }
});