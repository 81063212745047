<template>
    <div class="input-group">
        <div class="input-group-btn">
            <button type="button" class="btn btn-flat" 
                @click="minus()" 
                :disabled="disabled"
                :class="classButton">
                <i class="fa fa-fw fa-minus"></i>
            </button>
        </div>
        <input type="number" class="form-control" 
            :name="name"
            :disabled="disabled"
            :readonly="readonlyText"
            :min="min" 
            :max="max"
            :step="step"
            v-model.number="currentValue" 
            @change="trigger">
        <div class="input-group-btn">
            <button type="button" class="btn btn-flat" 
                @click="plus()" 
                :disabled="disabled"
                :class="classButton">
                <i class="fa fa-fw fa-plus"></i>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NumberButton',
        props: {
            value: {
                type: Number,
                default: 0
            },
            disabled: {
                type: Boolean,
                default: false
            },
            readonlyText:{
                type: Boolean,
                default: false
            },
            name: {
                type: String,
                default: ''
            },
            min: {
                type: Number,
                default: 0
            },
            max: {
                type: Number,
                default: 999999
            },
            step: {
                type: Number,
                default: 1
            },
            classButton: {
                type: String,
                default: 'btn-default'
            }
        },
        data() {
            return {
                currentValue: this.value
            }
        },
        watch: {
            value(val) {
                this.currentValue = val;
            }
        },
        methods: {
            trigger (e) {
                if(e.target.value == ''){
                    this.currentValue = this.min
                }else{
                    this.currentValue = parseFloat(e.target.value)
                }
                this.$emit('input', this.currentValue)
                this.$emit('change')
            },
            plus(){
                if(this.currentValue < this.max){
                    if((parseFloat(this.currentValue) + this.step) > this.max){
                        this.currentValue = this.max
                    }else{
                        this.currentValue = parseFloat(this.currentValue) + this.step;
                    }
                    this.$emit('input', this.currentValue)
                    this.$emit('change')
                }
            },
            minus(){
                if(this.currentValue > this.min){
                    if((parseFloat(this.currentValue) - this.step) < this.min){
                        this.currentValue = this.min
                    }else{
                        this.currentValue = parseFloat(this.currentValue) - this.step;
                    }
                    this.$emit('input', this.currentValue)
                    this.$emit('change')
                }
            }
        },
    }
</script>

