<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-header -->
          <div class="box-header">
            <div class="row">
              <div class="col-xs-8 col-md-10">
                <div class="input-group">
                  <input type="text" class="form-control" v-model="params.keyword" v-on:keyup.enter="seach">
                  <span class="input-group-btn">
                    <button type="button" class="btn btn-default btn-flat" v-on:click="seach"><i class="fa fa-fw fa-search"></i> Tìm kiếm</button>
                  </span>
                </div>
              </div>
              <div class="col-xs-4 col-md-2">
                <button type="button" class="btn btn-primary btn-block btn-flat pull-right" v-on:click="add"><i class="fa fa-fw fa-plus"></i> Thêm mới</button>
              </div>
            </div>
          </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <data-viewer :thead="thead" :data="data">
              <template slot-scope="props" slot='id'>
                <span class="text-bold hidden-lg">Mã: </span>{{props.item.id}}
              </template>
              <template slot-scope="props" slot='name'>
                <span class="text-bold hidden-lg">Tên: </span>{{props.item.name}}
              </template>
             <template slot-scope="props" slot='email'>
                <span class="text-bold hidden-lg">Email: </span>{{props.item.email}}
              </template>
              <template slot-scope="props" slot='control'>
                <div class="pull-right">
                  <a @click="edit(props.item.id)" class="btn btn-info btn-xs" data-toggle="tooltip" title="Chỉnh sửa"><i class="fa fa-pencil"></i></a>
                  <a @click="change_password(props.item.id)" class="btn btn-default btn-xs" data-toggle="tooltip" title="Chỉnh sửa"><i class="fa fa-key"></i></a>
                </div>
              </template>
            </data-viewer>
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>

    <!-- Modal -->
    <div class="modal fade in" id="modal-form">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Người dùng</h4>
          </div>
          <div class="modal-body">
            <form-user ref="formUser" :click-submit="saveSuccess"></form-user>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->

    <!-- Modal change password -->
    <div class="modal fade in" id="modal-form-change-password">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Thay đổi mật khẩu</h4>
          </div>
          <div class="modal-body">
            <form-change-password ref="formChangePassword" :click-submit="saveSuccess"></form-change-password>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import DataViewer from '../../components/DataViewer';
import formUser from './form.vue'
import formChangePassword from './change_password.vue'

export default {
    name: 'UserIndex',
    components: {
      formUser, formChangePassword, VuejsDialog, DataViewer
    },
    data() {
        return {
          title: 'Quản lý người dùng',
          data:[],
          params: {
              keyword: ''
          },
          thead: [
            {
              key: 'id',
              title: 'Mã',
              class: 'col-xs-12 col-md-1'
            },
            {
              key: 'name',
              title: 'Tên',
              class: 'col-xs-12 col-md-5'
            },
            {
              key: 'email',
              title: 'Email',
              class: 'col-xs-12 col-md-5'
            },
            {
              key: 'control',
              title: 'Xử lý',
              class: 'col-xs-12 col-md-1'
            }
          ]
        }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
        this.seach();
    },
    methods: {
      seach() {
        var vm = this
        axios.get('/api/user/seach'+ '?' + $.param(this.params))
            .then(function(response) {
                NProgress.done()
                vm.data = response.data.data
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
      add() {
        this.$refs.formUser.open();
        $('#modal-form').modal('show')
      },
      edit(id) {
        this.$refs.formUser.open(id);
        $('#modal-form').modal('show')
      },
      change_password(id) {
        this.$refs.formChangePassword.open(id);
        $('#modal-form-change-password').modal('show')
      },
      saveSuccess()
      {
        $('#modal-form').modal('hide')
        $('#modal-form-change-password').modal('hide')
        this.seach()
      }
    }
}
</script>