<template>
  <div class="row">
    <div class="col-md-12  col-sm-12 col-xs-12">
      <!-- seach -->
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <!-- /btn-group -->
          <div class="input-group margin-bottom">
              <input type="text" 
                class="form-control" 
                placeholder="Nhập từ khóa..."
                v-model="params.keyword" 
                v-on:keyup.enter="seach">
              <div class="input-group-btn">
                  <button type="button" class="btn btn-default btn-flat" @click="seach">
                      <i class="glyphicon glyphicon-search"></i>
                  </button>
                  <button type="button" class="btn btn-default btn-flat" @click="OpenReadQrcode"><i class="glyphicon glyphicon-qrcode"></i></button>
              </div>
              <!-- /btn-group -->
          </div>
          <!-- /input-group -->
        </div>
      </div>
      <!-- ./seach -->

      <!-- danh sach sản phẩm -->
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" v-for="item in data" :key="item.id" v-show="item.status != 0">
          <product-item
            @Selected="productSelected"
            @KeepAdd="KeepAdd"
            :data="item" 
            :showButton="showButton"
            :showButtonKeep="showButtonKeep"
            :key="item.id">
          </product-item>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6 col-md-6">
          <span class="text-bold">Tổng tồn: {{totalQuantity | numFormat}}</span>
        </div>
        <div class="col-xs-6 col-md-6">
          <span class="text-bold">Tổng đặt hàng: {{totalQuantityOrder | numFormat}}</span>
        </div>
        <!--  Phân trang -->
        <div class="col-md-12 col-sm-12 col-xs-12">
          <b-pagination
            v-model="pagination.currentPage" 
            :total-rows="pagination.rows"
            :per-page="pagination.perPage"
            @change="handlePageChange">
          </b-pagination>
        </div>

      </div>
      <!-- ./danh sach sản phẩm -->

      <!-- Giữ hàng -->
      <KeepAdd ref="KeepAdd">
      </KeepAdd>
      <!-- ./Giữ hàng -->

       <!-- Bắn qrcode -->
      <ReadQrcode ref="ReadQrcode">
      </ReadQrcode>
      <!-- ./Bắn qrcode -->

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import NProgress from 'nprogress'
import ProductItem from './ProductItem'
import KeepAdd from './KeepAdd.vue';
import ReadQrcode from '../../Qrcode/ReadQrcode'

export default {
    name: 'ProductView',
    components: {
      ProductItem,
      KeepAdd,
      ReadQrcode
    },
    props: {
      showButton: {
        type: Boolean,
        default: true
      },
      showButtonKeep: {
        type: Boolean,
        default: false
      }
    },
    data() {
        return {
          data:[],
          pagination: {
            rows: 0,
            perPage: 20,
            currentPage: 1
          },
          params: {
              keyword: '',
              page: 1,
              perPage: 20
          }
        }
    },
    computed: {
        totalQuantity: function () {
          var total_quantity = 0
          if(this.data){
              for (var i = 0; i < this.data.length; i++) {
                if(this.data[i].status != 0){
                  if(this.data[i].products){
                    for (var j = 0; j < this.data[i].products.length; j++) {
                      if(this.data[i].products[j].status != 0){
                        total_quantity += parseFloat(this.data[i].products[j].currentinstock.quantity)
                      }
                    }
                  }
                }
              }
          }
          return total_quantity
        },
        totalQuantityOrder: function () {
          var total_quantity_order = 0
          if(this.data){
              for (var i = 0; i < this.data.length; i++) {
                if(this.data[i].status != 0){
                  if(this.data[i].products){
                    for (var j = 0; j < this.data[i].products.length; j++) {
                      if(this.data[i].products[j].status != 0){
                        total_quantity_order += parseFloat(this.data[i].products[j].currentinstock.order_quantity)
                      }
                    }
                  }
                }
              }
          }
          return total_quantity_order
        }
    },
    mounted(){
        //this.seach();
    },
    methods: {
      open(keyword){
        this.params.keyword = keyword
        if(this.params.keyword != null && this.params.keyword.trim() != ''){
          this.seach();
        }
      },
      getData() {
        NProgress.start()
        var vm = this
        vm.data = []
        axios.get('/api/model/seach_product_sale'+ '?' + $.param(this.params))
            .then(function(response) {
                NProgress.done()
                var dataResponse = response.data.data
                vm.data = dataResponse.data
                //Phân trang - gắng tổng số row
                vm.pagination.rows = dataResponse.total
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
      handlePageChange(value){
        //Phân trang - gáng giá trị
        this.params.page = value
        this.params.perPage = this.pagination.perPage

        this.getData()
      },
      seach() {
        //Phân trang - gáng giá trị
        this.params.page = 1
        this.pagination.currentPage = 1
        this.params.perPage = this.pagination.perPage

        this.getData()
      },
      productSelected(product, quantity) {
        this.$emit('productSelected', product, quantity)
      },
      KeepAdd(product) {
        this.$refs.KeepAdd.open(product)
      },
      OpenReadQrcode: function() {
          this.$refs.ReadQrcode.open();
      },
    }
}
</script>