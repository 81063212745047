<template>
<!-- info-box -->
<div class="info-box pull-left">
    <span class="info-box-icon">
        <!-- <img :src="data.images[0].link_image"> -->
        <v-lazy-image :src="data.images[0].link_image" />
    </span>

    <div class="info-box-content row">
        <div class="col-md-12 col-xs-12">
            <div class="row">
                <div class="col-md-12">
                    <span class="info-box-text text-green">
                        {{data.id}} - {{data.name}}
                        <span class="label label-success" v-if="data.status === 1">Đang bán</span>
                        <span class="label label-warning" v-else-if="data.status === 2">Chờ hàng</span>
                        <span class="label label-danger" v-else>Ngưng bán</span>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-xs-12">
                    <span class="text-bold">Màu sắc: </span>  
                    <span>{{colorListText}}</span> 
                </div>
                <div class="col-md-12 col-xs-12">
                    <span class="text-bold">Size: </span>  
                    <span>{{sizeListText}}</span> 
                </div>
                <div class="col-md-8 col-xs-12">
                    <span class="text-bold">Vị trí: </span>  
                    <span>{{data.location}}</span> 
                </div>
                <div class="col-md-4 col-xs-12">
                  <a v-on:click="Selected()" class="btn btn-primary btn-xs btn-block pull-right"><i class="glyphicon glyphicon-ok"></i> Chọn</a>
                </div>
            </div>
        </div>
    </div>
    <!-- /.info-box-content -->
</div>
<!-- /.info-box -->
</template>

<script>
import VLazyImage from "v-lazy-image";
export default {
    name: 'ModelItem',
    components: {VLazyImage},
    props: {
      data: {
          type: Object,
        },
    },
    data() {
        return {

        }
    },
    computed: {
      colorListText: function () {
        var colorListTemp = []
        for (var i = 0; i < this.data.products.length; i++) {
            if(!colorListTemp.some(o => o.id == this.data.products[i].color.id) && this.data.products[i].status != 0){
              colorListTemp.push(this.data.products[i].color)
            } 
        }
        return colorListTemp.map(o=>o.name).join(", ")
      },
      sizeListText: function () {
        var sizeListTemp = []
        for (var i = 0; i < this.data.products.length; i++) {
            if(!sizeListTemp.some(o => o.id == this.data.products[i].size.id)  && this.data.products[i].status == 1){
              sizeListTemp.push(this.data.products[i].size)
            } 
        }
        return sizeListTemp.map(o=>o.name).join(", ")
      }
    },
    methods: {
      Selected(){
        var vm = this
        axios.get('/api/model/seachsale/'+ vm.data.id)
        .then(function(response) {
            var model = response.data.data
            if(model.length > 0) 
            {
                vm.$emit('Selected', model[0])
                return
            }
        })
        .catch(function(error) {
            vm.$emit('Selected', null)
        })
      }
    }
}
</script>
<style lang="css" scoped>
.info-box-icon>img {
    width: 100%;
    max-height: 90px;
    vertical-align: unset;
}
.info-box-content{
    padding: 0px;
}
.info-box-text{
    white-space: unset;
}
.info-box {
    padding-bottom: 10px;
}
</style>