<template>
  <div class="row">
    <div class="col-md-12  col-sm-12 col-xs-12">
      <!-- seach -->
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <!-- /btn-group -->
          <div class="input-group margin-bottom">
              <input type="text" 
                class="form-control" 
                placeholder="Nhập từ khóa..."
                v-model="params.keyword" 
                v-on:keyup.enter="seach">
              <div class="input-group-btn">
                  <button type="button" class="btn btn-default btn-flat" @click="seach">
                      <i class="glyphicon glyphicon-search"></i>
                  </button>
              </div>
              <!-- /btn-group -->
          </div>
          <!-- /input-group -->
        </div>
      </div>
      <!-- ./seach -->

      <!-- danh sach sản phẩm -->
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" v-for="item in data" :key="item.id" v-show="item.status != 0">
          <product-item-client
            :data="item" 
            :key="item.id"
            :showquantity="showquantity"
            v-if="checkquantity(item)">
          </product-item-client>
        </div>
      </div>
      <!-- ./danh sach sản phẩm -->
      <!--  Phân trang -->
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <b-pagination
            v-model="pagination.currentPage" 
            :total-rows="pagination.rows"
            :per-page="pagination.perPage"
            @change="handlePageChange">
          </b-pagination>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import NProgress from 'nprogress'
import ProductItemClient from './ProductItemClient'

export default {
    name: 'ProductViewClient',
    components: {
      ProductItemClient,
    },
    props: {
      showquantity: {
          Boolean,
          default: false
      }
    },
    data() {
        return {
          data:[],
          pagination: {
            rows: 0,
            perPage: 20,
            currentPage: 1
          },
          params: {
              keyword: '',
              page: 1,
              perPage: 20
          }
        }
    },
    mounted(){
        //this.seach();
    },
    methods: {
      open(keyword){
        this.params.keyword = keyword
        this.seach();
      },
      getData() {
        NProgress.start()
        var vm = this
        vm.data = []
        axios.get('/api/product-seach-client/seach_product_sale'+ '?' + $.param(this.params))
            .then(function(response) {
                NProgress.done()
                var dataResponse = response.data.data
                vm.data = dataResponse.data
                //Phân trang - gắng tổng số row
                vm.pagination.rows = dataResponse.total
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
      handlePageChange(value){
        //Phân trang - gáng giá trị
        this.params.page = value
        this.params.perPage = this.pagination.perPage

        this.getData()
      },
      seach() {
        //Phân trang - gáng giá trị
        this.params.page = 1
        this.pagination.currentPage = 1
        this.params.perPage = this.pagination.perPage

        this.getData()
      },
      checkquantity(itemcheck){
        var result = false

        for (var i = 0; i < itemcheck.products.length; i++) {
          if(itemcheck.products[i].status == 1 && (parseFloat(itemcheck.products[i].currentinstock.quantity) - parseFloat(itemcheck.products[i].currentinstock.order_quantity) > 0)){
            result = true
          }
        }

        return result
      },
    }
}
</script>