<template>
    <!-- /btn-group -->
    <div>
        <!-- Modal -->
        <div class="modal fade in" style="padding-right: 0px;" :id="'modal-form-soan-hang-vourcher-' + model_id"  data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span></button>
                        <h4 class="modal-title">Danh sách sản phẩm theo mẫu {{model_id}}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12 col-sm-12 col-xs-12" v-for="item in listDetail" :key="item.outputvourcher_detail.id">
                            <soan-hang-vourcher-item
                                :item="item"
                                @changeddata="$emit('changeddata')">
                            </soan-hang-vourcher-item>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Đóng</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.modal -->

    </div>
    <!-- /input-group -->
</template>

<script>
import axios from 'axios'
import SoanHangVourcherItem from './SoanHangVourcherItem.vue'

    export default {
        name: 'SoanHangVourcher',
        components: {
            SoanHangVourcherItem
        },
        props: {
            data: {
                type: Array
            },
            model_id: ''
        },  
        data() {
            return {

            }
        },
        computed: {
            listDetail: function () {
            var listDetailTemp = []
            if(this.data && this.data.length > 0){
                for (var i = 0; i < this.data.length; i++) {
                    for (var j = 0; j < this.data[i].outputvourcher_details.length; j++) {
                        if(this.data[i].outputvourcher_details[j].product.model_id == this.model_id && this.data[i].outputvourcher_details[j].status != 0){
                            var newItem = {};
                            newItem.outputvourcher_id = this.data[i].id
                            newItem.customer_name = this.data[i].customer_name
                            newItem.customer_nickname = this.data[i].customer_nickname
                            newItem.customer_phone = this.data[i].customer_phone
                            newItem.product_id = this.data[i].outputvourcher_details[j].product.id
                            newItem.outputvourcher_detail = this.data[i].outputvourcher_details[j]
                            listDetailTemp.push(newItem)
                        }
                    }
                }
            }
            listDetailTemp.sort(function(a, b){return a.product_id - b.product_id})
            return listDetailTemp
            },
        },
        methods: {
            open() {
                $('#modal-form-soan-hang-vourcher-'+this.model_id).modal('show')
            }
        }
    }
</script>
<style lang="css" scoped>
.modal-dialog,
.modal-content {
    /* 80% of window height */
    height: 100%;
    border: 0;
}

.modal-body {
    /* 100% = dialog height, 120px = header + footer */
    max-height: calc(100% - 120px);
    overflow-y: scroll;
}
.modal-footer {
    position: sticky;
    bottom: 0px;
    background: white;
}
@media (min-width: 768px){
    .modal-dialog {
        margin-top: 10px;
    }
}
</style>