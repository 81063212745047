<template>
  <div>
     <form role="form"  @submit.prevent="save" name="formModelEdit">
      <!-- box Thông Hình ảnh -->
      <div class="box box-primary">
        <!-- box-header -->
        <div class="box-header">
          <h3 class="box-title">Hình ảnh</h3>
        </div>
        <!-- /.box-header -->
        <!-- box-body -->
        <div class="box-body">
          <upload-image 
          v-model="form.images"
          ref="formUploadImage" 
          :data="form.images"
          @deleteImage="deleteImage"
          keyID="id"
          keyUrl="url"
          keyUrlFull="urlfull">
          </upload-image>
        </div>
        <!-- /.box-body -->
        <div class="box-footer no-border" v-if="formErrors.images">
         <span class="text-red" v-if="formErrors.images">{{formErrors.images[0]}}</span>
        </div>
      </div>
      <!-- /.box Hình ảnh --> 

      <!-- box Thông tin model -->
      <div class="box box-primary">
        <!-- box-header -->
        <div class="box-header">
          <h3 class="box-title">Thông tin model</h3>
        </div>
        <!-- /.box-header -->
        <!-- box-body -->
        <div class="box-body">
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <div class="form-group" :class="{'has-error': formErrors.category_model_id}">
                <div class="form-group" :class="{'has-error': formErrors.category_model_id}">
                  <label class="control-label" for="name">Loại đồ</label>
                  <Select2 :id="'category_model_id'" :name="'category_model_id'" :dataUrl="'/api/category_model/all'" v-model="form.category_model_id">
                    <option disabled value="0">--Chọn loại đồ--</option>
                  </Select2>
                  <span class="help-block" v-if="formErrors.category_model_id">{{formErrors.category_model_id[0]}}</span>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-md-3">
              <div class="form-group" :class="{'has-error': formErrors.id}">
                <label class="control-label" for="id">Mã Model</label>
                <input type="text" class="form-control" id="id" name="id" v-model="form.id" maxlength="20" autocomplete = "off" readonly>
                <span class="help-block" v-if="formErrors.id">{{formErrors.id[0]}}</span>
              </div>
            </div>

            <div class="col-xs-12 col-md-5">
              <div class="form-group" :class="{'has-error': formErrors.name}">
                <label class="control-label" for="name">Tên model</label>
                <input type="text" class="form-control" id="name" name="name" v-model="form.name">
                <span class="help-block" v-if="formErrors.name">{{formErrors.name[0]}}</span>
              </div>
            </div>

            <div class="col-xs-12 col-md-2">
              <div class="form-group" :class="{'has-error': formErrors.location}">
                <label class="control-label" for="location">Vị trí</label>
                <input type="text" 
                  class="form-control" 
                  id="location" 
                  name="location" 
                  v-model="form.location">
                <span class="help-block" v-if="formErrors.location">{{formErrors.location[0]}}</span>
              </div>
            </div>

            <div class="col-xs-12 col-md-2">
              <div class="form-group">
                <label class="control-label" for="status">Trạng thái:</label>
                <select class="form-control" id="status" name="status" v-model="form.status">
                  <option value="2">Chờ hàng</option>
                  <option value="1">Đang bán</option>
                  <option value="0">Ngưng bán</option>
                </select>
              </div>
            </div>

          </div>
          <div class="form-group">
            <label class="control-label" for="content">Mô tả:</label>
            <textarea class="form-control" rows="3" id="content" name="content" v-model="form.content"></textarea>
          </div>
        </div>
        <!-- /.box-body -->
      </div>
      <!-- /.box thông tin model--> 
      
      <!-- box giá -->
      <div class="box box-primary">
        <!-- box-header -->
        <div class="box-header">
          <h3 class="box-title">Thông tin giá</h3>
        </div>
        <!-- /.box-header -->
        <!-- box-body -->
        <div class="box-body">
          <table class="table table-striped">
              <tbody><tr>
                <th>Loại giá</th>
                <th style="width: 150px">Giá</th>
              </tr>
              <tr v-for="(item, index) in form.shop_prices" :key="item.shop_price_id">
                <input type="hidden" id="shop_prices['shop_price_id']" :name="'shop_prices['+index+'][shop_price_id]'" v-model="item.shop_price_id">
                <td>{{item.name}}</td>
                <td>
                  <div class="form-group" :class="{'has-error': formErrors['shop_prices.'+index+'.price']}">
                    <input type="number" class="form-control" id="shop_prices['price']" :name="'shop_prices['+index+'][price]'" v-model="item.price" :precision="0">
                    <span class="help-block" v-if="formErrors['shop_prices.'+index+'.price']">{{formErrors['shop_prices.'+index+'.price'][0]}}</span>
                   </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- /.box-body -->
      </div>
      <!-- /.box -->

      <!-- box Thông tin sản phẩm -->
      <div class="box box-primary">
        <!-- box-header -->
        <div class="box-header">
          <h3 class="box-title">Thông tin sản phẩm</h3>
        </div>
        <!-- /.box-header -->
        <!-- box-body -->
        <div class="box-body">
          <!-- Thuộc tính sản phẩm -->
           <div class="row">
              <div class="col-xs-12 col-md-6">
                <div class="form-group">
                  <label class="control-label" for="colorSelected">Màu sắc</label>
                  <Select2 
                    :id="'colorSelected'" 
                    :name="'colorSelected'" 
                    :dataUrl="'/api/color/all'" 
                    v-model="colorSelected" 
                    @input="colorInput"
                    @removeItem="colorRemove"
                    :multiple="true">
                  </Select2>
                </div>
              </div>
              <div class="col-xs-12 col-md-6">
                <div class="form-group">
                  <label class="control-label" for="sizeSelected">Size</label>
                  <Select2 
                    :id="'sizeSelected'" 
                    :name="'sizeSelected'" 
                    :dataUrl="'/api/size/all'" 
                    v-model="sizeSelected" 
                    @input="sizeInput"
                    @removeItem="sizeRemove"
                    :multiple="true">
                  </Select2>
                </div>
              </div>
           </div>
           <!-- ./Thuộc tính sản phẩm -->
           <!-- Danh sách sản phẩm -->
            <div class="row">
              <!-- row-product -->
              <div class="col-md-12 col-sm-12 col-xs-12" v-for="(item, index) in form.products" :key="index" v-show="item.status != 0">
                <!-- info-box -->
                <div class="info-box pull-left">
                    <span class="info-box-icon">
                        <img 
                          src="/images/noImg.png" 
                          v-on:click="showSelectedImage(index)"
                          v-if="item.image_id == ''"
                          >
                        <img 
                          :src="item.image_urlfull" 
                          v-on:click="showSelectedImage(index)"
                          v-else>
                    </span>

                    <div class="info-box-content row">
                        <div class="col-md-12 col-xs-12">
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-red" v-if="formErrors['products.'+index+'.image_id']">{{formErrors['products.'+index+'.image_id'][0]}}</span>
                                </div>
                                <div class="col-md-2 col-xs-12">
                                    <div class="form-group" :class="{'has-error': formErrors['products.'+index+'.id']}">
                                        <label class="control-label" for="id">Mã</label>
                                        <input type="text" class="form-control" v-model="item.id" maxlength="20" autocomplete = "off" readonly>
                                        <span class="help-block" v-if="formErrors['products.'+index+'.id']">{{formErrors['products.'+index+'.id'][0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-10 col-xs-12">
                                    <div class="form-group" :class="{'has-error': formErrors['products.'+index+'.name']}">
                                        <label class="control-label" for="name">Tên</label>
                                        <input type="text" class="form-control" v-model="item.name">
                                        <span class="help-block" v-if="formErrors['products.'+index+'.name']">{{formErrors['products.'+index+'.name'][0]}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-2 col-xs-6">
                                    <div class="form-group">
                                        <label class="control-label" for="color">Màu sắc</label>
                                        <input type="text" class="form-control" v-model="item.color.name" autocomplete = "off" readonly>
                                    </div>
                                </div>
                                <div class="col-md-2 col-xs-6">
                                    <div class="form-group">
                                        <label class="control-label" for="size">Size</label>
                                        <input type="text" class="form-control" v-model="item.size.name" autocomplete = "off" readonly>
                                    </div>
                                </div>
                                <div class="col-md-4 col-xs-6">
                                    <div class="form-group" :class="{'has-error': formErrors['products.'+index+'.surcharge_price']}">
                                        <label class="control-label" for="surcharge_price">Tiền phụ thu</label>
                                        <input type="number" 
                                          class="form-control" 
                                          :id="'products.'+index+'.surcharge_price'" 
                                          :name="'products.'+index+'.surcharge_price'"  
                                          v-model="item.surcharge_price" 
                                          :precision="0">
                                        <span class="help-block" v-if="formErrors['products.'+index+'.surcharge_price']">{{formErrors['products.'+index+'.surcharge_price'][0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4 col-xs-6">
                                    <div class="form-group">
                                        <label class="control-label" for="status">Trạng thái</label>
                                        <select class="form-control" id="status" name="status" v-model="item.status">
                                        <option value="1">Đang bán</option>
                                        <option value="2">Hết hàng</option>
                                        <option value="0">Ngưng bán</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
              </div>
            </div>
           <!-- ./Danh sách sản phẩm -->
        </div>
        <!-- /.box-body -->
        <div class="box-footer no-border" v-if="formErrors.products">
          <span class="text-red" v-if="formErrors.products">{{formErrors.products[0]}}</span>
        </div>
      </div>
      <!-- /.box -->

      <div class="form-group">
        <button type="submit" class="btn btn-primary pull-right">Cập nhật</button>
      </div>
     </form>
     <!-- Modal chọn hình -->
     <!-- Modal -->
      <div class="modal fade in" id="modal-form" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span></button>
              <h4 class="modal-title">Danh sách hình ảnh</h4>
            </div>
            <div class="modal-body">
              <selected-image
                :data="form.images"
                keyID="id"
                keyUrl="url"
                keyUrlFull="urlfull"
                @SelectedImage="SelectedImage">
              </selected-image>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->
     <!-- ./Modal chọn hình -->
  </div>
</template>

<script>
  import axios from 'axios'
  import NProgress from 'nprogress'
  import Switches from '../../components/Switches';
  import Select2 from '../../components/Select2.vue'
  import UploadImage from '../../components/UploadMultipleImage';
  import SelectedImage from '../../components/SelectedImage';
  import model from '../../objects/model'

  export default {
    name: 'formModelEdit',
    components: {Switches, UploadImage, Select2, SelectedImage},
    data() {
      return {
        title: 'Cập nhật sản phẩm',
        formErrors: {},
        modelData: {},
        id: '',
        form: {},
        colorList: [],
        colorSelected: [],
        sizeList: [],
        sizeSelected: [],
        productIndexSelectedImage: -1
      }
    },
    mounted(){
      this.$store.dispatch('setPageTitle', this.title)
      this.form = Object.assign({}, model)
      this.form.products = []
      this.id = this.$route.params.id
      this.getModel(this.id)
      this.getData()
    },
    methods: {
        save() {
            NProgress.start()
            let vm = this
            //loại bỏ các dòng có trạng thái status = 0
            var productListTempActived = vm.form.products.filter(product => product.status != 0)
            var productListTempDeleted = vm.form.products.filter(product => product.status == 0)
            for (var i = 0; i < productListTempDeleted.length; i++) {
              if(vm.modelData.products.some(o => o.id == productListTempDeleted[i].id)){
                productListTempActived.push(productListTempDeleted[i])
              }
            }
            vm.form.products = productListTempActived

            axios['put']('/api/model/update', vm.form)
            .then(function (response) {
                vm.$snotify.success('Cập nhật thành công!', 'Success')
                vm.$router.push({path: '/product/edit/'+response.data.data.id})
                NProgress.done()
            })
            .catch(function (error) {
                NProgress.done()
                vm.formErrors = error.response.data.errors
            })
        },

        getData() {
            var vm = this
            //Lấy danh mục màu sắc
            axios.get('/api/color/all')
                .then(function(response) {
                    vm.colorList = response.data.data
                })
                .catch(function(error) {
                    vm.colorList = []
                    console.logerror()
                })

            //Lấy danh mục size
            axios.get('/api/size/all')
                .then(function(response) {
                  vm.sizeList = response.data.data
                })
                .catch(function(error) {
                    console.logerror()
                    vm.sizeList = []
                })
        },
        getModel(modelID)
        {
            NProgress.start()
            var vm = this
            axios.get('/api/model/select/' + modelID)
                .then(function(response) {
                    vm.modelData = response.data.data
                    vm.initData()
                    NProgress.done()
                })
                .catch(function(error) {
                    NProgress.done()
                    console.log(error)
                })
        },

        initData() {
            this.form.id = this.modelData.id
            this.form.name = this.modelData.name
            this.form.content = this.modelData.content
            this.form.status = this.modelData.status
            this.form.location = this.modelData.location
            this.form.category_model_id = this.modelData.category_model_id

            //Khởi tạo shop_prices
            this.form.shop_prices = []
            for (var i = 0; i < this.modelData.shop_prices.length; i++) {
                if(this.modelData.shop_prices[i].status != 3 && this.modelData.shop_prices[i].shop.status == 1){
                  var newItem = {};
                  newItem.shop_price_id = this.modelData.shop_prices[i].id
                  var priceName = this.modelData.shop_prices[i].shop.name + ' - ' + this.modelData.shop_prices[i].name
                  if(this.modelData.shop_prices[i].surcharge == 1){
                    priceName += ' (Phụ thu giá SP)'
                  }
                  newItem.name = priceName
                  newItem.price = parseFloat(this.modelData.shop_prices[i].pivot.price)
                  this.form.shop_prices.push(newItem)
                }
            }
            //Khởi tạo images
            this.form.images = []
            for (var i = 0; i < this.modelData.images.length; i++) {
                var newItem = {};
                newItem.id = this.modelData.images[i].id
                newItem.url = this.modelData.images[i].url
                newItem.urlfull = this.modelData.images[i].link_image
                this.form.images.push(newItem)
            }
            //Khởi tạo product
            this.form.products = []
            for (var i = 0; i < this.modelData.products.length; i++) {
                var newItem = {};
                newItem.id = this.modelData.products[i].id
                newItem.name = this.modelData.products[i].name
                newItem.color_id = this.modelData.products[i].color_id
                newItem.color = this.modelData.products[i].color
                newItem.size_id = this.modelData.products[i].size_id
                newItem.size = this.modelData.products[i].size
                newItem.image_id = this.modelData.products[i].image_id
                newItem.surcharge_price =  parseFloat(this.modelData.products[i].surcharge_price)
                if(this.modelData.products[i].image){
                  newItem.image_urlfull = this.modelData.products[i].image.link_image
                }
                newItem.status = this.modelData.products[i].status
                this.form.products.push(newItem)

                //Gán danh sách màu sắc đã chọn
                if(!this.colorSelected.some(o => o == this.modelData.products[i].color_id) && this.modelData.products[i].status != 0)
                {
                  this.colorSelected.push(this.modelData.products[i].color_id)
                }

                //Gán danh sách size đã chọn
                if(!this.sizeSelected.some(o => o == this.modelData.products[i].size_id) && this.modelData.products[i].status != 0 )
                {
                  this.sizeSelected.push(this.modelData.products[i].size_id)
                }
            }
            //sort lại dữ liệu
            this.form.products.sort(function(a, b){return a.color_id - b.color_id})
        },

        colorInput(){
            this.genProduct()
        },

        colorRemove(valTemp){
            var productListTemp = this.form.products.filter(product => product.color_id == valTemp)
            if(productListTemp.length > 0){
            for (var i = 0; i < productListTemp.length; i++) {
                var productIndex= this.form.products.findIndex(product => product == productListTemp[i])
                this.form.products[productIndex].status = 0
            }
            this.genProduct()
            }
        },

        sizeInput(){
            this.genProduct()
        },

        sizeRemove(valTemp){
            var productListTemp = this.form.products.filter(product => product.size_id == valTemp)
            if(productListTemp.length > 0){
            for (var i = 0; i < productListTemp.length; i++) {
                var productIndex= this.form.products.findIndex(product => product == productListTemp[i])
                this.form.products[productIndex].status = 0
            }
            this.genProduct()
            }
        },

        genProduct() {
            var vm = this
            //gen tạo danh sách sản phẩm
            if(vm.colorSelected.length>0 && vm.sizeSelected.length>0)
            {
              for (var i = 0; i < vm.colorSelected.length; i++) {
                  for (var j = 0; j < vm.sizeSelected.length; j++) {
                      //Kiểm tra đã tồn tại sản phẩm theo thuộc tính này chưa
                      var productIndex= vm.form.products.findIndex(product => product.color_id == vm.colorSelected[i] 
                                                                      && product.size_id == vm.sizeSelected[j])

                      if(productIndex<0){
                          var colorTemp = vm.colorList.find(color => color.id == vm.colorSelected[i])
                          var sizeTemp = vm.sizeList.find(size => size.id == vm.sizeSelected[j])
                          var newItem = {};

                          newItem.id = vm.form.id + vm.colorSelected[i].toString() + vm.sizeSelected[j].toString();
                          newItem.name = vm.form.name + ' - ' + colorTemp.name + ' - ' + sizeTemp.name
                          newItem.color_id = vm.colorSelected[i]
                          newItem.color = colorTemp
                          newItem.size_id = vm.sizeSelected[j]
                          newItem.size = sizeTemp
                          newItem.image_id = ''
                          newItem.image_urlfull = ''
                          newItem.quantity = 0
                          newItem.status = 1
                          newItem.surcharge_price = 0
                          vm.form.products.push(newItem)

                      }else{
                          if(vm.form.products[productIndex].status == 0){
                          vm.form.products[productIndex].status = 1
                          }
                      }
                  }
              }
              //sort lại dữ liệu
              vm.form.products.sort(function(a, b){return a.color_id - b.color_id})
            }
        },

        showSelectedImage(valIndex){
            this.productIndexSelectedImage = valIndex 
            $('#modal-form').modal('show')
        },

        SelectedImage(valItem){
            $('#modal-form').modal('hide')
            this.form.products[this.productIndexSelectedImage].image_urlfull = valItem.urlfull
            this.form.products[this.productIndexSelectedImage].image_id = valItem.id
        },

        deleteImage(valItem){
            var productListTemp = this.form.products.filter(product => product.image_id == valItem.id)
            if(productListTemp.length > 0){
                for (var i = 0; i < productListTemp.length; i++) {
                var productIndex= this.form.products.findIndex(product => product == productListTemp[i])
                this.form.products[productIndex].image_id = ''
                this.form.products[productIndex].image_urlfull = ''
                }
            }
        }
    }
  }
</script>
<style lang="css" scoped>
.info-box-icon>img {
    width: 100%;
    max-height: 90px;
    min-height: 90px;
    vertical-align: unset;
}
.info-box-content{
    padding: 0px;
}
.info-box-text{
    white-space: unset;
}
.info-box {
    padding-bottom: 10px;
}
</style>