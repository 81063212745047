<template>
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="col-md-12 col-sm-12 col-xs-12">
            <h4 class="text-blue">Thông Tin người nhận</h4>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="form-group" :class="{'has-error': formErrors.province_id}">
                <label class="control-label" for="name">Tỉnh thành</label>
                <Select2 :id="'province_id'" 
                    :name="'province_id'" 
                    :data="provinceList"
                    @changed="changedprovince()" 
                    v-model="province_id"
                    :allowClear="false"
                    :placeholder="'Chọn tỉnh thành'"
                    :multiple="false">
                </Select2>
                <span class="help-block" v-if="formErrors.province_id">{{formErrors.province_id[0]}}</span>
            </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="form-group" :class="{'has-error': formErrors.district_id}">
                <label class="control-label" for="name">Quận huyện</label>
                <Select2 :id="'district_id'" 
                    :name="'district_id'" 
                    :data="districtList"
                    v-model="district_id"
                    :allowClear="false"
                    :placeholder="'Chọn quận huyện'"
                    :multiple="false">
                </Select2>
                <span class="help-block" v-if="formErrors.district_id">{{formErrors.district_id[0]}}</span>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12">
           <h4 class="text-blue">Thông Tin vận chuyển</h4>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
            <label class="control-label">Phương thức vận chuyển:</label>
            <div class="form-group">
                <select class="form-control" v-model="transport">
                    <option value="road">Đường bộ</option>
                    <option value="fly">Máy bay</option>
                </select>
            </div> 
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="form-group" :class="{'has-error': formErrors.total_weight}">
                <label class="control-label">Tổng trọng lượng(1 bộ = 0.3 kg):</label>
                <input type="number" class="form-control"
                    id="total_weight"
                    list="datalist_weights" 
                    name="total_weight"
                    v-model="total_weight"
                    step="0.1">
                <datalist id="datalist_weights">
                    <option value="0.2" />
                    <option value="0.3" />
                    <option value="0.5" />
                    <option value="1" />
                    <option value="1.5" />
                    <option value="2" />
                    <option value="2.5" />
                    <option value="3" />
                </datalist>
                <span class="help-block" v-if="formErrors.total_weight">{{formErrors.total_weight[0]}}</span>
            </div> 
        </div>
        <div class="col-md-4 col-sm-4 col-xs-6">
            <span class="text-bold">Phí ship: </span> 
            <span class="text-bold text-red" v-if="data.fee">{{data.fee | numFormat}} đ</span> 
        </div>
        <div class="col-md-4 col-sm-4 col-xs-6">
            <span class="text-bold">Hỗ trợ giao: </span> 
            <span class="text-bold text-red" v-if="data.delivery">{{data.delivery?'Có':'Không'}}</span> 
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12">
            <div class="form-group">
                <button 
                    @click="submit()"
                    type="button" 
                    class="btn btn-primary pull-right">Xác nhận
                </button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import NProgress from 'nprogress'
import Select2 from '../Select2New.vue'

export default {
    name: 'GHTKFee',
    components: {
      Select2
    },
    data() {
        return {
            formErrors: {},
            data:{},
            provinceList: [],
            districtList: [],
            province_id: '',
            district_id: '',
            transport: 'road',
            total_weight: ''
        }
    },
    mounted(){
        var vm = this
        axios.get('/api/province/all')
            .then(function(response) {
                vm.provinceList = response.data.data
            })
            .catch(function(error) {
                vm.provinceList = []
            })
    },
    methods: {
        submit(){
            var vm = this
            this.formErrors = {}
            this.validate()
            if(Object.keys(this.formErrors).length === 0 && this.formErrors.constructor === Object){
                var province = this.provinceList.find(o => o.id == this.province_id)
                var district = this.districtList.find(o => o.id == this.district_id)

                var result = {
                    province: province.name,
                    district: district.name,
                    weight: this.total_weight,
                    transport: this.transport
                }

                axios['post']('/api/logistics/shipment/ghtk/fee', result)
                .then(function (response) {
                    vm.data = {}
                    var result = response.data
                    if(result.success==true){
                        vm.data = result.data
                        vm.$snotify.success('Lấy cước phí thành công!', 'Success')
                    }else{
                        vm.$snotify.error(result.message, 'Lỗi')
                    }
                })
                .catch(function (error) {
                    vm.formErrors = error.response.data.errors
                })
            }
        },
        changedprovince(){
            var vm = this
            if(vm.province_id != '' && vm.province_id != null){
            axios.get('/api/district/seachbyprovince/'+vm.province_id)
                .then(function(response) {
                    vm.districtList = response.data.data
                })
                .catch(function(error) {
                    vm.districtList = []
                })
            }else{
                vm.districtList = []
            }
        },
        validate(){
            if(this.province_id == null || this.province_id == ''){
                var error = ["Vui lòng chọn tỉnh thành"]
                this.formErrors.province_id = error
            }
            if(this.district_id == null || this.district_id == ''){
                var error = ["Vui lòng chọn quận huyện"]
                this.formErrors.district_id = error
            }
            if(this.total_weight == null || this.total_weight == '' || parseFloat(this.total_weight)<=0){
                var error = ["Vui lòng nhập trọng lượng"]
                this.formErrors.total_weight = error
            }
        }
    }
}
</script>
