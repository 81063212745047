<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-header -->
          <div class="box-header with-border">
            <div class="row">

              <div class="col-xs-6 col-md-4">
                <div class="form-group">
                  <input type="text" class="form-control" v-model="params.keyword" v-on:keyup.enter="seach">
                </div>
              </div>

              <div class="col-xs-6 col-md-4">
                <div class="form-group">
                  <Select2 :id="'category_model_id'" 
                    :name="'category_model_id'" 
                    :dataUrl="'/api/category_model/all'"
                    v-model="params.category_model_id"
                    :multiple="true">
                  </Select2>
                </div>
              </div>

              <div class="col-xs-12 col-md-4">
                <div class="form-group">
                  <button type="button" class="btn btn-default" v-on:click="seach"><i class="fa fa-fw fa-search"></i> Tìm kiếm</button>
                  <router-link class="btn btn-primary" to="/product/add"><i class="fa fa-fw fa-plus"></i> Thêm mới</router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <div class="row">
              <!-- row-product -->
              <div class="col-md-6 col-sm-12 col-xs-12" v-for="item in data" :key="item.id">
                <model-view :data="item" :key="item.id"></model-view>
              </div>
            </div>

            <div class="row">
              <!-- Phân trang-->
              <div class="col-md-12 col-sm-12 col-xs-12">
                <b-pagination
                  v-model="pagination.currentPage" 
                  :total-rows="pagination.rows"
                  :per-page="pagination.perPage"
                  @change="handlePageChange">
                </b-pagination>
              </div>
            </div>
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import NProgress from 'nprogress'
import modelView from './components/modelView'
import Pagination from '../../components/Pagination';
import Select2 from '../../components/Select2.vue'

export default {
    name: 'formModelList',
    components: {
      modelView, Pagination, Select2
    },
    data() {
        return {
          title: 'Quản lý sản phẩm',
          data:[],
          pagination: {
            rows: 0,
            perPage: 20,
            currentPage: 1
          },
          params: {
              keyword: '',
              category_model_id: '',
              page: 1,
              perPage: 20
          }
        }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
        this.seach();
    },
    methods: {
      getData() {
        NProgress.start()
        var vm = this
        axios.get('/api/model/seach'+ '?' + $.param(this.params))
            .then(function(response) {
                NProgress.done()
                var dataResponse = response.data.data
                vm.data = dataResponse.data
                //Phân trang - gắng tổng số row
                vm.pagination.rows = dataResponse.total
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
      handlePageChange(value){
        //Phân trang - gáng giá trị
        this.params.page = value
        this.params.perPage = this.pagination.perPage

        this.getData()
      },
      seach() {
        //Phân trang - gáng giá trị
        this.params.page = 1
        this.pagination.currentPage = 1
        this.params.perPage = this.pagination.perPage

        this.getData()
      },
      clickCallback (pageNum) {
        console.log(pageNum)
      }
    }
}
</script>