<template>
<!-- Modal -->
<div class="modal fade in" id="modal-form-address_elected" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">Thông tin địa chỉ</h4>
        </div>
        <div class="modal-body">
            <div class="form-group" :class="{'has-error': formErrors.province_id}">
                <label class="control-label" for="name">Tỉnh thành</label>
                <Select2 :id="'province_id'" 
                    :name="'province_id'" 
                    :data="provinceList"
                    @changed="changedprovince()" 
                    v-model="province_id"
                    :allowClear="false"
                    :placeholder="'Chọn tỉnh thành'"
                    :multiple="false">
                </Select2>
                <span class="help-block" v-if="formErrors.province_id">{{formErrors.province_id[0]}}</span>
            </div>
            <div class="form-group" :class="{'has-error': formErrors.district_id}">
                <label class="control-label" for="name">Quận huyện</label>
                <Select2 :id="'district_id'" 
                    :name="'district_id'" 
                    :data="districtList"
                    @changed="changeddistrict()" 
                    v-model="district_id"
                    :allowClear="false"
                    :placeholder="'Chọn quận huyện'"
                    :multiple="false">
                </Select2>
                <span class="help-block" v-if="formErrors.district_id">{{formErrors.district_id[0]}}</span>
            </div>
            <div class="form-group" :class="{'has-error': formErrors.ward_id}">
                <label class="control-label" for="name">Phường xã</label>
                <Select2 :id="'ward_id'" 
                    :name="'ward_id'" 
                    :data="wardList"
                    v-model="ward_id"
                    :allowClear="false"
                    :placeholder="'Chọn phường xã'"
                    :multiple="false">
                </Select2>
                <span class="help-block" v-if="formErrors.ward_id">{{formErrors.ward_id[0]}}</span>
            </div>
            <div class="form-group" :class="{'has-error': formErrors.address}">
                <label class="control-label" for="name">Tổ/Thôn/Đường</label>
                <textarea class="form-control" 
                    rows="3" 
                    id="address" 
                    name="address" 
                    v-model="address">
                </textarea>
                <span class="help-block" v-if="formErrors.address">{{formErrors.address[0]}}</span>
            </div>
            <div class="form-group">
                <button 
                    @click="submit()"
                    type="button" 
                    class="btn btn-primary btn-block">Xác nhận
                </button>
            </div>
        </div>
    </div>
    <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
</template>

<script>
    import axios from 'axios'
    import Select2 from './Select2New.vue'

    export default {
        name: 'AddressSelected',
        components: {Select2},
        props: {

        },
        data() {
            return {
                formErrors: {},
                provinceList: [],
                districtList: [],
                wardList: [],
                province_id: '',
                district_id: '',
                ward_id: '',
                address: ''
            }
        },
        methods: {
            open(){
                $('#modal-form-address_elected').modal('show')
                var vm = this
                vm.province_id = ''
                axios.get('/api/province/all')
                    .then(function(response) {
                        vm.provinceList = response.data.data
                    })
                    .catch(function(error) {
                        vm.provinceList = []
                    })
            },
            submit(){
                this.formErrors = {}
                this.validate()
                if(Object.keys(this.formErrors).length === 0 && this.formErrors.constructor === Object){
                    var province = this.provinceList.find(o => o.id == this.province_id)
                    var district = this.districtList.find(o => o.id == this.district_id)
                    var ward = this.wardList.find(o => o.id == this.ward_id)

                    var result = {
                        province: province,
                        district: district,
                        ward: ward,
                        address: this.address
                    }
                    this.$emit('selected', result)
                    $('#modal-form-address_elected').modal('hide')
                }
            },
            changedprovince(){
                var vm = this
                if(vm.province_id != '' && vm.province_id != null)
                {
                    axios.get('/api/district/seachbyprovince/'+vm.province_id)
                        .then(function(response) {
                            vm.districtList = response.data.data
                        })
                        .catch(function(error) {
                            vm.districtList = []
                        })
                }else
                {
                    vm.districtList = []
                }
            },
            changeddistrict(){
                var vm = this
                if(vm.district_id != '' && vm.district_id != null){
                axios.get('/api/ward/seachbydistrict/'+vm.district_id)
                    .then(function(response) {
                        vm.wardList = response.data.data
                    })
                    .catch(function(error) {
                        vm.wardList = []
                    })
                }else{
                    vm.wardList = []
                }
            },
            validate(){
                if(this.province_id == null || this.province_id == ''){
                    var error = ["Vui lòng chọn tỉnh thành"]
                    this.formErrors.province_id = error
                }
                if(this.district_id == null || this.district_id == ''){
                    var error = ["Vui lòng chọn quận huyện"]
                    this.formErrors.district_id = error
                }
                if(this.ward_id == null || this.ward_id == ''){
                    var error = ["Vui lòng chọn phường xã"]
                    this.formErrors.ward_id = error
                }
                if(this.address.trim().length < 1){
                    var error = ["Vui lòng nhập Tổ/Thôn/Đường"]
                    this.formErrors.address = error
                }
            }
        }
    }
</script>
