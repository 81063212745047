<template>
<div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
            <!-- box-body -->
            <div class="box-body">
                <product-view-client
                    ref="formProductView">
                </product-view-client>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import ProductViewClient from './components/ProductViewClient.vue'

    export default {
        name: 'productSeachClient',
        components: {
            ProductViewClient
        },
        props: {
            value: null,
            placeholder: {
                type: String,
                default: 'Nhập mã sản phẩm hoặc model'
            },
            autoClearKeyword: {
                type: Boolean,
                default: true
            } 
        },
        data() {
            return {
                title: 'Tra cứu sản phẩm',
                params: {
                    keyword: ''
                }
            }
        },
        mounted(){
            this.$store.dispatch('setPageTitle', this.title)
            this.$refs.formProductView.open(this.params.keyword);
        },
        methods: {
            seach() {
                var vm = this
                var products = []
                if(vm.params.keyword.trim() != ''){
                    axios.get('/api/product-seach-client/seach_sale_by_id'+ '?' + $.param(vm.params))
                    .then(function(response) {
                        products = response.data.data
                        if(products.length > 0) 
                        {
                            if(vm.autoClearKeyword){
                                vm.params.keyword = ''
                            }
                            return
                        }else{
                            vm.$refs.formProductView.open(vm.params.keyword);
                           // $('#modal-form-product-seach').modal('show')
                        }
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
               }else{
                    this.$refs.formProductView.open(this.params.keyword);
                    //$('#modal-form-product-seach').modal('show')
               }
            }
        }
    }
</script>
<style lang="css" scoped>

</style>