<template>
<!-- Modal -->
<div class="modal fade in" id="modal-form-vncpost-order" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
    <div class="modal-content">
        <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title">ĐĂNG ĐƠN LÊN VNCPOST - {{outputvourcher.id}}</h4>
        </div>
        <div class="modal-body">
           <div class="row">
               <div class="col-md-4 col-sm-12 col-xs-12">
                   <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <h4 class="text-blue">Thông Tin người nhận</h4>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <span class="text-bold">SĐT: </span>  
                            <span>{{outputvourcher.customer_phone}}</span> 
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <span class="text-bold">Họ tên: </span>  
                            <span>{{outputvourcher.customer_name}}</span> 
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <span class="text-bold">Nick name: </span>  
                            <span>{{outputvourcher.customer_nickname}}</span> 
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <span class="text-bold">Địa chỉ: </span>  
                            <span>{{form.address}}</span>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <span class="text-bold">Xã/Phường: </span>  
                            <span>{{form.ward}}</span> 
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <span class="text-bold">Huyện/Quận: </span>  
                            <span>{{form.district}}</span> 
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <span class="text-bold">Tỉnh/Thành phố: </span>  
                            <span>{{form.province}}</span> 
                        </div>
                   </div>
                   <div class="row" v-if="outputvourcher.shipment_id">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <h4 class="text-blue">Thông Tin vận đơn</h4>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <span class="text-bold">Mã vận đơn: </span>  
                            <span class="text-bold text-red">{{outputvourcher.shipment_id}}</span> 
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <span class="text-bold">Trạng thái đơn: </span>  
                            <span>{{outputvourcher.shipment_status_text}}</span> 
                            <button type="button" class="btn btn-default btn-flat"><i class="glyphicon glyphicon-refresh"></i></button>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <span class="text-bold">Ngày cập nhật: </span>  
                            <span>{{outputvourcher.shipment_status_updated}}</span> 
                        </div>
                   </div>
                   <div class="row" v-if="Object.keys(dataOrder).length > 0">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <h4 class="text-blue">Thông Tin vận đơn</h4>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <span class="text-bold">Mã vận đơn: </span>  
                            <span class="text-bold text-red">{{dataOrder.Code}}</span> 
                        </div>
                         <div class="col-md-12 col-sm-12 col-xs-12">
                            <span class="text-bold">Phí ship: </span>  
                            <span class="text-bold text-red">{{parseFloat(dataOrder.FreightFee) | numFormat}}</span> 
                        </div>
                   </div>
               </div>
                <div class="col-md-8 col-sm-12 col-xs-12">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <h4 class="text-blue">Thông Tin lấy hàng</h4>
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-6">
                            <span class="text-bold">Tổng số lượng: </span>  
                            <span>{{totalQuantity}}</span>  
                        </div>
                        <div class="col-md-8 col-sm-8 col-xs-6">
                            <span class="text-bold">Tiền thu hộ: </span>  
                            <span class="text-bold text-blue">{{parseFloat(outputvourcher.total_payment)| numFormat}}</span> 
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <div class="form-group" :class="{'has-error': formErrors.total_weight}">
                                <label class="control-label">Tổng trọng lượng:</label>
                                <input type="number" class="form-control"
                                    id="total_weight"
                                    list="datalist_weights" 
                                    name="total_weight"
                                    v-model="form.total_weight"
                                    @blur="getFee()"
                                    step="0.1">
                                <datalist id="datalist_weights">
                                    <option value="0.2" />
                                    <option value="0.3" />
                                    <option value="0.5" />
                                    <option value="1" />
                                    <option value="1.5" />
                                    <option value="2" />
                                    <option value="2.5" />
                                    <option value="3" />
                                </datalist>
                                <span class="help-block" v-if="formErrors.total_weight">{{formErrors.total_weight[0]}}</span>
                            </div> 
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                            <label class="control-label">Dịch vụ vận chuyển:</label>
                            <div class="form-group">
                                <select class="form-control" v-model="form.ServiceId" @change="getFee()">
                                    <option value="12491">Chuyển phát tiết kiệm</option>
                                    <option value="12490">Chuyển phát nhanh</option>
                                </select>
                            </div> 
                        </div>
                        
                        <div class="col-md-8 col-sm-8 col-xs-6">
                            <label class="control-label">Ghi chú giao hàng:</label>
                            <div class="form-group">
                                <textarea class="form-control" rows="3" 
                                    id="note" 
                                    name="note"
                                    v-model="form.note"
                                    placeholder="Nhập ghi chú khi giao hàng">
                                </textarea>
                            </div> 
                        </div>
                        <div class="col-md-4 col-sm-4 col-xs-6">
                            <h4 class="text-bold">Ship: <span class="text-red" v-if="parseFloat(dataFee.FreightFee)>0">{{parseFloat(dataFee.FreightFee) | numFormat}}</span></h4>
                            <h4 class="text-bold">Ship GHTK: <span class="text-blue" v-if="parseFloat(dataFeeGHTK.fee)>0">{{parseFloat(dataFeeGHTK.fee) | numFormat}}</span></h4>
                            <label class="text-bold">Dự kiến giao: <span class="text-red" v-if="dataFee.EstimateTimeToDelivery">{{dataFee.EstimateTimeToDelivery}}</span></label>
                        </div>
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <button 
                                type="button" 
                                class="btn btn-default"
                                data-dismiss="modal">Thoát
                            </button>
                            <button 
                                type="button"
                                @click="submit()"
                                :disabled="enableButtonSave"
                                class="btn btn-primary pull-right">Gửi VNCPOST
                            </button>
                        </div>
                    </div>
               </div>
           </div>
        </div>
    </div>
    <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'VNCPOSTOrder',
        components: {},
        props: {
            
        },
        data() {
            return {
                formErrors: {},
                enableButtonSave: false,
                dataFee: {},
                dataFeeGHTK: {},
                dataOrder: {},
                outputvourcher: {},
                form: {
                    id: '',
                    tel: '',
                    name: '',
                    address: '',
                    province: '',
                    district: '',
                    ward: '',
                    note: '',
                    ServiceId: '',
                    total_weight: '',
                    pick_money: 0,
                    FreightFee: 0,
                    order_quantity: 0
                },
            }
        },
        computed: {
            totalQuantity(){
                var totalQuantityTemp = 0
                if(typeof(this.outputvourcher.outputvourcher_details) != 'undefined'){
                    for (var i = 0; i < this.outputvourcher.outputvourcher_details.length; i++) {
                        if(this.outputvourcher.outputvourcher_details[i].status != 0 ){
                            totalQuantityTemp = totalQuantityTemp + parseFloat(this.outputvourcher.outputvourcher_details[i].quantity)
                        }
                    }
                }
                return totalQuantityTemp
            }
            // noteGHTK(){
            //     var noteTemp = ''
            //     if(this.form.note!=null){
            //         noteTemp = 'Cho kiểm hàng không cho thử, ' + this.form.note
            //     }else{
            //         noteTemp = 'Cho kiểm hàng không cho thử'
            //     }
            //     return noteTemp
            // }
        },
        methods: {
            open(outputvourcher_id){
                $('#modal-form-vncpost-order').modal('show')
                var vm = this
                vm.dataOrder = {}
                vm.dataFee = {}
                vm.dataFeeGHTK = {}
                vm.outputvourcher = {}
                vm.enableButtonSave = false
                axios.get('/api/inventory/output/select/' + outputvourcher_id)
                    .then(function(response) {
                        vm.outputvourcher = response.data.data
                        vm.initData(vm.outputvourcher)
                        vm.getFee()
                        vm.getFeeGHTK()
                    })
                    .catch(function(error) {
                        vm.outputvourcher = {}
                    })
            },
            submit(){
                this.enableButtonSave = true
                var vm = this
                this.formErrors = {}
                this.validate()
                 this.form.order_quantity = this.totalQuantity 
                if(Object.keys(this.formErrors).length === 0 && this.formErrors.constructor === Object){
                    axios['post']('/api/logistics/shipment/vncpost/order', this.form)
                    .then(function (response) {
                        var result = response.data
                        if(result.success==true){
                            vm.dataOrder = result.data
                            vm.$emit('form-submit')
                            vm.$snotify.success('Đăng đơn thành công!', 'Success')
                        }else{
                            vm.$snotify.error(result.message, 'Lỗi')
                        }
                    })
                    .catch(function (error) {
                        vm.formErrors = error.response.data.errors
                        console.log(error)
                        vm.$snotify.error('Đăng đơn bị lỗi', 'Lỗi')
                        vm.enableButtonSave = false
                    })
                }
            },
            validate(){
                if(this.form.total_weight == null || this.form.total_weight == ''){
                    var error = ["Vui lòng nhập trọng lượng"]
                    this.formErrors.total_weight = error
                    this.enableButtonSave = false
                }
            },
            initData(outputvourcher){
                this.form.id = outputvourcher.id
                this.form.tel = outputvourcher.customer_phone
                this.form.name = outputvourcher.customer_name
                this.form.address = outputvourcher.customer_street
                this.form.province = outputvourcher.customer_province.name
                this.form.district = outputvourcher.customer_district.name
                this.form.ward = outputvourcher.customer_ward.name

                //this.form.note = outputvourcher.note
                //xử lý note
                var noteTemp = ''
                if(outputvourcher.note!=null){
                    noteTemp = outputvourcher.note + ', Cho kiểm hàng không cho thử'
                }else{
                    noteTemp = 'Cho kiểm hàng không cho thử'
                }
                this.form.note = noteTemp
                this.form.ServiceId = '12490'
                this.form.total_weight = parseFloat(outputvourcher.total_weight)
                this.form.pick_money = parseFloat(outputvourcher.total_payment)
            },
            getFee(){
                var result = {
                    province: this.form.province,
                    district: this.form.district,
                    weight: parseFloat(this.form.total_weight),
                    COD: this.form.pick_money,
                    ServiceId: this.form.ServiceId
                }
                var vm = this
                vm.dataFee = {}
                if(this.form.total_weight == null || this.form.total_weight == '' || parseFloat(this.form.total_weight) == 0){
                    return
                }
                axios['post']('/api/logistics/shipment/vncpost/fee', result)
                .then(function (response) {
                    var result = response.data
                    if(result.success==true){
                        vm.dataFee = result.data
                        vm.form.FreightFee = parseFloat(dataFee.FreightFee)
                    }else{
                        vm.$snotify.error(result.message, 'Lỗi lấy phí vận chuyển')
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
            },
            getFeeGHTK(){
                var result = {
                    province: this.form.province,
                    district: this.form.district,
                    ward: this.form.ward,
                    address: this.form.address,
                    weight: parseFloat(this.form.total_weight),
                    transport: 'road'
                }
                var vm = this
                vm.dataFeeGHTK = {}
                if(this.form.total_weight == null || this.form.total_weight == '' || parseFloat(this.form.total_weight) == 0){
                    return
                }
                axios['post']('/api/logistics/shipment/ghtk/fee', result)
                .then(function (response) {
                    var result = response.data
                    if(result.success==true){
                        vm.dataFeeGHTK = result.data
                    }else{
                        vm.$snotify.error(result.message, 'Lỗi lấy phí vận chuyển')
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
            }
        }
    }
</script>
