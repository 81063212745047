/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
window.Vuex = require('vuex');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import App from './views/App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)
//VuejsDialog
import VuejsDialog from "vuejs-dialog"
Vue.use(VuejsDialog)
//Snotify
import Snotify, { SnotifyPosition } from 'vue-snotify'
const options = {
    toast: {
        position: SnotifyPosition.rightTop,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false
    }
}
Vue.use(Snotify, options)
//moment
import moment from 'moment'
Vue.prototype.moment = moment
//numFormat
import numFormat from 'vue-filter-number-format';
Vue.filter('numFormat', numFormat);

//VueHtmlToPaper
import VueHtmlToPaper from 'vue-html-to-paper';
const optionsPrint = {
    name: '_blank',
    specs: [
      'fullscreen=yes',
      'titlebar=yes',
      'scrollbars=yes'
    ],
    styles: [
       'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css'
      ]
  }
  Vue.use(VueHtmlToPaper, optionsPrint);

const app = new Vue({
    el: '#app',
    components: { App },
    template: '<app></app>',
    router,
    store,
});
//console.log(window);
//xử lý cho cộng tác viên
if(window.location.pathname == '/khach-hang' && window.location.hash == '#/'){
    window.location = window.base_url + '/#/tra-cuu';
}
window.axios.interceptors.response.use(null, (error) => {
    if (error.response === undefined) {
        console.log(error);
        return;
    }
    if (error.response.status === 403) {
        app.$swal({
            title: 'Bạn không có quyền truy cập trang này.',
            text: 'Vui lòng liên hệ quản trị viên để được hướng dẫn.',
            type: 'warning',
            showCloseButton: true,
            confirmButtonClass: "btn-danger",
            confirmButtonText: "OK",
        }).then((result) => {
            if(result.value) {
                window.location = window.base_url;
            }
        })
    }
    if (error.response.status === 401) {
        window.location = window.base_url + '/login';
    }

    return Promise.reject(error);
});
