<template>
    <div>
			<button type="button" class="btn btn-default" data-toggle="modal" data-backdrop="static" data-target="#modal-default">
				Launch Default Modal
			</button>
      <div class="form-group">
          <label for="status" class="control-label">Trạng thái:</label>
          <switches v-model="status" name="status"  type-bold="true" theme="bootstrap" color="success"></switches>
      </div>

			<!-- Modal -->
        <div class="modal fade in" id="modal-default">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">Default Modal</h4>
              </div>
              <div class="modal-body">
                <p>One fine body&hellip;</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
              </div>
            </div>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
        <!-- /.modal -->
        <a @click="remove()" class="btn btn-danger btn-xs" data-toggle="tooltip" title="Xóa"><i class="fa fa-trash"></i></a>
         <a @click="ok()" class="btn btn-danger btn-xs" data-toggle="tooltip" title="OK">OK</a>
        <div class="form-group">
          <p>Selected: {{ selected }}</p>
          <Select2 
            dataUrl="/api/color/all" 
            v-model="selected" 
            :multiple="true">
            <option disabled value="0">--Chọn dữ liệu--</option>
          </Select2>
        </div>
        <!-- <div class="form-group">
          <input-tag v-model="tags" @input="input_tag" :beforeAdding="beforeAdding"></input-tag>
          {{tags}}
        </div> -->
        <div class="form-group">
          <product-seach @productSelected="productSelected" :autoClearKeyword='true'>
          </product-seach>
          {{product.id + ' - ' +  product.name}}
        </div>
        <div class="form-group">
          <model-seach @modelSelected="modelSelected" :autoClearKeyword='true'>
          </model-seach>
          {{model.id + ' - ' +  model.name}}
        </div>
    
        <input type="checkbox" id="jack" value="Jack" v-model="checkedNames">
        <label for="jack">Jack</label>
        <input type="checkbox" id="john" value="John" v-model="checkedNames">
        <label for="john">John</label>
        <input type="checkbox" id="mike" value="Mike" v-model="checkedNames">
        <label for="mike">Mike</label>
        <br>
        <span>Checked names: {{ checkedNames }}</span>

        <button type="button" class="btn btn-primary">Save changes</button>
        <div class="form-group">
          <number-button v-model="numbervalue"></number-button>
          {{numbervalue}}
        </div>

        <div class="form-group">
          <AddressSelected ref="AddressSelected"
            @selected="SelectedAddress"
          ></AddressSelected>
          <button type="button" class="btn btn-primary" @click="AddressSelected()">Chọn địa chỉ</button>
          {{resultadress}}
        </div>
        <div class="form-group">
          <SelectedImage></SelectedImage>
          <CustomerSeach ref="CustomerSeach">

          </CustomerSeach>
          <button type="button" class="btn btn-primary" @click="CustomerSeach()">Chọn khách hàng</button>
        </div>

        <!-- SOURCE  print-->
        <div id="printMe">
          <h1>Print me!</h1>
        </div>
        <!-- OUTPUT -->
        <button @click="print">In test</button>

        <bill>

        </bill>

        <div class="form-group">
          <GHTKOrder ref="GHTKOrder">

          </GHTKOrder>
          <button type="button" class="btn btn-primary" @click="GHTKOrder()">GHTK</button>
        </div>
        <div class="form-group">
          <input type="search" list="languages" placeholder="Pick a programming language..">

          <datalist id="languages">
            <option value="PHP" />
            <option value="C++" />
            <option value="Java" />
            <option value="Ruby" />
            <option value="Python" />
            <option value="Go" />
            <option value="Perl" />
            <option value="Erlang" />
          </datalist>
        </div>

</div>
</template>

<script>
import Switches from '../components/Switches';
import Select2 from '../components/Select2.vue';
import ProductSeach from '../components/ProductSeach/ProductSeach.vue';
import ModelSeach from '../components/ModelSeach/ModelSeach.vue';
import NumberButton from '../components/NumberButton.vue';
import AddressSelected from '../components/AddressSelected.vue';
import CustomerSeach from '../components/CustomerSeach.vue';
import SelectedImage from '../components/SelectedImage';
import bill from './reports/bill';
import GHTKOrder from '../components/GHTK/GHTKOrder.vue';
//import InputTag from 'vue-input-tag';
export default {
    components: {GHTKOrder, CustomerSeach, bill, Switches, Select2, ProductSeach, ModelSeach, NumberButton, AddressSelected, SelectedImage},
    data() {
        return {
          checkedNames: [],
          status:1,
          selected: [1],
          product: {},
          model:{},
          tags: ['A','B'],
          options: [
            { id: 1, text: 'Hello' },
            { id: 2, text: 'World' }
          ],
          numbervalue: 0,
          resultadress:{}
      }
    },
    methods: {
      GHTKOrder: function() {
        //this.$refs.GHTKOrder.open();
      },
      AddressSelected: function() {
        this.$refs.AddressSelected.open();
      },
      CustomerSeach: function() {
        this.$refs.CustomerSeach.open();
      },
      SelectedAddress: function(result) {
        this.resultadress = result
      },
      add: function() {
        $('#myModal').modal('show')
      },
      remove: function(){
        var $this = this
        this.$dialog.confirm('Are you sure?', {
                    loader: true,
                    okText: 'Đồng ý',
                    cancelText: 'Hủy bỏ'
                })
                    .then((dialog) => {
                        dialog.close()
                    });
      },
       ok: function(){
        var vm = this
        vm.$snotify.success('Cập nhật thành công!', 'Success')
      },
      input_tag: function(aa){
        console.log(aa)
      },
      beforeAdding: function(a){
        console.log(a)
      },
      productSelected(product) {
        this.product = product
      },
      modelSelected(model) {
        this.model = model
      },
      print() {
      // Pass the element id here
      this.$htmlToPaper('print', () => {
        console.log('Printing done or got cancelled!');
      });
    }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', 'Trang kiểm tra control');
    }
}
</script>

<style>

</style>
