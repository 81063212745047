<template>
  <div class="row">
    <div class="col-xs-12">
          <!-- box -->
          <div class="box">
            <!-- box-header -->
            <div class="box-header">
                <div class="row">
                  <div class="col-xs-6 col-md-2">
                    <div class="form-group">
                      <date-picker 
                        class="form-control"
                        :config="configDate" 
                        v-model="params.from_date">
                      </date-picker>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-2">
                    <div class="form-group">
                      <date-picker 
                        class="form-control"
                        :config="configDate" 
                        v-model="params.to_date">
                      </date-picker>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-2">
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="params.keyword" v-on:keyup.enter="seach" placeholder="Nhập từ khóa...">
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-4">
                    <div class="form-group">
                      <select v-model="params.delivery_type" 
                          id="delivery_type" 
                          name="delivery_type" 
                          class="form-control">
                          <option value="-1">Tất cả</option>
                          <option value="0">Đơn vị vận chuyển - GHTK</option>
                          <option value="5">Đơn vị vận chuyển - JTEXPRESS</option>
                          <option value="3">Đơn vị vận chuyển - Bưu điện</option>
                          <option value="4">Đơn vị vận chuyển - VNCPOST</option>
                          <option value="1">Shop giao</option>
                          <option value="2">Lấy tại shop</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-2">
                    <div class="form-group">
                      <button type="button" class="btn btn-default btn-block btn-flat" v-on:click="seach"><i class="fa fa-fw fa-search"></i> Tìm kiếm</button>
                    </div>
                  </div>
                </div>
            </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <data-viewer :thead="thead" :data="data">
              <template slot-scope="props" slot='outputvourcher_info'>
                <div class="col-xs-12 col-md-12">
                  <span class="label label-success" v-if="props.item.status === 1">Hoàn tất</span>
                  <span class="label label-default" v-else-if="props.item.status === 2">Chưa chốt</span>
                  <span class="label label-primary" v-else-if="props.item.status === 3">Đã chốt</span>
                  <span class="label label-info" v-else-if="props.item.status === 4">Đang soạn</span>
                  <span class="label label-warning" v-else-if="props.item.status === 5">Đang soạn - chờ hàng</span>
                  <span class="label label-primary" v-else-if="props.item.status === 6">Soạn xong</span>
                  <span class="label label-danger" v-else>Đã hủy</span>
                </div>

                <div class="col-xs-12 col-md-12" v-if="props.item.shop">
                    <span class="text-bold">Cữa hàng: </span>{{props.item.shop.name}}
                </div>
                
                <div class="col-xs-12 col-md-12" v-if="props.item.partner">
                    <span class="text-bold">CTV: </span>{{props.item.partner.partner_name}} - {{props.item.partner.partner_phone}}
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Mã phiếu: </span>
                  <router-link
                    :to="'/outputvourcher/sale/edit/'+props.item.id">
                    {{props.item.id}}
                  </router-link>
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Ngày xuất: </span>{{moment(props.item.output_date).format('DD/MM/YYYY')}}
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Ghi chú: </span>{{props.item.note}}
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold text-red">Số lượng lấy về: </span>{{parseFloat(props.item.return_quantity_note)| numFormat}}
                </div>
              </template>

              <template slot-scope="props" slot='outputvourcher_customer'>
                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Khách hàng: </span>{{props.item.customer_name}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Điện thoại: </span>{{props.item.customer_phone}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Nick Name: </span>{{props.item.customer_nickname}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Địa chỉ: </span>{{props.item.customer_address}}
                </div>
              </template>

              <template slot-scope="props" slot='outputvourcher_shipment'>
                <div class="col-xs-12 col-md-12">
                  <span class="label label-success" v-if="props.item.delivery_type == 0">Đơn vị vận chuyển - GHTK</span> 
                  <span class="label label-success" v-else-if="props.item.delivery_type == 3">Đơn vị vận chuyển - Bưu điện</span> 
                  <span class="label label-success" v-else-if="props.item.delivery_type == 4">Đơn vị vận chuyển - VNCPOST</span> 
                  <span class="label label-success" v-else-if="props.item.delivery_type == 1">Shop giao</span> 
                  <span class="label label-success" v-else-if="props.item.delivery_type == 5">Đơn vị vận chuyển - JTEXPRESS</span> 
                  <span class="label label-success" v-else>Lấy tại shop</span> 
                </div>

                <div class="col-xs-12 col-md-12" v-if="props.item.shipment_id">
                  <span class="text-bold">Mã VĐ: </span>{{props.item.shipment_id}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Trạng thái: </span>
                  <span v-if="(props.item.delivery_type === 1 || props.item.delivery_type === 2) && props.item.status === 1">Đã giao tại shop</span>
                  <span v-if="props.item.delivery_type === 3 && props.item.status === 1">Đã gửi bưu điện</span>
                  <!-- <span v-if="props.item.delivery_type === 5 && props.item.status === 1">Đã gửi JTEXPRESS</span> -->
                  <span v-if="(props.item.delivery_type === 0 || props.item.delivery_type === 4 || props.item.delivery_type === 5) && props.item.status === 1">{{props.item.shipment_status_text}}</span>
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Tiền ship: </span>{{parseFloat(props.item.shipment_fee) | numFormat}}
                </div>

                 <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Tiền ship cod thu: </span>{{parseFloat(props.item.shipment_pick_money) | numFormat}}
                </div>
                
                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Trạng thái giao: </span>  
                  <span v-if="props.item.payment_status === 1">Giao thành công</span>
                  <span v-else-if="props.item.payment_status === 0">Chưa giao</span>
                  <span v-else-if="props.item.payment_status === 2">Giao 1 phần</span>
                  <span v-else-if="props.item.payment_status === 3">Trả về</span>
                  <span v-else>Không xác định</span> 
                </div>
              </template>

              <template slot-scope="props"  slot='control'>
                <div class="col-xs-12 col-md-12">
                  <router-link class="btn btn-xs btn-primary btn-block btn-flat" 
                    :to="'/outputvourcher/show/'+props.item.id">
                    Xem
                  </router-link>
                </div>
              </template>
            </data-viewer>
            <div class="row">
              <div class="col-xs-6 col-md-6">
                <span class="text-bold">Tổng đơn: {{total_count}}</span>
              </div>
              <div class="col-xs-6 col-md-6">
                <span class="text-bold">Tổng số lượng lấy về: {{total_return_quantity_note_sum | numFormat}}</span>
              </div>
            </div>
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import Select2 from '../../../components/Select2.vue'
import DataViewer from '../../../components/DataViewer';

import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'
import datePicker from 'vue-bootstrap-datetimepicker'

export default {
    name: 'ReturnQuantityNoteIndex',
    components: {
       VuejsDialog, DataViewer, datePicker, Select2
    },
    data() {
        return {
          title: 'Quản lý hàng lấy về',
          data:[],
          params: {
              keyword: '',
              from_date: '',
              to_date: '',
              delivery_type: -1
          },
          configDate: {
            format: 'DD/MM/YYYY',
            useCurrent: false,
            showClear: false,
            showClose: true,
            allowInputToggle: true,
          },
          thead: [
            {
              key: 'outputvourcher_info',
              title: 'Thông tin đơn',
              class: 'col-xs-12 col-md-4'
            },
            {
              key: 'outputvourcher_customer',
              title: 'Thông tin khách hàng',
              class: 'col-xs-12 col-md-4'
            },
            {
              key: 'outputvourcher_shipment',
              title: 'Thông tin vận đơn',
              class: 'col-xs-12 col-md-3'
            },
            {
              key: 'control',
              title: '',
              class: 'col-xs-12 col-md-1'
            }
          ]
        }
    },
    computed: {
        total_count: function () {
          var countRow = 0
          if(this.data){
            countRow = this.data.length
          }
          return countRow
        },
        total_return_quantity_note_sum: function () {
          var amount_sum = 0
          if(this.data){
            for (var i = 0; i < this.data.length; i++) {
              amount_sum += parseFloat(this.data[i].return_quantity_note)
            }
          }
          return amount_sum
        }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
        this.params.from_date = this.moment(new Date()).add(-1, 'days').format('DD/MM/YYYY')
        this.params.to_date = this.moment(new Date()).format('DD/MM/YYYY')
        this.seach();
    },
    methods: {
      seach() {
        NProgress.start()
        var vm = this
        axios.get('/api/inventory/output/sale/seach_return_quantity_note'+ '?' + $.param(this.params))
            .then(function(response) {
                NProgress.done()
                vm.data = response.data.data
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
     
    }
}
</script>