<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-body -->
          <div class="box-body">
            <div class="row">
                <div class="col-md-4 col-sm-12 col-xs-12">
                     <!-- box Thông tin -->
                    <div class="box box-primary">
                        <!-- box-header -->
                        <div class="box-header with-border">
                            <h3 class="box-title">Thông tin nhập</h3>
                        </div>
                        <!-- /.box-header -->
                        <!-- box-body -->
                        <div class="box-body">
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Họ tên: </span>  
                                    <span>{{form.customer_name}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Điện thoại: </span>  
                                    <span class="text-bold text-red">{{form.customer_phone}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Nick name: </span>  
                                    <span>{{form.customer_nickname}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Địa chỉ: </span>  
                                    <span>{{form.customer_address}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <hr>
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Hình thức nhập: </span>  
                                    <span v-if="form.input_type">{{form.input_type.name}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Ngày tạo đơn: </span>  
                                    <span>{{form.created_at}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Nhân viên: </span>  
                                    <span>{{userInput}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Ghi chú: </span>  
                                    <span>{{form.note}}</span>
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <hr>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                    <span class="text-bold">Tổng SL: </span>  
                                    <span>{{totalQuantity}}</span> 
                                </div>
                                <div class="col-md-8 col-xs-12">
                                    <span class="text-bold">Tổng tiền hàng: </span>  
                                    <span>{{parseFloat(form.total_amount)| numFormat}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Tổng vận chuyển: </span>  
                                    <span>{{parseFloat(form.shipping_cost)| numFormat}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Phải trả: </span>  
                                    <span>{{parseFloat(form.total_payment)| numFormat}}</span> 
                                </div>
                            </div>
                        </div>
                        <!-- ./box-body -->
                    </div>
                </div>
                <div class="col-md-8 col-sm-12 col-xs-12">
                    <!-- box Thông tin -->
                    <div class="box box-primary">
                        <!-- box-header -->
                        <div class="box-header with-border">
                            <h3 class="box-title">Danh sách sản phẩm</h3>
                        </div>
                        <!-- /.box-header -->
                        <!-- box-body -->
                        <div class="box-body">
                            <div class="row">
                                <!-- row-product -->
                                <div class="col-md-12 col-sm-12 col-xs-12" v-for="item in form.inputvourcher_details" :key="item.id">
                                    <input-vourcher-item
                                    :item="item"
                                    v-show="item.status != 0">
                                    </input-vourcher-item>
                                </div>
                                <!-- /.row-product -->
                            </div>
                        </div>
                        <!-- ./box-body -->
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <a class="btn btn-default" @click="goBack()"><i class="glyphicon glyphicon-arrow-left"></i> Quay lại</a>
                </div>
            </div>
                
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import NProgress from 'nprogress'
import DataViewer from '../../components/DataViewer';
import InputVourcherItem from './components/InputVourcherItem'
export default {
    name: 'InputVourcherDetail',
    components: {
      InputVourcherItem, DataViewer
    },
    data() {
        return {
            title: 'Phiếu nhập',
            form: {},
            id: '',
            thead: [
            {
              key: 'status_update_date',
              title: 'Ngày',
              class: 'col-xs-12 col-md-3'
            },
            {
              key: 'status_update_user.name',
              title: 'Nhân viên',
              class: 'col-xs-6 col-md-6'
            },
            {
              key: 'status',
              title: 'Trạng thái',
              class: 'col-xs-6 col-md-3'
            }
          ]
        }
    },
    computed: {
        userInput(){
            var userTemp = ''
            if(typeof(this.form.input_user) != 'undefined'){
                userTemp = this.form.input_user.name
            }
            return userTemp
        },
        totalQuantity(){
            var totalQuantityTemp = 0
            if(typeof(this.form.inputvourcher_details) != 'undefined'){
                for (var i = 0; i < this.form.inputvourcher_details.length; i++) {
                if(this.form.inputvourcher_details[i].status != 0 ){
                    totalQuantityTemp = totalQuantityTemp + parseFloat(this.form.inputvourcher_details[i].quantity)
                }
                }
            }
            return totalQuantityTemp
        },
    },
    mounted(){
        NProgress.start()
        this.id = this.$route.params.id
        this.title = this.title +' - ' + this.id
        this.$store.dispatch('setPageTitle', this.title)
        this.getInputVourcher(this.id)
        NProgress.done()
    },
    methods: {
        goBack(){
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push('/')
        },
        async getInputVourcher(InputVourcherID)
        {
            var vm = this
            await axios.get('/api/inventory/input/select/' + InputVourcherID)
                .then(function(response) {
                    vm.form = response.data.data
                })
                .catch(function(error) {
                    console.log(error)
                })
        }
    }
}
</script>
<style lang="css" scoped>
.info-box-icon>img {
    width: 100%;
    max-height: 90px;
    vertical-align: unset;
}
.info-box-content{
    padding: 0px;
}
.info-box-text{
    white-space: unset;
}
.info-box {
    padding-bottom: 10px;
}
</style>
