<template>
  <div>
     <form role="form" @submit.prevent="save">
        <div class="form-group" :class="{'has-error': formErrors.id}">
            <label class="control-label" for="id">Mã</label>
            <input type="text" 
                class="form-control"
                id="id" 
                name="id" 
                v-model="form.id" 
                :readonly="isEdit"
                maxlength="20"
                autocomplete = "off">
            <span class="help-block" v-if="formErrors.id">{{formErrors.id[0]}}</span>
        </div>
        <div class="form-group" :class="{'has-error': formErrors.province_id}">
          <label class="control-label" for="name">Tỉnh thành</label>
          <Select2 :id="'province_id'" 
            :name="'province_id'" 
            :dataUrl="'/api/province/all'"
            v-model="form.province_id"
            :allowClear="false"
            :placeholder="'Chọn tỉnh thành'"
            :multiple="false">
          </Select2>
          <span class="help-block" v-if="formErrors.province_id">{{formErrors.province_id[0]}}</span>
        </div>
        <div class="form-group" :class="{'has-error': formErrors.name}">
          <label class="control-label" for="name">Tên</label>
          <input type="text" class="form-control" id="name" name="name" v-model="form.name">
          <span class="help-block" v-if="formErrors.name">{{formErrors.name[0]}}</span>
        </div>
        
        <div class="form-group" :class="{'has-error': formErrors.jtexpress_name}">
          <label class="control-label" for="jtexpress_name">Tên jtexpress</label>
          <input type="text" class="form-control" id="jtexpress_name" name="jtexpress_name" v-model="form.jtexpress_name">
          <span class="help-block" v-if="formErrors.jtexpress_name">{{formErrors.jtexpress_name[0]}}</span>
        </div>

        <div class="form-group">
          <label class="control-label" for="status">Trạng thái:</label>
          <switches v-model="form.status" name="status" id="status" type-bold="true" theme="bootstrap" color="success"></switches>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary btn-block">Cập nhật</button>
        </div>
     </form>
  </div>
</template>

<script>
  import axios from 'axios'
  import Switches from '../../components/Switches';
  import Select2 from '../../components/Select2New.vue'
  import district from '../../objects/district'

  export default {
    name: 'formDistrict',
    components: {Switches, Select2},
    props: {
      clickSubmit: {
        type: Function,
        default: () => { }
      }
    },
    data() {
      return {
        formErrors: {},
        isEdit:false,
        form: {}
      }
    },
    methods: {
      open(id){
        this.formErrors = {}
        if(typeof id != 'undefined'){
          this.isEdit = true
          this.getDistrict(id)
        }
        else{
          this.isEdit = false
          this.form = Object.assign({}, district);
        }
      },
      save() {
        var vm = this
        if(!this.isEdit){
          axios['post']('/api/district/insert', this.form)
          .then(function (response) {
              vm.clickSubmit()
              vm.$snotify.success('Thêm mới thành công!', 'Success')
          })
          .catch(function (error) {
              vm.formErrors = error.response.data.errors
          })
        }
        else{
          axios['put']('/api/district/update', this.form)
          .then(function (response) {
              vm.clickSubmit()
              vm.$snotify.success('Cập nhật thành công!', 'Success')
          })
          .catch(function (error) {
              vm.formErrors = error.response.data.errors
          })
        }
        
      },
      getDistrict(id){
        var vm = this
        axios.get('/api/district/select/' + id)
            .then(function(response) {
                vm.form = response.data.data
            })
            .catch(function(error) {
                console.log(error)
            })
      }
    }
  }
</script>