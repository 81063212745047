<template>
<!-- Modal -->
<div class="modal fade in" id="modal-form-customer_seach" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Tìm kiếm khách hàng</h4>
            <div class="row">
                <div class="col-xs-6 col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="params.keyword" v-on:keyup.enter="seach">
                    </div>
                </div>
                <div class="col-xs-6 col-md-6">
                    <div class="form-group">
                        <select class="form-control" id="type" name="type" v-model="params.type">
                            <option value="-1">Tất cả</option>
                            <option value="0">Khách lẻ</option>
                            <option value="1">Khách sỉ</option>
                            <option value="2">Nhà cung cấp</option>
                        </select>
                    </div>
                </div>
                <div class="col-xs-12 col-md-12">
                    <div class="form-group">
                        <button type="button" class="btn btn-default btn-block btn-flat" v-on:click="seach"><i class="fa fa-fw fa-search"></i> Tìm kiếm</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-body">
            <data-viewer :thead="thead" :data="data">
              <template slot-scope="props" slot='name'>
                <span class="text-bold hidden-lg">Tên: </span>{{props.item.name}}
              </template>
              <template slot-scope="props" slot='nickname'>
                <span class="text-bold hidden-lg">Nick Name: </span>{{props.item.nickname}}
              </template>
              <template slot-scope="props" slot='phone'>
                <span class="text-bold hidden-lg">Điện thoại: </span>{{props.item.phone}}
              </template>
              <template slot-scope="props" slot='address'>
                <span class="text-bold hidden-lg">Địa chỉ: </span>{{props.item.address}}
              </template>
              <template slot-scope="props" slot='control'>
                <div class="pull-right">
                  <a @click="submit(props.item)" class="btn btn-primary btn-xs btn-block"><i class="glyphicon glyphicon-ok"></i> Chọn</a>
                </div>
              </template>
            </data-viewer>
        </div>
    </div>
    <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
</template>

<script>
    import axios from 'axios'
    import NProgress from 'nprogress'
    import VuejsDialog from "vuejs-dialog"
    import DataViewer from './DataViewer';

    export default {
        name: 'CustomerSeach',
        components: {DataViewer, NProgress, VuejsDialog},
        props: {
            type: {
                type: Number,
                default: -1
            },
        },
        data() {
            return {
                data:[],
                params: {
                    keyword: '',
                    type: -1
                },
                thead: 
                [
                    {
                        key: 'name',
                        title: 'Tên',
                        class: 'col-xs-12 col-md-3'
                    },
                    {
                        key: 'nickname',
                        title: 'Nick Name',
                        class: 'col-xs-6 col-md-2'
                    },
                    {
                        key: 'phone',
                        title: 'Điện thoại',
                        class: 'col-xs-6 col-md-2'
                    },
                    {
                        key: 'address',
                        title: 'Địa chỉ',
                        class: 'col-xs-12 col-md-4'
                    },
                    {
                        key: 'control',
                        label: 'Xử lý',
                        class: 'col-xs-12 col-md-1'
                    }
                ]
            }
        },
        mounted(){
            this.params.type = this.type
        },
        methods: {
            open(){
                $('#modal-form-customer_seach').modal('show')
            },
            submit(result){
                this.$emit('selected', result)
                $('#modal-form-customer_seach').modal('hide')
            },
            seach() {
                var vm = this
                if(!vm.params.keyword && vm.params.type != 2){
                    var textMessage = 'Vui lòng nhập từ khóa tìm kiếm'
                    vm.$dialog.alert(textMessage, {
                                        okText: 'Đồng ý'
                                    })
                    .then(function(dialog) {
                        return
                    });
                }else{
                    axios.get('/api/inventory/output/sale/seach_customer_sale'+ '?' + $.param(this.params))
                    .then(function(response) {
                        NProgress.done()
                        vm.data = response.data.data
                    })
                    .catch(function(error) {
                        console.log(error)
                        NProgress.done()
                    })
                }
            },
        }
    }
</script>
