<template>
  <div>
     <form role="form" @submit.prevent="save" name="formShopPrice">
        <input type="hidden" id="id" name="id" v-model="form.id">
        <div class="form-group" :class="{'has-error': formErrors.shop_id}">
          <label class="control-label" for="name">Cữa hàng áp dụng</label>
          <Select2 :id="'shop_id'" :name="'shop_id'" :dataUrl="'/api/shop/all'" v-model="form.shop_id">
            <option disabled value="0">--Chọn cữa hàng áp dụng--</option>
          </Select2>
          <span class="help-block" v-if="formErrors.shop_id">{{formErrors.shop_id[0]}}</span>
        </div>
        <div class="form-group" :class="{'has-error': formErrors.name}">
          <label class="control-label" for="name">Tên loại giá</label>
          <input type="text" class="form-control" id="name" name="name" v-model="form.name">
          <span class="help-block" v-if="formErrors.name">{{formErrors.name[0]}}</span>
        </div>
        <div class="form-group" :class="{'has-error': formErrors.price}">
          <label class="control-label" for="price">Giá mặc định</label>
          <input type="number" class="form-control" id="price" name="price" v-model="form.price" :precision="0">
          <span class="help-block" v-if="formErrors.price">{{formErrors.price[0]}}</span>
        </div>
        <div class="form-group">
          <!-- <switches v-model="form.surcharge" name="surcharge" id="surcharge" type-bold="true" theme="bootstrap" color="success"></switches> -->
          <input
            type="checkbox"
            v-model="form.surcharge"
            name="surcharge"
            true-value="1"
            false-value="0"
          >
          <label class="control-label" for="surcharge">Phụ thu SP</label>
        </div>
        <div class="form-group">
          <!-- <label class="control-label" for="ispartner">Giá tính tiền CTV:</label>
          <switches v-model="form.ispartner" name="ispartner" id="ispartner" type-bold="true" theme="bootstrap" color="success"></switches> -->
           <input
            type="checkbox"
            v-model="form.ispartner"
            name="ispartner"
            true-value="1"
            false-value="0"
          >
          <label class="control-label" for="ispartner">Giá tính tiền CTV</label>
        </div>
        <div class="form-group">
          <label class="control-label" for="status">Trạng thái:</label>
          <switches v-model="form.status" name="status" id="status" type-bold="true" theme="bootstrap" color="success"></switches>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary btn-block">Cập nhật</button>
        </div>
     </form>
  </div>
</template>

<script>
  import axios from 'axios'
  import Switches from '../../components/Switches';
  import Select2 from '../../components/Select2.vue'
  import shop_price from '../../objects/shop_price'

  export default {
    name: 'formShopPrice',
    components: {Switches, Select2},
    props: {
      clickSubmit: {
        type: Function,
        default: () => { }
      }
    },
    data() {
      return {
        formErrors: {},
        isEdit:false,
        form: {}
      }
    },
    methods: {
      open(id){
        this.formErrors = {}
        if(typeof id != 'undefined'){
          this.isEdit = true
          this.getShopPrice(id)
        }
        else{
          this.isEdit = false
          this.form = Object.assign({}, shop_price);
        }
      },
      save() {
        let vm = this,
        form = document.forms.namedItem("formShopPrice"),
        data = new FormData(form),
        header = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};

        if(!this.isEdit){
          axios['post']('/api/shop_price/insert', data, header)
          .then(function (response) {
              vm.clickSubmit()
              vm.$snotify.success('Thêm mới thành công!', 'Success')
          })
          .catch(function (error) {
              vm.formErrors = error.response.data.errors
          })
        }
        else{
          data.append('_method', 'PUT')
          axios['post']('/api/shop_price/update', data, header)
          .then(function (response) {
              vm.clickSubmit()
              vm.$snotify.success('Cập nhật thành công!', 'Success')
          })
          .catch(function (error) {
              vm.formErrors = error.response.data.errors
          })
        }
        
      },
      getShopPrice(id){
        var vm = this
        axios.get('/api/shop_price/select/' + id)
            .then(function(response) {
                vm.form = response.data.data
                vm.form.price = parseFloat(vm.form.price);
            })
            .catch(function(error) {
                console.log(error)
            })
      }
    }
  }
</script>