export default {
    id: '',
    name: '',
    nickname: '',
    phone: '',
    address: '',
    description: '',
    type: 0,
    status: 1,

    province_id: '',
    province: {},
    district_id: '',
    district: {},
    ward_id: '',
    ward: {},
    street: '',
    status_warning: 0
}
