<template>
<div>
<!-- info-box -->
<div class="info-box pull-left">
    <span class="info-box-icon">
        <!-- <img :src="item.product.image.link_image"> -->
        <v-lazy-image :src="item.outputvourcher_detail.product.image.link_image" />
    </span>

    <div class="info-box-content row">
            <div class="col-md-12 col-xs-12">
                <div class="row">
                    <div class="col-md-12">
                        <span class="text-bold">
                            {{item.outputvourcher_id + ' - ' + item.customer_name + ' ('+(item.customer_nickname==null?'':item.customer_nickname)+')'}} 
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <span class="info-box-text text-green">
                            {{item.outputvourcher_detail.product.id + ' - ' + item.outputvourcher_detail.product.name}} 
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-xs-6">
                        <span class="label label-success" v-if="item.outputvourcher_detail.status === 1">Đã soạn</span>
                        <span class="label label-warning" v-else-if="item.outputvourcher_detail.status === 3">Thiếu hàng</span>
                        <span class="label label-default" v-else>Chưa soạn</span>
                    </div>
                   
                    <div class="col-md-4 col-xs-6"> 
                            <span class="text-bold">Vị trí:</span> 
                            {{item.outputvourcher_detail.product.model.location}} 
                    </div>

                    <div class="col-md-4 col-xs-12">
                        <span class="text-bold">Tồn kho: </span>  
                        Tồn<span class="text-light-blue">({{parseFloat(item.outputvourcher_detail.product.currentinstock.quantity)}})</span> - Đặt<span class="text-red">({{parseFloat(item.outputvourcher_detail.product.currentinstock.order_quantity)}})</span>
                    </div>

                    <div class="col-md-4 col-xs-6">
                        <span class="text-bold">Số lượng: </span>  
                        <span class="text-bold text-red">{{parseFloat(item.outputvourcher_detail.quantity)}}</span> 
                    </div>
                    <div class="col-md-8 col-xs-6">
                        <span class="text-bold">Giá bán: </span>  
                        <span>{{parseFloat(item.outputvourcher_detail.saleprice) | numFormat}}</span> 
                    </div>
                    <div class="col-md-12 col-xs-12">
                        <span class="text-bold">Ghi chú: </span>  
                        <span>{{item.outputvourcher_detail.note}}</span> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-6">
                        <a class="btn btn-xs btn-danger"
                            @click="processStatus(item.outputvourcher_detail.id, 3)" v-if="item.outputvourcher_detail.status === 2">
                            THIẾU HÀNG
                        </a>
                    </div>
                    <div class="col-md-6 col-xs-6">
                        <a class="btn btn-xs btn-primary pull-right" 
                            @click="processStatus(item.outputvourcher_detail.id, 1)" v-if="item.outputvourcher_detail.status != 1">
                            SOẠN XONG
                        </a>
                    </div>
                </div>
            </div>
    </div>
    <!-- /.info-box-content -->
</div>
</div>
</template>

<script>
import axios from 'axios'
import VLazyImage from "v-lazy-image";

export default {
    name: 'SoanHangVourcherItem',
    components: {VLazyImage},
    props: {
      item: {
          type: Object
        },
    },
    data() {
        return {
            selected_id: '',
            status:-1
        }
    },
    computed: {

    },
    methods: {
        processStatus(id, status){
            this.selected_id = id
            this.status = status

            this.processDetailStatus()
        },
        processDetailStatus(){
            let vm = this
            var dataUpdate = {id:vm.selected_id, status: vm.status};
           
            axios['put']('/api/inventory/output/update_detail_status', dataUpdate)
            .then(function (response) {
                vm.$snotify.success('Cập nhật trạng thái thành công!', 'Success')
                vm.$emit('changeddata')
            })
            .catch(function (error) {
                vm.$snotify.error('Cập nhật trạng thái thất bại!', 'Error')
                console.log(error)
            })
        }
    }
}
</script>
<style lang="css" scoped>
.info-box-icon>img {
    width: 100%;
    max-height: 90px;
    vertical-align: unset;
}
.info-box-content{
    padding: 0px;
}
.info-box-text{
    white-space: unset;
}
.info-box {
    padding-bottom: 10px;
}
</style>