<template>
<div>
<!-- info-box -->
<div class="info-box pull-left">
    <span class="info-box-icon">
        <img :src="item.product.image.link_image">
    </span>

    <div class="info-box-content row">
            <div class="col-md-12 col-xs-12">
                <div class="row">
                    <div class="col-md-12">
                        <span class="info-box-text text-green">
                            {{item.product.id + ' - ' + item.product.name}} 
                            <span class="label label-success" v-if="item.product.model.status === 1">Đang bán</span>
                            <span class="label label-warning" v-else-if="item.product.model.status === 2">Chờ hàng</span>
                            <span class="label label-danger" v-else>Ngưng bán</span>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-xs-4">
                        <span class="text-bold">Vị trí:</span>  
                        <span>{{item.product.model.location}}</span>  
                    </div>
                    <div class="col-md-4 col-xs-4">
                        <span class="text-bold">Số lượng: </span>  
                        <span class="text-bold text-red">{{parseFloat(item.quantity)}}</span> 
                    </div>
                    <div class="col-md-4 col-xs-4">
                        <span class="text-bold">Giá nhập: </span>  
                        <span>{{parseFloat(item.price)| numFormat}}</span> 
                    </div>
                </div>
            </div>
    </div>
    <!-- /.info-box-content -->
</div>
<!-- /.info-box -->
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'InputVourcherItem',
    components: {},
    props: {
      item: {
          type: Object
        },
    },
    data() {
        return {
            
        }
    },
    computed: {

    },
    methods: {

    }
}
</script>
<style lang="css" scoped>
.info-box-icon>img {
    width: 100%;
    max-height: 90px;
    vertical-align: unset;
}
.info-box-content{
    padding: 0px;
}
.info-box-text{
    white-space: unset;
}
.info-box {
    padding-bottom: 10px;
}
</style>