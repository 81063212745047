<template>
    <inputVourcherAdd 
        :seach_output="true"
        :input_type_id="input_type_id"
        :customer_type_id="-1"></inputVourcherAdd>
</template>

<script>
import inputVourcherAdd from './components/inputVourcherAdd'

export default {
    name: 'inputVourcherAddReturnSale',
        components: {
            inputVourcherAdd
        },
    data() {
        return {
            title: 'Thêm mới - Nhập trả hàng bán',
            formErrors: {},
            input_type_id: 0
        }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
        this.getData();
    },
    methods: {
        getData() {
            var vm = this
            //Lấy hình thức nhập từ NCC
            axios.get('/api/config/select/INPUT_TYPE_RETURNSALE')
                .then(function(response) {
                    if(response.data.data.value){
                        vm.input_type_id = parseFloat(response.data.data.value)
                    } 
                })
                .catch(function(error) {
                    vm.input_type_id = 0
                    console.log(error)
                })
        }
    }
}
</script>