<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-header -->
          <div class="box-header">
            <div class="row">
              <div class="col-xs-6 col-md-2">
                <div class="form-group">
                  <label class="control-label" for="paymentvourcher_date_from">Từ ngày</label>
                  <date-picker 
                    class="form-control"
                    :config="configDate" 
                    v-model="params.paymentvourcher_date_from">
                  </date-picker>
                </div>
              </div>

              <div class="col-xs-6 col-md-2">
                <div class="form-group">
                  <label class="control-label" for="paymentvourcher_date_to">Đến ngày</label>
                  <date-picker 
                    class="form-control"
                    :config="configDate" 
                    v-model="params.paymentvourcher_date_to">
                  </date-picker>
                </div>
              </div>

              <div class="col-xs-6 col-md-4">
                <div class="form-group">
                  <label class="control-label" for="keyword">Từ khóa</label>
                  <input type="text" class="form-control" 
                  v-model="params.keyword" 
                  v-on:keyup.enter="seach"
                  placeholder="Nhập từ khóa...">
                </div>
              </div>

              <div class="col-xs-6 col-md-4">
                  <div class="form-group">
                      <label class="control-label" for="paymentvourcher_status">Trạng thái</label>
                      <select v-model="params.paymentvourcher_status" 
                          id="paymentvourcher_status" 
                          name="paymentvourcher_status" 
                          class="form-control">
                          <option v-for="option in statusData" :key="option.id" v-bind:value="option.id">
                              {{ option.name }}
                          </option>
                      </select>
                  </div>
              </div>

              <div class="col-xs-8 col-md-4">
                <div class="form-group">
                  <Select2 :id="'paymentvourcher_partner_id'" 
                    :name="'paymentvourcher_partner_id'" 
                    :dataUrl="'/api/ctv/partner/all'"
                    :keyID="'partner_id'"
                    :keyName="'partner_name'"
                    v-model="params.paymentvourcher_partner_id"
                    :allowClear="true"
                    :placeholder="'Chọn CTV'"
                    :multiple="false">
                  </Select2>
                </div>
              </div>

              <div class="col-xs-4 col-md-6">
                <div class="form-group">
                  <button type="button" class="btn btn-default btn-flat" v-on:click="seach"><i class="fa fa-fw fa-search"></i> Tìm kiếm</button>
                </div>
              </div>
            </div>
          </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <data-viewer :thead="thead" :data="data">
              <template slot-scope="props" slot='partner_info'>
                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Shop: </span>{{props.item.paymentvourcher_partner.partner_shop.name}}
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Tên CTV: </span>{{props.item.paymentvourcher_partner.partner_name}}
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Điên thoại: </span>{{props.item.paymentvourcher_partner.partner_phone}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Tên shop CTV: </span>{{props.item.paymentvourcher_partner.partner_shop_name}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">Điạ chỉ: </span>{{props.item.paymentvourcher_partner.partner_address}}
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Ngày nhận: </span>{{props.item.paymentvourcher_partner.partner_begindate}}
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Loại CTV: </span>
                  <span class="label label-info" v-if="(props.item.paymentvourcher_partner.partner_type === 1)">CTV ưu đãi</span>
                  <span class="label label-info" v-else>CTV thường</span>
                </div>
                
              </template>

              <template slot-scope="props" slot='paymentvourcher_info'>
                <div class="col-xs-12 col-md-12" v-if="props.item.paymentvourcher_note">
                  <span class="text-bold">Ghi chú: </span><span class="text-bold text-red">{{props.item.paymentvourcher_note}}</span>
                </div>
                
                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Ngày tạo: </span>{{props.item.paymentvourcher_date}}
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold text-red">Tiền chi CTV: {{parseFloat(props.item.paymentvourcher_total_money) | numFormat}} </span>
                </div>

                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Tiền hệ thống tính: </span>{{parseFloat(props.item.paymentvourcher_total_amount) | numFormat}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Tổng số đơn: </span>{{props.item.paymentvourcher_total_count}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="label label-success" v-if="props.item.paymentvourcher_status === 1">Đã thanh toán</span>
                  <span class="label label-info" v-else-if="props.item.paymentvourcher_status === 2">Chưa thanh toán</span>
                  <span class="label label-warning" v-else>Không xác định</span>
                </div>

                <div class="col-xs-12 col-md-6">
                  <router-link
                    :to="'paymentvourcher/'+props.item.paymentvourcher_id">
                    Xem chi tiết
                  </router-link>
                </div>
              </template>

              <template slot-scope="props" slot='control'>
                <div class="col-xs-12 col-md-12" v-if="props.item.paymentvourcher_status === 2">
                    <button type="button" @click="remove(props.item.paymentvourcher_id)" class="btn btn btn-danger pull-right"> <i class="fa fa-trash"></i> Xóa</button>
                </div>
               </template>
            </data-viewer>
            <div class="row">
              <div class="col-xs-6 col-md-6">
                <span class="text-bold">Tổng SL đơn: {{total_count_order}}</span>
              </div>
              <div class="col-xs-6 col-md-6">
                <span class="text-bold">Tổng tiền chi CTV: {{total_money_sum | numFormat}}</span>
              </div>
            </div>

          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import DataViewer from '../../../components/DataViewer'
import Select2 from '../../../components/Select2New.vue'

import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'
import datePicker from 'vue-bootstrap-datetimepicker'

export default {
    name: 'PaymentvourcherSeach',
    components: {
      VuejsDialog, DataViewer, Select2, datePicker
    },
    data() {
        return {
          title: 'Danh sách thanh toán CTV',
          enableButtonSave: false,
          formErrors: {},
          data:[],
          params: {
              keyword: '',
              paymentvourcher_partner_id: '',
              paymentvourcher_status: 2,
              paymentvourcher_date_from:'',
              paymentvourcher_date_to:''
          },
          configDate: {
            format: 'DD/MM/YYYY',
            useCurrent: false,
            showClear: false,
            showClose: true,
            allowInputToggle: true,
          },
          statusData:[
            {
              id: 1,
              name: 'Đã thanh toán'
            },
            {
              id: 2,
              name: 'Chưa thanh toán'
            }
          ],
          thead: [
            {
              key: 'partner_info',
              title: 'Thông tin cộng tác viên',
              class: 'col-xs-12 col-md-6'
            },
            {
              key: 'paymentvourcher_info',
              title: 'Thông tin thanh toán',
              class: 'col-xs-12 col-md-5'
            },
            {
              key: 'control',
              title: '',
              class: 'col-xs-12 col-md-1'
            }
          ]
        }
    },
    computed: {
      total_count_order: function () {
        var amount_sum = 0
        if(this.data){
          for (var i = 0; i < this.data.length; i++) {
            amount_sum += parseFloat(this.data[i].paymentvourcher_total_count)
          }
        }
        return amount_sum
      },
      total_money_sum: function () {
        var amount_sum = 0
        if(this.data){
          for (var i = 0; i < this.data.length; i++) {
            amount_sum += parseFloat(this.data[i].paymentvourcher_total_money)
          }
        }
        return amount_sum
      }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
        this.params.paymentvourcher_date_from = this.moment(new Date()).add(-1, 'M').format('DD/MM/YYYY')
        this.params.paymentvourcher_date_to = this.moment(new Date()).format('DD/MM/YYYY')
        this.seach();
    },
    methods: {
      seach() {
        NProgress.start()
        var vm = this
        axios.get('/api/ctv/paymentvourcher/seach'+ '?' + $.param(this.params))
            .then(function(response) {
                NProgress.done()
                vm.data = response.data.data
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
      remove(id) {
        var $this = this
        this.$dialog.confirm('Bạn có muốn xóa?', {
            loader: true,
            okText: 'Đồng ý',
            cancelText: 'Hủy bỏ'
        })
            .then((dialog) => {
                $this.del(id)
                dialog.close()
            });
      },
      del(id){
        var vm = this
        axios['put']('/api/ctv/paymentvourcher/delete/'+id)
        .then(function (response) {
            var result = response.data
            if(result.success==false){
              vm.$snotify.error(result.message, 'Lỗi')
            }else{
              vm.$snotify.success(result.message, 'Success')
            }
            vm.seach()
        })
        .catch(function (error) {
          vm.$snotify.error('Xóa xác nhận đơn hàng lỗi vui lòng kiểm tra và thử lại', 'Lỗi')
          console.log(error)
        })
      }
    }
}
</script>