<template>
<!-- info-box -->
<div class="info-box pull-left">
    <span class="info-box-icon">
        <!-- <img :src="data.images[0].link_image"> -->
        <v-lazy-image :src="data.images[0].link_image" />
    </span>

    <div class="info-box-content row">
        <div class="col-md-12 col-xs-12">
            <div class="row">
                <div class="col-md-12">
                    <span class="info-box-text text-green">
                       <router-link :to="'/product/edit/'+data.id">
                        {{data.id}} - {{data.name}}
                        </router-link>
                        <span class="label label-success pull-right" v-if="data.status === 1">Đang bán</span>
                        <span class="label label-warning pull-right" v-else-if="data.status === 2">Chờ hàng</span>
                        <span class="label label-danger pull-right" v-else>Ngưng bán</span>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-xs-12">
                    <span class="text-bold">Loại đồ: </span>  
                    <span v-if="data.category_model">{{data.category_model.name}}</span> 
                </div>

                <div class="col-md-6 col-xs-6">
                    <span class="text-bold">Màu sắc: </span>  
                    <span>{{colorListText}}</span> 
                </div>
                
                <div class="col-md-6 col-xs-6">
                    <span class="text-bold">Size: </span>  
                    <span>{{sizeListText}}</span> 
                </div>

                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label class="control-label" for="location">Vị trí hàng hóa:</label>
                    <div class="input-group">
                        <input type="text" 
                        class="form-control" 
                        id="location" 
                        name="location" 
                        v-model="data.location" 
                        placeholder="Nhập vị trí"
                        autocomplete = "off">
                        <span class="input-group-btn">
                            <button type="button" class="btn btn-default btn-flat" @click="saveLocation()"><i class="glyphicon glyphicon-floppy-disk"></i></button>
                        </span>
                    </div>
                  </div>
                </div>

            </div>
        </div>
    </div>
    <!-- /.info-box-content -->
</div>
<!-- /.info-box -->
</template>

<script>
import VLazyImage from "v-lazy-image";
export default {
    name: 'modelView',
    components: {VLazyImage},
    props: {
      data: {
          type: Object
        },
    },
    data() {
        return {

        }
    },
    computed: {
      colorListText: function () {
        var colorListTemp = []
        for (var i = 0; i < this.data.products.length; i++) {
            if(this.data.products && this.data.products[i].color){
                if(!colorListTemp.some(o => o.id == this.data.products[i].color.id)){
                    colorListTemp.push(this.data.products[i].color)
                } 
            }
        }
        return colorListTemp.map(o=>o.name).join(", ")
      },
      sizeListText: function () {
        var sizeListTemp = []
        for (var i = 0; i < this.data.products.length; i++) {
            if(this.data.products && this.data.products[i].size){
                if(!sizeListTemp.some(o => o.id == this.data.products[i].size.id)){
                sizeListTemp.push(this.data.products[i].size)
                } 
            }
        }
        return sizeListTemp.map(o=>o.name).join(", ")
      }
    },
    methods: {
        open(model){

        },
        saveLocation(){
          let vm = this
          var dataUpdate = {id:vm.data.id, location: vm.data.location};
          axios['put']('/api/model/update_location/'+vm.data.id, dataUpdate)
          .then(function (response) {
              vm.$snotify.success('Cập nhật vị trí thành công!', 'Success')
          })
          .catch(function (error) {
              vm.$snotify.error('Cập nhật vị trí thất bại!', 'Error')
              console.log(error)
          })
      },

    }
}
</script>
<style lang="css" scoped>
.info-box-icon>img {
    width: 100%;
    max-height: 90px;
    vertical-align: unset;
}
.info-box-content{
    padding: 0px;
}
.info-box-text{
    white-space: unset;
}
.info-box {
    padding-bottom: 10px;
}
</style>