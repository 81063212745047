<template>
<div>
<!-- info-box -->
<div class="info-box pull-left">
    <span class="info-box-icon">
        <!-- <img :src="item.product.image.link_image"> -->
        <v-lazy-image :src="item.product.image.link_image" />
    </span>

    <div class="info-box-content row">
            <div class="col-md-12 col-xs-12">
                <div class="row">
                    <div class="col-md-12">
                        <span class="info-box-text text-green">
                             {{item.product.id + ' - ' + item.product.name}} 
                            <span class="label label-success" v-if="item.product.model.status === 1" v-show="false">Đang bán</span>
                            <span class="label label-warning" v-else-if="item.product.model.status === 2">Chờ hàng</span>
                            <span class="label label-danger" v-else>Ngưng bán</span>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-xs-6">
                        <span class="label label-success" v-if="item.status === 1">Đã soạn</span>
                        <span class="label label-warning" v-else-if="item.status === 3">Thiếu hàng</span>
                        <span class="label label-default" v-else>Chưa soạn</span>
                    </div>
                   
                    <div class="col-md-4 col-xs-6"> 
                        <a href="javascript:void(0)" @click="showLocation(item.product.model_id, item.product.model.location)">
                            <span class="text-bold">Vị trí:
                            </span> 
                            {{item.product.model.location}} 
                        </a>
                    </div>
                    <div class="col-md-4 col-xs-12">
                        <span class="text-bold">Tồn kho: </span>  
                        Tồn<span class="text-light-blue">({{parseFloat(item.product.currentinstock.quantity)}})</span> - Đặt<span class="text-red">({{parseFloat(item.product.currentinstock.order_quantity)}})</span>
                    </div>
                    <div class="col-md-4 col-xs-12">
                        <span class="text-bold">Số lượng: </span>  
                        <span class="text-bold text-red">{{parseFloat(item.quantity)}}</span> 
                    </div>
                    <div class="col-md-8 col-xs-12">
                        <span class="text-bold">Giá bán: </span>  
                        <span>{{parseFloat(item.saleprice) | numFormat}}</span> 
                    </div>
                    <div class="col-md-12 col-xs-12">
                        <span class="text-bold">Ghi chú: </span>  
                        <span>{{item.note}}</span> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-xs-6">
                        <a class="btn btn-xs btn-danger"
                            @click="processStatus(item.id, 3, true)" v-if="item.status === 2">
                            THIẾU HÀNG
                        </a>
                    </div>
                    <div class="col-md-6 col-xs-6">
                        <a class="btn btn-xs btn-primary pull-right" 
                            @click="processStatus(item.id, 1, false)" v-if="item.status != 1">
                            SOẠN XONG
                        </a>
                    </div>
                </div>
            </div>
    </div>
    <!-- /.info-box-content -->
</div>
<!-- /.info-box -->
    <!-- Modal xử lý-->
    <div class="modal fade in" :id="'modal-form-note-'+item.id" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Nhập nội dung ghi chú</h4>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <input type="hidden" v-model="selected_id">
                <input type="hidden" v-model="status">
                <input type="hidden" v-model="show_note">
                <textarea class="form-control" rows="3" id="note" name="note" v-model="note"></textarea>
            </div>
            <div class="form-group">
                <button type="button" class="btn btn-primary btn-block" @click="processDetailStatus()">Xác nhận</button>
            </div>
        </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->

    <!-- Modal cập nhật vị trị-->
    <div class="modal fade in" :id="'modal-form-location-'+item.id" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Cập nhật vị trí</h4>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <input type="text" class="form-control" v-model="location" autocomplete = "off">
            </div>
            <div class="form-group">
                <button type="button" class="btn btn-primary btn-block" @click="processLocation(selected_model, location)">Cập nhật</button>
            </div>
        </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
</div>
</template>

<script>
import axios from 'axios'
import VLazyImage from "v-lazy-image";

export default {
    name: 'OutputVourcherItem',
    components: {VLazyImage},
    props: {
      item: {
          type: Object
        },
    },
    data() {
        return {
            selected_id: '',
            status:-1,
            note: '',
            show_note: false,
            selected_model: '',
            location: ''
        }
    },
    computed: {

    },
    methods: {
        showLocation(modelid, location){
            this.location = location
            this.selected_model = modelid
            $('#modal-form-location-'+this.item.id).modal('show')
        },
        processLocation(){
            let vm = this
            var dataUpdate = {id:vm.selected_model, location: vm.location};
            axios['put']('/api/model/update_location/'+vm.selected_model, dataUpdate)
            .then(function (response) {
                vm.$snotify.success('Cập nhật vị trí thành công!', 'Success')
                vm.$emit('changeddata')
                $('#modal-form-location-'+vm.item.id).modal('hide')
            })
            .catch(function (error) {
                vm.$snotify.error('Cập nhật vị trí thất bại!', 'Error')
                console.log(error)
            })
        },
        processStatus(id, status, showNote){
            this.note = ''
            this.selected_id = id
            this.show_note = showNote
            this.status = status

            if(this.show_note == true){
                $('#modal-form-note-'+this.item.id).modal('show')
            }else{
                this.processDetailStatus()
            }
        },
        processDetailStatus(){
            let vm = this
            if(vm.show_note == true){
                var dataUpdate = {id:vm.selected_id, status: vm.status, note: vm.note};
            }else{
                var dataUpdate = {id:vm.selected_id, status: vm.status};
            }
           
            axios['put']('/api/inventory/output/update_detail_status', dataUpdate)
            .then(function (response) {
                vm.$snotify.success('Cập nhật trạng thái thành công!', 'Success')
                vm.$emit('changeddata')

                //Nếu có hiển thị form nhập ghi chú thì ẩn form đi
                if(vm.show_note == true){
                    $('#modal-form-note-'+vm.item.id).modal('hide')                 
                }
            })
            .catch(function (error) {
                vm.$snotify.error('Cập nhật trạng thái thất bại!', 'Error')
                console.log(error)
            })
        }
    }
}
</script>
<style lang="css" scoped>
.info-box-icon>img {
    width: 100%;
    max-height: 90px;
    vertical-align: unset;
}
.info-box-content{
    padding: 0px;
}
.info-box-text{
    white-space: unset;
}
.info-box {
    padding-bottom: 10px;
}
</style>