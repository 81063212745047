<template>
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <!-- seach -->
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <!-- /btn-group -->
          <div class="input-group margin-bottom">
              <input type="text" 
                class="form-control" 
                placeholder="Nhập từ khóa..."
                v-model="params.keyword" 
                v-on:keyup.enter="seach">
              <div class="input-group-btn">
                  <button type="button" class="btn btn-default btn-flat" @click="seach">
                      <i class="glyphicon glyphicon-search"></i>
                  </button>
                  <button type="button" class="btn btn-default btn-flat"><i class="glyphicon glyphicon-qrcode"></i></button>
              </div>
              <!-- /btn-group -->
          </div>
          <!-- /input-group -->
        </div>
      </div>
      <!-- ./seach -->
      
      <!-- danh sach sản phẩm -->
      <div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12" v-for="item in data" :key="item.id">
          <model-item 
              @Selected="modelSelected"
              :data="item" 
              :key="item.id">
            </model-item>
        </div>
      </div>
      <!-- ./danh sach sản phẩm -->
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import NProgress from 'nprogress'
import ModelItem from './ModelItem'

export default {
    name: 'ModelView',
    components: {
      ModelItem
    },
    data() {
        return {
          data:[],
          params: {
              keyword: ''
          }
        }
    },
    mounted(){
        //this.seach();
    },
    methods: {
      open(keyword){
        this.params.keyword = keyword
        this.seach();
      },
      seach() {
        NProgress.start()
        var vm = this
        vm.data = []
        axios.get('/api/model/seachsale'+ '?' + $.param(this.params))
            .then(function(response) {
                NProgress.done()
                vm.data = response.data.data
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
      modelSelected(model) {
        this.$emit('modelSelected', model)
      }
    }
}
</script>
