<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-body -->
          <div class="box-body">
            <div class="row">
                <div class="col-md-4 col-sm-12 col-xs-12">
                     <!-- box Thông tin -->
                    <div class="box box-primary">
                        <!-- box-header -->
                        <div class="box-header with-border">
                            <h3 class="box-title">Thông tin</h3>
                        </div>
                        <!-- /.box-header -->
                        <!-- box-body -->
                        <div class="box-body">
                            <input type="hidden" id="customer_id" name="customer_id" v-model="form.customer_id">
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Ngày tạo đơn: </span>  
                                    <span>{{outputData.created_at}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Nhân viên: </span>  
                                    <span>{{userOuput}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Tình trạng đơn: </span>  
                                    <span class="label label-success" v-if="outputData.status === 1">Hoàn tất</span>
                                    <span class="label label-default" v-else-if="outputData.status === 2">Chưa chốt</span>
                                    <span class="label label-primary" v-else-if="outputData.status === 3">Đã chốt</span>
                                    <span class="label label-info" v-else-if="outputData.status === 4">Đang soạn</span>
                                    <span class="label label-warning" v-else-if="outputData.status === 5">Đang soạn - chờ hàng</span>
                                    <span class="label label-primary" v-else-if="outputData.status === 6">Soạn xong</span>
                                    <span class="label label-danger" v-else>Đã hủy</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Nhân viên cập nhật: </span>  
                                    <span>{{userUpdateStatus}}</span>
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <hr>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group" :class="{'has-error': formErrors.customer_phone}">
                                        <label class="control-label" for="customer_phone">Điện thoại<span class="text-red">(*)</span></label>
                                        <input type="text" 
                                        class="form-control" 
                                        id="customer_phone" 
                                        name="customer_phone" 
                                        v-model="form.customer_phone" 
                                        @blur="getCustomerByPhone(form.customer_phone)"
                                        placeholder="Nhập điện thoại"
                                        autocomplete = "off">
                                        <span class="help-block" v-if="formErrors.customer_phone">{{formErrors.customer_phone[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group" :class="{'has-error': formErrors.customer_name}">
                                        <label class="control-label" for="customer_name">Họ tên<span class="text-red">(*)</span></label>
                                         <div class="input-group">
                                            <input type="text" 
                                            class="form-control" 
                                            id="customer_name" 
                                            name="customer_name" 
                                            onClick="this.select();"
                                            v-model="form.customer_name" 
                                            placeholder="Nhập tên khách hàng"
                                            autocomplete = "off">
                                            <span class="input-group-btn">
                                                <button type="button" class="btn btn-default btn-flat" @click="CustomerSeachShow()"><i class="fa fa-fw fa-search"></i></button>
                                            </span>
                                        </div>
                                        <CustomerSeach ref="CustomerSeach"
                                            @selected="CustomerSeach">
                                        </CustomerSeach>
                                        <span class="help-block" v-if="formErrors.customer_name">{{formErrors.customer_name[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group" :class="{'has-error': formErrors.customer_nickname}">
                                        <label class="control-label" for="customer_nickname">Nick Name</label>
                                        <input type="text" 
                                        class="form-control" 
                                        id="customer_nickname" 
                                        name="customer_nickname" 
                                        v-model="form.customer_nickname" 
                                        placeholder="Nhập facbook/zalo"
                                        autocomplete = "off">
                                        <span class="help-block" v-if="formErrors.customer_nickname">{{formErrors.customer_nickname[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group"  :class="{'has-error': formErrors.delivery_type}">
                                        <label class="control-label" for="delivery_type">Hình thức giao<span class="text-red">(*)</span></label>
                                        <select v-model="form.delivery_type" id="delivery_type" name="delivery_type" class="form-control">
                                            <option value="0">Đơn vị vận chuyển - GHTK</option>
                                            <option value="5">Đơn vị vận chuyển - JTEXPRESS</option>
                                            <option value="3">Đơn vị vận chuyển - Bưu điện</option>
                                            <option value="4">Đơn vị vận chuyển - VNCPOST</option>
                                            <option value="1">Shop giao</option>
                                            <option value="2">Lấy tại shop</option>
                                        </select>
                                        <span class="help-block" v-if="formErrors.delivery_type">{{formErrors.delivery_type[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group" :class="{'has-error': formErrors.customer_province_id}">
                                        <label class="control-label" for="customer_province_id">Địa chỉ<span class="text-red" v-if="form.delivery_type==0 || form.delivery_type==3">(*)</span></label>
                                        <div class="input-group">
                                            <input type="text" 
                                            v-model="addressText"
                                            class="form-control" 
                                            id="customer_province_id" 
                                            name="customer_province_id" 
                                            readonly
                                            placeholder="Chọn địa chỉ...">
                                            <span class="input-group-btn">
                                                <button type="button" 
                                                class="btn btn-default btn-flat"
                                                @click="showAddressSelected()"><i class="glyphicon glyphicon-home"></i>
                                                </button>
                                            </span>
                                        </div>
                                        <AddressSelected ref="AddressSelected"
                                        @selected="AddressSelected">
                                        </AddressSelected>
                                        <span class="help-block" v-if="formErrors.customer_province_id">{{formErrors.customer_province_id[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group" :class="{'has-error': formErrors.customer_address}">
                                        <label class="control-label" for="customer_address">Địa chỉ đầy đủ<span class="text-red">(*)</span></label>
                                        <textarea class="form-control" rows="2" 
                                            id="customer_address" 
                                            name="customer_address"
                                            v-model="form.customer_address"
                                            placeholder="Nhập địa chỉ">
                                        </textarea>
                                        <span class="help-block" v-if="formErrors.customer_address">{{formErrors.customer_address[0]}}</span>
                                    </div>
                                </div>
                                
                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group" :class="{'has-error': formErrors.shop_id}">
                                        <label class="control-label" for="shop_id">Cữa hàng<span class="text-red">(*)</span></label>
                                        <select v-model="form.shop_id" 
                                            id="shop_id" 
                                            name="shop_id"
                                            @change="onChangeShop()"
                                            class="form-control"
                                            disabled>
                                            <option disabled value="">--Chọn cữa hàng--</option>
                                            <option v-for="option in listShop" :key="option.id" v-bind:value="option.id">
                                                {{ option.name }}
                                            </option>
                                        </select>
                                        <span class="help-block" v-if="formErrors.shop_id">{{formErrors.shop_id[0]}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group" :class="{'has-error': formErrors.source_address_id}">
                                        <label class="control-label" for="shop_id">Nguồn đơn<span class="text-red">(*)</span></label>
                                        <select v-model="form.source_address_id" id="source_address_id" name="source_address_id" class="form-control">
                                            <option disabled value="">--Chọn nguồn đơn--</option>
                                            <option v-for="option in listSourceAddress" :key="option.id" v-bind:value="option.id">
                                                {{ option.name }}
                                            </option>
                                        </select>
                                        <span class="help-block" v-if="formErrors.source_address_id">{{formErrors.source_address_id[0]}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group" :class="{'has-error': formErrors.partner_id}">
                                        <label class="control-label" for="partner_id">Cộng tác viên<span class="text-red">(*)</span></label>
                                        <div class="input-group">
                                            <select v-model="form.partner_id" 
                                                id="partner_id" 
                                                name="partner_id" 
                                                class="form-control">
                                                <option value="0">Đơn hàng shop</option>
                                                    <option v-for="option in partner_in_store" :key="option.partner_id" v-bind:value="option.partner_id">
                                                        {{ option.partner_name }} - {{option.partner_phone}}
                                                    </option>
                                            </select>
                                            <span class="input-group-btn">
                                                <button type="button" class="btn btn-default btn-flat" @click="refreshPartner"><i class="glyphicon glyphicon-refresh"></i></button>
                                            </span>
                                        </div>
                                        <span v-if="price_note_partner" class="text-red">{{price_note_partner}}</span>
                                        <span class="help-block" v-if="formErrors.partner_id">{{formErrors.partner_id[0]}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 col-xs-12">
                                   <div class="form-group" :class="{'has-error': formErrors.payment_not_auto_confirm}">
                                        <input type="checkbox" id="payment_not_auto_confirm" v-model="form.payment_not_auto_confirm">
                                        <label for="payment_not_auto_confirm">Không tự động đối soát</label>
                                        <span class="help-block" v-if="formErrors.payment_not_auto_confirm">{{formErrors.payment_not_auto_confirm[0]}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 col-xs-12">
                                   <div class="form-group" :class="{'has-error': formErrors.payment_note}">
                                        <label class="control-label" for="payment_note">Ghi chú thanh toán<span class="text-red">(Bắt buột khi không tự đối soát)</span></label>
                                        <textarea class="form-control" rows="2" 
                                            id="payment_note" 
                                            name="payment_note"
                                            v-model="form.payment_note"
                                            placeholder="Nhập ghi chú thêm khi thanh toán">
                                        </textarea>
                                        <span class="help-block" v-if="formErrors.payment_note">{{formErrors.payment_note[0]}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group" :class="{'has-error': formErrors.shop_price_id}">
                                        <label class="control-label" for="customer_address">Loại giá<span class="text-red">(*)</span></label>
                                        <div class="input-group">
                                            <select 
                                                v-model="form.shop_price_id" 
                                                id="shop_price_id" 
                                                name="shop_price_id" 
                                                @change="onChangeShopPrice()"
                                                class="form-control">
                                                    <option disabled value="">--Chọn loại giá--</option>
                                                    <option v-for="option in shop_price_in_store" :key="option.id" v-bind:value="option.id">
                                                        {{ option.name }}
                                                    </option>
                                            </select>
                                            <span class="input-group-btn">
                                                <button type="button" class="btn btn-default btn-flat" @click="refreshPrice"><i class="glyphicon glyphicon-refresh"></i></button>
                                            </span>
                                        </div>
                                        <span class="help-block" v-if="formErrors.shop_price_id">{{formErrors.shop_price_id[0]}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 col-xs-12">
                                   <div class="form-group" :class="{'has-error': formErrors.surcharge}">
                                        <input type="checkbox" id="payment_not_auto_confirm" v-model="form.surcharge">
                                        <label for="surcharge">Phụ thu sản phẩm</label>
                                        <span class="help-block" v-if="formErrors.surcharge">{{formErrors.surcharge[0]}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group">
                                        <label class="control-label" for="GiaThoaThuan">Giá thỏa thuận:</label>
                                        <div class="input-group">
                                            <input type="number" class="form-control"
                                            id="GiaThoaThuan" 
                                            name="GiaThoaThuan"
                                            v-model="GiaThoaThuan"
                                            min="0">
                                            <span class="input-group-btn">
                                                <button type="button" class="btn btn-default btn-flat" @click="refreshPriceThoaThuan"><i class="glyphicon glyphicon-refresh"></i></button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group" :class="{'has-error': formErrors.return_quantity_note}">
                                        <label class="control-label" for="return_quantity_note">Số lượng lấy về:</label>
                                        <div class="input-group">
                                            <input type="number" class="form-control"
                                            id="return_quantity_note" 
                                            name="return_quantity_note"
                                            v-model="form.return_quantity_note"
                                            min="0">
                                            <span class="input-group-btn">
                                                <button type="button" class="btn btn-default btn-flat" @click="refreshreturn_quantity_note"><i class="glyphicon glyphicon-refresh"></i></button>
                                            </span>
                                        </div>
                                        <span class="help-block" v-if="formErrors.return_quantity_note">{{formErrors.return_quantity_note[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xs-12">
                                   <div class="form-group" :class="{'has-error': formErrors.note}">
                                        <label class="control-label" for="customer_address">Ghi chú</label>
                                        <textarea class="form-control" rows="2" 
                                            id="note" 
                                            name="note"
                                            v-model="form.note"
                                            placeholder="Nhập ghi chú thêm">
                                        </textarea>
                                        <span class="help-block" v-if="formErrors.note">{{formErrors.note[0]}}</span>
                                    </div>
                                </div>

                                <!-- <div class="col-md-12 col-xs-12">
                                   <div class="form-group" :class="{'has-error': formErrors.customer_link}">
                                        <label class="control-label" for="customer_link">Link khách hàng</label>
                                        <textarea class="form-control" rows="2" 
                                            id="customer_link" 
                                            name="customer_link"
                                            v-model="form.customer_link"
                                            placeholder="Nhập link xem">
                                        </textarea>
                                        <span class="help-block" v-if="formErrors.customer_link">{{formErrors.customer_link[0]}}</span>
                                    </div>
                                </div> -->

                                <div class="col-md-12 col-xs-12">
                                   <div class="form-group" :class="{'has-error': formErrors.note_internal}">
                                        <label class="control-label" for="note_internal">Ghi chú nội bộ</label>
                                        <textarea class="form-control" rows="2" 
                                            id="note_internal" 
                                            name="note_internal"
                                            v-model="form.note_internal"
                                            placeholder="Nhập ghi chú nội bộ">
                                        </textarea>
                                        <span class="help-block" v-if="formErrors.note_internal">{{formErrors.note_internal[0]}}</span>
                                    </div>
                                </div>
                                
                                <!-- Không hiển thị nếu là mobile -->
                                <div class="col-md-12 col-xs-12 hidden-xs">
                                    <hr>
                                </div>
                                <div class="col-md-6 col-xs-6 hidden-xs">
                                    <div class="form-group" :class="{'has-error': formErrors.total_quantity}">
                                        <label class="control-label">Tổng số lượng:</label>
                                        <input type="number" class="form-control" readonly
                                            id="total_quantity" 
                                            name="total_quantity"
                                            v-model="form.total_quantity">
                                        <span class="help-block" v-if="formErrors.total_quantity">{{formErrors.total_quantity[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-6 hidden-xs">
                                    <div class="form-group" :class="{'has-error': formErrors.total_weight}">
                                        <label class="control-label">Tổng trọng lượng:</label>
                                        <input type="number" class="form-control"
                                            id="total_weight" 
                                            name="total_weight"
                                            v-model.number="form.total_weight"
                                            step="0.3">
                                        <span class="help-block" v-if="formErrors.total_weight">{{formErrors.total_weight[0]}}</span>
                                    </div>
                                </div>

                                <div class="col-md-6 col-xs-6 hidden-xs">
                                    <div class="form-group" :class="{'has-error': formErrors.total_amount}">
                                        <label class="control-label">Tổng tiền hàng:</label>
                                        <input type="number" class="form-control"
                                            id="total_amount" 
                                            name="total_amount"
                                            v-model="form.total_amount" readonly>
                                        <span class="help-block" v-if="formErrors.total_amount">{{formErrors.total_amount[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-6 hidden-xs">
                                    <div class="form-group" :class="{'has-error': formErrors.shipping_cost}">
                                        <label class="control-label">Tiền vận chuyển:</label>
                                        <div class="input-group">
                                            <input type="number" class="form-control"
                                                id="shipping_cost" 
                                                name="shipping_cost"
                                                v-model="form.shipping_cost"
                                                @change="checkSum"
                                                min="0">
                                            <span class="input-group-btn">
                                                <button type="button" class="btn btn-default btn-flat" @click="showGHTKFee()"><i class="fa fa-fw fa-money"></i></button>
                                            </span>
                                        </div>
                                        <span class="help-block" v-if="formErrors.shipping_cost">{{formErrors.shipping_cost[0]}}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-6 hidden-xs">
                                    <div class="form-group" :class="{'has-error': formErrors.total_payment}">
                                        <label class="control-label">Tiền thanh toán:</label>
                                        <input type="number" class="form-control"
                                            id="total_payment" 
                                            name="total_payment"
                                            v-model="form.total_payment"
                                            min="0">
                                        <span class="help-block" v-if="formErrors.total_payment">{{formErrors.total_payment[0]}}</span>
                                    </div> 
                                </div>
                                <!-- ./Không hiển thị nếu là mobile -->
                            </div>
                            <!-- Không hiển thị nếu là mobile -->
                            <div class="row">
                                <div class="col-md-6 col-xs-6 hidden-xs">
                                    <button type="button" class="btn btn-warning btn-block btn-flat" @click="showProcess"><i class="glyphicon glyphicon-list-alt"></i> Xử lý</button>
                                </div>
                                <div class="col-md-6 col-xs-6 hidden-xs">
                                    <button type="button" class="btn btn-primary btn-block btn-flat" @click="save()" :disabled="enableButtonSave"><i class="fa fa-save"></i> Cập nhật</button>
                                </div>
                            </div>
                            <!-- ./Không hiển thị nếu là mobile -->
                        </div>
                        <!-- ./box-body -->
                    </div>
                </div>
                <div class="col-md-8 col-sm-12 col-xs-12">
                    <!-- box Thông tin -->
                    <div class="box box-primary">
                        <!-- box-header -->
                        <div class="box-header with-border" v-if="form.status!=1">
                            <product-seach @productSelected="productSelected" :autoClearKeyword='true'>
                            </product-seach>
                        </div>
                        <!-- /.box-header -->
                        <!-- box-body -->
                        <div class="box-body">
                            <div class="row">
                                <!-- row-product -->
                                <div class="col-md-12 col-sm-12 col-xs-12" v-for="(item, index) in form.outputvourcher_details" :key="index" v-show="item.status != 0">
                                    <!-- info-box -->
                                    <div class="info-box pull-left">
                                        <span class="info-box-icon">
                                            <!-- <img :src="item.product.image.link_image" :alt="item.product.name"> -->
                                            <v-lazy-image :src="item.product.image.link_image" />
                                        </span>

                                        <div class="info-box-content row">
                                                <div class="col-md-12 col-xs-12">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <span class="info-box-text text-green">
                                                                {{item.product.id}} - {{item.product.name}}
                                                                <span class="label label-success" v-if="item.product.model.status === 1" v-show="false">Đang bán</span>
                                                                <span class="label label-warning" v-else-if="item.product.model.status === 2">Chờ hàng</span>
                                                                <span class="label label-danger" v-else>Ngưng bán</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-4 col-xs-4">
                                                            <span class="label label-success" v-if="item.status === 1">Đã soạn</span>
                                                            <span class="label label-warning" v-else-if="item.status === 3">Thiếu hàng</span>
                                                            <span class="label label-default" v-else>Chưa soạn</span>
                                                        </div>
                                                        <div class="col-md-8 col-xs-8">
                                                            <span class="text-bold">Tồn kho: </span>  
                                                            Tồn<span class="text-light-blue">({{parseFloat(item.product.currentinstock.quantity)}})</span> - Đặt<span class="text-red">({{parseFloat(item.product.currentinstock.order_quantity)}})</span>
                                                        </div>
                                                        <div class="col-md-12 col-xs-12">
                                                            <span class="text-bold">Vị trí:</span>  
                                                            <span>{{item.product.model.location}}</span> 
                                                        </div>
                                                        
                                                        <div class="col-md-4 col-xs-7">
                                                            <div class="form-group" :class="{'has-error': formErrors['outputvourcher_details.'+index+'.quantity']}">
                                                                <label class="control-label">Số lượng:</label>
                                                                <!-- <input type="number" class="form-control" 
                                                                    id="outputvourcher_details['quantity']" 
                                                                    :name="'outputvourcher_details['+index+'][quantity]'" 
                                                                    v-model="item.quantity"
                                                                    step="1"
                                                                    min="0"
                                                                    @change="checkSum"> -->
                                                                <number-button 
                                                                    :name="'outputvourcher_details['+index+'][quantity]'" 
                                                                    v-model="item.quantity"
                                                                    :disabled="item.status === 1"
                                                                    @change="checkSum">
                                                                </number-button>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-xs-5">
                                                            <div class="form-group" :class="{'has-error': formErrors['outputvourcher_details.'+index+'.saleprice']}">
                                                                <label class="control-label">Đơn giá:</label>
                                                                <input type="number" class="form-control" 
                                                                    id="outputvourcher_details['saleprice']" 
                                                                    :name="'outputvourcher_details['+index+'][saleprice]'" 
                                                                    v-model="item.saleprice"
                                                                    step="1"
                                                                    min="0"
                                                                    @change="checkSum">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3 col-xs-6">
                                                            <div class="form-group" :class="{'has-error': formErrors['outputvourcher_details.'+index+'.total']}">
                                                                <label class="control-label">Thành tiền:</label>
                                                                <input type="number" class="form-control" 
                                                                    id="outputvourcher_details['total']" 
                                                                    :name="'outputvourcher_details['+index+'][total]'" 
                                                                    v-model="item.total" readonly>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2 col-xs-6">
                                                            <div class="form-group">
                                                            <label class="control-label"></label>
                                                               <a class="btn btn-danger btn-block" 
                                                               v-if="outputData.status != 1"
                                                               v-on:click="deleteDetail(index)">
                                                                <i class="fa fa-fw fa-trash"></i>
                                                               </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                        <!-- /.info-box-content -->
                                    </div>
                                    <!-- /.info-box -->

                                </div>
                                <!-- /.row-product -->
                                <!-- row-Thông tin tiền -->
                                <div class="row">
                                    <!-- Không hiển thị web -->
                                    <div class="col-md-12 col-xs-12 hidden-md hidden-sm hidden-lg">
                                        <hr>
                                    </div>
                                    <div class="col-md-6 col-xs-6 hidden-md hidden-sm hidden-lg">
                                        <div class="form-group" :class="{'has-error': formErrors.total_quantity}">
                                            <label class="control-label">Tổng số lượng:</label>
                                            <input type="number" class="form-control" readonly
                                                id="total_quantity" 
                                                name="total_quantity"
                                                v-model="form.total_quantity">
                                            <span class="help-block" v-if="formErrors.total_quantity">{{formErrors.total_quantity[0]}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-xs-6 hidden-md hidden-sm hidden-lg">
                                        <div class="form-group" :class="{'has-error': formErrors.total_weight}">
                                            <label class="control-label">Tổng trọng lượng:</label>
                                            <input type="number" class="form-control"
                                                id="total_weight" 
                                                name="total_weight"
                                                v-model.number="form.total_weight"
                                                step="0.3">
                                            <span class="help-block" v-if="formErrors.total_weight">{{formErrors.total_weight[0]}}</span>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-xs-6 hidden-md hidden-sm hidden-lg">
                                        <div class="form-group" :class="{'has-error': formErrors.total_amount}">
                                            <label class="control-label">Tổng tiền hàng:</label>
                                            <input type="number" class="form-control"
                                                id="total_amount" 
                                                name="total_amount"
                                                v-model="form.total_amount" readonly>
                                            <span class="help-block" v-if="formErrors.total_amount">{{formErrors.total_amount[0]}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-xs-6 hidden-md hidden-sm hidden-lg">
                                        <div class="form-group" :class="{'has-error': formErrors.shipping_cost}">
                                            <label class="control-label">Tiền vận chuyển:</label>
                                            <div class="input-group">
                                                <input type="number" class="form-control"
                                                    id="shipping_cost" 
                                                    name="shipping_cost"
                                                    v-model="form.shipping_cost"
                                                    @change="checkSum"
                                                    min="0">
                                                <span class="input-group-btn">
                                                    <button type="button" class="btn btn-default btn-flat" @click="showGHTKFee()"><i class="fa fa-fw fa-money"></i></button>
                                                </span>
                                            </div>
                                            <span class="help-block" v-if="formErrors.shipping_cost">{{formErrors.shipping_cost[0]}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-xs-6 hidden-md hidden-sm hidden-lg">
                                        <div class="form-group" :class="{'has-error': formErrors.total_payment}">
                                            <label class="control-label">Tiền thanh toán:</label>
                                            <input type="number" class="form-control"
                                                id="total_payment" 
                                                name="total_payment"
                                                v-model="form.total_payment"
                                                min="0">
                                            <span class="help-block" v-if="formErrors.total_payment">{{formErrors.total_payment[0]}}</span>
                                        </div> 
                                    </div>
                                    <!-- ./Không hiển thị web -->
                                </div>
                                <!-- ./row-Thông tin tiền -->
                                 <!-- Không hiển thị web -->
                                <div class="row">
                                    <div class="col-md-6 col-xs-6 hidden-md hidden-sm hidden-lg">
                                        <button type="button" class="btn btn-primary btn-block btn-flat" @click="save()" :disabled="enableButtonSave"><i class="fa fa-save"></i> Cập nhật</button>
                                    </div>
                                    <div class="col-md-6 col-xs-6 hidden-md hidden-sm hidden-lg">
                                        <button type="button" class="btn btn-warning btn-block btn-flat" @click="showProcess"><i class="glyphicon glyphicon-list-alt"></i> Xử lý</button>
                                    </div>
                                </div>
                                <!-- ./Không hiển thị web -->
                            </div>
                        </div>
                        <!-- ./box-body -->
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <a class="btn btn-default" @click="goBack()"><i class="glyphicon glyphicon-arrow-left"></i> Quay lại</a>
                    <router-link class="btn btn-default btn-flat"
                        :to="'/outputvourcher/sale/add'">
                        <i class="fa fa-cart-plus"></i> Thêm đơn
                    </router-link>
                    <a class="btn btn-default" @click="print('print')"><i class="fa fa-print"></i> In hóa đơn <span class="text-red" v-if="form.isprint == true">(Đã in)</span></a>
                    <a class="btn btn-default" @click="print('print_a7')"><i class="fa fa-print"></i> In hóa đơn A7<span class="text-red" v-if="form.isprint == true">(Đã in)</span></a>
                </div>
            </div>
                
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>
    <!-- Modal xử lý-->
    <div class="modal fade in" id="modal-form-process" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">Xử lý</h4>
            </div>
            <div class="modal-body">
                <button type="button" class="btn btn-primary btn-block btn-flat" @click="updateStatus(3)" v-if="form.status === 2"><i class="glyphicon glyphicon-ok"></i> CHỐT HÀNG</button>
                <button type="button" class="btn btn-primary btn-block btn-flat" @click="updateStatus(4)" v-if="form.status === 6"><i class="glyphicon glyphicon-ok"></i> TRẠNG THÁI SOẠN HÀNG</button>
                <button type="button" class="btn btn-primary btn-block btn-flat" @click="updateStatus(6)" v-if="form.status === 1 && (form.delivery_type != 0 && form.delivery_type != 4 && form.delivery_type != 5)"><i class="glyphicon glyphicon-ok"></i> TRẠNG THÁI SOẠN XONG</button>
                <button type="button" class="btn btn-danger btn-block btn-flat" @click="del()"><i class="fa fa-fw fa-trash"></i> HỦY ĐƠN</button>
            </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
    <!-- Modal tra cứu cước -->
    <div class="modal fade in" id="modal-form-ghtk-fee">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">Tra cứu cước</h4>
            </div>
            <div class="modal-body">
                <GHTKFee>
                </GHTKFee>
            </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->



    <!-- Mẫu in bill-->
    <bill :data="outputData" :isobject="true"></bill>
    <!-- ./Mẫu in bill-->

     <!-- Mẫu in bill a7-->
    <BillA7 :data="outputData" :isobject="true"></BillA7>
    <!-- ./Mẫu in bill a7-->
  </div>
</template>

<script>
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import VLazyImage from "v-lazy-image";
import Select2 from '../../../components/Select2.vue'
import AddressSelected from '../../../components/AddressSelected.vue';
import NumberButton from '../../../components/NumberButton.vue';
import ProductSeach from '../../../components/ProductSeach/ProductSeach.vue';
import CustomerSeach from '../../../components/CustomerSeach.vue';
import outputvourcher from '../../../objects/outputvourcher'
import GHTKFee from '../../../components/GHTK/GHTKFee.vue'
import Bill from '../../reports/bill';
import BillA7 from '../../reports/BillA7';

export default {
    name: 'formOutputVourcherSaleEdit',
    components: {
      ProductSeach, 
      Select2, 
      NumberButton, 
      VuejsDialog, 
      VLazyImage, 
      AddressSelected, 
      CustomerSeach,
      GHTKFee,
      Bill,
      BillA7
    },
    data() {
        return {
            title: 'Cập nhật đơn hàng',
            formErrors: {},
            enableButtonSave: false,
            form: {},
            outputData: {},
            id: '',
            listCalculationWeight: [],
            listShopPrice: [],
            listShop: [],
            listSourceAddress: [],
            listPartner: [],
            GiaThoaThuan: '',
            addressText: ''
        }
    },
    mounted(){
        NProgress.start()
        this.id = this.$route.params.id
        this.title = 'Đơn hàng - ' + this.id
        this.$store.dispatch('setPageTitle', this.title);
        this.form = Object.assign({}, outputvourcher)
        this.form.outputvourcher_details = []
        this.getData()
        this.getOutputVourcher(this.id)
        NProgress.done()
    },
    computed: {
        shop_price_in_store: function () {
            return this.listShopPrice.filter(o=>o.shop_id == this.form.shop_id)
        },
        partner_in_store: function () {
            return this.listPartner.filter(o=>o.partner_shop_id == this.form.shop_id)
        },
        userOuput(){
            var userTemp = ''
            if(typeof(this.outputData.output_user) != 'undefined'){
                userTemp = this.outputData.output_user.name
            }
            return userTemp
        },
        userUpdateStatus(){
            var userTemp = ''
            if(typeof(this.outputData.status_update_user) != 'undefined'){
                userTemp = this.outputData.status_update_user.name
            }
            return userTemp
        },
        price_note_partner: function () {
            var notePrice = ''
            if(this.form.partner_id > 0){
                var partnerTemp = this.listPartner.find(o => o.partner_id == this.form.partner_id)      
                if(partnerTemp != null){
                    notePrice = partnerTemp.partner_description_price
                }  
            }
            return notePrice
        },
    },
    methods: {
        onChangeShop(){
            this.form.partner_id = 0;
            this.form.shop_price_id = '';
        },
        onChangeShopPrice(){
            var ShopPriceTemp = this.listShopPrice.find(o => o.id == this.form.shop_price_id)  
            if(this.form.partner_id > 0){
                this.form.surcharge = 0
            }else{
                this.form.surcharge = ShopPriceTemp.surcharge
            }
        },
        goBack(){
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push('/')
        },
        getData() {
            var vm = this
            //Lấy data 1 lần
            axios.get('/api/inventory/output/sale/get_masterdata_form')
                .then(function(response) {
                    var responseData = response.data.data
                    vm.listShopPrice = responseData['shop_prices']
                    vm.listShop =  responseData['shops']
                    vm.listSourceAddress = responseData['source_address']
                    vm.listCalculationWeight = responseData['calculation_weights']
                    vm.listPartner = responseData['partners']
                })
                .catch(function(error) {
                    vm.listShopPrice = []
                    vm.listShop = []
                    vm.listSourceAddress = []
                    vm.listCalculationWeight = []
                    vm.listPartner = []
                    console.log(error)
                })

            // //Lấy danh mục loại giá
            // axios.get('/api/shop_price/all')
            //     .then(function(response) {
            //     vm.listShopPrice = response.data.data
            //     })
            //     .catch(function(error) {
            //         vm.listShopPrice = []
            //         console.log(error)
            //     })

            // //Lấy danh mục shop
            // axios.get('/api/shop/all')
            //     .then(function(response) {
            //     vm.listShop = response.data.data
            //     })
            //     .catch(function(error) {
            //         vm.listShop = []
            //         console.log(error)
            //     })

            //  //Lấy danh mục nguồn đơn
            // axios.get('/api/source_address/all')
            //     .then(function(response) {
            //     vm.listSourceAddress = response.data.data
            //     })
            //     .catch(function(error) {
            //         vm.listSourceAddress = []
            //         console.log(error)
            //     })

            // //Lấy danh mục tính trọng lượng
            // axios.get('/api/calculation_weight/all')
            //     .then(function(response) {
            //     vm.listCalculationWeight = response.data.data
            //     })
            //     .catch(function(error) {
            //         vm.listCalculationWeight = []
            //         console.log(error)
            //     })

            //Lấy danh mục CTV
            // axios.get('/api/ctv/partner/all')
            //     .then(function(response) {
            //     vm.listPartner = response.data.data
            //     })
            //     .catch(function(error) {
            //         vm.listPartner = []
            //         console.log(error)
            //     })
        },
        save() {
            NProgress.start()
            let vm = this
            vm.formErrors = {}
            vm.enableButtonSave = true
            
            //loại bỏ các dòng có trạng thái status = 0
            var listDetailTempActived = vm.form.outputvourcher_details.filter(o => o.status != 0)
            var listDetailTempDeleted = vm.form.outputvourcher_details.filter(o => o.status == 0)
            for (var i = 0; i < listDetailTempDeleted.length; i++) {
              if(vm.outputData.outputvourcher_details.some(o => o.id == listDetailTempDeleted[i].id)){
                listDetailTempActived.push(listDetailTempDeleted[i])
              }
            }
            vm.form.outputvourcher_details = listDetailTempActived

            axios['put']('/api/inventory/output/sale/update', vm.form)
            .then(function (response) {
                NProgress.done()
                vm.$snotify.success('Cập nhật thành công!', 'Success')
                vm.outputData = response.data.data
                vm.initData()
                vm.enableButtonSave = false
            })
            .catch(function (error) {
                NProgress.done()
                vm.$snotify.error('Cập nhật thất bại, vui lòng kiểm tra lại!', 'Error')
                vm.formErrors = error.response.data.errors
                vm.enableButtonSave = false
                console.log(error)
            })
        },
        getOutputVourcher(OutputVourcherID)
        {
            var vm = this
            axios.get('/api/inventory/output/sale/select_sale/' + OutputVourcherID)
                .then(function(response) {
                    vm.outputData = response.data.data
                    vm.initData()
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        initData() {
            this.form.id = this.outputData.id
            this.form.output_type_id = this.outputData.output_type_id
            this.form.output_date = this.outputData.output_date
            this.form.output_user_id = this.outputData.output_user_id
            this.form.customer_id = this.outputData.customer_id
            this.form.customer_name = this.outputData.customer_name
            this.form.customer_nickname = this.outputData.customer_nickname
            this.form.customer_phone = this.outputData.customer_phone
            this.form.customer_address = this.outputData.customer_address

            this.form.customer_province_id = this.outputData.customer_province_id
            this.form.customer_province = this.outputData.customer_province
            this.form.customer_district_id = this.outputData.customer_district_id
            this.form.customer_district = this.outputData.customer_district
            this.form.customer_ward_id = this.outputData.customer_ward_id
            this.form.customer_ward = this.outputData.customer_ward
            this.form.customer_street = this.outputData.customer_street

            this.addressText =  this.fullAddress()

            this.form.note = this.outputData.note
            this.form.note_internal = this.outputData.note_internal
            this.form.status = this.outputData.status
            this.form.status_update_date = this.outputData.status_update_date
            this.form.status_update_user_id = this.outputData.status_update_user_id
            this.form.shop_id = this.outputData.shop_id
            this.form.shop_price_id = this.outputData.shop_price_id
            this.form.source_address_id = this.outputData.source_address_id

            this.form.delivery_type = this.outputData.delivery_type
            this.form.customer_link = this.outputData.customer_link
            this.form.partner_id = this.outputData.partner_id

            this.form.payment_not_auto_confirm = this.outputData.payment_not_auto_confirm
            this.form.payment_note = this.outputData.payment_note
            this.form.return_quantity_note = parseFloat(this.outputData.return_quantity_note)
            this.form.isprint = this.outputData.isprint
            this.form.surcharge = this.outputData.surcharge
            
            //Khởi tạo outputvourcher_details
            this.form.outputvourcher_details = []
            for (var i = 0; i < this.outputData.outputvourcher_details.length; i++) {
                var newItem = {};
                newItem.id = this.outputData.outputvourcher_details[i].id
                newItem.outputvourcher_id = this.outputData.outputvourcher_details[i].outputvourcher_id
                newItem.product_id = this.outputData.outputvourcher_details[i].product_id
                newItem.product = this.outputData.outputvourcher_details[i].product
                newItem.quantity = parseFloat(this.outputData.outputvourcher_details[i].quantity)
                newItem.costprice = parseFloat(this.outputData.outputvourcher_details[i].costprice)
                newItem.saleprice = parseFloat(this.outputData.outputvourcher_details[i].saleprice)
                newItem.total = parseFloat(this.outputData.outputvourcher_details[i].saleprice) * parseFloat(this.outputData.outputvourcher_details[i].quantity)
                newItem.status = this.outputData.outputvourcher_details[i].status
                this.form.outputvourcher_details.push(newItem)
            }

            this.checkSum()

            this.form.total_payment = parseFloat(this.outputData.total_payment)
            this.form.total_amount = parseFloat(this.outputData.total_amount)
            this.form.shipping_cost = parseFloat(this.outputData.shipping_cost)
            this.form.total_weight = parseFloat(this.outputData.total_weight)

        },
        productSelected(product, quantity) {
            var productIndex= this.form.outputvourcher_details.findIndex(o => o.product_id == product.id
                                                                        && o.status != 0)
            var priceIndex= product.model.shop_prices.findIndex(o => o.id ==  this.form.shop_price_id)                                                           
            if(productIndex<0){
                var newItem = {};
                newItem.id = '',
                newItem.outputvourcher_id = ''
                newItem.product_id = product.id
                newItem.product = product
                newItem.quantity = parseFloat(quantity)
                newItem.costprice = 0
                newItem.saleprice = 0
                if(priceIndex>=0)
                {
                    newItem.saleprice = parseFloat(product.model.shop_prices[priceIndex].pivot.price)
                    if(this.form.surcharge == 1){
                        newItem.saleprice += parseFloat(product.surcharge_price)
                    }
                }
                newItem.total = 0
                newItem.status = 2
                this.form.outputvourcher_details.push(newItem)
            }else{
                var vm = this

                if(this.form.outputvourcher_details[productIndex].status != 1){
                    this.form.outputvourcher_details[productIndex].quantity = parseFloat(this.form.outputvourcher_details[productIndex].quantity + parseFloat(quantity))
                }else{
                    vm.$dialog.alert('Sản phẩm đã được soạn không được thêm mới. Vui lòng xóa và thêm lại sản phẩm này!', {
                            okText: 'Đồng ý'
                        })
                        .then(function(dialog) {
                            return
                        });
                }
            }

            this.checkSum()
        },
        deleteDetail(index) {
            this.form.outputvourcher_details[index].status = 0
            this.checkSum()
        },
        checkSum() {
            this.form.total_quantity = 0
            this.form.total_amount = 0
            for (var i = 0; i < this.form.outputvourcher_details.length; i++) {
                if(this.form.outputvourcher_details[i].status != 0){
                        this.form.outputvourcher_details[i].total =  parseFloat(this.form.outputvourcher_details[i].quantity) * parseFloat(this.form.outputvourcher_details[i].saleprice) 
                        this.form.total_quantity = parseFloat(this.form.total_quantity) + parseFloat(this.form.outputvourcher_details[i].quantity)
                        this.form.total_amount = parseFloat(this.form.total_amount) + parseFloat(this.form.outputvourcher_details[i].total)
                }
            }

            this.CalculationWeight()
            this.form.total_payment = parseFloat(this.form.total_amount) + parseFloat(this.form.shipping_cost)
        },
        getCustomerByPhone(phone){
            let vm = this
            if(phone.trim().length == 0){
                return
            }
            // if(phone.trim() == vm.outputData.customer_phone){
            //     return
            // }
            axios.get('/api/inventory/output/sale/select_by_phone_sale/'+ phone)
                .then(function(response) {
                    var dataTemp = response.data.data
                    if(dataTemp == null){
                        return
                    }else{
                        if(dataTemp.status_warning == 2){
                            var textMessage = 'Khách hàng được liệt kê vào danh sách không bán ['+dataTemp.description+']'
                            vm.$dialog.alert(textMessage, {
                                                okText: 'Đồng ý'
                                            })
                            .then(function(dialog) {
                                return
                            });
                        }else if(dataTemp.status_warning == 1){
                            var textMessage = 'Khách hàng được liệt kê vào danh sách cần cảnh báo khi bán ['+dataTemp.description+']. Đồng ý để tiếp tục!'
                            vm.$dialog.confirm(textMessage, {
                                loader: true,
                                okText: 'Đồng ý',
                                cancelText: 'Hủy bỏ'
                            })
                            .then((dialog) => {
                                dialog.close()
                                if(dataTemp.outputvourchers.length>0 && dataTemp.outputvourchers[0].id != vm.id){
                                    //Kiểm tra đơn hàng xem khách hàng có bị trùng đơn không
                                    if(dataTemp.outputvourchers[0].status != 1){
                                        var textMessage = 'Khách hàng ['+dataTemp.name+'] tồn tại đơn hàng chưa hoàn thành vào ngày ' + vm.moment(dataTemp.outputvourchers[0].output_date).format('DD/MM/YYYY') + ' . Vui lòng kiểm tra lại trước khi tạo đơn mới!'
                                        vm.$dialog.alert(textMessage, {
                                                            okText: 'Đồng ý'
                                                        })
                                        .then(function(dialog) {
                                            return
                                        });
                                    }else{
                                        var date_output = Date.parse(dataTemp.outputvourchers[0].status_update_date)
                                        var date_now = new Date()
                                        var date_check = date_now.setDate(date_now.getDate() - 2)
                                        var date_output_show = vm.moment(dataTemp.outputvourchers[0].status_update_date).format('DD/MM/YYYY')

                                        if(date_output >= date_check){
                                            var textMessage = 'Khách hàng ['+dataTemp.name+'] vừa được gửi đơn vào ngày ' + date_output_show + ' . Bạn có tiếp tục tạo đơn mới cho khách hàng này!'
                                            vm.$dialog.confirm(textMessage, {
                                                loader: true,
                                                okText: 'Đồng ý',
                                                cancelText: 'Hủy bỏ'
                                            })
                                            .then((dialog) => {
                                                vm.setCustomerInfo(dataTemp)
                                                dialog.close()
                                            });
                                        }else{
                                            vm.setCustomerInfo(dataTemp)
                                        }
                                    }
                                }else{
                                    vm.setCustomerInfo(dataTemp)
                                }
                            });
                        }else{
                            if(dataTemp.outputvourchers.length>0 && dataTemp.outputvourchers[0].id != vm.id){
                                //Kiểm tra đơn hàng xem khách hàng có bị trùng đơn không
                                if(dataTemp.outputvourchers[0].status != 1){
                                    var textMessage = 'Khách hàng ['+dataTemp.name+'] tồn tại đơn hàng chưa hoàn thành vào ngày ' + vm.moment(dataTemp.outputvourchers[0].output_date).format('DD/MM/YYYY') + ' . Vui lòng kiểm tra lại trước khi tạo đơn mới!'
                                    vm.$dialog.alert(textMessage, {
                                                        okText: 'Đồng ý'
                                                    })
                                    .then(function(dialog) {
                                        return
                                    });
                                }else{
                                    var date_output = Date.parse(dataTemp.outputvourchers[0].status_update_date)
                                    var date_now = new Date()
                                    var date_check = date_now.setDate(date_now.getDate() - 2)
                                    var date_output_show = vm.moment(dataTemp.outputvourchers[0].status_update_date).format('DD/MM/YYYY')

                                    if(date_output >= date_check){
                                        var textMessage = 'Khách hàng ['+dataTemp.name+'] vừa được gửi đơn vào ngày ' + date_output_show + ' . Bạn có tiếp tục tạo đơn mới cho khách hàng này!'
                                        vm.$dialog.confirm(textMessage, {
                                            loader: true,
                                            okText: 'Đồng ý',
                                            cancelText: 'Hủy bỏ'
                                        })
                                        .then((dialog) => {
                                            vm.setCustomerInfo(dataTemp)
                                            dialog.close()
                                        });
                                    }else{
                                        vm.setCustomerInfo(dataTemp)
                                    }
                                }
                            }else{
                                vm.setCustomerInfo(dataTemp)
                            }
                        }
                    }
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        setCustomerInfo(customerInfo){
            this.form.customer_id = customerInfo.id
            this.form.customer_phone = customerInfo.phone
            this.form.customer_name = customerInfo.name
            this.form.customer_nickname = customerInfo.nickname
            this.form.customer_address = customerInfo.address

            this.form.customer_province_id = customerInfo.province_id
            this.form.customer_province = customerInfo.province
            this.form.customer_district_id = customerInfo.district_id
            this.form.customer_district = customerInfo.district
            this.form.customer_ward_id = customerInfo.ward_id
            this.form.customer_ward = customerInfo.ward
            this.form.customer_street = customerInfo.address

            this.addressText =  this.fullAddress()

            // Cập nhật phiếu thì khi thay số điện thoại không gán các trường này
            // this.form.shop_id = customerInfo.outputvourchers.length > 0 ? customerInfo.outputvourchers[0].shop_id : ''
            // this.form.shop_price_id = customerInfo.outputvourchers.length > 0 ? customerInfo.outputvourchers[0].shop_price_id : ''
            // this.form.source_address_id = customerInfo.outputvourchers.length > 0 ? customerInfo.outputvourchers[0].source_address_id : ''
        },
        refreshPrice(){
            if(this.form.outputvourcher_details.length>0){
                for (var i = 0; i < this.form.outputvourcher_details.length; i++) {
                    var priceTemp = this.form.outputvourcher_details[i].product.model.shop_prices.find(o => o.id ==  this.form.shop_price_id)      
                    if(priceTemp != null){
                        this.form.outputvourcher_details[i].saleprice = parseFloat(priceTemp.pivot.price)
                        if(this.form.surcharge == 1){
                            this.form.outputvourcher_details[i].saleprice += parseFloat(this.form.outputvourcher_details[i].product.surcharge_price)
                        }
                    }  
                }
                this.checkSum()
            }
        },
        refreshPartner(){
            this.form.partner_id = 0;
        },
        refreshPriceThoaThuan(){
            let vm = this
            if(this.GiaThoaThuan.trim() == ''){
                var textMessage = 'Vui lòng nhập giá thỏa thuận!'
                vm.$dialog.alert(textMessage, {
                                    okText: 'Đồng ý'
                                })
                .then(function(dialog) {
                    return
                });
            }
            if(this.form.outputvourcher_details.length>0){
                for (var i = 0; i < this.form.outputvourcher_details.length; i++) {
                    if(parseFloat(this.GiaThoaThuan) > 0){
                        this.form.outputvourcher_details[i].saleprice = parseFloat(this.GiaThoaThuan)
                        if(this.form.surcharge == 1){
                            this.form.outputvourcher_details[i].saleprice += parseFloat(this.form.outputvourcher_details[i].product.surcharge_price)
                        }
                    }  
                }
                this.checkSum()
            }
        },
        showProcess(){
            $('#modal-form-process').modal('show')
        },
        del(){
            var vm = this
            vm.$dialog.confirm('Bạn có muốn xóa?', {
                loader: true,
                okText: 'Đồng ý',
                cancelText: 'Hủy bỏ'
            })
            .then((dialog) => {
                axios['put']('/api/inventory/output/delete/'+this.id)
                .then(function (response) {
                    var result = response.data
                    if(result.success==true){
                        vm.$snotify.success(result.message, 'Success')
                        $('#modal-form-process').modal('hide')
                        vm.$router.push({path: '/outputvourcher/sale'})
                    }else{
                        vm.$snotify.error(result.message, 'Lỗi khi xóa')
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
                dialog.close()
            });
        },
        updateStatus(status){
            var vm = this
            axios['put']('/api/inventory/output/update_status/'+this.form.id+'/'+status)
            .then(function (response) {
               vm.$snotify.success('Cập nhật trạng thái thành công!', 'Success')
               $('#modal-form-process').modal('hide')
               vm.getOutputVourcher(vm.id)
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        showAddressSelected(){
            this.$refs.AddressSelected.open();
        },
        AddressSelected(result){
            this.form.customer_province_id = result.province.id
            this.form.customer_province = result.province
            this.form.customer_district_id = result.district.id
            this.form.customer_district = result.district
            this.form.customer_ward_id = result.ward.id
            this.form.customer_ward = result.ward
            this.form.customer_street = result.address
            this.form.customer_address = this.fullAddress()
            this.addressText =  this.form.customer_address
        },
        fullAddress(){
            var AddressTemp = ''
            if(this.form.customer_province_id){
                AddressTemp = this.form.customer_street + ', '
                AddressTemp += this.form.customer_ward.name + ', '
                AddressTemp += this.form.customer_district.name  + ', '
                AddressTemp +=this.form.customer_province.name
            }
            return AddressTemp
        },
        CustomerSeachShow() {
            this.$refs.CustomerSeach.open();
        },
        CustomerSeach(result){
            this.getCustomerByPhone(result.phone)
        },
        CalculationWeight(){
            var CalculationWeightTemp = this.listCalculationWeight.find(o => o.id ==  parseFloat(this.form.total_quantity))
            if(CalculationWeightTemp != null){
                this.form.total_weight = parseFloat(CalculationWeightTemp.value)
            }else{
                this.form.total_weight = parseFloat(this.form.total_quantity * 0.3).toFixed(1)
            }
        },
        showGHTKFee(){
            $('#modal-form-ghtk-fee').modal('show')
        },
        print(printName) {
            var vm = this
            this.$htmlToPaper(printName, () => {
                 axios['put']('/api/inventory/output/sale/update_outputvourcher_isprint/'+vm.form.id)
                .then(function (response) {
                    vm.form.isprint = true
                })
                .catch(function (error) {
                    console.log(error)
                })
                //console.log('In thành công');
            });
        },
        refreshreturn_quantity_note(){
            if(parseFloat(this.form.return_quantity_note) > 0){
                this.form.note = 'Lấy ' +  parseFloat(this.form.return_quantity_note) + ' bộ về'
            }
        }
    }
}
</script>
<style lang="css" scoped>
.info-box-icon>img {
    width: 100%;
    max-height: 90px;
    vertical-align: unset;
}
.info-box-content{
    padding: 0px;
}
.info-box-text{
    white-space: unset;
}
.info-box {
    padding-bottom: 10px;
}
</style>