<template>
    <select :name="name" :id="id" :multiple="multiple" class="form-control">
        <slot></slot>
    </select>
</template>

<script>
    require('select2')
    import 'select2/dist/css/select2.min.css'

    export default {
        name: 'select2',
        props: {
            name: '',
            id: '',
            value: null,
            dataUrl: {
                type: String,
                default: ''
            },
            keyID: {
                type: String,
                default: 'id'
            },
            keyName: {
                type: String,
                default: 'name'
            },
            multiple: {
                Boolean,
                default: false
            },
            options: {
                type: Object,
                default: () => ({
                    width: '100%',
                    theme: 'classic'
                })
            },
            data:{
                type: Array
            }
        },
        data() {
            return {
                select2data: []
            }
        },
        methods: {
            bind(options){
                let vm = this;
                let select = $(this.$el)

                this.formatOptions(options.data)
                options.data = this.select2data
                select.select2(options).on('change', function () {
                        vm.$emit('input', select.val())
                })
                select.trigger("input")
                select.val(this.value).trigger('change')
            },
            formatOptions(data) {
                let vm = this
                for (let item in data) {
                    if(typeof data[item][vm.keyName] != 'undefined'){
                        vm.select2data.push({ id: data[item][vm.keyID], text: data[item][vm.keyName]})
                    }
                }
                
            },
            getList() {
                let vm = this
                axios.get(this.dataUrl)
                    .then(function (response) {
                        let data = vm.formatOptions(response.data.data)
                        vm.options.data = vm.select2data
                        vm.bind(vm.options)
                    })
            },
            refresh(data){
                this.options.data = data
                this.bind(this.options)
            }
        },
        watch: {
            value: function (newVal, oldVal) {
                if(this.multiple == true){
                    if ([...newVal].sort().join(",") !== [...$(this.$el).val()].sort().join(","))
                    $(this.$el).val(newVal).trigger('change');

                    if(newVal.length != oldVal.length){
                        if(newVal.length > oldVal.length){
                            for (var i = 0; i < newVal.length; i++) {
                               var exitsTemp = oldVal.some(o => o == newVal[i])
                               if(!exitsTemp){
                                    $(this.$el).trigger('change')
                                    this.$emit('addItem', newVal[i])
                                    return
                               }
                            }
                        }else{
                            for (var i = 0; i < oldVal.length; i++) {
                               var exitsTemp = newVal.some(o => o == oldVal[i])
                               if(!exitsTemp){
                                    this.$emit('removeItem', oldVal[i])
                                    return
                               }
                            }
                        }
                    }
                }else{
                    $(this.$el).val(this.value).trigger('change')
                }
                //gọi sự kiện changed khi có thay đổi giá trị
                if(oldVal != newVal){
                    this.$emit('changed')
                }
            }

        },
        mounted: function () {
            //Nếu có truyền vào URL hiểu là lấy trực tiếp từ nguồn thì lấy danh sách từ api
            if (this.dataUrl !== '') {
               this.getList()
            }
            else{
                if(this.data.length > 0){
                    this.options.data = this.data
                }
                this.bind(this.options)
            }
        },
        destroyed: function () {
            //$(this.$el).off().select2('destroy')
        }
    }

</script>

<style>

</style>
