<template>
    <!-- /btn-group -->
    <div class="input-group">
        <input type="text" 
            class="form-control" 
            v-model="params.keyword"   
            v-on:keyup.enter="seach"
            :placeholder="placeholder">
        <div class="input-group-btn">
            <button type="button" class="btn btn-default btn-flat" @click="seach">
                <i class="glyphicon glyphicon-search"></i>
            </button>
            <button type="button" class="btn btn-default btn-flat"><i class="glyphicon glyphicon-qrcode"></i></button>
        </div>
        <!-- /btn-group -->
            <!-- Modal -->
        <div class="modal fade in" id="modal-form-model-seach"  data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span></button>
                    <h4 class="modal-title">Tìm kiếm sản phẩm</h4>
                </div>
                <div class="modal-body">
                    <model-view
                        ref="formModelView"
                        @modelSelected="modelSelected">
                    </model-view>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Đóng</button>
                </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.modal -->
    </div>
    <!-- /input-group -->
</template>

<script>
import axios from 'axios'
import ModelView from './components/ModelView.vue'

    export default {
        name: 'ModelSeach',
        components: {
            ModelView
        },
        props: {
            value: null,
            placeholder: {
                type: String,
                default: 'Nhập mã model'
            },
            autoClearKeyword: {
                type: Boolean,
                default: true
            } 
        },
        data() {
            return {
                params: {
                    keyword: ''
                }
            }
        },
        methods: {
            seach() {
                var vm = this
                if(vm.params.keyword.trim() != ''){
                    axios.get('/api/model/seachsale/'+ vm.params.keyword.trim())
                    .then(function(response) {
                        var model = response.data.data
                        if(model.length > 0) 
                        {
                            if(vm.autoClearKeyword){
                                vm.params.keyword = ''
                            }
                            vm.$emit('modelSelected', model[0])
                            return
                        }else{
                            vm.$refs.formModelView.open(vm.params.keyword);
                            $('#modal-form-model-seach').modal('show')
                        }
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
               }else{
                    this.$refs.formModelView.open(this.params.keyword);
                    $('#modal-form-model-seach').modal('show')
               }
            },
            modelSelected(model) {
                $('#modal-form-model-seach').modal('hide')

                if(this.autoClearKeyword){
                    this.params.keyword = ''
                }
                this.$emit('modelSelected', model)
            }
        }
    }
</script>
<style lang="css" scoped>

</style>