<template>
<!-- Modal -->
<div class="modal fade in" id="modal-form-read-qrcode" data-backdrop="static" data-keyboard="false" ref="vuemodal">
    <div class="modal-dialog modal-lg">
    <div class="modal-content">
        <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
           <div class="row">
               <div class="col-md-12 col-sm-12 col-xs-12">
                    <p class="error">{{ error }}</p>
                    <p class="decode-result">{{ result }}</p>
                    <qrcode-stream :camera="camera" v-if="show" @decode="onDecode" @init="onInit" />
               </div>
            </div>
        </div>
    </div>
    <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
</template>

<script>
    import { QrcodeStream } from 'vue-qrcode-reader'

    export default {
        name: 'ReadQrcode',
        components: {QrcodeStream},
        props: {
            
        },
        data() {
            return {
                result: '',
                error: '',
                show: false,
                camera: 'auto'
            }
        },
        mounted(){
            $(this.$refs.vuemodal).on("hidden.bs.modal", this.closed)
        },
        computed: {
          
        },
        methods: {
            open(){
                $('#modal-form-read-qrcode').modal('show')
                this.show = true
                this.result = ''
                this.error = ''
            },
            closed(){
                this.show = false
            },
            submit(){

            },
            onDecode (result) {
                this.result = result
            },
            async onInit (promise) {
                try {
                    await promise
                } catch (error) {
                    if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permisson"
                    } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                    } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                    } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                    } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                    } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                    }
                }
            }
        }
    }
</script>
