<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-body -->
          <div class="box-body">
            <form-customer ref="formCustomer" :click-submit="saveSuccess"></form-customer>
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>
  </div>
</template>

<script>
import formCustomer from './form.vue'

export default {
    name: 'CustomerAdd',
    components: {
      formCustomer
    },
    data() {
        return {
          title: 'Thêm mới khách hàng'
        }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
        this.$refs.formCustomer.open();
    },
    methods: {
      saveSuccess()
      { 
        this.$router.push({path: '/customer'})
      }
    }
}
</script>