<template>
    <div :id="id" style="visibility: hidden">
        <h4 class="text-uppercase text-center"><strong>{{shopNameText}}</strong></h4>
        <h5 class="text-uppercase text-center"><strong>CHẤT LƯỢNG – UY TÍN – GIÁ CẢ PHẢI CHĂNG</strong></h5>
        <h5 class="text-center"><strong>{{addressText}}</strong></h5>
        <h5 class="text-center">----------------------------oOo----------------------------</h5>
        <div class="row">
            <div class="col-md-7 col-xs-7">
                <h5>Người gửi: {{nameText}}</h5>
                <h5>Điện thoại/Zalo: <span>{{phoneText}}</span></h5>
                <h5>Website: <span>{{websiteText}}</span></h5>
                <h5>Facebook: <span>{{facbookText}}</span></h5>
            </div>
            <div class="col-md-5 col-xs-5">
                <p class="text-muted well well-sm text-justify">
                   <strong>
                    - ĐƯỢC XEM HÀNG ĐỂ KIỂM TRA MẪU VÀ SỐ LƯỢNG. TRƯỜNG HỢP KHÁCH KHÔNG ƯNG VUI LÒNG THANH TOÁN TIỀN PHÍ SHIP 1 CHIỀU.
                    <br>
                    - KHÔNG GIAO ĐƯỢC LIÊN HỆ SĐT SHOP, KHÔNG TỰ Ý HỦY ĐƠN.
                   </strong>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-7 col-xs-7">
                <h5>Mã đơn hàng: <strong>{{data.id}}</strong></h5>
                <h5>Người nhận: {{data.customer_name}}<span v-if="data.customer_nickname"> ({{data.customer_nickname}})</span></h5>
                <h5>Địa chỉ: {{data.customer_address}}</h5>
                <h5>Điện thoại: <strong>{{data.customer_phone}}</strong> - COD: <strong>{{parseFloat(data.total_payment)| numFormat}} ({{totalQuantity}} bộ)</strong></h5>
                <h5>Ghi chú: <strong>{{data.note}}</strong></h5>
            </div>
            <div class="col-md-5 col-xs-5" v-if="data.delivery_type == 0">
                <strong v-if="data.shipment_id">{{data.shipment_id}}</strong> 
                <barcode v-if="data.shipment_id" :value="MaVanDon" width=3 height=70>
                    Show this if the rendering fails.
                </barcode>
            </div>
        </div>
    </div>
</template>

<script>
import VueBarcode from 'vue-barcode';

export default {
    name: 'Bill',
    components: {
        'barcode': VueBarcode
    },
    props: {
        id: {
            type: String,
            default: 'print'
        },
        data: {
          type: Object
        },
        isobject: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            
        }
    },
    computed: {
        totalQuantity(){
            var totalQuantityTemp = 0
            if(this.isobject == true){
                if(typeof(this.data.outputvourcher_details) != 'undefined'){
                    for (var i = 0; i < this.data.outputvourcher_details.length; i++) {
                        if(this.data.outputvourcher_details[i].status != 0 ){
                            totalQuantityTemp = totalQuantityTemp + parseFloat(this.data.outputvourcher_details[i].quantity)
                        }
                    }
                }
            }else{
                totalQuantityTemp = this.data.total_quantity
            }
            
            
            return parseFloat(totalQuantityTemp)
        },
        nameText(){
            var name = ''
            if(this.isobject == true){
                if(this.data.partner_id > 0){
                    name = this.data.partner ? this.data.partner.partner_name.toUpperCase() : ''
                }else{
                    //name = 'HÀ THỊ HỒNG THÚY'
                    name = this.data.shop ? this.data.shop.shopmanagername.toUpperCase() : ''
                }
            }else{
                if(this.data.partner_name){
                    name = this.data.partner_name ? this.data.partner_name.toUpperCase() : ''
                }else{
                    //name = 'HÀ THỊ HỒNG THÚY'
                    name = this.data.shop_shopmanagername ? this.data.shop_shopmanagername.toUpperCase() : ''
                }
            }
            
            return name
        },
        shopNameText(){
            var name = ''
            if(this.isobject == true){
                if(this.data.partner_id > 0){
                    name = this.data.partner ? this.data.partner.partner_shop_name : ''
                }else{
                    name = this.data.shop ? this.data.shop.name:''
                }
            }else{
                if(this.data.partner_name){
                    name = this.data.partner_shop_name ? this.data.partner_shop_name : ''
                }else{
                    name = this.data.shop_name ? this.data.shop_name:''
                }
            }
            return name.toUpperCase()
        },
        addressText(){
            var address = ''
            
            if(this.isobject == true){
                address = this.data.shop ? this.data.shop.address : ''
                if(this.data.partner_id > 0){
                    address = this.data.partner ? this.data.partner.partner_address : ''
                }else{
                    address = this.data.shop ? this.data.shop.address : ''
                }
            }else{
                address = this.data.shop_address ? this.data.shop_address : ''
                if(this.data.partner_name){
                    address = this.data.partner_address ? this.data.partner_address : ''
                }else{
                    address = this.data.shop_address ? this.data.shop_address : ''
                }
            }
            return address
        },
        phoneText(){
            var phone = ''
            if(this.isobject == true){
                if(this.data.partner_id > 0){
                    phone = this.data.partner ? this.data.partner.partner_phone_text : ''
                }else{
                    phone = this.data.shop ? this.data.shop.phone_text : ''
                }
            }else{
                if(this.data.partner_name){
                    phone = this.data.partner_phone_text ? this.data.partner_phone_text : ''
                }else{
                    phone = this.data.shop_phone_text ? this.data.shop_phone_text : ''
                }
            }
            return phone
        },
        websiteText(){
            var website = ''
            if(this.isobject == true){
                if(this.data.partner_id > 0){
                    website = this.data.partner ? this.data.partner.partner_website : ''
                }else{
                    website = this.data.shop ? this.data.shop.website : ''
                }
            }else{
                if(this.data.partner_name){
                    website = this.data.partner_website ? this.data.partner_website : ''
                }else{
                    website = this.data.shop_website ? this.data.shop_website : ''
                }
            }
            
            return website
        },
        facbookText(){
            var facbook = ''
            if(this.isobject == true){
                if(this.data.partner_id > 0){
                    facbook = this.data.partner ? this.data.partner.partner_facbook : ''
                }else{
                    facbook = this.data.shop ? this.data.shop.facbook : ''
                }
            }else{
                if(this.data.partner_name){
                    facbook = this.data.partner_facbook ? this.data.partner_facbook : ''
                }else{
                    facbook = this.data.shop_facbook ? this.data.shop_facbook : ''
                }
            }
            
            return facbook
        },
        MaVanDon(){
            var vandon = ''
            if(this.data.shipment_id){
                var myArray = this.data.shipment_id.split(".")
                vandon = myArray[myArray.length - 1]
            }
            return vandon
        }
    },
    methods: {
        
    }
}
</script>