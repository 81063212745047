<template>
    <div :id="id" style="visibility: hidden;">
        <div class="text-uppercase text-center"><strong>{{shopNameText}}</strong></div>
        <div class="text-uppercase text-center"><strong style="font-size: 12px;">CHẤT LƯỢNG – UY TÍN – GIÁ CẢ PHẢI CHĂNG</strong></div>
        <div class="text-center">{{addressText}}</div>    

        <table style="width:100%;">
        <tr style="border: 0.5px dotted black; border-collapse: collapse;" v-if="data.delivery_type == 0">
            <td colspan="2" class="text-center" style="padding-left: 10px; padding-right: 10px;">
                <barcode v-if="data.shipment_id" :value="MaVanDon" width=3 height=70>
                    Show this if the rendering fails.
                </barcode>
                <strong v-if="data.shipment_id">{{data.shipment_id}}</strong> 
            </td>
        </tr>
        <tr style="border: 0.5px dotted black; border-collapse: collapse;">
            <td colspan="2" style=" padding: 5px;">
                Người gửi: <span style="font-weight: bold;"> {{nameText}} ({{phoneText}})</span>
            </td>
        </tr>
        <tr style="border: 0.5px dotted black; border-collapse: collapse;">
            <td colspan="2" style=" padding: 5px;">
                Người nhận: <span style="font-weight: bold;">{{data.customer_name}} <span v-if="data.customer_nickname"> ({{data.customer_nickname}})</span></span>
                <br>
                ĐT: <span style="font-weight: bold;">{{data.customer_phone}}</span>
                <br>
                Địa chỉ: {{data.customer_address}}
            </td>
        </tr>
        <tr style="border: 0.5px dotted black; border-collapse: collapse;">
            <td style="width:60%; padding: 5px;">
                Mã: <span style="font-weight: bold;">{{data.id}}</span>
                <br>
                Ghi chú: {{data.note}}
            </td>
            <td style="width:40%; text-align: center; border: 0.5px dotted black; border-collapse: collapse;">
                <span style="font-weight: bold;">COD<br>{{parseFloat(data.total_payment)| numFormat}} ({{totalQuantity}} bộ)</span>
            </td>
        </tr>
        </table>

        <div style="font-size: 10px; text-align: center;"><i>Được xem hàng kiểm tra mẫu và số lượng. Trường hợp khách không ưng thanh toán phí 1 chiều.</i></div>
    </div>
</template>

<script>
import VueBarcode from 'vue-barcode';

export default {
    name: 'BillA7',
    components: {
        'barcode': VueBarcode
    },
    props: {
        id: {
            type: String,
            default: 'print_a7'
        },
        data: {
          type: Object
        },
        isobject: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            
        }
    },
    computed: {
        totalQuantity(){
            var totalQuantityTemp = 0
            if(this.isobject == true){
                if(typeof(this.data.outputvourcher_details) != 'undefined'){
                    for (var i = 0; i < this.data.outputvourcher_details.length; i++) {
                        if(this.data.outputvourcher_details[i].status != 0 ){
                            totalQuantityTemp = totalQuantityTemp + parseFloat(this.data.outputvourcher_details[i].quantity)
                        }
                    }
                }
            }else{
                totalQuantityTemp = this.data.total_quantity
            }
            
            
            return parseFloat(totalQuantityTemp)
        },
        nameText(){
            var name = ''
            if(this.isobject == true){
                if(this.data.partner_id > 0){
                    name = this.data.partner ? this.data.partner.partner_name.toUpperCase() : ''
                }else{
                    //name = 'HÀ THỊ HỒNG THÚY'
                    name = this.data.shop ? this.data.shop.shopmanagername.toUpperCase() : ''
                }
            }else{
                if(this.data.partner_name){
                    name = this.data.partner_name ? this.data.partner_name.toUpperCase() : ''
                }else{
                    //name = 'HÀ THỊ HỒNG THÚY'
                    name = this.data.shop_shopmanagername ? this.data.shop_shopmanagername.toUpperCase() : ''
                }
            }
            
            return name
        },
        shopNameText(){
            var name = ''
            if(this.isobject == true){
                if(this.data.partner_id > 0){
                    name = this.data.partner ? this.data.partner.partner_shop_name : ''
                }else{
                    name = this.data.shop ? this.data.shop.name:''
                }
            }else{
                if(this.data.partner_name){
                    name = this.data.partner_shop_name ? this.data.partner_shop_name : ''
                }else{
                    name = this.data.shop_name ? this.data.shop_name:''
                }
            }
            return name.toUpperCase()
        },
        addressText(){
            var address = ''
            
            if(this.isobject == true){
                address = this.data.shop ? this.data.shop.address : ''
                if(this.data.partner_id > 0){
                    address = this.data.partner ? this.data.partner.partner_address : ''
                }else{
                    address = this.data.shop ? this.data.shop.address : ''
                }
            }else{
                address = this.data.shop_address ? this.data.shop_address : ''
                if(this.data.partner_name){
                    address = this.data.partner_address ? this.data.partner_address : ''
                }else{
                    address = this.data.shop_address ? this.data.shop_address : ''
                }
            }
            return address
        },
        phoneText(){
            var phone = ''
            if(this.isobject == true){
                if(this.data.partner_id > 0){
                    phone = this.data.partner ? this.data.partner.partner_phone_text : ''
                }else{
                    phone = this.data.shop ? this.data.shop.phone_text : ''
                }
            }else{
                if(this.data.partner_name){
                    phone = this.data.partner_phone_text ? this.data.partner_phone_text : ''
                }else{
                    phone = this.data.shop_phone_text ? this.data.shop_phone_text : ''
                }
            }
            return phone
        },
        websiteText(){
            var website = ''
            if(this.isobject == true){
                if(this.data.partner_id > 0){
                    website = this.data.partner ? this.data.partner.partner_website : ''
                }else{
                    website = this.data.shop ? this.data.shop.website : ''
                }
            }else{
                if(this.data.partner_name){
                    website = this.data.partner_website ? this.data.partner_website : ''
                }else{
                    website = this.data.shop_website ? this.data.shop_website : ''
                }
            }
            
            return website
        },
        facbookText(){
            var facbook = ''
            if(this.isobject == true){
                if(this.data.partner_id > 0){
                    facbook = this.data.partner ? this.data.partner.partner_facbook : ''
                }else{
                    facbook = this.data.shop ? this.data.shop.facbook : ''
                }
            }else{
                if(this.data.partner_name){
                    facbook = this.data.partner_facbook ? this.data.partner_facbook : ''
                }else{
                    facbook = this.data.shop_facbook ? this.data.shop_facbook : ''
                }
            }
            
            return facbook
        },
        MaVanDon(){
            var vandon = ''
            if(this.data.shipment_id){
                var myArray = this.data.shipment_id.split(".")
                vandon = myArray[myArray.length - 1]
            }
            return vandon
        }
    },
    methods: {
        
    }
}
</script>