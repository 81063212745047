<template>
  <div>
     <form role="form" @submit.prevent="save">
        <input type="hidden" id="id" name="id" v-model="form.id">
        <div class="form-group" :class="{'has-error': formErrors.name}">
          <label class="control-label" for="name">Tên loại đồ</label>
          <input type="text" class="form-control" id="name" name="name" v-model="form.name">
          <span class="help-block" v-if="formErrors.name">{{formErrors.name[0]}}</span>
        </div>
        <div class="form-group">
          <label class="control-label" for="status">Trạng thái:</label>
          <switches v-model="form.status" name="status" id="status" type-bold="true" theme="bootstrap" category_model="success"></switches>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary btn-block">Cập nhật</button>
        </div>
     </form>
  </div>
</template>

<script>
  import axios from 'axios'
  import Switches from '../../components/Switches';
  import category_model from '../../objects/category_model'

  export default {
    name: 'formColor',
    components: {Switches},
    props: {
      clickSubmit: {
        type: Function,
        default: () => { }
      }
    },
    data() {
      return {
        formErrors: {},
        isEdit:false,
        form: {}
      }
    },
    methods: {
      open(id){
        this.formErrors = {}
        if(typeof id != 'undefined'){
          this.isEdit = true
          this.getColor(id)
        }
        else{
          this.isEdit = false
          this.form = Object.assign({}, category_model);
        }
      },
      save() {
        var vm = this
        if(!this.isEdit){
          axios['post']('/api/category_model/insert', this.form)
          .then(function (response) {
              vm.clickSubmit()
              vm.$snotify.success('Thêm mới thành công!', 'Success')
          })
          .catch(function (error) {
              vm.formErrors = error.response.data.errors
          })
        }
        else{
          axios['put']('/api/category_model/update', this.form)
          .then(function (response) {
              vm.clickSubmit()
              vm.$snotify.success('Cập nhật thành công!', 'Success')
          })
          .catch(function (error) {
              vm.formErrors = error.response.data.errors
          })
        }
        
      },
      getColor(id){
        var vm = this
        axios.get('/api/category_model/select/' + id)
            .then(function(response) {
                vm.form = response.data.data
            })
            .catch(function(error) {
                console.log(error)
            })
      }
    }
  }
</script>