<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-header -->
          <div class="box-header">
            <div class="row">
              <div class="col-xs-12 col-md-2">
                <div class="form-group">
                  <input type="text" class="form-control" 
                  v-model="params.keyword" 
                  v-on:keyup.enter="seach"
                  placeholder="Nhập từ khóa...">
                </div>
              </div>

              <div class="col-xs-12 col-md-2">
                <div class="form-group">
                  <Select2 :id="'shop_id'" 
                    :name="'shop_id'" 
                    :dataUrl="'/api/shop/all'"
                    :keyID="'id'"
                    :keyName="'name'"
                    v-model="params.shop_id"
                    :allowClear="true"
                    :placeholder="'Chọn shop'"
                    :multiple="false">
                  </Select2>
                </div>
              </div>

              <div class="col-xs-12 col-md-2">
                <div class="form-group">
                  <Select2 :id="'partner_id'" 
                    :name="'partner_id'" 
                    :dataUrl="'/api/ctv/partner/all'"
                    :keyID="'partner_id'"
                    :keyName="'partner_name'"
                    v-model="params.partner_id"
                    :allowClear="true"
                    :placeholder="'Chọn CTV'"
                    :multiple="true">
                  </Select2>
                </div>
              </div>

              <div class="col-xs-12 col-md-2">
                <div class="form-group">
                  <select v-model="params.delivery_type" 
                      id="delivery_type" 
                      name="delivery_type" 
                      class="form-control">
                      <option value="-1">Tất cả</option>
                      <option value="0">Đơn vị vận chuyển - GHTK</option>
                      <option value="5">Đơn vị vận chuyển - JTEXPRESS</option>
                      <option value="3">Đơn vị vận chuyển - Bưu điện</option>
                      <option value="4">Đơn vị vận chuyển - VNCPOST</option>
                      <option value="1">Shop giao</option>
                      <option value="2">Lấy tại shop</option>
                  </select>
                </div>
              </div>

              <div class="col-xs-12 col-md-2">
                <div class="form-group">
                  <Select2 :id="'payment_status'" 
                    :name="'payment_status'" 
                    :data="statusData"
                    v-model="params.payment_status"
                    :allowClear="true"
                    :placeholder="'Chọn trạng thái'"
                    :multiple="true">
                  </Select2>
                </div>
              </div>
              
              <div class="col-xs-12 col-md-2">
                <div class="form-group">
                  <button type="button" class="btn btn-default btn-flat" v-on:click="seach"><i class="fa fa-fw fa-search"></i> Tìm kiếm</button>
                </div>
              </div>
            </div>
          </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <data-viewer :thead="thead" :data="data">
              <template slot-scope="props" slot='outputvourcher_info'>
                <span class="text-bold">Shop: </span>{{props.item.shop.name}}
                <br>
                <span class="text-bold">CTV: </span>{{props.item.partner.partner_name}} - {{props.item.partner.partner_phone}}
                <br>
                <span class="text-bold">Mã đơn: </span>
                <router-link
                  :to="'/outputvourcher/show/'+props.item.id">
                  {{props.item.id}}
                </router-link>
                <br>
                <span class="text-bold">Ngày xuất: </span>{{props.item.output_date}}
                <br>
                <span class="text-bold">Đơn vị giao: </span>  
                <span v-if="props.item.delivery_type == 0">Đơn vị vận chuyển - GHTK</span> 
                <span v-else-if="props.item.delivery_type == 3">Đơn vị vận chuyển - Bưu điện</span> 
                <span v-else-if="props.item.delivery_type == 4">Đơn vị vận chuyển - VNCPOST</span> 
                <span v-else-if="props.item.delivery_type == 5">Đơn vị vận chuyển - JTEXPRESS</span> 
                <span v-else-if="props.item.delivery_type == 1">Shop giao</span> 
                <span v-else>Lấy tại shop</span> 
                <br>
                <span class="text-bold">Mã VĐ: </span>{{props.item.shipment_id}}
                <br>
                <span class="text-bold">Trạng thái: </span>
                <span class="label label-info" v-if="(props.item.delivery_type === 1 || props.item.delivery_type === 2) && props.item.status === 1">Đã giao tại shop</span>
                <span class="label label-info" v-if="props.item.delivery_type === 3 && props.item.status === 1">Đã gửi bưu điện</span>
                <span class="label label-info" v-if="(props.item.delivery_type === 0 || props.item.delivery_type === 4 || props.item.delivery_type === 5) && props.item.status === 1">{{props.item.shipment_status_text}}</span>
                <!-- <span class="label label-info" v-if="props.item.delivery_type === 5 && props.item.status === 1">Đã gửi JTEXPRESS</span> -->
                <button type="button" 
                  class="btn btn-default btn-flat" 
                  @click="updateStatusShipment(props.item.id, props.item.shipment_status)"
                  v-if="props.item.shipment_id && props.item.delivery_type === 0"><i class="glyphicon glyphicon-refresh"></i>
                </button>
                <br>
                <span class="text-bold">Trạng thái giao: </span>  
                <span class="label label-success" v-if="props.item.payment_status === 1">Đã giao thành công</span>
                <span class="label label-default" v-else-if="props.item.payment_status === 0">Chưa giao</span>
                <span class="label label-warning" v-else-if="props.item.payment_status === 2">Giao 1 phần</span>
                <span class="label label-danger" v-else-if="props.item.payment_status === 3">Trả về</span>
                <span class="label label-danger" v-else>Không xác định</span> 
                <br>
                <span class="text-bold">Tên KH: </span>{{props.item.customer_name}}
                <br>
                <span class="text-bold">ĐT KH: </span>{{props.item.customer_phone}}
                <br>
                <span class="text-bold">ĐC KH: </span>{{props.item.customer_address}}
                <br>
                <span class="text-bold">Ghi chú: </span><span class="text-bold  text-red">{{props.item.note}}</span>
                <br>
                <span class="text-bold">Tiền thu khách: </span>{{parseFloat(props.item.total_payment) | numFormat}}
                <br>
                <span class="text-bold">Đơn vị giao thu: </span>{{parseFloat(props.item.shipment_pick_money) | numFormat}}
              </template>

              <template slot-scope="props" slot='outputvourcher_payment_system'>
                <span class="text-bold">Tổng SL: </span>{{props.item.outputvourcher_payment_system.payment_total_count}}
                <br>
                <span class="text-bold">Tổng SL xác nhận: </span>{{props.item.outputvourcher_payment_system.payment_total_countconfirm}}
                <br>
                <span class="text-bold">Tổng tiền thu khách: </span>{{parseFloat(props.item.outputvourcher_payment_system.payment_total_money) | numFormat}}
                <br>
                <span class="text-bold">Tổng tiền hàng: </span>{{parseFloat(props.item.outputvourcher_payment_system.payment_total_cost) | numFormat}}
                <br>
                <span class="text-bold">Ship gửi: </span>{{parseFloat(props.item.outputvourcher_payment_system.payment_fee) | numFormat}}
                <br>
                <span class="text-bold">Ship hoàn: </span>{{parseFloat(props.item.outputvourcher_payment_system.payment_fee_return) | numFormat}}
                <br>
                <span class="text-bold">Chiết khấu giới thiệu: </span>{{parseFloat(props.item.outputvourcher_payment_system.payment_broker_discount) | numFormat}}
                <br>
                <span class="text-bold">Tiền thực tế CTV: </span>{{parseFloat(props.item.outputvourcher_payment_system.payment_total_money - props.item.outputvourcher_payment_system.payment_total_cost - props.item.outputvourcher_payment_system.payment_fee - props.item.outputvourcher_payment_system.payment_fee_return) | numFormat}}
                <br>
                <!--<button type="button" @click="updateConfirm(props.item.id)" :disabled="enableButtonSave" class="btn btn-info btn-block">Đã đối soát</button>-->
              </template>

              <template slot-scope="props" slot='outputvourcher_payment'>
                <div class="col-md-4 col-xs-12">
                    <div class="form-group">
                        <label class="control-label" for="payment_note">Ghi chú</label>
                        <textarea class="form-control" rows="1" 
                            id="payment_note" 
                            name="payment_note"
                            v-model="props.item.outputvourcher_payment.payment_note"
                            placeholder="Nhập ghi chú thêm">
                        </textarea>
                    </div>
                </div>

                <div class="col-md-4 col-xs-12">
                    <div class="form-group">
                        <label class="control-label" for="payment_confirm_date">Ngày xác nhận</label>
                        <date-picker 
                          class="form-control"
                          :config="configDate" 
                          id="payment_confirm_date" 
                          name="payment_confirm_date"
                          v-model="props.item.outputvourcher_payment.payment_confirm_date">
                        </date-picker>
                    </div>
                </div>

                <div class="col-md-4 col-xs-12">
                    <div class="form-group">
                        <label class="control-label" for="payment_status">Trạng thái</label>
                        <select v-model="props.item.outputvourcher_payment.payment_status" 
                            id="payment_status" 
                            name="payment_status" 
                            @change="onChangeStatus(props.item.id, props.item.outputvourcher_payment.payment_status)"
                            class="form-control">
                            <option v-for="option in statusData" :key="option.id" v-bind:value="option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-md-4 col-xs-12">
                  <div class="form-group">
                    <label class="control-label">Tổng SL xác nhận:</label>
                    <input type="number" class="form-control"
                        id="payment_total_countconfirm" 
                        name="payment_total_countconfirm"
                        v-model="props.item.outputvourcher_payment.payment_total_countconfirm">
                  </div>
                </div>

                <div class="col-md-4 col-xs-12">
                  <div class="form-group">
                    <label class="control-label">Tổng tiền thu khách:</label>
                    <input type="number" class="form-control"
                        id="payment_total_money" 
                        name="payment_total_money"
                        v-model="props.item.outputvourcher_payment.payment_total_money">
                  </div>
                </div>

                <div class="col-md-4 col-xs-12">
                  <div class="form-group">
                    <label class="control-label">Tổng tiền hàng:</label>
                    <input type="number" class="form-control"
                        id="payment_total_cost" 
                        name="payment_total_cost"
                        v-model="props.item.outputvourcher_payment.payment_total_cost">
                  </div>
                </div>

                <div class="col-md-4 col-xs-12">
                  <div class="form-group">
                    <label class="control-label">Ship gửi:</label>
                    <input type="number" class="form-control"
                        id="payment_fee" 
                        name="payment_fee"
                        v-model="props.item.outputvourcher_payment.payment_fee">
                  </div>
                </div>
                
                <div class="col-md-4 col-xs-12">
                  <div class="form-group">
                    <label class="control-label">Ship hoàn:</label>
                    <input type="number" class="form-control"
                        id="payment_fee_return" 
                        name="payment_fee_return"
                        v-model="props.item.outputvourcher_payment.payment_fee_return">
                  </div>
                </div>

                <div class="col-md-4 col-xs-12">
                  <div class="form-group">
                    <label class="control-label">Chiết khấu giới thiệu:</label>
                    <input type="number" class="form-control"
                        id="payment_broker_discount" 
                        name="payment_broker_discount"
                        v-model="props.item.outputvourcher_payment.payment_broker_discount">
                  </div>
                </div>

                <div class="col-md-4 col-xs-6">
                  <span class="text-bold">Tiền thực tế CTV: </span><span class="text-bold text-red">{{parseFloat(props.item.outputvourcher_payment.payment_total_money - props.item.outputvourcher_payment.payment_total_cost - props.item.outputvourcher_payment.payment_fee - props.item.outputvourcher_payment.payment_fee_return) | numFormat}}</span>
                </div>

                <div class="col-md-8 col-xs-6">
                  <button type="button" @click="saveSuccess(props.item.outputvourcher_payment)" :disabled="enableButtonSave" class="btn btn-primary btn-block">Xác nhận</button>
                </div>
              </template>
            </data-viewer>

            <div class="row">
              <div class="col-xs-12 col-md-12">
                <span class="text-bold">Tổng SL đơn: {{total_count}}</span>
              </div>
            </div>
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import DataViewer from '../../../components/DataViewer'
import Select2 from '../../../components/Select2New.vue'

import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'
import datePicker from 'vue-bootstrap-datetimepicker'

export default {
    name: 'OutputvourcherPaymentCalIndex',
    components: {
      VuejsDialog, DataViewer, Select2, datePicker
    },
    data() {
        return {
          title: 'Xác nhận đơn hàng CTV',
          enableButtonSave: false,
          data:[],
          params: {
              keyword: '',
              shop_id: '',
              partner_id: '',
              payment_status:'',
              delivery_type:  -1
          },
          configDate: {
            format: 'DD/MM/YYYY',
            useCurrent: true,
            showClear: false,
            showClose: true,
            allowInputToggle: true,
          },
          statusData:[
            {
              id: 0,
              name: 'Chưa giao'
            },
            {
              id: 1,
              name: 'Đã giao thành công'
            },
            {
              id: 2,
              name: 'Giao 1 phần'
            },
            {
              id: 3,
              name: 'Trả về'
            }
          ],
          thead: [
            {
              key: 'outputvourcher_info',
              title: 'Thông tin đơn',
              class: 'col-xs-12 col-md-4'
            },
            {
              key: 'outputvourcher_payment_system',
              title: 'Hệ thống tính',
              class: 'col-xs-12 col-md-2'
            },
            {
              key: 'outputvourcher_payment',
              title: 'Thông tin xác nhận',
              class: 'col-xs-12 col-md-6'
            }
          ]
        }
    },
    computed: {
      total_count: function () {
        var countRow = 0
        if(this.data){
          countRow = this.data.length
        }
        return countRow
      }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
        //this.seach();
    },
    methods: {
      seach() {
        NProgress.start()
        var vm = this
        axios.get('/api/ctv/outputvourcherpayment/seach_cal'+ '?' + $.param(this.params))
            .then(function(response) {
                NProgress.done()
                vm.data = response.data.data
                for (var i = 0; i < vm.data.length; i++) {
                  vm.data[i].outputvourcher_payment.payment_confirm_date = vm.moment(vm.data[i].outputvourcher_payment.payment_confirm_date).format('DD/MM/YYYY')
                }
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
      saveSuccess(data) {
        var $this = this
        if(data.payment_total_countconfirm == 0 || data.payment_total_cost == 0){
          $this.$dialog.confirm('Số lượng xác nhận hoặc tiền hàng = 0 bạn có muốn lưu?', 
          {
            loader: true,
            okText: 'Đồng ý lưu',
            cancelText: 'Hủy bỏ'
          })
          .then((dialog) => {
              $this.saveSuccess_save(data)
              dialog.close()
          });
        }else{
          $this.saveSuccess_save(data)
        }
      },
      saveSuccess_save(data)
      {
        NProgress.start()
        this.enableButtonSave = true
        let vm = this

        axios['post']('/api/ctv/outputvourcherpayment/insert', data)
            .then(function (response) {
                var result = response.data
                NProgress.done()
                if(result.success==true){
                  vm.$snotify.success('Cập nhật thành công!', 'Success')
                }
                else{
                    vm.$snotify.error(result.message, 'Lỗi')
                }
                vm.enableButtonSave = false
                vm.seach()
            })
            .catch(function (error) {
                NProgress.done()
                vm.$snotify.error('Cập nhật thất bại, vui lòng kiểm tra lại!', 'Error')
                vm.enableButtonSave = false
                console.log(error)
            })
      },
      updateConfirm(id) {
        var $this = this
        this.$dialog.confirm('Bạn có xác nhận đã đối soát?', {
            loader: true,
            okText: 'Đồng ý',
            cancelText: 'Hủy bỏ'
        })
            .then((dialog) => {
                $this.updateConfirmOK(id)
                dialog.close()
            });
      },
      updateConfirmOK(id)
      {
        this.enableButtonSave = true
        var vm = this
        axios['put']('/api/ctv/outputvourcherpayment/update_confirm/'+id)
        .then(function (response) {
            var result = response.data
            if(result.success==false){
              vm.$snotify.error(result.message, 'Lỗi')
            }else{
              vm.$snotify.success(result.message, 'Success')
            }
            vm.enableButtonSave = false
            vm.seach()
        })
        .catch(function (error) {
          vm.$snotify.error('Xác nhận đối soát vui lòng kiểm tra và thử lại', 'Lỗi')
          vm.enableButtonSave = false
          console.log(error)
        })
      },
      onChangeStatus(outputvourcher_id, payment_status) {
        var vm = this
        var paramsTempp = {
              'outputvourcher_id': outputvourcher_id,
              'payment_status': payment_status
          }
        axios.get('/api/ctv/outputvourcherpayment/cal'+ '?' + $.param(paramsTempp))
            .then(function(response) {
                var dataResultTempp
                dataResultTempp = response.data.data
                dataResultTempp.payment_confirm_date = vm.moment(dataResultTempp.payment_confirm_date).format('DD/MM/YYYY')
                var dataTemp = vm.data.find(o => o.id == dataResultTempp.outputvourcher_id)      
                if(dataTemp != null){
                    dataTemp.outputvourcher_payment = dataResultTempp
                }  
            })
            .catch(function(error) {
                console.log(error)
            })
      },
      
      updateStatusShipment(id, statusold) {
          var vm = this
          axios['post']('/api/logistics/shipment/ghtk/status/'+id)
          .then(function (response) {
              var result = response.data
              if(result.success==true){
                var statusnew = result.data.status
                vm.$snotify.success('Cập nhật trạng thái thành công!', 'Success')
                if(statusold!=statusnew){
                  vm.seach()
                }
              }else{
                  vm.$snotify.error(result.message, 'Lỗi cập nhật')
              }
          })
          .catch(function (error) {
              vm.$snotify.error('Không xử lý được yêu cầu cập nhật', 'Lỗi cập nhật')
              console.log(error)
          })
      }
    }
}
</script>