<template>
  <div>
     <form role="form" @submit.prevent="save" name="formShop">
        <input type="hidden" id="id" name="id" v-model="form.id">
        <div class="form-group" :class="{'has-error': formErrors.name}">
          <label class="control-label" for="name">Tên cữa hàng</label>
          <input type="text" class="form-control" id="name" name="name" v-model="form.name">
          <span class="help-block" v-if="formErrors.name">{{formErrors.name[0]}}</span>
        </div>
        <div class="form-group" :class="{'has-error': formErrors.shopmanagername}">
          <label class="control-label" for="name">Tên người quản lý</label>
          <input type="text" class="form-control" id="shopmanagername" name="shopmanagername" v-model="form.shopmanagername">
          <span class="help-block" v-if="formErrors.shopmanagername">{{formErrors.shopmanagername[0]}}</span>
        </div>
        <div class="form-group" :class="{'has-error': formErrors.phone}">
          <label class="control-label" for="phone">Điện thoại chính</label>
          <input type="text" class="form-control" id="phone" name="phone" v-model="form.phone">
          <span class="help-block" v-if="formErrors.phone">{{formErrors.phone[0]}}</span>
        </div>
        <div class="form-group" :class="{'has-error': formErrors.phone_text}">
          <label class="control-label" for="phone_text">Danh sách điện thoại</label>
          <input type="text" class="form-control" id="phone_text" name="phone_text" v-model="form.phone_text">
          <span class="help-block" v-if="formErrors.phone_text">{{formErrors.phone_text[0]}}</span>
        </div>
        <div class="form-group" :class="{'has-error': formErrors.website}">
          <label class="control-label" for="website">Website</label>
          <input type="text" class="form-control" id="website" name="website" v-model="form.website">
          <span class="help-block" v-if="formErrors.website">{{formErrors.website[0]}}</span>
        </div>
        <div class="form-group" :class="{'has-error': formErrors.facbook}">
          <label class="control-label" for="facbook">Facbook</label>
          <input type="text" class="form-control" id="facbook" name="facbook" v-model="form.facbook">
          <span class="help-block" v-if="formErrors.facbook">{{formErrors.facbook[0]}}</span>
        </div>
        <div class="form-group">
          <label class="control-label" for="name">Địa chỉ</label>
          <textarea class="form-control" rows="3" id="address" name="address" v-model="form.address"></textarea>
        </div>
        <div class="form-group">
          <label class="control-label" for="status">Trạng thái:</label>
          <switches v-model="form.status" name="status" id="status" type-bold="true" theme="bootstrap" shop="success"></switches>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary btn-block">Cập nhật</button>
        </div>
     </form>
  </div>
</template>

<script>
  import axios from 'axios'
  import Switches from '../../components/Switches';
  import shop from '../../objects/shop'

  export default {
    name: 'formShop',
    components: {Switches},
    props: {
      clickSubmit: {
        type: Function,
        default: () => { }
      }
    },
    data() {
      return {
        formErrors: {},
        isEdit:false,
        form: {}
      }
    },
    methods: {
      open(id){
        this.formErrors = {}
        if(typeof id != 'undefined'){
          this.isEdit = true
          this.getShop(id)
        }
        else{
          this.isEdit = false
          this.form = Object.assign({}, shop);
        }
      },
      save() {
        let vm = this,
        form = document.forms.namedItem("formShop"),
        data = new FormData(form),
        header = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};

        if(!this.isEdit){
          axios['post']('/api/shop/insert', data, header)
          .then(function (response) {
              vm.clickSubmit()
              vm.$snotify.success('Thêm mới thành công!', 'Success')
          })
          .catch(function (error) {
              vm.formErrors = error.response.data.errors
          })
        }
        else{
          data.append('_method', 'PUT')
          axios['post']('/api/shop/update', data, header)
          .then(function (response) {
              vm.clickSubmit()
              vm.$snotify.success('Cập nhật thành công!', 'Success')
          })
          .catch(function (error) {
              vm.formErrors = error.response.data.errors
          })
        }
        
      },
      getShop(id){
        var vm = this
        axios.get('/api/shop/select/' + id)
            .then(function(response) {
                vm.form = response.data.data
            })
            .catch(function(error) {
                console.log(error)
            })
      }
    }
  }
</script>