<template>
    <div class="row">
        <div class="col-md-3 col-xs-4" v-for="(item, index) in uploadData" :key="item[keyID]">
            <div class="thumbnail">
                <img :src="item[keyUrlFull]" class="imagelist">
                <div class="caption">
                    <button type="button" 
                        class="btn-xs btn-danger btn-block"
                        v-on:click="deleteImage(item, index)">
                        {{deleteText}}
                    </button>
                </div>
            </div>
        </div>
        <!-- /.col -->
        <div class="col-md-3 col-xs-4">
            <input type="file" id="files" 
                style="display:none" 
                ref="files"
                multiple 
                v-on:change="handleFileUploads()">
            <a class="btn btn-app" v-on:click="addImage()">
                <i class="fa fa-cloud-upload"></i> Thêm mới
            </a>
        </div>
        <!-- /.col -->
    </div>
    <!-- /.row -->
</template>

<script>
import axios from 'axios'
import NProgress from 'nprogress'

    export default {
        name: 'UploadMultipleImage',
        props: {
            value: {
                type: Array,
                default: () => []
            },
            deleteText: {
                type: String,
                default: 'Xóa'
            },
            keyID: {
                type: String,
                default: 'id'
            },
            keyUrl: {
                type: String,
                default: 'url'
            },
            keyUrlFull: {
                type: String,
                default: 'urlfull'
            }
        },
        data() {
            return {
                uploadData: [...this.value],
                files: ''
            }
        },
        watch: {
            value() {
                this.uploadData = [...this.value];
            }
        },
        methods: {
            deleteImage(item, index){
                this.uploadData.splice(index,1)
                this.$emit('input', this.uploadData)
                this.$emit('deleteImage', item)
            },
            addImage(){
                $('#files').click()
            },
            handleFileUploads() {
                this.files = this.$refs.files.files;
                this.save()
            },
            save() {
                NProgress.start()
                let vm = this,
                data = new FormData(),
                header = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
                for( var i = 0; i < this.files.length; i++ ){
                    let file = this.files[i];
                    data.append('files[' + i + ']', file);
                }
                axios['post']('/api/upload/imagelist', data, header)
                .then(function (response) {
                    for (var i = 0; i < response.data.data.length; i++) {
                        var newItem = {};
                        newItem[vm.keyID] = response.data.data[i].id
                        newItem[vm.keyUrl] = response.data.data[i].url
                        newItem[vm.keyUrlFull] = response.data.data[i].urlfull
                        vm.uploadData.push(newItem)
                    }
                    vm.$emit('input', vm.uploadData)
                    vm.$emit('addImage', response.data.data)
                    NProgress.done()
                    //vm.$snotify.success('Upload hình ảnh thành công!', 'Success')
                })
                .catch(function (error) {
                    NProgress.done()
                    console.log(error)
                    vm.$snotify.success('Upload hình ảnh thất bại!', 'Success')
                })
            },
        }
    }
</script>
<style lang="css" scoped>
.imagelist{
        height: 115px;
    }
@media (max-width:767px){
    .imagelist{
        height: 70px;
    }
}
</style>