<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-header -->
          <div class="box-header">
            <div class="row">
              <div class="col-xs-12 col-md-12">
                <div class="input-group">
                  <input type="text" class="form-control" v-model="params.keyword" v-on:keyup.enter="seach">
                  <span class="input-group-btn">
                    <button type="button" class="btn btn-default btn-flat" v-on:click="seach"><i class="fa fa-fw fa-search"></i> Tìm kiếm</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12" v-for="item in data" :key="item.id">
                    <!-- info-box -->
                    <div class="info-box pull-left">
                        <span class="info-box-icon">
                            <v-lazy-image :src="item.product.image.link_image" />
                        </span>

                        <div class="info-box-content row">
                                <div class="col-md-12 col-xs-12">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span class="info-box-text text-green">
                                                {{item.product.id + ' - ' + item.product.name}} 
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-xs-6">
                                            <span class="text-bold">Điện thoại:</span>  
                                            <span>{{item.customer_phone}}</span>  
                                        </div>

                                        <div class="col-md-8 col-xs-6">
                                            <span class="text-bold">Khách hàng: </span>  
                                            <span>{{item.customer_name}}</span> 
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-xs-6">
                                            <span class="text-bold">Nhân viên:</span>  
                                            <span>{{item.created_user.name}}</span>  
                                        </div>
                                        <div class="col-md-2 col-xs-6">
                                            <span class="text-bold">Số lượng: </span>  
                                            <span class="text-bold text-red">{{parseFloat(item.quantity)}}</span> 
                                        </div>
                                        <div class="col-md-4 col-xs-6">
                                            <span class="text-bold">Ngày tạo:</span>  
                                            <span>{{moment(item.created_at).format('DD/MM/YYYY hh:mm')}}</span>  
                                        </div>
                                        <div class="col-md-2 col-xs-6">
                                            <a class="btn btn-danger btn-block"  
                                                v-on:click="remove(item.id)">
                                                <i class="fa fa-fw fa-trash"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
            </div>
                
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import NProgress from 'nprogress'
import VLazyImage from "v-lazy-image";
export default {
    name: 'KeepIndex',
    components: {
        VLazyImage
    },
    data() {
        return {
            title: 'Quản lý giữ hàng',
            data:[],
            params: {
                keyword: ''
            },
        }
    },
    mounted(){
        NProgress.start()
        this.title = this.title
        this.$store.dispatch('setPageTitle', this.title)
        this.seach();
        NProgress.done()
    },
    methods: {
        seach() {
            NProgress.start()
            var vm = this
            vm.data = []
            axios.get('/api/keep/seach'+ '?' + $.param(this.params))
                .then(function(response) {
                    NProgress.done()
                    vm.data = response.data.data
                })
                .catch(function(error) {
                    console.log(error)
                    NProgress.done()
                })
        },
        remove(id) {
            var $this = this
            this.$dialog.confirm('Bạn có muốn xóa?', {
                loader: true,
                okText: 'Đồng ý',
                cancelText: 'Hủy bỏ'
            })
                .then((dialog) => {
                    $this.del(id)
                    dialog.close()
                });
        },
        del(id){
            var vm = this
            axios['put']('/api/keep/delete/'+id)
            .then(function (response) {
                vm.$snotify.success('Xóa thành công!', 'Success')
                vm.seach()
            })
            .catch(function (error) {
                console.log(error)
            })
        },
    }
}
</script>
<style lang="css" scoped>
.info-box-icon>img {
    width: 100%;
    max-height: 90px;
    vertical-align: unset;
}
.info-box-content{
    padding: 0px;
}
.info-box-text{
    white-space: unset;
}
.info-box {
    padding-bottom: 10px;
}
</style>
