<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-header -->
          <div class="box-header">
            <div class="row">
              <div class="col-xs-6 col-md-2">
                <div class="form-group">
                  <date-picker 
                    class="form-control"
                    :config="configDate" 
                    v-model="params.from_date">
                  </date-picker>
                </div>
              </div>
              <div class="col-xs-6 col-md-2">
                <div class="form-group">
                  <date-picker 
                    class="form-control"
                    :config="configDate" 
                    v-model="params.to_date">
                  </date-picker>
                </div>
              </div>
              <div class="col-xs-12 col-md-4">
                <div class="form-group">
                  <input type="text" class="form-control" v-model="params.keyword" v-on:keyup.enter="seach" placeholder="Nhập từ khóa...">
                </div>
              </div>
              <div class="col-xs-12 col-md-4">
                <div class="form-group">
                  <Select2 :id="'output_type_id'" 
                    :name="'output_type_id'" 
                    :dataUrl="'/api/output_type/all'" 
                    v-model="params.output_type_id"
                    :multiple="true">
                  </Select2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-md-4">
                <div class="form-group">
                  <Select2 :id="'status'" 
                    :name="'status'" 
                    :data="statusData" 
                    v-model="params.status"
                    :multiple="true">
                  </Select2>
                </div>
              </div>
              <div class="col-xs-12 col-md-2">
                <div class="form-group">
                  <button type="button" class="btn btn-default btn-block btn-flat" v-on:click="seach"><i class="fa fa-fw fa-search"></i> Tìm kiếm</button>
                </div>
              </div>
            </div>
          </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <data-viewer :thead="thead" :data="data">
              <template slot-scope="props" slot='status'>
                <span class="label label-success" v-if="props.item.status === 1">Hoàn tất</span>
                <span class="label label-default" v-else-if="props.item.status === 2">Chưa chốt</span>
                <span class="label label-primary" v-else-if="props.item.status === 3">Đã chốt</span>
                <span class="label label-info" v-else-if="props.item.status === 4">Đang soạn</span>
                <span class="label label-warning" v-else-if="props.item.status === 5">Đang soạn - chờ hàng</span>
                <span class="label label-primary" v-else-if="props.item.status === 6">Soạn xong</span>
                <span class="label label-danger" v-else>Đã hủy</span>
              </template>
              <template slot-scope="props" slot='id'>
                <span class="text-bold hidden-lg">Mã phiếu: </span>
                <router-link :to="'/outputvourcher/sale/edit/'+props.item.id">
                  {{props.item.id}}
                </router-link>
              </template>
              <template slot-scope="props" slot='output_date'>
                <span class="text-bold hidden-lg">Ngày tạo: </span>{{moment(props.item.output_date).format('DD/MM/YYYY hh:mm')}}
              </template>
              <template slot-scope="props" slot='customer_name'>
                <span class="text-bold hidden-lg">Khách hàng: </span>{{props.item.customer_name}}
              </template>
              <template slot-scope="props" slot='customer_phone'>
                <span class="text-bold hidden-lg">Điện thoại: </span>{{props.item.customer_phone}}
              </template>
              <template slot-scope="props" slot='output_type.name'>
                <span class="text-bold hidden-lg">Hình thức: </span>{{props.item.output_type.name}}
              </template>
              <template slot-scope="props"  slot='control'>
                <router-link class="btn btn-xs btn-primary btn-block btn-flat" v-if="props.item.output_type_id!=OUPUT_TYPE_INV" 
                  :to="'/outputvourcher/show/'+props.item.id">
                  Xem
                </router-link>
              </template>
            </data-viewer>
            <div class="row">
              <div class="col-xs-6 col-md-3">
                <span class="text-bold">Tổng đơn: {{total_count}}</span>
              </div>
              <div class="col-xs-6 col-md-3">
                <span class="text-bold">Tổng tiền hàng: {{total_amount_sum | numFormat}}</span>
              </div>
              <div class="col-xs-6 col-md-3">
                <span class="text-bold">Tổng vận chuyển: {{total_shipping_cost_sum | numFormat}}</span>
              </div>
              <div class="col-xs-6 col-md-3">
                <span class="text-bold">Tổng thu khách: {{total_payment_sum | numFormat}}</span>
              </div>
            </div>
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import Select2 from '../../../components/Select2.vue'
import DataViewer from '../../../components/DataViewer'

import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'
import datePicker from 'vue-bootstrap-datetimepicker'

export default {
    name: 'OutputVourcherIndex',
    components: {
       VuejsDialog, DataViewer, Select2, datePicker
    },
    data() {
        return {
          title: 'Danh sách phiếu xuất',
          OUPUT_TYPE_INV: 0,
          data:[],
          params: {
              keyword: '',
              output_type_id: '',
              status: '',
              from_date: '',
              to_date: ''
          },
          configDate: {
            format: 'DD/MM/YYYY',
            useCurrent: false,
            showClear: false,
            showClose: true,
            allowInputToggle: true,
          },
          statusData:[
            {
              id: 1,
              name: 'Hoàn tất'
            },
            {
              id: 2,
              name: 'Chưa chốt'
            },
            {
              id: 3,
              name: 'Đã chốt'
            },
            {
              id: 4,
              name: 'Đang soạn'
            },
            {
              id: 5,
              name: 'Đang soạn - chờ hàng'
            },
            {
              id: 6,
              name: 'Soạn xong'
            },
          ],
          thead: [
            {
              key: 'status',
              title: 'Trạng thái đơn',
              class: 'col-xs-6 col-md-2'
            },
            {
              key: 'id',
              title: 'Mã phiếu xuất',
              class: 'col-xs-6 col-md-2'
            },
            {
              key: 'output_date',
              title: 'Ngày xuất',
              class: 'col-xs-12 col-md-2'
            },
            {
              key: 'customer_name',
              title: 'Khách hàng',
              class: 'col-xs-6 col-md-2'
            },
            {
              key: 'customer_phone',
              title: 'Điện thoại',
              class: 'col-xs-6 col-md-1'
            },
            {
              key: 'output_type.name',
              title: 'Hình thức',
              class: 'col-xs-12 col-md-2'
            },
            {
              key: 'control',
              title: 'Xử lý',
              class: 'col-xs-12 col-md-1'
            }
          ]
        }
    },
    computed: {
      total_count: function () {
        var countRow = 0
        if(this.data){
          countRow = this.data.length
        }
        return countRow
      },
      total_amount_sum: function () {
        var amount_sum = 0
        if(this.data){
          for (var i = 0; i < this.data.length; i++) {
            amount_sum += parseFloat(this.data[i].total_amount)
          }
        }
        return amount_sum
      },
      total_shipping_cost_sum: function () {
        var shipping_cost_sum = 0
        if(this.data){
          for (var i = 0; i < this.data.length; i++) {
            shipping_cost_sum += parseFloat(this.data[i].shipping_cost)
          }
        }
        return shipping_cost_sum
      },
      total_payment_sum: function () {
        var payment_sum = 0
        if(this.data){
          for (var i = 0; i < this.data.length; i++) {
            payment_sum += parseFloat(this.data[i].total_payment)
          }
        }
        return payment_sum
      }
    },
    mounted(){
        this.$store.dispatch('setPageTitle', this.title);
        this.params.from_date = this.moment(new Date()).format('DD/MM/YYYY')
        this.params.to_date = this.moment(new Date()).format('DD/MM/YYYY')
        this.seach();
        this.getConfig()
    },
    methods: {
      seach() {
        NProgress.start()
        var vm = this
        axios.get('/api/inventory/output/seach'+ '?' + $.param(this.params))
            .then(function(response) {
                NProgress.done()
                vm.data = response.data.data
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
      getConfig(){
        var vm = this
        axios.get('/api/config/select/OUPUT_TYPE_INV')
            .then(function(response) {
                vm.OUPUT_TYPE_INV = response.data.data.value
            })
            .catch(function(error) {
                console.log(error)
            })
      }
    }
}
</script>