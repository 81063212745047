<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-header -->
          <div class="box-header">
            <div class="row">
              <div class="col-xs-6 col-md-4">
                <span class="text-bold">Shop: </span>{{data.paymentvourcher_partner.partner_shop.name}}
              </div>

              <div class="col-xs-6 col-md-4">
                <span class="text-bold">Tên CTV: </span>{{data.paymentvourcher_partner.partner_name}}
              </div>

              <div class="col-xs-6 col-md-4">
                <span class="text-bold">Tên shop CTV: </span>{{data.paymentvourcher_partner.partner_shop_name}}
              </div>

              <div class="col-xs-6 col-md-4">
                <span class="text-bold">Điện thoại: </span>{{data.paymentvourcher_partner.partner_phone}}
              </div>

              <div class="col-xs-6 col-md-4">
                <span class="text-bold">Địa chỉ: </span>{{data.paymentvourcher_partner.partner_address}}
              </div>

              <div class="col-xs-6 col-md-4">
                <span class="text-bold">Thông tin tài khoản: </span>{{data.paymentvourcher_partner.partner_description_pay}}
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 col-md-12">
                <span class="text-red">THÔNG TIN THANH TOÁN</span>
              </div>
            </div>
            
            <div class="row">
              <div class="col-xs-12 col-md-12" v-if="data.paymentvourcher_note">
                  <span class="text-bold">Ghi chú: </span><span class="text-bold text-red">{{data.paymentvourcher_note}}</span>
              </div>

              <div class="col-xs-12 col-md-4">
                <span class="text-bold">Thanh toán đến ngày: </span>{{this.moment(data.paymentvourcher_date).format('DD/MM/YYYY')}}
              </div>

              <div class="col-xs-12 col-md-4">
                  <span class="text-bold text-red">Chiết khấu giới thiệu: {{parseFloat(total_broker_discount) | numFormat}} </span>
              </div>

              <div class="col-xs-12 col-md-4">
                <span class="text-bold text-red">Tiền chi CTV: {{parseFloat(data.paymentvourcher_total_money) | numFormat}} </span>
              </div>

              <div class="col-xs-12 col-md-4">
                <span class="text-bold">Tiền hệ thống tính: </span>{{parseFloat(data.paymentvourcher_total_amount) | numFormat}}
              </div>

              <div class="col-xs-6 col-md-4">
                <span class="text-bold">Ngày tạo: </span>{{this.moment(data.created_at).format('DD/MM/YYYY HH:mm')}}
              </div>

              <div class="col-xs-6 col-md-4">
                <span class="text-bold">Tổng số đơn: </span>{{data.paymentvourcher_total_count}}
              </div>

              <div class="col-xs-6 col-md-4">
                <span class="label label-success" v-if="data.paymentvourcher_status === 1">Đã thanh toán</span>
                <span class="label label-info" v-else-if="data.paymentvourcher_status === 2">Chưa thanh toán</span>
                <span class="label label-warning" v-else>Không xác định</span>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 col-md-12">
                <button type="button" @click="goBack()" class="btn btn btn-default">Quay lại</button>
                <button type="button" @click="confirmPayment()" class="btn btn btn-primary">Đã thanh toán</button>

                <a type="button" :href="'/api/ctv/paymentvourcher/export_excel/'+id">
                  <button class="btn btn btn-default">Xuất excel</button>
                </a>

                 <a type="button" :href="'/api/ctv/paymentvourcher/export_excel_all/'+id">
                  <button class="btn btn btn-default">Xuất excel đối soát CTV</button>
                </a>

              </div>
            </div>

          </div>
          <!-- /.box-header -->
          <!-- box-body -->
          <div class="box-body">
            <data-viewer :thead="thead" :data="data.paymentvourcher_details">
              <template slot-scope="props" slot='outputvourcher_info'>
                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Mã đơn: </span>
                  <router-link
                    :to="'/outputvourcher/show/'+props.item.outputvourcher.id">
                    {{props.item.outputvourcher.id}}
                  </router-link>
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Ngày tạo: </span>{{props.item.outputvourcher.created_at}}
                </div>
                
                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Mã VĐ: </span>{{props.item.outputvourcher.shipment_id}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Trạng thái: </span>
                  <span class="label label-info" v-if="(props.item.outputvourcher.delivery_type === 1 && props.item.outputvourcher.delivery_type === 2) && props.item.outputvourcher.status === 1">Đã giao tại shop</span>
                  <span class="label label-info" v-if="props.item.outputvourcher.delivery_type === 3 && props.item.outputvourcher.status === 1">Đã gửi bưu điện</span>
                  <span class="label label-info" v-if="(props.item.outputvourcher.delivery_type === 0 || props.item.outputvourcher.delivery_type === 4 || props.item.outputvourcher.delivery_type === 5) && props.item.outputvourcher.status === 1">{{props.item.outputvourcher.shipment_status_text}}</span>
                  <!-- <span class="label label-info" v-if="props.item.outputvourcher.delivery_type === 5 && props.item.outputvourcher.status === 1">Đã gửi JTEXPRESS</span> -->
                </div>
                
                
                <div class="col-xs-12 col-md-6">
                  <span class="text-bold">Trạng thái giao: </span>  
                  <span class="label label-success" v-if="props.item.outputvourcher.payment_status === 1">Giao thành công</span>
                  <span class="label label-default" v-else-if="props.item.outputvourcher.payment_status === 0">Chưa giao</span>
                  <span class="label label-warning" v-else-if="props.item.outputvourcher.payment_status === 2">Giao 1 phần</span>
                  <span class="label label-danger" v-else-if="props.item.outputvourcher.payment_status === 3">Trả về</span>
                  <span class="label label-danger" v-else>Không xác định</span> 
                </div>
                
                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Tên KH: </span>{{props.item.outputvourcher.customer_name}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">ĐT KH: </span>{{props.item.outputvourcher.customer_phone}}
                </div>
                
                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Tiền thu khách: </span>{{parseFloat(props.item.outputvourcher.total_payment) | numFormat}}
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="text-bold">ĐC KH: </span>{{props.item.outputvourcher.customer_address}}
                </div>              
              </template>

              <template slot-scope="props" slot='payment_info'>
                <div class="col-xs-12 col-md-12" v-if="props.item.outputvourcher_payments.payment_note">
                  <span class="text-bold">Ghi chú xác nhận: </span><span class="text-bold text-red">{{props.item.outputvourcher_payments.payment_note}}</span>
                </div>
                
                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Tổng SL trên đơn: </span>{{props.item.outputvourcher_payments.payment_total_count}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Tổng SL xác nhận: </span>{{props.item.outputvourcher_payments.payment_total_countconfirm}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Tổng thu khách: </span>{{parseFloat(props.item.outputvourcher_payments.payment_total_money) | numFormat}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Tổng tiền hàng: </span>{{parseFloat(props.item.outputvourcher_payments.payment_total_cost) | numFormat}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Ship gửi: </span>{{parseFloat(props.item.outputvourcher_payments.payment_fee) | numFormat}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Ship hoàn: </span>{{parseFloat(props.item.outputvourcher_payments.payment_fee_return) | numFormat}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Người xác nhận: </span>{{props.item.outputvourcher_payments.payment_user.name}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Ngày xác nhận: </span>{{props.item.outputvourcher_payments.payment_confirm_date}}
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Chiết khấu giới thiệu: </span> <span class="text-bold text-red">{{parseFloat(props.item.outputvourcher_payments.payment_broker_discount) | numFormat}} </span>
                </div>

                <div class="col-xs-6 col-md-6">
                  <span class="text-bold">Tiền thực tế CTV: </span> <span class="text-bold text-red">{{parseFloat(props.item.outputvourcher_payments.payment_total_money - props.item.outputvourcher_payments.payment_total_cost - props.item.outputvourcher_payments.payment_fee - props.item.outputvourcher_payments.payment_fee_return) | numFormat}} </span>
                </div>

                <div class="col-xs-12 col-md-12">
                  <span class="label label-success" v-if="props.item.outputvourcher_payments.payment_status === 1">Đã giao thành công</span>
                  <span class="label label-info" v-else-if="props.item.outputvourcher_payments.payment_status === 2">Giao 1 phần</span>
                  <span class="label label-warning" v-else-if="props.item.outputvourcher_payments.payment_status === 3">Trả về</span>
                </div>
              </template>
            </data-viewer>
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import VuejsDialog from "vuejs-dialog"
import DataViewer from '../../../components/DataViewer'
import Select2 from '../../../components/Select2New.vue'

import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'
import datePicker from 'vue-bootstrap-datetimepicker'

export default {
    name: 'PaymentvourcherDetail',
    components: {
      VuejsDialog, DataViewer, Select2, datePicker
    },
    data() {
        return {
          title: 'Chi tiết thanh toán',
          formErrors: {},
          data:[],
          id: '',
          thead: [
            {
              key: 'outputvourcher_info',
              title: 'Thông tin xuất hàng',
              class: 'col-xs-12 col-md-6'
            },
            {
              key: 'payment_info',
              title: 'Thông tin xác nhận',
              class: 'col-xs-12 col-md-6'
            }
          ]
        }
    },
    computed: {
      total_broker_discount: function () {
        var amount_sum = 0
        if(this.data){
          for (var i = 0; i < this.data.paymentvourcher_details.length; i++) {
            amount_sum += parseFloat(this.data.paymentvourcher_details[i].outputvourcher_payments.payment_broker_discount)
          }
        }
        return amount_sum
      },
    },
    mounted(){
        this.id = this.$route.params.id
        this.title = this.title +' - ' + this.id
        this.$store.dispatch('setPageTitle', this.title);
        this.getData();
    },
    methods: {
      goBack(){
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push('/')
      },
      getData() {
        NProgress.start()
        var vm = this
        axios.get('/api/ctv/paymentvourcher/select/'+vm.id)
            .then(function(response) {
                NProgress.done()
                vm.data = response.data.data
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      },
      confirmPayment() {
        var $this = this
        this.$dialog.confirm('Bạn có muốn xác nhận thanh toán?', {
            loader: true,
            okText: 'Đồng ý',
            cancelText: 'Hủy bỏ'
        })
        .then((dialog) => {
            $this.confirmPaymentSave()
            dialog.close()
        });
      },
      confirmPaymentSave(){
        var vm = this
        var paramsTempp = {
          'paymentvourcher_id': vm.data.paymentvourcher_id
        }
        axios['put']('/api/ctv/paymentvourcher/update_confirm', paramsTempp)
        .then(function (response) {
            var result = response.data
            if(result.success==false){
              vm.$snotify.error(result.message, 'Lỗi')
            }else{
              vm.$snotify.success(result.message, 'Success')
              location.reload()
            }
        })
        .catch(function (error) {
          vm.$snotify.error('lỗi vui lòng kiểm tra và thử lại', 'Lỗi')
          console.log(error)
        })
      },
      exportExcel() {
        NProgress.start()
        var vm = this
        axios.get('/api/ctv/paymentvourcher/export_excel/'+vm.id)
            .then(function(response) {
                NProgress.done()
                vm.data = response.data.data
            })
            .catch(function(error) {
                console.log(error)
                NProgress.done()
            })
      }
    }
}
</script>