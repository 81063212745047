<template>
    <!-- /btn-group -->
    <div class="input-group">
        <input type="text" 
            class="form-control" 
            v-model="params.keyword"   
            v-on:keyup.enter="seach"
            :placeholder="placeholder">
        <div class="input-group-btn">
            <button type="button" class="btn btn-default btn-flat" @click="seach">
                <i class="glyphicon glyphicon-search"></i>
            </button>
            <button type="button" class="btn btn-default btn-flat"><i class="glyphicon glyphicon-qrcode"></i></button>
        </div>
        <!-- /btn-group -->
            <!-- Modal -->
        <div class="modal fade in" style="padding-right: 0px;" id="modal-form-product-seach"  data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span></button>
                        <h4 class="modal-title">Tìm kiếm sản phẩm</h4>
                    </div>
                    <div class="modal-body">
                        <product-view
                            ref="formProductView"
                            @productSelected="productSelected">
                        </product-view>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Đóng</button>
                        <span class="pull-right text-red" v-if="parseFloat(QuantitySelected)>0">Bạn đã chọn tổng: {{parseFloat(QuantitySelected)}}</span>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.modal -->
    </div>
    <!-- /input-group -->
</template>

<script>
import axios from 'axios'
import ProductView from './components/ProductView.vue'

    export default {
        name: 'ProductSeach',
        components: {
            ProductView
        },
        props: {
            value: null,
            placeholder: {
                type: String,
                default: 'Nhập mã sản phẩm hoặc model'
            },
            autoClearKeyword: {
                type: Boolean,
                default: true
            } 
        },
        data() {
            return {
                params: {
                    keyword: ''
                },
                QuantitySelected: 0
            }
        },
        methods: {
            seach() {
                var vm = this
                var products = []
                if(vm.params.keyword != null && vm.params.keyword.trim() != ''){
                    axios.get('/api/product/seach_sale_by_id'+ '?' + $.param(vm.params))
                    .then(function(response) {
                        products = response.data.data
                        if(products.length > 0) 
                        {
                            if(vm.autoClearKeyword){
                                vm.params.keyword = ''
                            }
                            vm.$emit('productSelected', products[0], 1)
                            return
                        }else{
                            vm.$refs.formProductView.open(vm.params.keyword);
                            vm.QuantitySelected = 0
                            $('#modal-form-product-seach').modal('show')
                        }
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
               }else{
                    vm.$refs.formProductView.open(this.params.keyword);
                    vm.QuantitySelected = 0
                    $('#modal-form-product-seach').modal('show')
               }
            },
            productSelected(product, quantity) {
                //$('#modal-form-product-seach').modal('hide')

                if(this.autoClearKeyword){
                    this.params.keyword = ''
                }
                this.$emit('productSelected', product, quantity)
                this.QuantitySelected = parseFloat(this.QuantitySelected) + parseFloat(quantity)
            }
        }
    }
</script>
<style lang="css" scoped>
.modal-dialog,
.modal-content {
    /* 80% of window height */
    height: 100%;
    border: 0;
}

.modal-body {
    /* 100% = dialog height, 120px = header + footer */
    max-height: calc(100% - 120px);
    overflow-y: scroll;
}
.modal-footer {
    position: sticky;
    bottom: 0px;
    background: white;
}
@media (min-width: 768px){
    .modal-dialog {
        margin-top: 10px;
    }
}
</style>