<template>
  <div class="row">
    <div class="col-xs-12">
        <!-- box -->
        <div class="box">
          <!-- box-body -->
          <div class="box-body">
            <div class="row">
                <div class="col-md-4 col-sm-12 col-xs-12">
                     <!-- box Thông tin -->
                    <div class="box box-primary">
                        <!-- box-header -->
                        <div class="box-header with-border">
                            <h3 class="box-title">Thông tin đơn hàng</h3>
                            <div class="box-tools pull-right">
                                <button type="button" 
                                    class="btn btn-box-tool" 
                                    data-toggle="modal" 
                                    @click="getOutputVourcherLog()"
                                    data-target="#modal-form-log"><i class="glyphicon glyphicon-hourglass"></i>
                                </button>
                            </div>
                        </div>
                        <!-- /.box-header -->
                        <!-- box-body -->
                        <div class="box-body">
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Họ tên: </span>  
                                    <span>{{form.customer_name}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Điện thoại: </span>  
                                    <span class="text-bold text-red">{{form.customer_phone}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Nick name: </span>  
                                    <span>{{form.customer_nickname}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Địa chỉ: </span>  
                                    <span>{{form.customer_address}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <hr>
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Cữa hàng: </span>  
                                    <span v-if="form.shop">{{form.shop.name}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Nguồn đơn: </span>  
                                    <span v-if="form.source_address">{{form.source_address.name}}</span> 
                                </div>

                                <div class="col-md-12 col-xs-12" v-if="form.partner">
                                    <span class="text-bold">Cộng tác viên: </span>  
                                    <span class="text-red">{{form.partner.partner_name}} - {{form.partner.partner_phone}}</span> 
                                </div>

                                <div class="col-md-12 col-xs-12" v-if="form.partner">
                                    <span class="text-bold">Ghi chú giá: </span>  
                                    <span class="text-red">{{form.partner.partner_description_price}}</span> 
                                </div>

                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Hình thức đối soát: </span>  
                                    <span v-if="form.payment_not_auto_confirm == 0">Tự động</span> 
                                    <span v-else>Không tự động</span> 
                                </div>

                                <div class="col-md-12 col-xs-12" v-if="form.payment_note">
                                    <span class="text-bold">Ghi chú thanh toán: </span>  
                                    <span class="text-red">{{form.payment_note}}</span> 
                                </div>

                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Hình thức giao: </span>  
                                    <span v-if="form.delivery_type == 0">Đơn vị vận chuyển - GHTK</span> 
                                    <span v-else-if="form.delivery_type == 3">Đơn vị vận chuyển - Bưu điện</span> 
                                    <span v-else-if="form.delivery_type == 4">Đơn vị vận chuyển - VNCPOST</span>
                                    <span v-else-if="form.delivery_type == 5">Đơn vị vận chuyển - JTEXPRESS</span>
                                    <span v-else-if="form.delivery_type == 1">Shop giao</span> 
                                    <span v-else>Lấy tại shop</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Ngày tạo đơn: </span>  
                                    <span>{{moment(form.created_at).format('DD/MM/YYYY hh:mm')}}</span> 
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <span class="text-bold">Ngày gửi: </span>
                                    <span>{{moment(form.shipment_date).format('DD/MM/YYYY hh:mm')}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Nhân viên: </span>  
                                    <span v-if="form.output_user">{{form.output_user.name}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Tình trạng đơn: </span>  
                                    <span class="label label-success" v-if="form.status === 1">Hoàn tất</span>
                                    <span class="label label-default" v-else-if="form.status === 2">Chưa chốt</span>
                                    <span class="label label-primary" v-else-if="form.status === 3">Đã chốt</span>
                                    <span class="label label-info" v-else-if="form.status === 4">Đang soạn</span>
                                    <span class="label label-warning" v-else-if="form.status === 5">Đang soạn - chờ hàng</span>
                                    <span class="label label-primary" v-else-if="form.status === 6">Soạn xong</span>
                                    <span class="label label-danger" v-else>Đã hủy</span> 
                                </div>

                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Ghi chú: </span>  
                                    <span class="text-bold text-info">{{form.note}}</span>
                                </div>

                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Ghi chú nội bộ: </span>  
                                    <span class="text-bold text-red">{{form.note_internal}}</span>
                                </div>

                                <!-- <div class="col-md-12 col-xs-12" v-if="form.customer_link != null && form.customer_link.length > 0">
                                    <span class="text-bold">Link khách hàng: </span>  
                                    <a v-bind:href="form.customer_link" target = "_blank">
                                        {{ customer_link_text }}
                                    </a>
                                </div> -->

                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Nhân viên cập nhật: </span>  
                                    <span v-if="form.status_update_user">{{form.status_update_user.name}}</span>
                                </div>
                                <div class="col-md-12 col-xs-12" v-if="form.delivery_type == 0 || form.delivery_type == 4 || form.delivery_type == 5">
                                    <hr>
                                </div>
                                <div class="col-md-12 col-xs-12" v-if="form.delivery_type == 0 || form.delivery_type == 4 || form.delivery_type == 5">
                                    <span class="text-bold">Mã vận đơn: </span>  
                                    <span class="text-bold text-red">{{form.shipment_id}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12" v-if="form.delivery_type == 0 || form.delivery_type == 4 || form.delivery_type == 5">
                                    <span class="text-bold">Trạng thái VĐ: </span>  
                                    <span class="text-bold text-red">{{form.shipment_status_text}}</span> 
                                    <button type="button" 
                                        class="btn btn-default btn-flat" 
                                        @click="updateStatusShipment()"
                                        v-if="form.shipment_id && form.delivery_type == 0"><i class="glyphicon glyphicon-refresh"></i></button>

                                    <button type="button" 
                                        class="btn btn-default btn-flat" 
                                        @click="updateStatusShipment_JTEXPRESS()"
                                        v-if="form.shipment_id && form.delivery_type == 5"><i class="glyphicon glyphicon-refresh"></i></button>
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <hr>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                    <span class="text-bold">Tổng SL: </span>  
                                    <span>{{totalQuantity}}</span> 
                                </div>
                                <div class="col-md-8 col-xs-12">
                                    <span class="text-bold">Tổng tiền hàng: </span>  
                                    <span>{{parseFloat(form.total_amount)| numFormat}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Tổng vận chuyển: </span>  
                                    <span>{{parseFloat(form.shipping_cost)| numFormat}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Tổng vận chuyển TT: </span>  
                                    <span>{{parseFloat(shipping_cost_tt)| numFormat}}</span> 
                                </div>
                                <div class="col-md-12 col-xs-12">
                                    <span class="text-bold">Phải thu khách: </span>  
                                    <span>{{parseFloat(form.total_payment)| numFormat}}</span> 
                                </div>
                            </div>
                        </div>
                        <!-- ./box-body -->
                    </div>
                </div>
                <div class="col-md-8 col-sm-12 col-xs-12">
                    <!-- box Thông tin -->
                    <div class="box box-primary">
                        <!-- box-header -->
                        <div class="box-header with-border">
                            <h3 class="box-title">Danh sách sản phẩm</h3>
                        </div>
                        <!-- /.box-header -->
                        <!-- box-body -->
                        <div class="box-body">
                            <div class="row">
                                <!-- row-product -->
                                <div class="col-md-12 col-sm-12 col-xs-12" v-for="item in form.outputvourcher_details" :key="item.id">
                                    <output-vourcher-item
                                    :item="item"
                                    @changeddata="getOutputVourcher(form.id)"
                                    v-show="item.status != 0">
                                    </output-vourcher-item>
                                </div>
                                <!-- /.row-product -->
                                <div class="col-md-6 col-sm-6 col-xs-6">
                                    <button @click="updateStatus(5)" type="button" class="btn btn-warning btn-block btn-flat" v-show="form.status == 3 || form.status == 4"><i class="glyphicon glyphicon-ok"></i> THIẾU HÀNG</button>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6">
                                    <button @click="updateStatus(6)" type="button" class="btn btn-success btn-block btn-flat" v-show="form.status != 6 && form.status != 1"><i class="glyphicon glyphicon-ok"></i> HOÀN THÀNH</button>
                                </div>
                                
                                <!-- <div class="col-md-6 col-sm-6 col-xs-6">
                                    <button @click="updateStatus(1)" type="button" class="btn btn-success btn-block btn-flat" v-show="form.status == 6"><i class="glyphicon glyphicon-ok"></i> HOÀN THÀNH</button>
                                </div> -->
                            </div>
                        </div>
                        <!-- ./box-body -->
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <a class="btn btn-default" @click="goBack()"><i class="glyphicon glyphicon-arrow-left"></i> Quay lại</a>
                    <a class="btn btn-default" @click="print('print')"><i class="fa fa-print"></i> In hóa đơn <span class="text-red" v-if="form.isprint == true">(Đã in)</span></a>
                    <a class="btn btn-default" @click="print('print_a7')"><i class="fa fa-print"></i> In hóa đơn A7<span class="text-red" v-if="form.isprint == true">(Đã in)</span></a>
                </div>
            </div>
                
          </div>
          <!-- /.box-body -->
        </div>
        <!-- /.box -->
    </div>

    <!-- Modal log status-->
    <div class="modal fade in" id="modal-form-log">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Lịch sử thao tác</h4>
        </div>
        <div class="modal-body">
           <data-viewer :thead="thead" :data="outputvourcherlog">
                <template slot-scope="props" slot='status_update_date'>
                    <span class="text-bold hidden-lg">Ngày: </span>{{props.item.status_update_date}}
                </template>
                <template slot-scope="props" slot='status_update_user.name'>
                    <span class="text-bold hidden-lg" v-if="props.item.status_update_user">Nhân viên: </span>{{props.item.status_update_user.name}}
                </template>
                <template slot-scope="props" slot='status'>
                    <span class="label label-success" v-if="props.item.status === 1">Hoàn tất</span>
                    <span class="label label-default" v-else-if="props.item.status === 2">Chưa chốt</span>
                    <span class="label label-primary" v-else-if="props.item.status === 3">Đã chốt</span>
                    <span class="label label-info" v-else-if="props.item.status === 4">Đang soạn</span>
                    <span class="label label-warning" v-else-if="props.item.status === 5">Đang soạn - chờ hàng</span>
                    <span class="label label-primary" v-else-if="props.item.status === 6">Soạn xong</span>
                    <span class="label label-danger" v-else>Đã hủy</span> 
                </template>
           </data-viewer>
            <div class="form-group">
                <button type="button" class="btn btn-primary btn-block" data-dismiss="modal">Thoát</button>
            </div>
        </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->

    <!-- Mẫu in bill-->
    <bill :data="form" :isobject="true"></bill>
    <!-- ./Mẫu in bill-->

    <!-- Mẫu in bill a7-->
    <BillA7 :data="form" :isobject="true"></BillA7>
    <!-- ./Mẫu in bill a7-->

  </div>
</template>
<script>
import axios from 'axios'
import NProgress from 'nprogress'
import DataViewer from '../../../components/DataViewer'
import OutputVourcherItem from './components/OutputVourcherItem'
import Bill from '../../reports/bill';
import BillA7 from '../../reports/BillA7';
export default {
    name: 'OutputVourcherDetail',
    components: {
      OutputVourcherItem, 
      DataViewer, 
      Bill,
      BillA7
    },
    data() {
        return {
            title: 'Đơn hàng',
            form: {},
            outputvourcherlog:[],
            id: '',
            thead: [
            {
              key: 'status_update_date',
              title: 'Ngày',
              class: 'col-xs-12 col-md-3'
            },
            {
              key: 'status_update_user.name',
              title: 'Nhân viên',
              class: 'col-xs-6 col-md-6'
            },
            {
              key: 'status',
              title: 'Trạng thái',
              class: 'col-xs-6 col-md-3'
            }
          ]
        }
    },
    computed: {
        totalQuantity(){
            var totalQuantityTemp = 0
            if(typeof(this.form.outputvourcher_details) != 'undefined'){
                for (var i = 0; i < this.form.outputvourcher_details.length; i++) {
                    if(this.form.outputvourcher_details[i].status != 0 ){
                        totalQuantityTemp = totalQuantityTemp + parseFloat(this.form.outputvourcher_details[i].quantity)
                    }
                }
            }
            return totalQuantityTemp
        },
        shipping_cost_tt: function () {
            var shipping_cost_tt = 0
            if(this.form.delivery_type==0){
                shipping_cost_tt = parseFloat(this.form.shipment_fee)
            }else{
                shipping_cost_tt = parseFloat(this.form.shipping_cost)
            }
          return shipping_cost_tt
        },
        customer_link_text(){
            var customer_link_text_tmp = ''
            if(this.form.customer_link != null && this.form.customer_link.length > 0){
                customer_link_text_tmp = this.form.customer_link.substr(0, 50)
            }
            return customer_link_text_tmp
        }
    },
    mounted(){
        NProgress.start()
        this.id = this.$route.params.id
        this.title = this.title +' - ' + this.id
        this.$store.dispatch('setPageTitle', this.title)
        this.getOutputVourcher(this.id)
        NProgress.done()
    },
    methods: {
        goBack(){
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push('/')
        },
        getOutputVourcherLog()
        {
            var vm = this
            axios.get('/api/inventory/output/outputvourcherlog/' + vm.id)
                .then(function(response) {
                    vm.outputvourcherlog = response.data.data
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        async getOutputVourcher(OutputVourcherID)
        {
            var vm = this
            await axios.get('/api/inventory/output/select/' + OutputVourcherID)
                .then(function(response) {
                    vm.form = response.data.data
                    vm.form.outputvourcher_details.sort(function(a, b){return a.product.model_id - b.product.model_id})
                })
                .catch(function(error) {
                    console.log(error)
                })
        },
        updateStatus(status){
            if(status==6){
                var indexTemp= this.form.outputvourcher_details.findIndex(o => o.status != 1 && o.status != 0)
                if(indexTemp>=0){
                    this.$dialog.alert('Chưa soạn xong hàng, vui lòng kiểm tra và hoàn tất hàng').then(function(dialog) {
                       
                    });
                    return;
                }
            }
            var vm = this
            axios['put']('/api/inventory/output/update_status/'+vm.id+'/'+status)
            .then(function (response) {
               vm.$snotify.success('Cập nhật trạng thái thành công!', 'Success')
               vm.getOutputVourcher(vm.id)
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        print(printName) {
            var vm = this
            this.$htmlToPaper(printName,() => {
                axios['put']('/api/inventory/output/sale/update_outputvourcher_isprint/'+vm.id)
                .then(function (response) {
                    vm.form.isprint = true
                })
                .catch(function (error) {
                    console.log(error)
                })
                //console.log('In thành công');
            });
        },
        updateStatusShipment() {
            var vm = this
            axios['post']('/api/logistics/shipment/ghtk/status/'+vm.id)
            .then(function (response) {
               var result = response.data
               if(result.success==true){
                   vm.form.shipment_status = result.data.status
                   vm.form.shipment_status_text = result.data.status_text
                   vm.$snotify.success('Cập nhật trạng thái thành công!', 'Success')
               }else{
                   vm.$snotify.error(result.message, 'Lỗi cập nhật')
               }
            })
            .catch(function (error) {
                vm.$snotify.error('Không xử lý được yêu cầu cập nhật', 'Lỗi cập nhật')
                console.log(error)
            })
        },
        updateStatusShipment_JTEXPRESS() {
            var vm = this
            axios['post']('/api/logistics/shipment/jtexpress/status/'+vm.id)
            .then(function (response) {
               var result = response.data
               if(result.success==true){
                   //vm.form.shipment_status = result.data.status
                   //vm.form.shipment_status_text = result.data.status_text
                   vm.$snotify.success('Cập nhật trạng thái thành công!', 'Success')
               }else{
                   vm.$snotify.error(result.message, 'Lỗi cập nhật')
               }
            })
            .catch(function (error) {
                vm.$snotify.error('Không xử lý được yêu cầu cập nhật', 'Lỗi cập nhật')
                console.log(error)
            })
        }
    }
}
</script>
<style lang="css" scoped>
.info-box-icon>img {
    width: 100%;
    max-height: 90px;
    vertical-align: unset;
}
.info-box-content{
    padding: 0px;
}
.info-box-text{
    white-space: unset;
}
.info-box {
    padding-bottom: 10px;
}
</style>
