export default {
    id: '',
    input_type_id: 0,
    input_date: '',
    input_user_id: 0,
    total_payment: 0,
    total_amount: 0,
    shipping_cost: 0,
    customer_id: 0,
    customer_name: '',
    customer_nickname: '',
    customer_phone: '',
    customer_address: '',
    note: '',
    voucherconcern: '',
    status: 1,
    status_update_date: '',
    status_update_user_id: 0,

    inputvourcher_details: [],

    total_quantity: 0,
}